'use client';

import isEqual from 'lodash/isEqual';
import { useParams } from 'next/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';
import { FC, useRef, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Pagination from '@mui/material/Pagination';
import { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';
import { useGetProducts, useGetProductsTags, useGetProductsCategories } from 'src/api/product';

import ProductFilters from 'src/sections/products/product-filters';

import { ComponentTypeProps } from 'src/types/page-generator';
import { Ib2bProduct, Ib2bTableFilter, Ib2bTableFilterValue } from 'src/types/b2b';

import { ITag } from '../../../../types/tag';
import { useRouter } from '../../../../routes/hooks';
import { ICategoryItem } from '../../../../types/product';
import { useGetMerchantsTags } from '../../../../api/merchant';
import { ProductCard2 } from '../../../products/product-card2';
import { useResponsive } from '../../../../hooks/use-responsive';
import { API } from '../../../../helpers/api';
import { getKosher, getLocation } from '../../../../api/attributes';

interface ProductOpenPrice {
  price: number;
  title: string;
  value: string;
}

const Products: FC<ComponentTypeProps> = ({ block }) => {
  const sliderRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const { dispatch, state } = useAppContext();
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const { t } = useTranslate();
  const LIMIT = block?.settings?.enableRotation
    ? block?.settings?.rotationLimit
    : block?.settings?.limit || 8;
  const { communicationWithProducts } = state;
  const isMobile = useResponsive('down', 'sm');
  const params = useParams();
  const router = useRouter();
  const [page, setPage] = useState(1);
  const { user } = useAuthContext();
  const OPTIONS = ['1', '2', '3'];
  const { id, title } = useParams();
  const { smbAccount } = state;
  const combinedBlock: any = block?.products || [];
  const actionButtons: any = block?.actionButtons || [];
  const [checked, setChecked] = useState(false);
  const localCategories =
    block?.settings?.enableCategoryFilter && block?.settings?.categories
      ? block?.settings?.categories
      : [];
  const localProductsTags =
    block?.settings?.enableTagsFilter && block?.settings?.productsTags
      ? block?.settings?.productsTags
      : [];

  const localTagsIds = localProductsTags.map((tag: { value: string; }) => tag.value);

  const { products: localProducts } = useGetProducts({
    smbAccount: state.smbAccount.id,
  });
  const [mainProducts, setCombinedBlock] = useState([]);
  const [productOpenPrices, setProductOpenPrices] = useState<ProductOpenPrice[]>([]);
  const { merchantsTags } = useGetMerchantsTags({});
  const { productsCategories } = useGetProductsCategories({});
  const { productsTags: filterProductsTags } = useGetProductsTags({});
  const handleOpenPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    product: Ib2bProduct,
    maxPrice: number,
    minPrice: number,
  ) => {
    const { value } = event.target;
    const newPrice: ProductOpenPrice = {
      value: product.id,
      title: product.title,
      price: parseInt(value, 10),
    };

    if (newPrice.price > maxPrice) {
      newPrice.price = maxPrice;
    }

    setProductOpenPrices((prevState: ProductOpenPrice[]) => {
      const updatedPrices = [...prevState.filter((price) => price.value !== product.id), newPrice];
      dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload: updatedPrices });
      return updatedPrices;
    });

    const localDataString: string | null = typeof window !== 'undefined' ? localStorage.getItem('openPrice') : '';

    if (localDataString) {
      const localData: ProductOpenPrice[] = JSON.parse(localDataString);
      const updated: ProductOpenPrice[] = [
        ...localData.filter((price: ProductOpenPrice) => price.value !== product.id),
        newPrice,
      ];
      localStorage.setItem('openPrice', JSON.stringify(updated));
    } else {
      localStorage.setItem('openPrice', JSON.stringify([newPrice]));
    }
  };

  useEffect(() => {
    getKosher(dispatch);
    getLocation(dispatch);
  }, []);

  useEffect(() => {
    if (block?.openPriceProducts) {
      const payload = block?.openPriceProducts;
      dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload });
      dispatch({
        type: ActionTypes.SET_OPEN_PRICE_PRODUCTS_FROM_BUILDER,
        payload,
      });
    }
  }, [block?.openPriceProducts]);

  let priceType: any = '';
  let priceChecked: string = '';
  if (block?.settings?.enableSwitch) {
    if (checked) {
      priceChecked = 'business_price';
    } else if (block?.settings?.enableCategoryFilter) {
      priceChecked = '';
    } else priceChecked = 'price';
  }
  if (
    block &&
    block.settings &&
    !block.settings.enableSwitch &&
    block?.settings?.enableCategoryFilter &&
    block?.settings?.enableTagsFilter
  ) {
    priceType = {};
  } else {
    priceType = {
      priceType: priceChecked,
    };
  }

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const updatedCombinedBlock = combinedBlock?.map((item: any) => {
      let updatedItem = { ...item };
      localProducts?.forEach((product: Ib2bProduct) => {
        if (updatedItem.id === product.id) {
          updatedItem = {
            ...updatedItem,
            prices: product.prices,
            categories: product.categories,
          };
        }
      });
      return updatedItem;
    });
    setCombinedBlock(updatedCombinedBlock);
  }, [localProducts]);

  const defaultFilters: Ib2bTableFilter = {
    name: '',
    type: [],
    category: [],
    area: [],
    price: [0, 3000],
    search: '',
  };
  const swiperRef = useRef<SwiperCore | null>(null);

  const [filters, setFilters] = useState(defaultFilters);

  const merchantTagIds: string[] = [];
  const productTagIds: string[] = [];
  const categoryIds: string[] = [];
  filters.type.forEach((fType: string) =>
    productTagIds.push(String(filterProductsTags.find((mt: ITag) => mt.name === fType)?.id)),
  );
  filters.area.forEach((a: string) =>
    merchantTagIds.push(String(merchantsTags.find((pt: ITag) => pt.name === a)?.id)),
  );
  filters.category.forEach((c: string) =>
    categoryIds.push(String(productsCategories.find((cat: ICategoryItem) => cat.title === c)?.id)),
  );
  const currentCategories: string[] = [];
  // localCategories.forEach((a: ICategoryItem) => {
  //   currentCategories.push(String(a.value));
  // });

  const currentProductsTags: string[] = [];
  if (!communicationWithProducts) {
    localCategories.forEach((a: ICategoryItem) => {
      currentCategories.push(String(a.value));
    });
  }

  if (communicationWithProducts) {
    currentCategories.push(state?.activeCategory && state?.activeCategory[0]?.id);
  }
  const categories = categoryIds?.length ? categoryIds : currentCategories;

  const tags = productTagIds?.length ? productTagIds : currentProductsTags;

  const props =
    block?.settings?.isAlternativeDesign && !block?.settings?.partialSize
      ? {
        offset: (page - 1) * LIMIT || 0,
        search: filters.name,
        minPrice: filters.price[0],
        maxPrice: filters.price[1],
        categoryIds: title === 'category' ? [id] : categories,
        tagsIds: block?.settings?.enableTagsFilter ? localTagsIds : tags,
        merchantTagsIds: merchantTagIds,
        ...priceType,
        smbAccount: smbAccount.id,
        merchantId: title === 'merchant' ? id : '',
      }
      : {
        limit: LIMIT,
        offset: (page - 1) * LIMIT || 0,
        search: filters.name,
        minPrice: filters.price[0],
        maxPrice: filters.price[1],
        categoryIds: title === 'category' ? [id] : categories,
        tagsIds: block?.settings?.enableTagsFilter ? localTagsIds : tags,
        merchantTagsIds: merchantTagIds,
        ...priceType,
        smbAccount: smbAccount.id,
        merchantId: title === 'merchant' ? id : '',
      };

  const { products, totalCount } = useGetProducts({ ...props });

  const canReset = !isEqual(defaultFilters, filters);
  const dataFiltered = applyFilter({
    inputData: products,
    filters,
  });
  const dataFilteredCustom = applyFilter({
    inputData: mainProducts,
    filters,
  });

  const handleFilters = (name: string, filterValue: Ib2bTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };
  const handleResetCategory = () => {
    setFilters((prevState) => ({
      ...prevState,
      category: [],
    }));
  };

  const handleResetType = () => {
    setFilters((prevState) => ({
      ...prevState,
      type: [],
    }));
  };

  const handleResetArea = () => {
    setFilters((prevState) => ({
      ...prevState,
      area: [],
    }));
  };

  useEffect(() => {
    setPage(1);
  }, [filters, state?.activeCategory]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const settings = {
    // dots: !(block?.settings?.isAlternativeDesign && !isMobile),
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: !isMobile ? 6 : 3,
    slidesToScroll: 1,
  };

  const filterView = (
    <>
      {block?.settings?.enableFilter &&
        !block?.settings?.customMode &&
        !block?.settings?.enableCategoryFilter &&
        !block?.settings?.enableTagsFilter && (
          <ProductFilters
            filters={filters}
            block={block}
            onFilters={handleFilters}
            canReset={canReset}
            handleResetCategory={handleResetCategory}
            handleResetType={handleResetType}
            handleResetArea={handleResetArea}
            // typeOption={state.productsTags.map((mTag: ITag) => mTag.name)}
            // areaOption={state.merchantsTags.map((mTag: ITag) => mTag.name)}
            typeOption={state?.kosher}
            areaOption={state?.location}
            categoryOption={productsCategories.map((mTag: ITag) => mTag.title)}
          />
        )}
    </>
  );

  const ScrollbarContainer = styled('div')({
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  });

  const handleFilterCategory = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      handleFilters(
        'category',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,
      );
    },
    [handleFilters],
  );

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFilters('name', event.target.value);
    },
    [handleFilters],
  );

  const handleClick = (categoryTitle: string) => {
    const event = {
      target: { value: [categoryTitle] },
    } as SelectChangeEvent<string[]>;

    handleFilterCategory(event);
  };

  return (
    <>
      {block?.settings?.enableSwitch &&
        !block?.settings?.customMode &&
        !block?.settings?.enableCategoryFilter &&
        !block?.settings?.enableTagsFilter && (
          <FormControlLabel
            control={<Switch checked={checked} onChange={handleChange} />}
            label={checked ? 'B2B' : 'B2C'}
          />
        )}
      {block?.settings?.enableFilter &&
        !block?.settings?.customMode &&
        !block?.settings?.enableCategoryFilter &&
        !block?.settings?.enableTagsFilter && (
          <ProductFilters
            filters={filters}
            block={block}
            onFilters={handleFilters}
            canReset={canReset}
            handleResetCategory={handleResetCategory}
            handleResetType={handleResetType}
            handleResetArea={handleResetArea}
            typeOption={state?.kosher}
            areaOption={state?.location}
            categoryOption={productsCategories.map((mTag: ITag) => mTag.title)}
          />
        )}
      <Grid container spacing={3} rowGap={3} justifyContent="center">
        {block?.settings?.customMode ? (
          <>
            {!block?.settings?.enableSlider ? (
              <>
                {dataFilteredCustom?.map((item: any, idx: number) => {
                  const product: any = item || [];
                  return (
                    <ProductCard2
                      key={idx}
                      block={item}
                      localSettings={block}
                      product={product}
                      mode="custom"
                      openPriceProducts={block?.openPriceProducts}
                    />
                  );
                })}
              </>
            ) : (
              <Box
                width={0.95}
                m={2}
                sx={{
                  '& .MuiGrid-root': { maxWidth: '100%' },
                  '& .swiper-wrapper': { justifyContent: 'center' },
                }}
              >
                <Swiper
                  speed={500}
                  slidesPerView="auto"
                  loop
                  mousewheel={{
                    forceToAxis: true,
                    sensitivity: 1,
                    releaseOnEdges: true,
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {dataFilteredCustom?.map((item: any, ind: number) => {
                    const product: any = item || [];
                    return (
                      <SwiperSlide
                        style={{
                          width: '25%',
                          padding: 3,
                        }}
                      >
                        <ProductCard2
                          key={ind}
                          block={item}
                          localSettings={block}
                          product={product}
                          mode="custom"
                          openPriceProducts={block?.openPriceProducts}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Box>
            )}
          </>
        ) : (
          <>
            {!block?.settings?.enableSlider ? (
              <>
                {dataFiltered?.map((product: Ib2bProduct, i: number) => (
                  <ProductCard2
                    key={i}
                    // @ts-ignore
                    block={block}
                    product={product}
                    mode="notCustom"
                    openPriceProducts={block?.openPriceProducts}
                  />
                ))}
              </>
            ) : (
              <Box
                width={0.95}
                m={2}
              >
                <Swiper
                  speed={500}
                  slidesPerView={isMobile ? 1 : 3}
                  loop
                  mousewheel={{
                    forceToAxis: true,
                    sensitivity: 1,
                    releaseOnEdges: true,
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {dataFiltered?.map(
                    (product: Ib2bProduct, index: number) => (
                      <SwiperSlide
                        style={{
                          width: 'auto',
                          padding: 3,
                        }}
                      >
                        <ProductCard2
                          key={index}
                          block={block}
                          product={product}
                          mode="notCustom"
                          openPriceProducts={block?.openPriceProducts}
                        />
                      </SwiperSlide>
                    ),
                  )}
                </Swiper>
              </Box>
            )}
          </>
        )}
      </Grid>
      {!block?.settings?.customMode && (
        <Stack justifyContent="center" sx={{ mt: '40px' }}>
          {block?.settings?.enablePagination && (
            <Pagination
              onChange={handleChangePage}
              count={Math.round(state.productCount / LIMIT)}
              color="primary"
              sx={{ margin: '32px auto' }}
            />
          )}
        </Stack>
      )}
    </>

  );
};

function applyFilter({
                       inputData,
                       filters,
                     }: {
  inputData: Ib2bProduct[];
  filters: Ib2bTableFilter;
}) {
  const { name, area, price, category } = filters;

  // if (name) {
  //   inputData = inputData.filter(
  //     (product) => product.title.toLowerCase().indexOf(name.toLowerCase()) !== -1
  //   );
  // }
  //
  // if (area.length) {
  //   inputData = inputData?.filter((product: any) => area.includes(product.area));
  // }
  //
  // if (category.length) {
  //   inputData = inputData?.filter((product) => category.includes(product.category));
  // }

  // if (price) {
  //   inputData = inputData?.filter(
  //     (product) =>
  //       fTimestamp(product.price) >= fTimestamp(price[0]) &&
  //       fTimestamp(product.price) <= fTimestamp(price[1])
  //   );
  // }

  return inputData;
}

export default Products;
