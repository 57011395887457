'use client';

import { useMemo } from 'react';

import Typography from '@mui/material/Typography';

import { BlockType } from '../../../types/page-generator';
import { getContentValueFromProps } from '../../generate-page/utils';

type Props = {
  block: BlockType | undefined;
};

export const Greetings = ({ block }: Props) => {
  const getFieldValue = getContentValueFromProps(block);

  const title = useMemo(() => {
    if (block?.title) {
      return block?.title;
    }
    return getFieldValue('title');
  }, [block]);

  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [block?.settings.forceRtl]);

  const contentTitleTextColor = useMemo(
    () => getFieldValue('settings.contentTitleTextColor'),
    [block?.settings.contentTitleTextColor]
  );
  const contentTitleFontSize = useMemo(
    () => getFieldValue('settings.contentTitleFontSize'),
    [block?.settings.contentTitleFontSize]
  );
  const contentTitleFontWeight = useMemo(
    () => getFieldValue('settings.contentTitleFontWeight'),
    [block?.settings.contentTitleFontWeight]
  );
  const contentTitleFontStyle = useMemo(
    () => getFieldValue('settings.contentTitleFontStyle'),
    [block?.settings.contentTitleFontStyle]
  );
  const contentTitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentTitleTextDecoration'),
    [block?.settings.contentTitleTextDecoration]
  );

  const titleStylesAlignItems = useMemo(
    () => getFieldValue('settings.titleStylesAlignItems'),
    [block?.settings.titleStylesAlignItems]
  );

  const contentClasses = useMemo(
    () => getFieldValue('settings.contentClasses'),
    [block?.settings.contentClasses]
  );
  return (
    <Typography
      className={contentClasses}
      sx={{
        direction: !forceRtl ? 'rtl' : 'ltr',
        color: contentTitleTextColor,
        fontSize: contentTitleFontSize,
        fontWeight: contentTitleFontWeight,
        fontStyle: contentTitleFontStyle,
        textDecoration: contentTitleTextDecoration,
        textAlign: titleStylesAlignItems,
        width: '100%',
      }}
    >
      {title}
    </Typography>
  );
};
