'use client';

import { FC, useRef, useState } from 'react';

import { alpha, styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Stack,
  IconButton,
  Typography,
  IconButtonProps,
} from '@mui/material';

import Image from '../../../../components/image';
import { MotionContainer } from '../../../../components/animate';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { RightIcon } from '../../../../components/carousel/arrow-icons';
import Carousel, { CarouselDots } from '../../../../components/carousel';

type ItemProps = {
  id: string;
  image: string;
};

interface StyledIconButtonProps extends IconButtonProps {
  filled?: boolean;
  shape?: 'circular' | 'rounded';
  hasChildren?: boolean;
}

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.25),
}));

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'filled' && prop !== 'hasChildren' && prop !== 'shape',
})<StyledIconButtonProps>(({ filled, shape, hasChildren, theme }) => ({
  color: 'inherit',
  transition: theme.transitions.create('all', {
    duration: theme.transitions.duration.shorter,
  }),
  ...(shape === 'rounded' && {
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
  }),
  ...(!filled && {
    opacity: 0.48,
    '&:hover': {
      opacity: 1,
    },
  }),
  ...(filled && {
    color: alpha(theme.palette.common.white, 0.8),
    backgroundColor: alpha(theme.palette.grey[900], 0.48),
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[900],
    },
  }),
  ...(hasChildren && {
    zIndex: 9,
    top: '50%',
    position: 'absolute',
    marginTop: theme.spacing(-2.5),
  }),
}));

export const BannersCarousel: FC<ComponentTypeProps> = ({ block }) => {
  const carouselRef = useRef<Carousel>(null);
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');

  const combinedCarousel: any = block?.combinedCarousel || [];

  const [currentIndex, setCurrentIndex] = useState(
    theme.direction === 'ltr' ? combinedCarousel && combinedCarousel.length - 1 : 0
  );

  const carouselSettings = {
    speed: 800,
    dots: false,
    arrows: false,
    autoplay: false,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
    ...CarouselDots({
      sx: {
        top: 20,
        left: 20,
        position: 'absolute',
      },
    }),
  };

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };
  return (
    <Stack direction="row" width={isMobile ? '102%' : '100%'} gap={3} alignItems="center">
      <StyledIconButton
        onClick={handlePrev}
        sx={{
          color: block?.settings?.buttonsColor,
          left: 16,
        }}
      >
        <RightIcon icon="fa6-solid:circle-arrow-right" />
      </StyledIconButton>
      <Card sx={{ height: 'auto', width: '100%' }}>
        <Carousel ref={carouselRef} {...carouselSettings}>
          {combinedCarousel.map((app: any, index: number) => (
            <Box
              key={index}
              sx={{
                width: '300px',
                height:
                  block?.settings?.enableFirstText &&
                  block?.settings?.enableSecondText &&
                  block?.settings?.enableThirdText
                    ? '500px'
                    : '300px',
                position: 'relative',
              }}
            >
              <Image
                alt="present"
                src={app.image}
                sx={{
                  width: '100%',
                  height:
                    block?.settings?.enableFirstText &&
                    block?.settings?.enableSecondText &&
                    block?.settings?.enableThirdText
                      ? '70%'
                      : '100%',
                  padding: 1,
                  '& img': {
                    borderRadius: '5px',
                    objectFit: block?.settings?.objectFit,
                  },
                }}
              />
              <Stack
                gap={1}
                justifyContent="space-between"
                width={1}
                alignItems="flex-end"
                sx={{
                  display:
                    !block?.settings?.enableFirstText &&
                    !block?.settings?.enableSecondText &&
                    !block?.settings?.enableThirdText
                      ? 'none'
                      : '',
                  direction: 'rtl',
                  height: '30%',
                  // position: 'absolute',
                  // background: 'white',
                  // opacity: 0.5,
                  // bottom: 0,
                  px: 2,
                  py: 1,
                  width: 1,
                }}
              >
                {block?.settings?.enableFirstText && (
                  <Typography
                    sx={{
                      textAlign: 'end',
                      fontSize: block?.settings?.firstTitleFontSize,
                      width: isMobile ? '100%' : block?.settings?.firstTitleWidth,
                      color: block?.settings?.firstTitleTextColor,
                      fontWeight: block?.settings?.firstTitleFontWeight,
                      fontStyle: block?.settings?.firstTitleFontStyle,
                      textDecoration: block?.settings?.firstTitleTextDecoration,
                    }}
                  >
                    {app.firstTitle}
                  </Typography>
                )}
                {block?.settings?.enableSecondText && (
                  <Typography
                    sx={{
                      textAlign: 'end',
                      fontSize: block?.settings?.secondTitleFontSize,
                      width: isMobile ? '100%' : block?.settings?.secondTitleWidth,
                      color: block?.settings?.secondTitleTextColor,
                      fontWeight: block?.settings?.secondTitleFontWeight,
                      fontStyle: block?.settings?.secondTitleFontStyle,
                      textDecoration: block?.settings?.secondTitleTextDecoration,
                    }}
                  >
                    {app.secondTitle}
                  </Typography>
                )}
                {block?.settings?.enableThirdText && (
                  <Typography
                    sx={{
                      textAlign: 'end',
                      fontSize: block?.settings?.thirdTitleFontSize,
                      width: isMobile ? '100%' : block?.settings?.thirdTitleWidth,
                      color: block?.settings?.thirdTitleTextColor,
                      fontWeight: block?.settings?.thirdTitleFontWeight,
                      fontStyle: block?.settings?.thirdTitleFontStyle,
                      textDecoration: block?.settings?.thirdTitleTextDecoration,
                    }}
                  >
                    {app.thirdTitle}
                  </Typography>
                )}
              </Stack>
            </Box>
          ))}
        </Carousel>
      </Card>
      <StyledIconButton
        onClick={handleNext}
        sx={{
          color: block?.settings?.buttonsColor,
          right: 16,
        }}
      >
        <RightIcon icon="fa6-solid:circle-arrow-left" />
      </StyledIconButton>
    </Stack>
  );
};

type CarouselItemProps = {
  item: ItemProps;
  isActive?: boolean;
};

function CarouselItem({ item, isActive }: CarouselItemProps) {
  const { image } = item;

  return (
    <MotionContainer animate={isActive} sx={{ position: 'relative', padding: 1 }}>
      {/* <StyledOverlay /> */}
    </MotionContainer>
  );
}
