import React, { forwardRef } from 'react';
import globalConfig from 'content-config';

import Link from '@mui/material/Link';
import { Box, BoxProps } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import Image from 'src/components/image';

interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  src?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, src, ...other }, ref) => {
  const srcLogo = src ?? globalConfig.builder.logo;

  if (disabledLink) {
    return <Image src={srcLogo} alt="alt" />;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...other.sx,
        }}
        {...other}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
          {/* SVG paths */}
        </svg>
      </Box>
    </Link>
  );
});

export default Logo;
