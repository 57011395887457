'use client';

import { useRef, useState } from 'react';
import { useParams } from 'next/navigation';
import { Swiper as SwiperCore } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import { ProductCard } from './product-card';
import { useTranslate } from '../../locales';
import { Ib2bProduct } from '../../types/b2b';
import Iconify from '../../components/iconify';
import { BlockType } from '../../types/generator';
import { useResponsive } from '../../hooks/use-responsive';
import { ActionTypes, useAppContext } from '../../contexts/AppContext';

interface ProductOpenPrice {
  price: number;
  title: string;
  value: string;
}

type Props = {
  block: BlockType | undefined;
  dataFiltered: Ib2bProduct[];
  checked: boolean;
  handleChange: Function;
  title: string | string[];
  open: boolean;
  toggleDrawer: Function;
  filterView: any;
  dataFilteredCustom: Ib2bProduct[];
}


export const NotAlternativeProducts = ({ block, dataFiltered, checked, handleChange, title, open, toggleDrawer, filterView, dataFilteredCustom }: Props) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const params = useParams();
  const { t } = useTranslate();
  const isMobile = useResponsive('down', 'sm');
  const { dispatch, state } = useAppContext();
  const [productOpenPrices, setProductOpenPrices] = useState<ProductOpenPrice[]>([]);
  const handleOpenPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    product: Ib2bProduct,
    maxPrice: number,
    minPrice: number,
  ) => {
    const { value } = event.target;
    const newPrice: ProductOpenPrice = {
      value: product.id,
      title: product.title,
      price: parseInt(value, 10),
    };

    if (newPrice.price > maxPrice) {
      newPrice.price = maxPrice;
    }

    setProductOpenPrices((prevState: ProductOpenPrice[]) => {
      const updatedPrices = [...prevState.filter((price) => price.value !== product.id), newPrice];
      dispatch({ type: ActionTypes.SET_OPEN_PRICE_PRODUCTS, payload: updatedPrices });
      return updatedPrices;
    });

    const localDataString: string | null = typeof window !== 'undefined' ? localStorage.getItem('openPrice') : '';

    if (localDataString) {
      const localData: ProductOpenPrice[] = JSON.parse(localDataString);
      const updated: ProductOpenPrice[] = [
        ...localData.filter((price: ProductOpenPrice) => price.value !== product.id),
        newPrice,
      ];
      localStorage.setItem('openPrice', JSON.stringify(updated));
    } else {
      localStorage.setItem('openPrice', JSON.stringify([newPrice]));
    }
  };
  return (
    <Box
      sx={{ '& .MuiGrid-root ': { margin: 0, width: '100%', mb: block?.settings?.mb } }}
    >
      {block?.settings?.enableSwitch && !block?.settings?.customMode && (
        <FormControlLabel
          control={<Switch checked={checked} onChange={() => handleChange()} />}
          label={checked ? 'B2B' : 'B2C'}
        />
      )}
      {title === 'vendor' && (
        <Stack width={1} mb={2}>
          <Divider />
          <Typography
            sx={{ fontSize: '20px', fontWeight: 100, my: 2 }}
          >{`${t('All products')}`}</Typography>
        </Stack>
      )}
      {isMobile ? (
        <>
          <Stack width={1}>
            <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />
          </Stack>
          <Drawer open={open} onClose={toggleDrawer(false)}>
            {filterView}
          </Drawer>
        </>
      ) : (
        <>{filterView}</>
      )}

      <Grid
        container
        spacing={3}
        rowGap={3}
        justifyContent="center"
        // alignItems="stretch"
        sx={{ display: 'flex' }}
      >
        {block?.settings?.customMode ? (
          <>
            {!isMobile ? (
              <>
                {!block?.settings?.enableSlider ? (
                  <>
                    {dataFilteredCustom?.map((item: any, ind: number) => {
                      const product: any = item || [];
                      return (
                        <ProductCard
                          key={ind}
                          block={item}
                          localSettings={block}
                          product={product}
                          mode="custom"
                          openPriceProducts={block?.openPriceProducts}
                          handleOpenPriceChange={handleOpenPriceChange}
                          productOpenPrices={productOpenPrices}
                          merchantId={params?.id}
                        />
                      );
                    })}
                  </>
                ) : (
                  <Box
                    width={0.95}
                    m={2}
                    sx={{
                      '& .MuiGrid-root': { maxWidth: '100%' },
                      '& .swiper-wrapper': { justifyContent: 'center' },
                    }}
                  >
                    <Swiper
                      speed={500}
                      slidesPerView="auto"
                      loop
                      mousewheel={{
                        forceToAxis: true,
                        sensitivity: 1,
                        releaseOnEdges: true,
                      }}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                      onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                      }}
                    >
                      {dataFilteredCustom?.map((item: any, ind: number) => {
                        const product: any = item || [];
                        return (
                          <SwiperSlide
                            key={ind}
                            style={{
                              width: '25%',
                              padding: 3,
                            }}
                          >
                            <ProductCard
                              key={ind}
                              block={item}
                              localSettings={block}
                              product={product}
                              mode="custom"
                              openPriceProducts={block?.openPriceProducts}
                              handleOpenPriceChange={handleOpenPriceChange}
                              productOpenPrices={productOpenPrices}
                              merchantId={params?.id}
                            />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ '& .swiper-slide': { width: '70vw', margin: 1 } }}>
                <Box
                  sx={{
                    width: '90vw',
                  }}
                >
                  <Swiper
                    speed={500}
                    slidesPerView="auto"
                    mousewheel={{
                      forceToAxis: true,
                      sensitivity: 1,
                      releaseOnEdges: true,
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                    onSwiper={(swiper) => {
                      swiperRef.current = swiper;
                    }}
                  >
                    {dataFilteredCustom?.map((item: any, ind: number) => {
                      const product: any = item || [];
                      return (
                        <SwiperSlide key={ind}>
                          <ProductCard
                            key={ind}
                            block={item}
                            localSettings={block}
                            product={product}
                            mode="custom"
                            openPriceProducts={block?.openPriceProducts}
                            handleOpenPriceChange={handleOpenPriceChange}
                            productOpenPrices={productOpenPrices}
                            merchantId={params?.id}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <>
            {!isMobile ? (
              <>
                {!block?.settings?.enableSlider ? (
                  <>
                    {dataFiltered?.map((product: Ib2bProduct, idx: number) => (
                      <ProductCard
                        key={idx}
                        // @ts-ignore
                        block={block}
                        product={product}
                        mode="notCustom"
                        openPriceProducts={block?.openPriceProducts}
                        handleOpenPriceChange={handleOpenPriceChange}
                        productOpenPrices={productOpenPrices}
                        merchantId={params?.id}
                      />
                    ))}
                  </>
                ) : (
                  <Box
                    width={0.95}
                    m={2}
                    sx={{
                      '& .MuiGrid-root': { maxWidth: '100%' },
                      '& .swiper-wrapper': { justifyContent: 'center' },
                    }}
                  >
                    <Swiper
                      speed={500}
                      slidesPerView="auto"
                      loop
                      mousewheel={{
                        forceToAxis: true,
                        sensitivity: 1,
                        releaseOnEdges: true,
                      }}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                      onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                      }}
                    >
                      {dataFiltered?.map((product: Ib2bProduct, idx: number) => (
                        <SwiperSlide
                          key={idx}
                          style={{
                            width: '25%',
                            padding: 3,
                          }}
                        >
                          <ProductCard
                            localSettings={block}
                            block={block}
                            product={product}
                            mode="notCustom"
                            openPriceProducts={block?.openPriceProducts}
                            handleOpenPriceChange={handleOpenPriceChange}
                            productOpenPrices={productOpenPrices}
                            merchantId={params?.id}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ '& .swiper-slide': { width: '70vw', margin: 1 } }}>
                <Box
                  sx={{
                    width: '90vw',
                  }}
                >
                  <Swiper
                    speed={500}
                    slidesPerView="auto"
                    mousewheel={{
                      forceToAxis: true,
                      sensitivity: 1,
                      releaseOnEdges: true,
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                    onSwiper={(swiper) => {
                      swiperRef.current = swiper;
                    }}
                  >
                    {dataFiltered?.map((product: Ib2bProduct, idx: number) => (
                      <SwiperSlide key={idx}>
                        <ProductCard
                          key={idx}
                          // @ts-ignore
                          block={block}
                          product={product}
                          mode="notCustom"
                          openPriceProducts={block?.openPriceProducts}
                          handleOpenPriceChange={handleOpenPriceChange}
                          productOpenPrices={productOpenPrices}
                          merchantId={params?.id}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Box>
              </Box>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};
