import { AuthUserType } from '../auth/types';
import { BOT_ID } from '../config-global';

export enum FileTypes {
  file = 'file',
  video = 'video',
  image = 'image',
  document = 'document',
  archive = 'archive',
}

export function detectURLFileFormat(url: string) {
  if (['jpg', 'png', 'jpeg', 'tiff'].some((v) => url.includes(v))) return FileTypes.image;
  if (['mp4', 'mov', 'avi', 'webm'].some((v) => url.includes(v))) return FileTypes.video;
  if (['pdf', 'doc', 'docx', 'html', 'xls', 'xlsx'].some((v) => url.includes(v)))
    return FileTypes.document;
  if (['zip', '7z', 'tar', 'gz', 'rar'].some((v) => url.includes(v))) return FileTypes.archive;
  return FileTypes.file;
}

export function iconFromType(type: FileTypes) {
  switch (type) {
    case FileTypes.video:
      return 'mdi:file-video';
    case FileTypes.document:
      return 'mdi:file-document';
    case FileTypes.image:
      return 'mdi:file-image';
    case FileTypes.archive:
      return 'mdi:archive';
    case FileTypes.file:
    default:
      return 'mdi:file';
  }
}

// for File Manager
export function parseUserForFm(user: AuthUserType) {
  return {
    ssoUserId: user?.id || '',
    botId: +BOT_ID,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phoneNumber: user?.phone || '',
  };
}
