'use client';

import React from 'react';
import { Box, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Image from '../../../../../components/image';

const ProductDetailsSquareList = () => {
  const ProductDetailsListArr = [
    {
      image: '/assets/images/productDetails/banner.jpg',
      image_text: '10$-',
    },
    {
      image: '/assets/images/productDetails/banner.jpg',
      image_text: '10$-',
    },
    {
      image: '/assets/images/productDetails/banner.jpg',
      image_text: '10$-',
    },
  ];

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      pagination={{ clickable: true }}
    >
      {ProductDetailsListArr.map((el, index) => (
        <SwiperSlide key={index}>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              overflow: 'hidden',
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <Image
              src={el.image}
              alt="product"
              style={{ width: '100%', height: 'auto' }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 16,
                left: 26,
                transform: 'translateX(-50%)',
                backgroundColor: 'primary.main',
                borderRadius: 1,
                padding: '4px 8px',
              }}
            >
              <Typography variant="body2" color="white">
                {el.image_text}
              </Typography>
            </Box>
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductDetailsSquareList;
