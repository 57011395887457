'use client';

import React, { FC } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { ComponentTypeProps } from '../../../../types/page-generator';

const TitleAndButton: FC<ComponentTypeProps> = ({ block }) => {
  const text: any = block?.text;
  return (
    <Container maxWidth="xs" sx={{ py: 4, mb: '-35px' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ direction: 'rtl' }}
      >
        <Typography
          sx={{
            color: 'primary.main',
            cursor: 'pointer',
          }}
        >
          {'<<'} לכל השירותים
        </Typography>
        <Typography>{text}</Typography>
      </Stack>
    </Container>
  );
};

export default TitleAndButton;
