import { _mock } from './_mock';
import { randomInArray } from '../utils/random';

export const _virtualCards = [...Array(10)].map((_, index) => ({
  id: _mock.id(1),
  name: randomInArray(['טעינה', 'טעינה']),
  secondName: randomInArray(['איגור גוברמן', 'איגור גוברמן']),
  total: randomInArray(['₪500']),
  code: randomInArray(['234234', '56546456', '234636']),
  date: _mock.time(3),
  place: randomInArray(['עופר גראנד קניון באר שבע', 'עופר גראנד קניון באר שבע']),
  company: randomInArray(['כסף', 'קופון']),
  type: randomInArray(['active', 'pending']),
}));

export const _tabManagement = [...Array(10)].map((_, index) => ({
  id: _mock.id(1),
  name: randomInArray(['טעינה', 'טעינה']),
  secondName: randomInArray(['איגור גוברמן', 'איגור גוברמן']),
  total: randomInArray(['₪500']),
  typeOfTransfer: randomInArray(['כסף', 'קופון']),
  date: _mock.time(3),
  type: randomInArray(['active', 'pending']),
}));

export const _tabHistory = [
  {
    id: _mock.id(1),
    name: 'ארנונה',
    secondName: 'עיריית באר שבע',
    total: '₪3500',
    type: 'חיוב',
    date: _mock.time(3),
  },
  {
    id: _mock.id(1),
    name: 'טעינה',
    secondName: 'מלב ברודסקי',
    total: '₪500',
    type: 'זיכוי',
    date: _mock.time(3),
  },
  {
    id: _mock.id(1),
    name: 'מים',
    secondName: 'מי שבע',
    total: '₪300',
    type: 'חיוב',
    date: _mock.time(3),
  },
  {
    id: _mock.id(1),
    name: 'מע"מ ומיסים',
    secondName: 'מיסים',
    total: '₪15000',
    type: 'חיוב',
    date: _mock.time(3),
  },
  {
    id: _mock.id(1),
    name: 'תקבול',
    secondName: 'אקספרט סנטר',
    total: '₪500',
    type: 'זיכוי',
    date: _mock.time(3),
  },
  {
    id: _mock.id(1),
    name: 'מוסד לביטוח לאומי',
    secondName: 'מיסים',
    total: '₪9000',
    type: 'חיוב',
    date: _mock.time(3),
  },
  {
    id: _mock.id(1),
    name: 'חשמל',
    secondName: 'חברת חשמל',
    total: '₪500',
    type: 'חיוב',
    date: _mock.time(3),
  },
  {
    id: _mock.id(1),
    name: 'קניית מט”ח',
    secondName: 'פיננסי',
    total: '₪7940',
    type: 'חיוב',
    date: _mock.time(3),
  },
  {
    id: _mock.id(1),
    name: 'תשלום קנס חניה',
    secondName: 'עיריית ירושלים',
    total: '₪300',
    type: 'חיוב',
    date: _mock.time(3),
  },
];
