export const textColorOptions = [
  { label: 'Inherit', value: 'inherit' },
  { label: 'Contrast Primary', value: 'primary.contrastText' },
  { label: 'Contrast Secondary', value: 'secondary.contrastText' },
  { label: 'Text primary', value: 'text.primary' },
  { label: 'Text secondary', value: 'text.secondary' },
  { label: 'Text disabled', value: 'text.disabled' },
  { label: 'Theme Primary', value: 'primary.main' },
  { label: 'Theme Secondary', value: 'secondary.main' },
];

export const colorOptions = [
  { label: 'Inherit', value: 'inherit' },
  { label: 'Primary Main', value: 'primary.main' },
  { label: 'Primary Light', value: 'primary.light' },
  { label: 'Primary Lighter', value: 'primary.lighter' },
  { label: 'Primary Dark', value: 'primary.dark' },
  { label: 'Primary Darker', value: 'primary.darker' },
  { label: 'Secondary Main', value: 'secondary.main' },
  { label: 'Secondary Light', value: 'secondary.light' },
  { label: 'Secondary Lighter', value: 'secondary.lighter' },
  { label: 'Secondary Dark', value: 'secondary.dark' },
  { label: 'Secondary Darker', value: 'secondary.darker' },
];

export const colorTextOptions = [
  { label: 'Default', value: '' },
  { label: 'Purple', value: 'purple' },
  { label: 'Dark Violet', value: 'darkviolet' },
  { label: 'Red', value: 'red' },
  { label: 'Blue', value: 'blue' },
  { label: 'Orange', value: 'orange' },
  { label: 'Black', value: 'black' },
  { label: 'Pink', value: 'pink' },
];

export const buttonColorOptions = [
  { value: 'info', label: 'Info' },
  { value: 'error', label: 'Error' },
  { value: 'warning', label: 'Warning' },
  { value: 'inherit', label: 'Inherit' },
  { value: 'primary', label: 'Primary' },
  { value: 'success', label: 'Success' },
  { value: 'secondary', label: 'Secondary' },
];
