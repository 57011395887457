import { Box } from '@mui/material';

import { IconPropsType } from '../types';

function PencilIcon({ width, color = '#FFF', height }: IconPropsType) {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height ?? width}
      fill="none"
      viewBox="0 0 120 120"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M35.001 76.376l-11.334 11.31H1v-22.62L60.502 5.685A16.03 16.03 0 0171.835 1 16.057 16.057 0 0183.17 5.685a15.993 15.993 0 014.695 11.31 15.967 15.967 0 01-4.695 11.311l-5.667 5.655-22.667-22.62m24.367 80.966l-12.308 6.436a2.226 2.226 0 01-3.078-1.117 2.213 2.213 0 01-.14-1.202l2.35-13.635-9.956-9.653a2.2 2.2 0 011.23-3.761l13.76-1.99 6.153-12.403a2.218 2.218 0 011.99-1.23 2.225 2.225 0 011.988 1.23l6.155 12.402 13.759 1.991a2.208 2.208 0 011.831 2.71c-.098.399-.307.763-.602 1.05l-9.956 9.654 2.346 13.63a2.201 2.201 0 01-.88 2.161 2.212 2.212 0 01-2.333.163l-12.309-6.436z"
      />
    </Box>
  );
}

export default PencilIcon;
