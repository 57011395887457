'use client';

import React, { FC, useState } from 'react';

import Container from '@mui/material/Container';
import SearchIcon from '@mui/icons-material/Search';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {
  Box,
  Link,
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { ComponentTypeProps } from '../../../../types/page-generator';
import SpecialistsListSquare from './specialist/specialists-list-sqaure';


const Appointment: FC<ComponentTypeProps> = ({ block }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [timeCalendarIsOpen, setTimeCalendarIsOpen] = useState(false);

  return (
    <Container maxWidth="xs">
      <Box className="appointmentPage page" padding={3}>
        <Box className="appointment">
          {/* Аккордеон для выбора специалиста */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">1. בחירת מומחה</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box mb={2}>
                <TextField
                  id="search"
                  type="search"
                  placeholder="חיפוש לפי שירות או מומחית"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Box>
              <SpecialistsListSquare />
            </AccordionDetails>
          </Accordion>

          {/* Аккордеон для выбора даты */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">2. בחירת תאריך</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                onClick={() => setCalendarIsOpen(!calendarIsOpen)}
                display="flex"
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                  border: '1px solid #ccc',
                  padding: 1,
                  borderRadius: 1,
                }}
              >
                <CalendarTodayIcon />
                {/* Здесь можно добавить DatePicker */}
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Аккордеон для выбора времени */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">3. בחירת זמן</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                onClick={() => setTimeCalendarIsOpen(!timeCalendarIsOpen)}
                display="flex"
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                  border: '1px solid #ccc',
                  padding: 1,
                  borderRadius: 1,
                }}
              >
                <MoreTimeIcon />
                {/* Здесь можно добавить выбор времени */}
              </Box>
              <Box display="flex" justifyContent="space-around" mb={2}>
                <Button variant="outlined">10:00</Button>
                <Button variant="outlined">12:00</Button>
                <Button variant="outlined">15:00</Button>
                <Button variant="outlined">16:00</Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box textAlign="center">
          <Link href="/confirm" className="goBtn">
            <Button variant="contained" color="primary">
              אישור
            </Button>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Appointment;
