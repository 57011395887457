'use client';

import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import Paper, { PaperProps } from '@mui/material/Paper';

import Image from 'src/components/image';
// components
import Iconify from 'src/components/iconify';

import { useTranslate } from '../../locales';
import { ICheckoutDeliveryOption } from '../../types/checkout';
import { ActionTypes, useAppContext } from '../../contexts/AppContext';

type Props = CardProps & {
  options: ICheckoutDeliveryOption[];
};

export default function CheckoutDelivery({ options, ...other }: Props) {
  const { control } = useFormContext();
  const { dispatch } = useAppContext();
  const { t } = useTranslate();

  const setDelivery = useCallback((method: string, cost: number) => {
    dispatch({ type: ActionTypes.SET_DELIVERY, payload: { method, cost } });
  }, []);

  return (
    <Card {...other}>
      <CardHeader title={`${t('Delivery')}`} />

      <Controller
        name="delivery"
        control={control}
        render={({ field }) => (
          <Box
            columnGap={2}
            rowGap={2.5}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
            sx={{ p: 3 }}
          >
            {options.map((option) => (
              <OptionItem
                key={option.label}
                option={option}
                selected={field.value === option.value}
                onClick={() => {
                  field.onChange(option.value);
                  setDelivery(option.label, Number(option.value));
                  // onApplyShipping(option.value);
                }}
              />
            ))}
          </Box>
        )}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

type OptionItemProps = PaperProps & {
  option: ICheckoutDeliveryOption;
  selected: boolean;
};

function OptionItem({ option, selected, ...other }: OptionItemProps) {
  const { value, label, description, imageUrl } = option;
  const { t } = useTranslate();
  return (
    <Paper
      variant="outlined"
      key={value}
      sx={{
        p: 2.5,
        cursor: 'pointer',
        display: 'flex',
        ...(selected && {
          boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
        }),
      }}
      {...other}
    >
      {imageUrl && (
        <Box sx={{ width: 32, height: 32 }}>
          <Image
            src={imageUrl}
            sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
            alt={`icon of ${label} delivery`}
          />
        </Box>
      )}
      {label === 'Free' && !imageUrl && <Iconify icon="carbon:bicycle" width={32} />}
      {label === 'Standard' && !imageUrl && <Iconify icon="carbon:delivery" width={32} />}
      {label === 'Express' && !imageUrl && <Iconify icon="carbon:rocket" width={32} />}
      {!['Free', 'Standard', 'Express', 'Without delivery'].includes(label) && !imageUrl && (
        <Iconify icon="carbon:delivery" width={32} />
      )}

      <ListItemText
        sx={{ ml: 2 }}
        primary={
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={{ flexGrow: 1 }}>
              {`${t(`${label}`)}`}
            </Box>
            <Box component="span" sx={{ typography: 'h6' }}>{`₪${value}`}</Box>
          </Stack>
        }
        secondary={`${t(`${description}`)}`}
        primaryTypographyProps={{ typography: 'subtitle1', mb: 0.5 }}
        secondaryTypographyProps={{ typography: 'body2' }}
      />
    </Paper>
  );
}
