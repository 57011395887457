'use client';

import { FC, useState } from 'react';
import { Box, Stack, TextField, Button, Typography } from '@mui/material';
import { ComponentTypeProps } from '../../../../types/page-generator';
import Iconify from '../../../../components/iconify';

export const Chat: FC<ComponentTypeProps> = ({ block }) => {
  const [messages, setMessages] = useState<string[]>([]);
  const [input, setInput] = useState<string>('');

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, input]);
      setInput('');
    }
  };

  return (
    <Stack height="50vh" width="100%" spacing={2}>
      <Box
        sx={{
          flexGrow: 1,
          border: '1px solid #ccc',
          borderRadius: 2,
          padding: 2,
          overflowY: 'auto',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Stack spacing={1}>
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: 1,
                padding: 1,
                backgroundColor: '#fff',
                boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
              }}
            >
              <Typography>{msg}</Typography>
            </Box>
          ))}
        </Stack>
      </Box>

      {/* Input and Send Button */}
      <Stack direction="row" spacing={1}>
        <Button variant="contained" color="primary" onClick={handleSend}>
          <Iconify icon="material-symbols-light:send" />
        </Button>
        <TextField
          fullWidth
          placeholder="Write something"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSend();
            }
          }}
        />
      </Stack>
    </Stack>
  );
};
