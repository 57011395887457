'use client';

import { useMemo, useState, useEffect, Key } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Image from '../../../../../components/image';
import { useRouter } from '../../../../../routes/hooks';
import { IMerchant } from '../../../../../types/merchant';
import { getContentValueFromProps } from '../../../utils';
import { BlockType } from '../../../../../types/page-generator';
import { useResponsive } from '../../../../../hooks/use-responsive';
import { uploadProductsImage } from '../../../../../helpers/uploadProductsImage';
import { useLocales } from '../../../../../locales';

type Props = {
  block: BlockType;
  merchant: IMerchant;
  key: Key
};

export const AlternativeBigMerchant = ({ block, merchant, key }: Props) => {
  const { currentLang } = useLocales();
  const router = useRouter();
  const imageLink = `${uploadProductsImage(`${merchant?.mainImageURL}`)}`;
  const isMobile = useResponsive('down', 'sm');
  const height = isMobile ? '40px' : '150px';
  const size = !isMobile ? 3 : 4;
  const getFieldValue = getContentValueFromProps(block);
  const [matchingTags, setMatchingTags] = useState<any>([]);
  const style = !block?.settings?.partialSize
    ? {
      border: block?.settings?.partialSize ? '1px solid white' : 'none',
      borderRadius: '15px',
      backgroundColor: 'white',
      p: 1,
    }
    : {
      border: block?.settings?.partialSize ? '1px solid white' : 'none',
      borderRadius: '15px',
      backgroundColor: 'white',
      p: 1,
      // width: block?.settings?.partialSize && '108px',
      width: '100%',
      height: block?.settings?.partialSize && '48px',
    };

  const tagsImages = useMemo(
    () => getFieldValue('tagsImages'),
    [block.tagsImages],
  );

  const merchantsTags = useMemo(() => {
    const tagsValue = getFieldValue('merchantsTags');
    if (Array.isArray(tagsValue)) {
      return tagsValue.map((tag: any) => ({
        ...tag,
        image: tagsImages[tag.value] ?? tag?.image,
      }));
    }
    return [];
  }, [block?.categories, tagsImages]);

  useEffect(() => {
    if (!merchant.tags || !block?.merchantsTags) return;
    if (block?.settings?.enableSmallInfo) {
      const tags = merchantsTags.filter((tag: any) =>
        merchant.tags.some(
          (productsTag: any) => productsTag.name === tag.title,
        ),
      );
      setMatchingTags(tags);
    }
  }, [merchant.tags, block?.merchantsTags, block?.settings?.enableSmallInfo]);

  return (
    <Stack
      key={key}
      width={1}
      sx={{
        border: block?.settings?.partialSize ? '1px solid white' : 'none',
        borderRadius: '15px',
        backgroundColor: 'white',
        p: 1,
        // width: block?.settings?.partialSize && '108px',
        width: '100%',
        height: block?.settings?.partialSize && '208px',
        position: 'relative',
      }}
    >
      <Box
        onClick={() => router.push(`/merchant/${merchant?.id}`)}
        sx={{
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundImage: `url(${imageLink})`,
          backgroundRepeat: 'no-repeat',
          height: '100%',
          borderRadius: '10px',
          transform: block?.settings?.enableRotation && 'rotate(-20deg)',
          mt: block?.settings?.enableRotation && '50px',
          width: block?.settings?.enableRotation ? '85%' : '100%',
          mx: block?.settings?.enableRotation && 'auto',
          cursor: 'pointer',
        }}
      />
      {block?.settings?.enableSmallInfo && (
        <Stack
          sx={{
            position: 'absolute',
            left: currentLang.value === 'he' ? '-20px' : 'auto',
            right: currentLang.value !== 'he' ? '-20px' : 'auto',
            top: '-10px',
            p: 1,
          }}
        >          {matchingTags.map((tag: any, index: number) => (
          <Stack key={index} direction={currentLang.value === 'he' ? 'row-reverse' : 'row'} alignItems="center">
            {(block?.settings?.smallInfoViewSettings === 'all' ||
              block?.settings?.smallInfoViewSettings === 'icon') && (
              <Image
                src={tag.image}
                sx={{ width: '35px', height: '35px', mr: 1 }}
              />
            )}
            {(block?.settings?.smallInfoViewSettings === 'all' ||
              block?.settings?.smallInfoViewSettings === 'text') && (
              <Typography
                sx={{
                  color: block?.settings?.smallInfoTextColor,
                  fontSize: block?.settings?.smallInfoFontSize,
                  fontWeight: block?.settings?.smallInfoFontWeight,
                  fontStyle: block?.settings?.smallInfoFontStyle,
                  textDecoration: block?.settings?.smallInfoTextDecoration,
                }}
              >
                {tag.title}
              </Typography>
            )}
          </Stack>
        ))}
        </Stack>
      )}
    </Stack>
  );
};
