import { endpoints } from 'src/utils/axios';

import { API } from 'src/helpers/api';
import { BOT_ID } from '../config-global';

type transferData = {
  hoursToExpire: string;
  phoneNumber: string;
  email: string;
  amount: number;
  virtualCardId: string;
  activityId: string;
};

export const getVirtualCardTypeById = async (id: string) => {
  try {
    const url = endpoints.virtualCardType.getById;
    const { data } = await API({
      url,
      method: 'GET',
      params: {
        id,
      },
    });
    return data.payload;
  } catch (e) {
    return e;
  }
};

export const virtualCardPelecard = async (
  virtualCardId: string,
  amount: number,
  smbAccountId: string
) => {
  try {
    const currentOrigin = window.location.origin;
    const { data } = await API({
      url: endpoints.virtualCardType.pelecard,
      method: 'POST',
      data: {
        virtualCardId,
        amount,
        botId: BOT_ID,
        successUrl: `${currentOrigin}/pelecard-virtual-card-success`,
        errorUrl: `${currentOrigin}/pelecard-virtual-card-error`,
        smbAccountId,
      },
    });

    if (!data.success || !data.IframeUrl) {
      throw new Error(data.message || 'Something went wrong');
    }
    return data.IframeUrl;
  } catch (e) {
    return '';
  }
};

export const virtualCardTransfer = async (transferData: transferData) => {
  try {
    const { data } = await API({
      url: endpoints.virtualCardType.transfer,
      method: 'POST',
      data: transferData,
    });

    if (!data.success || !data) {
      throw new Error(data.message || 'Something went wrong');
    }
    return data;
  } catch (e) {
    return '';
  }
};
