import { FieldTypes, SettingsField } from 'src/types/page-generator';

import commonSettings from '../../common/settings';

const settings: SettingsField[] = [
  ...commonSettings,
  {
    name: 'leadv1',
    type: FieldTypes.input,
    defaultValue: '',
    label: 'Lead V1 Setting',
    rowSize: 2,
  },
  {
    name: 'showPhoneNumber',
    type: FieldTypes.checkbox,
    defaultValue: true,
    label: 'Show phone number',
    rowSize: 3,
  },
];

export default settings;
