import { Box } from '@mui/material';

import { IconPropsType } from '../types';

function LineIcon({ width, color = '#FFF', height, sx }: IconPropsType) {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height ?? width}
      fill="none"
      viewBox="0 0 1200 2"
      sx={sx}
    >
      <line y1="1" x2="1200" y2="1" stroke={color} strokeWidth="1" />
    </Box>
  );
}

export default LineIcon;
