'use client';

import React from 'react';
import { Grid, Box } from '@mui/material';
import ProductsList from './products-list';

const ProductsSquareList = () => {
  const ProductsListArr = [
    {
      img: '/assets/images/marketPlace/product1.jpg',
      title: 'נקיון מהיר של הדירה',
      price: '85$',
      link: '/productdetails',
      productKey: 1,
    },
    {
      img: '/assets/images/marketPlace/product2.jpg',
      title: 'נקיון כולל של הדירה',
      price: '100$',
      link: '/productdetails',
      productKey: 2,
    },
    {
      img: '/assets/images/marketPlace/product3.jpg',
      title: 'נקיון דירה עם חיות מחמד',
      price: '120$',
      link: '/productdetails',
      productKey: 3,
    },
    {
      img: '/assets/images/marketPlace/product4.jpg',
      title: 'פתרון בעיות חיווט',
      price: '150$',
      link: '/productdetails',
      productKey: 4,
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      sx={{ direction: 'rtl' }}
    >
      {ProductsListArr.map((el, i) => (
        <Grid item xs={6} key={i}>
          <ProductsList
            img={el.img}
            title={el.title}
            price={el.price}
            link={el.link}
            productKey={el.productKey}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductsSquareList;
