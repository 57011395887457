'use client';

import { useState } from 'react';

import { Box, Grid, Stack, Typography } from '@mui/material';

import Iconify from '../../../../../../components/iconify';
import { useResponsive } from '../../../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../../../types/page-generator';

type Interests = {
  id: number;
  title: string;
  icon: string;
  active: boolean;
};

export const InterestsTab = ({ block }: ComponentTypeProps) => {
  const isMobile = useResponsive('down', 'sm');
  const [interests, setInterests] = useState<Interests[]>([
    { id: 1, title: 'תיאטרון', icon: 'la:theater-masks', active: true },
    { id: 2, title: 'מוזיקה', icon: 'ph:music-notes-simple', active: false },
    { id: 3, title: 'ספורט', icon: 'streamline:ball', active: true },
    { id: 4, title: 'אוכל', icon: 'la:pizza-slice', active: false },
    { id: 5, title: 'מחול', icon: 'mdi:dance-ballroom', active: true },
    { id: 6, title: 'העשרה עצמית', icon: 'mdi:human-handsup', active: false },
    { id: 7, title: 'סיורים', icon: 'ion:footsteps-outline', active: true },
    { id: 8, title: 'בעלי חיים', icon: 'ph:rabbit-light', active: false },
  ]);

  const onChange = (item: Interests) => {
    const updatedInterests = interests.map((i) => {
      if (i.id === item.id) {
        return {
          ...i,
          active: !i.active,
        };
      }
      return i;
    });
    setInterests(updatedInterests);
  };

  return (
    <Stack>
      <Stack direction="row" justifyContent={isMobile ? 'space-between' : ''}>
        <Typography sx={{ fontWeight: 600 }}>עדכונים ותחומי עניין</Typography>
        {isMobile && (
          <Stack direction="row">
            <Typography sx={{ fontWeight: 600, cursor: 'pointer' }}>שינוי</Typography>
            <Iconify icon="tdesign:edit" color="#282894" />
          </Stack>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography>בחרת לקבל את העדכונים דרך: SMS, דוא”ל שינוי</Typography>
        {!isMobile && (
          <>
            <Typography sx={{ fontWeight: 600, cursor: 'pointer' }}>שינוי</Typography>
            <Iconify icon="tdesign:edit" color="#282894" />
          </>
        )}
      </Stack>
      <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} spacing={1} mt={1}>
        {interests.map((item) => (
          <Grid
            item
            md={2.9}
            xs={5.9}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => onChange(item)}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{
                border: '1px solid #DCDFEA',
                borderRadius: '5px',
                bgcolor: item?.active ? '#c3edff' : 'inherit',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #DCDFEA',
                  width: '50px',
                  height: '50px',
                  borderRadius: '5px',
                  bgcolor: '#c3edff',
                }}
              >
                <Iconify icon={item.icon} />
              </Box>
              <Typography>{item.title}</Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};
