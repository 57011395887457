'use client';

import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useTranslate } from 'src/locales';

import Image from '../../../../../../components/image';
import Iconify from '../../../../../../components/iconify';
import { BlockType } from '../../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';

export default function VendorsDetails({ block }: { block: BlockType }) {
  const { t } = useTranslate();
  const { dispatch } = useAppContext();
  const { settings } = block;
  const { showPhoneNumber } = settings;
  const onClose = () => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
  };
  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <Iconify
          icon="iconamoon:close"
          onClick={() => onClose()}
          sx={{ cursor: 'pointer', color: 'grey.600' }}
        />
      </Stack>
      <Stack alignItems="flex-start">
        <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
          סניפים
        </Typography>
        <Stack direction="row-reverse" justifyContent="space-between" width={1} alignItems="center">
          <Stack direction="row-reverse" gap={2} sx={{ color: 'secondary.main' }}>
            <Typography sx={{ textDecoration: 'underline' }}>לאתר בית העסק לחץ כאן</Typography>
            <Iconify icon="fa-solid:globe" />
          </Stack>
          <Image src="/assets/images/happy-gift/wallet/pizza-hut.png" />
        </Stack>
        <Typography>Pizza Hut</Typography>
        <Typography>
          פיצה האט ידועה בעיקר בזכות הבצק העבה והעשיר שלה - הפאן פיצה שלנו - פיצה איכותית בינלאומית
          עם גיבוי רשת מקצועי והמון אהבה. בשנים האחרונות העמיקה פיצה האט את פעילותה בתחום המשלוחים
          וכיום היא רשת המשלוחים המובילה בישראל, עם למעלה מ-100 קטנועים ושליחויות צעירות דינמיות
          המשתוקקות להגיע לדלת הלקוחות עם מגוון הפיצות, הפסטות, הסלטים שלנו, מבחר מנות קשורות,
          קינוחים, גלידות ומשקאות.
        </Typography>
      </Stack>
    </>
  );
}
