function TableIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      fill="none"
      viewBox="0 0 37 18"
    >
      <path
        stroke="#004454"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.679 1v15.596M1 1h34.31M5.679 5.679H30.63M30.631 1v15.596"
      />
    </svg>
  );
}

export default TableIcon;
