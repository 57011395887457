// eslint-disable-next-line import/no-cycle
import { FieldTypes, SettingsField } from '../../../../../../types/page-generator';

const contentFields: SettingsField[] = [
  {
    name: 'modalId',
    type: FieldTypes.input,
    defaultValue: 'match-me',
    label: 'Modal Identifier (required) ',
    rowSize: 2,
  },
  {
    name: 'actionButtons',
    type: FieldTypes.buttons,
    defaultValue: [
      {
        label: 'העלה',
        color: 'secondary',
        size: 'medium',
        variant: 'contained',
        link: '#',
      },
    ],
    rowSize: 4,
    label: 'Action Buttons',
  },
  {
    name: 'isSupplierMode',
    label: 'Supplier Mode',
    type: FieldTypes.checkbox,
    defaultValue: false,
    rowSize: 2,
  },
];

export default contentFields;
