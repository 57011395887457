import { ReactNode } from 'react';

import { BoxProps } from '@mui/material/Box';

// STYLED COMPONENTS
import { Dot, DotList } from './styles';

// ==============================================================
interface Props extends BoxProps {
  dotColor?: string;
}
// ==============================================================

const CarouselDots = ({ dotColor, ...props }: Props) => ({
  customPaging: () => <Dot dotColor={dotColor} />,
  appendDots: (dots: ReactNode) => (
    <DotList component="ul" {...props}>
      {dots}
    </DotList>
  ),
});

export default CarouselDots;
