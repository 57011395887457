'use client';

import { FC } from 'react';

import { Card, Grid, Stack, Button, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';

const VoucherBanner: FC<ComponentTypeProps> = ({ block }) => (
  <Card>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Stack sx={{ color: '#3A0D5E', my: 2 }} gap={2}>
          <Image src="/assets/images/happy-gift/logo/happy-gift-card.png" sx={{ width: '50%' }} />
          <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
            משמח הרבה מעבר לכרטיס מתנה!
          </Typography>
          <Typography sx={{ fontWeight: 400 }}>
            מפנק הניתן למימוש ישיר במגוון רחב של רשתות אופנה, מסעדות, אטרקציות, נופש וספא. הטבות
            נוספות מחכות לכם באתר HappyGift, שם תוכלו למצוא הטבות נוספות במגוון תחומים הניתנות
            למימוש באמצעות קוד דיגיטלי שישלח אליכם{' '}
          </Typography>
          <Typography sx={{ fontWeight: 400, textDecoration: 'underline' }}>
            *הכרטיס ניתן לטעינה חוזרת
          </Typography>
          <Button sx={{ bgcolor: '#EB157B', color: 'white', borderRadius: '20px' }}>
            אני רוצה לשמח את העובדים שלי{' '}
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack alignItems="center" my={3}>
          <Image
            src="/assets/images/happy-gift/banners/group-cards-pink.png"
            sx={{ borderRadius: '20px', width: '70%' }}
          />
        </Stack>
      </Grid>
    </Grid>
  </Card>
);
export default VoucherBanner;
