'use client';

import { FC, useMemo } from 'react';
import { useRouter } from 'next/navigation';

import { Card, Grid, Stack, Button, Typography } from '@mui/material';

import Image from '../../../../components/image';
import { getContentValueFromProps } from '../../utils';
import { ComponentTypeProps } from '../../../../types/page-generator';

const AttractionsCoupons: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const link = useMemo(() => getFieldValue('link'), [block?.link]);
  const router = useRouter();

  return (
    <Grid container direction="row" spacing={1}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, id) => (
        <Grid key={id} item md={3} xs={12}>
          <Card
            key={item}
            onClick={() => router.push(`${link}/${item}`)}
            sx={{ cursor: 'pointer' }}
          >
            <Stack gap={2}>
              <Image src="/assets/images/happy-gift/banners/two_people.png" />
              <Stack px={2} gap={1}>
                <Typography sx={{ color: '#3A0D5E', textAlign: 'center' }}>בילוי זוגי</Typography>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{
                    color: '#3A0D5E',
                    border: '1px solid #EB157B',
                    borderRadius: '20px',
                    mb: 1,
                  }}
                >
                  לפרטים נוספים
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
export default AttractionsCoupons;
