export const providers = [
  {
    id: 1,
    image: '/assets/images/boom-buy/providers/1.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 2,
    image: '/assets/images/boom-buy/providers/2.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 3,
    image: '/assets/images/boom-buy/providers/3.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 4,
    image: '/assets/images/boom-buy/providers/4.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 5,
    image: '/assets/images/boom-buy/providers/5.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 6,
    image: '/assets/images/boom-buy/providers/6.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 7,
    image: '/assets/images/boom-buy/providers/7.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 8,
    image: '/assets/images/boom-buy/providers/8.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 9,
    image: '/assets/images/boom-buy/providers/9.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 10,
    image: '/assets/images/boom-buy/providers/10.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 11,
    image: '/assets/images/boom-buy/providers/11.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 12,
    image: '/assets/images/boom-buy/providers/12.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 13,
    image: '/assets/images/boom-buy/providers/13.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 14,
    image: '/assets/images/boom-buy/providers/14.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
  {
    id: 15,
    image: '/assets/images/boom-buy/providers/15.png',
    title: 'איכות ונוחות במקום אחד!',
    content:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
    description:
      'חנות WinStore הנה חנות אינטרנטית מקוונת הנמצאת באחריותה ובבעלותה הבלעדית של חברת ווין מגוון מנצח בע"מחברת ווין הוקמה בשנת 2010 על ידי שלומי רוחם, בעלים ומייסד של WIN מגוון מנצחחברת WIN הנה חברת יבוא ושיווק צעצועים, מוצרי עונות וחגים ועוד מגוון נושאים כפי שניתן לראות באתר.WIN החליטה להנגיש את המוצרים המיובאים על ידה לצרכן הסופי בכדי לקבל פידבק ישיר מהצרכן על מנת לשפר ולייעל את המוצרים.את כלל מוצרינו ניתן למצוא בהמון חנויות בכל רחבי הארץ',
  },
];
