'use client';

// @mui
import { Button, TableRow, Checkbox, TableCell } from '@mui/material';

import Label from '../../../../../../components/label';
import Iconify from '../../../../../../components/iconify';

// ----------------------------------------------------------------------

type PaidTableType = {
  id: string;
  date: string;
  paymentForThePeriod: string;
  amountToPay: string;
  propertyAddress: string;
  status: string;
};

type Props = {
  row: PaidTableType;
};

export default function NotPaidTableRow({ row }: Props) {
  const { date, paymentForThePeriod, amountToPay, propertyAddress, status } = row;

  return (
    <TableRow hover>
        <TableCell padding="checkbox">
          <Checkbox checked={false} />
        </TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>{paymentForThePeriod}</TableCell>
        <TableCell>{amountToPay}</TableCell>
        <TableCell>{propertyAddress}</TableCell>
        <TableCell>
          <Label color={status === 'לא שולם' ? 'warning' : 'error'}>
            {status === 'לא שולם' && <Iconify icon="iconamoon:attention-circle-fill" mx={1} />}
            {status}
          </Label>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            sx={{
              color: 'white',
              bgcolor: '#0059d2',
              borderRadius: '25px',
              px: '40px',
            }}
          >
            לתשלום
          </Button>
        </TableCell>
      </TableRow>
  );
}
