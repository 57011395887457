'use client';

import { FC, Key } from 'react';

import { Grid } from '@mui/material';

import SixHoverBlocksItem from './SixHoverBlocks-item';
import { useRouter } from '../../../../../routes/hooks';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../contexts/AppContext';

const SixHoverBlocksGrid: FC<ComponentTypeProps> = ({ block }) => {
  const router = useRouter();
  const { combinedIcons }: any = block || [];
  const { dispatch, state } = useAppContext();
  const column = () => {
    if (combinedIcons && combinedIcons.length % 3 === 0) return 4;
    if (combinedIcons && combinedIcons.length % 2 === 0) return 6;
    return 4;
  };
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const onChange = (item: any) => {
    if (item?.isDialogToggler) {
      setActiveDialog(item?.modalId);
    } else router.push(`${item?.link}`);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {combinedIcons?.map((item: any, id: Key | null | undefined) => (
        <Grid key={id} item md={column()} xs={6} onClick={() => onChange(item)}>
          <SixHoverBlocksItem icon={item.icon} text={item.title} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SixHoverBlocksGrid;
