'use client';

import { useSearchParams } from 'next/navigation';

import { Card, Stack, Typography } from '@mui/material';

// components

import Image from '../../components/image';
import { useTranslate } from '../../locales';
import { HOST_API } from '../../constants/common';
import Scrollbar from '../../components/scrollbar';
import { useAppContext } from '../../contexts/AppContext';

// ----------------------------------------------------------------------

export default function GreetingPhone({ senderName, addressee, mailingMessage }: any) {
  const { t } = useTranslate();
  const params = useSearchParams();
  const hideCoupons = params.get('hideCoupons');
  const { dispatch, state } = useAppContext();

  // const activeMerchant = useSelector((state) => state.merchant.activeEvent);
  // const coupons = useSelector((state) => state.product_2.products);
  // const merchantEventUrl = useSelector((state) => state.merchant.activeEvent.domain);
  // const { pickedMailingCoupons } = useSelector((state) => state.product_2);
  // const greetingImage = activeMerchant.settings?.greeting?.images?.imageUrl;
  // const greetingImageUrl = `${process.env.HOST_MEDIA_KEY}${greetingImage}`;
  //
  // const filteredCoupons = coupons?.filter(
  //   (coupon: ProductInterace) => activeMerchant?.settings?.couponIds?.includes(coupon?.id)
  // );

  // const sumTotalPrice = filteredCoupons.reduce(
  //   (accumulator: any, object: ProductInterace) => accumulator + object.discountValueB2B,
  //   0
  // );

  // const { isTransitionFromBuilder } = useSelector((state) => state.builder);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        maxWidth: 400,
        width: '100%',
      }}
    >
      <Stack
        sx={{
          width: 1,
          height: 1,
          position: 'relative',
          padding: '12px 18px 48px 18px',
        }}
      >
        <Image
          disabledEffect
          visibleByDefault
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            maxWidth: 565,
            width: '100%',
            zIndex: '1',
          }}
          alt="phone"
          src="/assets/illustrations/choosePresent/phone1.png"
        />

        {state.greetingImage ? (
          <Image
            disabledEffect
            visibleByDefault
            alt="phone"
            src={`${HOST_API}${state.greetingImage}`}
            sx={{
              borderTopRightRadius: '36px',
              borderTopLeftRadius: '36px',
              zIndex: 0,
              width: '100%',
              maxWidth: 560,
              minWidth: 300,
              height: 'auto',
              mt: 5,
            }}
          />
        ) : (
          ''
        )}

        {/* {activeMerchant?.settings?.greeting?.images?.imageUrl ? ( */}
        {/* <Image */}
        {/*   disabledEffect */}
        {/*   visibleByDefault */}
        {/*   alt="phone" */}
        {/*   src={greetingImageUrl} */}
        {/*   sx={{ */}
        {/*     borderTopRightRadius: '36px', */}
        {/*     borderTopLeftRadius: '36px', */}
        {/*     zIndex: 0, */}
        {/*     width: '100%', */}
        {/*     maxWidth: 560, */}
        {/*     minWidth: 300, */}
        {/*     height: 'auto', */}
        {/*   }} */}
        {/* /> */}
        {/* ) : ( */}
        {/* <Image */}
        {/*   disabledEffect */}
        {/*   visibleByDefault */}
        {/*   alt="phone" */}
        {/*   src={`${process.env.HOST_MEDIA_KEY}${filteredCoupons[0]?.image}`} */}
        {/*   sx={{ */}
        {/*     borderTopRightRadius: '36px', */}
        {/*     borderTopLeftRadius: '36px', */}
        {/*     zIndex: 0, */}
        {/*     width: '100%', */}
        {/*     maxWidth: 560, */}
        {/*     minWidth: 300, */}
        {/*     height: 'auto', */}
        {/*   }} */}
        {/* /> */}
        {/* )} */}
        <Scrollbar sx={{ height: '400px' }}>
          <Stack
            sx={{
              px: 3,
              pt: 2,
              height: 470,
              zIndex: 2,
              position: 'relative',
              mt: !state.greetingImage ? 5 : 0,
            }}
          >
            <Typography variant="subtitle2" component="div" sx={{ color: 'text.primary' }}>
              {`${t('sender: ')}`}
            </Typography>

            <Typography
              variant="subtitle2"
              component="div"
              sx={{ color: 'text.primary', overflowWrap: 'break-word', mt: 1 }}
            >
              {senderName}
            </Typography>

            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {`${t('addressee: ')}`}
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{ color: 'text.primary', overflowWrap: 'break-word', mt: 1 }}
            >
              {addressee}
            </Typography>

            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {`${t('description: ')}`}
            </Typography>

            <Typography
              variant="subtitle2"
              sx={{ color: 'text.primary', overflowWrap: 'break-word', mt: 1 }}
            >
              {mailingMessage}
            </Typography>

            {/* <Typography variant="subtitle2" sx={{ color: 'text.primary', fontWeight: 'bold', mt: 1, mb: 3}}>
            {`${translate('total: ')}`} ₪ {sumTotalPrice}
          </Typography> */}

            {/* <Scrollbar> */}
            {/*  <Stack spacing={3} sx={{ overflow: 'auto' }}> */}
            <Stack spacing={3}>
              {/* {!hideCoupons && */}
              {/*  pickedMailingCoupons.map((coupon: ProductInterace) => ( */}
              {/*    <Stack key={coupon.id} direction="row" justifyContent="space-between" spacing={3}> */}
              {/*      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}> */}
              {/*        {coupon?.title} */}
              {/*      </Typography> */}
              {/*      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}> */}
              {/*        ₪{coupon?.prices[0]?.value} */}
              {/*      </Typography> */}
              {/*    </Stack> */}
              {/*  ))} */}
            </Stack>
            {/* </Scrollbar> */}
            {/* {isTransitionFromBuilder && ( */}
            {/*  <Typography */}
            {/*    variant="subtitle2" */}
            {/*    sx={{ */}
            {/*      color: 'text.primary', */}
            {/*      zIndex: 1000, */}
            {/*      bottom: 20, */}
            {/*      position: 'absolute', */}
            {/*    }} */}
            {/*  > */}
            {/*    link:{' '} */}
            {/*    /!* <Link href={`https://${merchantEventUrl}.b24online.com`} target="_blank"> *!/ */}
            {/*    /!*  {merchantEventUrl}.b24online.com *!/ */}
            {/*    /!* </Link> *!/ */}
            {/*  </Typography> */}
            {/* )} */}
          </Stack>
        </Scrollbar>
      </Stack>
    </Card>
  );
}
