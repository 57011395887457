'use client';

import React, { FC, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useAuthContext } from 'src/auth/hooks';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Iconify from 'src/components/iconify';

import { ButtonField } from 'src/types/generator';
import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';
import { PROJECT_NAME } from '../../../../config-global';
import PwaInstallModal from '../../../../components/modals/install-pwa-modal';

const OneBigButton: FC<ComponentTypeProps> = ({ block }) => {
  const { dispatch } = useAppContext();
  const { isAuthenticated, logout } = useAuthContext();
  const getFieldValue = getContentValueFromProps(block);
  const isCityPeople = useMemo(() => PROJECT_NAME.toLowerCase() === 'citypeople', []);
  const [modalOpen, setModalOpen] = useState(false);
  const [isPwaInstalled, setIsPwaInstalled] = useState(false);

  const [actionButton] = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons]
  );

  const bannerClasses = useMemo(
    () => getFieldValue('settings.bannerClasses'),
    [block?.settings.bannerClasses]
  );

  const buttonColor = useMemo(
    () => getFieldValue('settings.buttonColor') ?? 'secondary',
    [block?.settings.buttonColor]
  );

  const buttonWidth = useMemo(
    () => getFieldValue('settings.buttonWidth') ?? '70%',
    [block?.settings.buttonWidth]
  );

  const buttonMinWidth = useMemo(
    () => getFieldValue('settings.buttonMinWidth') ?? '100',
    [block?.settings.buttonMinWidth]
  );

  const buttonHoverColor = useMemo(
    () => getFieldValue('settings.buttonHoverColor') ?? 'secondary.light',
    [block?.settings.buttonHoverColor]
  );

  const withArrowIcon = useMemo(
    () => getFieldValue('settings.withArrowIcon'),
    [block?.settings.withArrowIcon]
  );

  const isAuthButton = useMemo(
    () => getFieldValue('settings.isAuthButton'),
    [block?.settings.isAuthButton]
  );

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const [isIPhone, setIsIPhone] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
      setIsIPhone(/iPhone/i.test(navigator?.userAgent));
    }
  }, []);

  const isPWAInstalled = () => {
    // For iOS
    // @ts-ignore
    if (window.navigator?.standalone) {
      return true;
    }

    // For other platforms like Android
    return window.matchMedia('(display-mode: standalone)').matches;
  };

  useEffect(() => {
    setIsPwaInstalled(isPWAInstalled());
  }, []);

  const onInstallPWA = () => {
    if (isIPhone) {
      setModalOpen(true);
    }

    // @ts-ignore
    if (window?.deferredPrompt) {
      // @ts-ignore
      window.deferredPrompt?.prompt();
    }
  };

  // TODO: change later to simple class
  const mb =
    bannerClasses && bannerClasses.includes('marginBottom')
      ? `${bannerClasses.split('marginBottom-')[1]}px`
      : 0;

  const downloadLinkRowStyles = {
    mt: '10px',
    justifyContent: 'center',
    '& p': {
      fontWeight: '400',
      color: '#e18b5b',
      fontSize: '20px',
    },
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={2}
        className={bannerClasses}
        sx={{ mb }}
      >
        <Stack direction="column" width="100%" alignItems="center" spacing={2} py={1}>
          {isAuthButton ? (
            <>
              {!isAuthenticated ? (
                // @ts-ignore - проблемы с совместимостью типов, не критично
                <Button
                  variant={actionButton.variant || 'contained'}
                  color={buttonColor}
                  size={actionButton.size || 'large'}
                  endIcon={
                    withArrowIcon ? <Iconify icon="material-symbols-light:arrow-back-ios" /> : false
                  }
                  sx={{
                    borderRadius: 10,
                    fontWeight: 400,
                    m: '0 auto',
                    width: buttonWidth,
                    minWidth: `${buttonMinWidth}px`,
                    ...(buttonHoverColor && {
                      '&:hover': { backgroundColor: buttonHoverColor },
                    }),
                  }}
                  href={
                    (!actionButton?.link.includes('modal') &&
                      !actionButton.isDialogToggler &&
                      actionButton?.link) ||
                    ''
                  }
                  onClick={() => {
                    if (actionButton.isDialogToggler && actionButton.modalId) {
                      setActiveDialog(actionButton.modalId);
                    }
                  }}
                >
                  {actionButton.label || '[no label]'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color={buttonColor}
                  size={actionButton.size || 'large'}
                  sx={{ borderRadius: 10, fontWeight: 400, width: buttonWidth }}
                  onClick={() => {
                    logout();
                    dispatch({
                      type: ActionTypes.GET_APARTMENTS,
                      payload: { apartments: [], count: 0 },
                    });
                    dispatch({ type: ActionTypes.SET_SELECTED_APARTMENT, payload: null });
                    dispatch({ type: ActionTypes.SET_SELECTED_MERCHANT, payload: null });
                    dispatch({ type: ActionTypes.GET_CUSTOMER_BY_SSO_ID, payload: null });
                  }}
                >
                  {isCityPeople ? 'להתנתק' : 'LOG OUT'}
                </Button>
              )}
            </>
          ) : (
            <Button
              variant={actionButton.variant || 'contained'}
              color={buttonColor}
              size={actionButton.size || 'large'}
              endIcon={
                withArrowIcon ? <Iconify icon="material-symbols-light:arrow-back-ios" /> : false
              }
              sx={{
                borderRadius: 10,
                fontWeight: 400,
                m: '0 auto',
                width: buttonWidth,
                minWidth: `${buttonMinWidth}px`,
                ...(buttonHoverColor && {
                  '&:hover': { backgroundColor: buttonHoverColor },
                }),
              }}
              href={
                (!actionButton?.link.includes('modal') &&
                  !actionButton.isDialogToggler &&
                  actionButton?.link) ||
                ''
              }
              onClick={() => {
                if (actionButton.isDialogToggler && actionButton.modalId) {
                  setActiveDialog(actionButton.modalId);
                }
              }}
            >
              {actionButton.label || '[no label]'}
            </Button>
          )}
        </Stack>
      </Box>
      {isAuthButton && !isPwaInstalled && (
        <Stack direction="row" gap={1} sx={downloadLinkRowStyles}>
          <Typography>להורדת האפליקציה</Typography>
          <Typography>{'>>'}</Typography>
          <Typography
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={onInstallPWA}
          >
            לחצו כאן
          </Typography>
        </Stack>
      )}
      <PwaInstallModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </Container>
  );
};

export default OneBigButton;
