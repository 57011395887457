import { isNumber } from 'lodash';

import { endpoints } from 'src/utils/axios';

import { HOST_API } from 'src/constants/common';
import { ACTIVITY_ID, MERCHANT_ID } from 'src/config-global';

export async function fetchJSON(
  url: string,
  params?: Record<string, string | string[] | undefined>
) {
  const uniqueParam = new Date().getTime();
  let fullURL = `${HOST_API}${url}?_=${uniqueParam}`;

  if (params) {
    const paramArrays: Record<string, string[]> = {};
    const paramsRefined = Object.entries(params).reduce((acc, paramItem: [string, any]) => {
      const [key, value] = paramItem;

      if (!Array.isArray(value) || !!value || isNumber(value)) return { ...acc, [key]: value };
      if (Array.isArray(value)) paramArrays[key] = value;
      return acc;
    }, {});
    const urlParams = new URLSearchParams(paramsRefined);

    Object.entries(paramArrays).forEach(([key, paramValues]) => {
      if (Array.isArray(paramValues)) {
        paramValues.forEach((paramValue) => urlParams.append(key, paramValue));
      }
    });

    urlParams.forEach((value, key) => {
      fullURL += `&${key}=${value}`;
    });
  }

  try {
    const rawResult = await fetch(fullURL, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0' // Устанавливаем дату истечения в прошлое
      }
    });

    if (!rawResult.ok) {
      console.error(`HTTP error! status: ${rawResult.status}`);
      return { isError: true, message: `HTTP error! status: ${rawResult.status}` };
    }

    const result = await rawResult.json();

    return result;
  } catch (err) {
    return { isError: true, message: err.message };
  }
}

export async function getSections() {
  const result = await fetchJSON(endpoints.generator.getSections, {
    activityId: ACTIVITY_ID,
    merchantId: MERCHANT_ID,
  });
  return result;
}

// export async function getSectionsByDomain(domain: string) {
//   const activityResponse = await fetchJSON(endpoints.activity.getActivity, { domain });
//   const activityId = activityResponse?.payload?.id;
//   // console.log('activityId', activityId);
//   const result = await fetchJSON(endpoints.generator.getSections, {
//     activityId,
//   });
//   return result;
// }

const cache: Record<string, { data: any; expiration: number }> = {};

export async function getSectionsByDomain(domain: string, cacheDuration = 60000) {
  const now = Date.now();

  if (cache[domain] && cache[domain].expiration > now) {
    return cache[domain].data;
  }

  const activityResponse = await fetchJSON(endpoints.activity.getActivity, { domain });
  const activityId = activityResponse?.payload?.id;

  const result = await fetchJSON(endpoints.generator.getSections, { activityId });

  cache[domain] = {
    data: result,
    expiration: now + cacheDuration,
  };

  return result;
}
