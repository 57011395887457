'use client';

import { useState } from 'react';

import { Box, Stack, alpha, useTheme, Typography } from '@mui/material';

import Iconify from '../../../../../components/iconify';

type Props = {
  icon: any;
  text: string;
};

const SixHoverBlocksItem = ({ icon, text }: Props) => {
  const theme = useTheme();
  const [hoverd, setHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: `0 4px 4px 0 ${alpha(theme.palette.secondary.lighter, 0.1)}`,
        borderRadius: 4,
        bgcolor: alpha(theme.palette.primary.lighter, 0.2),
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.shorter,
        }),
        '&:hover': {
          bgcolor: theme.palette.primary.light,
          boxShadow: `0 4px 4px 0 ${alpha(theme.palette.secondary.light, 0.1)}`,
          cursor: 'pointer',
          color: theme.palette.common.white,
        },
        px: 1,
        py: 3,
      }}
    >
      <Stack alignItems="center">
        <Iconify
          icon={icon}
          width={75}
          color={hoverd ? theme.palette.common.white : theme.palette.secondary.main}
        />
        <Typography
          textAlign="center"
          color={hoverd ? theme.palette.common.white : theme.palette.secondary.main}
        >
          {text}
        </Typography>
      </Stack>
    </Box>
  );
};

export default SixHoverBlocksItem;
