'use client';

import { useState } from 'react';

import { Box, Stack, Button, Checkbox, Typography } from '@mui/material';

import { ComponentTypeProps } from 'src/types/page-generator';

export const MessageCityPeople = ({ block }: ComponentTypeProps) => {
  const [isCode, setIsCode] = useState(false);

  const handleClick = () => {
    if (!isCode) {
      setIsCode(true);
    }
  };

  return (
    <Box>
      <Stack p={3} gap={2} alignItems="center" sx={{ m: '0 auto' }} maxWidth="460px">
        <Typography color="primary" sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'start' }}>
          אימות חשבון
        </Typography>

        <Box display="flex" alignItems="flex-start" mt={1}>
          <Checkbox onChange={() => setIsCode(!isCode)} sx={{ p: 0, m: '4px 10px' }} />
          <Box>
            <Typography>אני מאשר/מאשרת תנאי שימוש במערכת</Typography>
            <Typography sx={{ textDecoration: 'underline', fontSize: 12 }}>
              לקריא תקנון השימוש
            </Typography>
          </Box>
        </Box>
        <Stack direction="row" gap={2} width="100%">
          <Button
            onClick={handleClick}
            fullWidth
            variant="contained"
            color="secondary"
            sx={{ borderRadius: 3, mt: 2, color: 'rgb(0, 68, 84)' }}
            size="large"
            disabled={!isCode}
          >
            <Typography>אישור</Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
