'use client';

import { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useLocales } from '../../locales';
import { BlockType } from '../../types/generator';
import { IVirtualCard } from '../../types/club-cards';
import WalletCardsTab from '../wallet/components/wallet-cards-tab';
import { SelectCoupon } from '../wallet/components/Coupons/SelectCoupon';

type Props = {
  block: BlockType
}

export const SelectedCoupons = ({ block }: Props) => {
  const text: string = block?.title as string;
  const { currentLang } = useLocales();
  const [openProduct, setOpenProduct] = useState<IVirtualCard | null>(null);
  const [openP2P, setOpenP2P] = useState<IVirtualCard | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [clubCardInfo, setClubCardInfo] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };

  const changeHistory = (id: string) => {
    // setOpenHistory(id);
  };
  return (
    <Stack m={1}>
      <Typography
        sx={{
          '& h1': { my: 1 },
          '& h2': { my: 1 },
          '& h3': { my: 1 },
          '& h4': { my: 1 },
          '& h5': { my: 1 },
          '& h6': { my: 1 },
          '& p': { margin: 0 },
          direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
          width: '100%',
          lineHeight: block?.settings?.lineHeight,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: text }} />
        {/* <SanitizeHTML html={contentText} /> */}
      </Typography>
      {/* <Typography */}
      {/*  sx={{ */}
      {/*    direction: currentLang.value === 'he' ? 'rtl' : 'ltr', */}
      {/*    color: block?.settings?.textColor, */}
      {/*    fontSize: block?.settings?.fontSize, */}
      {/*    fontStyle: block?.settings?.fontStyle, */}
      {/*    fontWeight: block?.settings?.fontWeight, */}
      {/*    textDecoration: block?.settings?.textDecoration, */}
      {/*    textAlign: block?.settings?.contentAlign, */}
      {/*    width: '100%', */}
      {/*    lineHeight: block?.settings?.lineHeight, */}
      {/*    mb: 1.5, */}
      {/*  }} */}
      {/* > */}
      {/*  {text} */}
      {/* </Typography> */}
      <Box>
        <WalletCardsTab
          selected
          openProduct={openProduct}
          setOpenProduct={setOpenProduct}
          setOpenP2P={setOpenP2P}
          block={block}
          openHistory={changeHistory}
          openModal={openModal}
          onChangeClubCardInfo={() => {
            setClubCardInfo(true);
          }}
        />
        <Box sx={{ my: 1, width: '50%' }} />
        <SelectCoupon selected />
      </Box>
    </Stack>
  );
};
