'use client';

import { FC, useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks';
import { useAppContext } from 'src/contexts/AppContext';

import { ComponentTypeProps } from 'src/types/page-generator';

export const InfoField: FC<ComponentTypeProps> = ({ block }) => {
  const { user } = useAuthContext();
  const { state } = useAppContext();
  const { t } = useTranslate();
  const theme = useTheme();

  const isClient = !state?.customer?.merchants?.length;
  const merchantName = !isClient && state?.customer?.merchants[0]?.title;
  const [firstName, lastName] = useMemo(() => {
    if (state.customer?.firstName || state?.customer?.lastName) {
      return [state?.customer?.firstName || '', state?.customer?.lastName || ''];
    }

    if (user?.firstName || user?.lastName) {
      return [user?.firstName || '', user?.lastName || ''];
    }

    return ['', t('Guest')];
  }, [user, state.customer]);

  const baseBlockStyles = {
    fontWeight: block?.settings?.textFontWeight,
    color: block?.settings?.textColor,
    fontSize: block?.settings?.textFontSize,
  };

  const userGreetingRowStyles = block?.settings?.fixPosition
    ? {
        position: 'fixed',
        top: '110px',
        '& p': baseBlockStyles,
      }
    : {
        '& p': baseBlockStyles,
      };

  return (
    <>
      {user && (
        <Stack direction="row" gap={1} sx={userGreetingRowStyles}>
          <Typography>היי</Typography>
          {isClient ? (
            <>
              <Typography>{firstName}</Typography>
              <Typography>{lastName}</Typography>
            </>
          ) : (
            <Typography>{merchantName || 'Merchant'}</Typography>
          )}
        </Stack>
      )}
    </>
  );
};
