import { Box } from '@mui/material';

import { IconPropsType } from '../types';

function StatisticBudgetIcon({ width, color = '#FFF', height }: IconPropsType) {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height ?? width}
      fill="none"
      viewBox="0 0 120 120"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M60 113.5c29.685 0 53.75-24.065 53.75-53.75S89.685 6 60 6 6.25 30.065 6.25 59.75 30.315 113.5 60 113.5z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M60 91.25c17.259 0 31.25-13.991 31.25-31.25S77.259 28.75 60 28.75 28.75 42.741 28.75 60 42.741 91.25 60 91.25z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M46.985 73.275A7.845 7.845 0 0054.64 77h5.033a10.657 10.657 0 0010.037-8.5 6.88 6.88 0 00-6.93-8.5h-5.56a6.881 6.881 0 01-6.93-8.5A10.658 10.658 0 0160.328 43h5.032a7.845 7.845 0 017.655 3.725M63.108 43l.777-4.25m-7.77 42.5l.778-4.25M60 91.25v22.497M32.985 44.29L13.538 32.98M87.16 44.545l19.555-11.128"
      />
    </Box>
  );
}

export default StatisticBudgetIcon;
