'use client';

import StarIcon from '@mui/icons-material/Star';

const FiveStar = () => {
  const arr = [0, 1, 2, 3, 4];
  return (
    <>
      {arr.map((el, i) => (
        <StarIcon
          className="fa fa-star checked"
          sx={{ color: 'primary.main' }}
        />
      ))}
    </>
  );
};

export default FiveStar;
