import { TFunction, DefaultNamespace } from 'i18next';

import { BasicPageParams } from './common';
import { IBlockClubCard, ICheckoutDeliveryOption } from './checkout';
// eslint-disable-next-line import/no-cycle
import { INavItem, BlockTypes, FieldValue, ButtonField, BlockVariants } from './generator';
import { ICustomer } from './customers';

export const blockTypes = [
  { name: 'banner', title: 'Banner', icon: 'solar:gallery-bold-duotone' },
  { name: 'filter', title: 'Filter', icon: 'solar:filter-bold-duotone' },
  { name: 'textblock', title: 'Text Section', icon: 'solar:text-field-focus-bold-duotone' },
  { name: 'cards', title: 'Cards', icon: 'solar:album-bold-duotone' },
  { name: 'swiper', title: 'Swiper', icon: 'solar:slider-vertical-bold-duotone' },
  { name: 'carousel', title: 'Carousel', icon: 'solar:posts-carousel-horizontal-bold-duotone' },
];

export type ApplySetting = (key: string, val: FieldValue) => void;

export interface BlockFieldsProps {
  block: BlockType;
  applySetting: ApplySetting;
  fieldsPattern: Record<BlockTypes, SettingsField[]>;
  parentField?: 'settings';
}

// export type FieldValue =
//   | string
//   | null
//   | number
//   | Record<string, any>
//   | Record<string, any>[]
//   | boolean
//   | string[]; // file URLs

export type SelectOptionType = {
  label: string;
  value: string | number;
  image?: string;
  icon?: string;
};

type Link = {
  path: string;
  label: string;
  icon?: string;
};

export type BlockPaymentMethod = {
  name: string;
  isEnable: boolean;
};

export type BlockType = {
  type: BlockTypes;
  name: string;
  settings: Record<string, any>;
  title?: string;
  contentText?: string;
  actionButtons?: ButtonField[];
  isServiceBlock?: boolean;
  isHidden?: boolean;
  isHideTitle?: boolean;
  links?: INavItem[];
  burgerLinks?: INavItem[];
  menuButtons?: ButtonField[];
  description?: string;
  logo?: string;
  copyright?: string;
  socialLinks?: Link[];
  footerLinks?: Link[];
  mobileLinks?: Link[];
  paymentMethods?: BlockPaymentMethod[];
  isDeliveryEnable?: boolean;
  deliveryMethods?: ICheckoutDeliveryOption[];
  clubCards?: IBlockClubCard[];
  [key: string]: FieldValue | undefined;
};

export enum FieldTypes {
  selectByThumbs = 'selectByThumbs',
  radiogroup = 'radiogroup',
  checkbox = 'checkbox',
  select = 'select',
  autocomplete = 'autocomplete',
  number = 'number',
  input = 'input',
  list = 'list',
  settingsList = 'settingsList',
  textBlocksList = 'textBlocksList',
  iconLinksList = 'iconLinksList',
  checkboxList = 'checkboxList',
  text = 'text',
  richtext = 'richtext',
  subheader = 'subheaer',
  upload = 'upload',
  switch = 'switch',
  buttons = 'buttons',
  group = 'group',
  color = 'color',
  code = 'code',
}

export type SettingsField = {
  name: string;
  type: FieldTypes;
  label: string;
  rowSize: 1 | 2 | 3 | 4;
  defaultValue?: FieldValue;
  options?: SelectOptionType[];
  uploadType?: 'image' | 'file';
  multiple?: boolean;
  showOn?: string;
  hideOn?: string;
  extra?: {
    direction?: 'row' | 'column';
    codeType?: CodeTypes;
    height?: string;
    placeholder?: string;
  };
  hideByDefault?: boolean;
  // for colors
  isColor?: boolean;
  // for list type
  noChildren?: boolean;
  fields?: SettingsField[];
};

export interface MainCarouselItem {
  title?: string;
  imgUrl?: string;
  buttonLik?: string;
  buttonText?: string;
  description?: string;
}
export enum CodeTypes {
  js = 'js',
  css = 'css',
  html = 'html',
}

export type ComponentTypeProps = {
  block?: BlockType;
  params?: BasicPageParams | any;
  t?: TFunction<DefaultNamespace, undefined>;
  selectedReport?: any;
  customersProps?: ICustomer[];
};

export type ListItemType = {
  path: string;
  icon: string;
  label: string;
  children?: ListItemType[];
};

export type BlocksTab = {
  title: string;
  icon: string;
  content: BlockType[];
};

export type BlocksTabs = {
  main: BlocksTab;
  [key: string]: BlocksTab;
};

export type SettingsFieldsPresetsByGroupTypePart = Record<BlockVariants, SettingsField[]>;
export type SettingsFieldsPresetsByGroupType = Record<
  BlockTypes,
  SettingsFieldsPresetsByGroupTypePart
>;
