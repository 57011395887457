'use client';

import Carousel from 'react-slick';
import { FC, useMemo } from 'react';

import { Grid, Container } from '@mui/material';

import PostItem from './post-item';
import { useRouter } from '../../../../routes/hooks';
import { getContentValueFromProps } from '../../utils';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { CarouselArrows, CarouselDots, useCarousel } from '../../../../components/carousel';

type BlogType = {
  content: string;
  enableComments: boolean;
  enableTitle: boolean;
  enableSubtitle: boolean;
  enableLinkText: boolean;
  enablePublicationDate: boolean;
  enableShare: boolean;
  enableViews: boolean;
  enableTextAfterPhoto: boolean;
  mainImage: string;
  mainName: string;
  subTitle: string;
  linkText: string;
  name: string;
  size: string;
  link: string;
};

const Blogs: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const router = useRouter();
  const blogs = useMemo(() => {
    const categoriesValue = getFieldValue('blogs');
    if (Array.isArray(categoriesValue)) {
      return categoriesValue.map((blog: BlogType) => ({
        ...blog,
      }));
    }
    return [];
  }, [block?.blogs]);
  const isMobile = useResponsive('down', 'sm');

  const responsive = [
    { breakpoint: 1024, settings: { slidesToShow: 3 } },
    { breakpoint: 650, settings: { slidesToShow: 2 } },
    { breakpoint: 426, settings: { slidesToShow: 1 } },
  ];

  const size = (blog: any) => {
    let blockSize;
    if (blog.size === 'big') blockSize = 6;
    if (blog.size === 'small') blockSize = 3;
    return blockSize;
  };

  const carousel = useCarousel({
    slidesToShow: 4,
    slidesToScroll: 1,
    ...CarouselDots({
      sx: { mt: 3 },
    }),
  });

  return (
    <>
      {isMobile ? (
        <Container maxWidth="xl" sx={{ mt: 0, position: 'relative' }}>
          <CarouselArrows
            filled
            onNext={carousel.onNext}
            onPrev={carousel.onPrev}
            leftButtonProps={{
              size: 'small',
              sx: { top: 'calc(50%)', left: -8 },
            }}
            rightButtonProps={{
              size: 'small',
              sx: { top: 'calc(50%)', right: -8 },
            }}
          >
            <Carousel ref={carousel.carouselRef} arrows slidesToShow={4} responsive={responsive}>
              {blogs.map((blog, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={size(blog)}
                  sx={{ cursor: 'pointer', height: '400px' }}
                  onClick={() => router.push(blog?.link)}
                >
                  <PostItem post={blog} index={index} />
                </Grid>
              ))}
            </Carousel>
          </CarouselArrows>
        </Container>
      ) : (
        <Grid container spacing={2} sx={{ direction: 'rtl', justifyContent: 'center' }}>
          {blogs.map((blog, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={size(blog)}
              sx={{ cursor: 'pointer', height: '336px' }}
              onClick={() => router.push(blog?.link)}
            >
              <PostItem post={blog} index={index} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Blogs;
