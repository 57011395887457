import { FieldTypes, SettingsField } from '../../../../../../types/page-generator';

const contentFields: SettingsField[] = [
  {
    name: 'modalId',
    type: FieldTypes.input,
    defaultValue: 'login',
    label: 'Modal Identifier (required)',
    rowSize: 2,
  },
  {
    name: 'mainImage',
    type: FieldTypes.upload,
    uploadType: 'image',
    rowSize: 4,
    label: 'Main image',
    defaultValue: '',
  },
];

export default contentFields;
