'use client';

import React from 'react';
import { Box, Typography, Button, Stack, Link } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Image from '../../../../../components/image';

type Props = {
  img: string;
  header: string;
  text: string;
  link: string;
};

const SpecialistAbout = ({ img, header, text, link }: Props) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      mb: 2,
      justifyContent: 'space-between',
    }}
  >
    <Box sx={{ mr: 2 }}>
      <Image
        src={img}
        alt="Doctor"
        style={{
          width: '80px',
          height: '80px',
          borderRadius: '50%',
          objectFit: 'cover',
        }}
      />
    </Box>
    <Box>
      <Typography variant="h6" sx={{ textAlign: 'right' }}>
        {header}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ textAlign: 'right' }}
      >
        {text}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
        <StarIcon color="primary" sx={{ width: '20px' }} />
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: '12px' }}
        >
          5.0 (1435 ביקורות)
        </Typography>
      </Stack>
    </Box>
    <Link style={{ textDecoration: 'none' }}>
      <Button
        variant="contained"
        sx={{
          color: 'primary.main',
          borderRadius: '20px',
          bgcolor: 'lightgray',
          '&:hover': {
            bgcolor: 'lightgray',
          },
        }}
      >
        אודות
      </Button>
    </Link>
  </Box>
);

export default SpecialistAbout;
