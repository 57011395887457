'use client';

import React from 'react';

import { Box, Grid } from '@mui/material';
import BestDealsSquare from './best-deal-square';

const BestDealsSquareList = () => {
  const BestDealsListArr = [
    {
      img: '/assets/images/marketPlace/product5.jpg',
      title: 'ניקוי חלונות',
      price: '65$',
      link: '/productdetails',
    },
    {
      img: '/assets/images/marketPlace/product6.jpg',
      title: 'תיקון נזילות',
      price: '70$',
      link: '/productdetails',
    },
  ];

  return (
    <Grid container spacing={2} width={1} sx={{ direction: 'rtl' }}>
      {BestDealsListArr.map((el, i) => (
        <Grid item xs={6} key={i}>
          <BestDealsSquare
            img={el.img}
            title={el.title}
            price={el.price}
            link={el.link}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default BestDealsSquareList;
