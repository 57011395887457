import { Box, BoxProps } from '@mui/material';

const QuestionMenuIcon = ({ ...other }: BoxProps) => (
  <Box
    component="svg"
    width="100%"
    height="100%"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...other}
  >
    <path
      d="M17.8384 33C26.0483 33 32.7037 26.2843 32.7037 18C32.7037 9.71573 26.0483 3 17.8384 3C9.62855 3 2.97314 9.71573 2.97314 18C2.97314 26.2843 9.62855 33 17.8384 33Z"
      stroke="#004454"
      strokeWidth="1.5"
    />
    <path
      d="M15.0513 13.3125C15.0513 11.7592 16.2992 10.5 17.8385 10.5C19.3778 10.5 20.6257 11.7592 20.6257 13.3125C20.6257 14.3437 20.0757 15.2452 19.2558 15.7348C18.5491 16.1566 17.8385 16.7966 17.8385 17.625V19.5"
      stroke="#004454"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M17.8381 25.5C18.6591 25.5 19.3246 24.8284 19.3246 24C19.3246 23.1716 18.6591 22.5 17.8381 22.5C17.0171 22.5 16.3516 23.1716 16.3516 24C16.3516 24.8284 17.0171 25.5 17.8381 25.5Z"
      fill="#004454"
    />
  </Box>
);

export default QuestionMenuIcon;
