import { PriceInterface } from 'src/types/b2b';
import { PriceTypeEnum } from 'src/types/product';

interface PriceObject {
  minPrice?: number;
  maxPrice?: number;
  basePrice?: number;
  discount?: number;
  businessPrice?: number;
  price?: number;
}

export function extractPrices(prices: PriceInterface[] | undefined) {
  const priceObj = prices?.reduce((acc, price) => {
    switch (price.type) {
      case PriceTypeEnum.MIN_PRICE:
        acc.minPrice = price.value;
        break;
      case PriceTypeEnum.MAX_PRICE:
        acc.maxPrice = price.value;
        break;
      case PriceTypeEnum.BASE_PRICE:
        acc.basePrice = price.value;
        break;
      case PriceTypeEnum.DISCOUNT:
        acc.discount = price.value;
        break;
      case PriceTypeEnum.BUSINESS_PRICE:
        acc.businessPrice = price.value;
        break;
      case PriceTypeEnum.PRICE:
        acc.price = price.value;
        break;
      default:
        break;
    }
    return acc;
  }, {} as PriceObject);

  return priceObj || {};
}
