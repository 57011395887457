const config = {
  companyName: 'Xtra',

  builder: {
    logo: 'https://api-xtra.b24online.com/upload/597a022b-432a-429d-aeb3-a565b832d61c.png',
    theme: {
      palette: {
        primary: {
          lighter: '#633689',
          light: '#4E1F74',
          main: '#3A0D5E',
          dark: '#270444',
          darker: '#170129',
          contrastText: '#FFFFFF',
        },
        secondary: {
          lighter: '#FFA177',
          light: '#F16323',
          main: '#F16323',
          dark: '#C64409',
          darker: '#9D3100',
          contrastText: '#FFFFFF',
        },
      },
    },
  },
};

export default config;
