import useSWR from 'swr';
import { useMemo } from 'react';

import { fetcher, endpoints } from 'src/utils/axios';

import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';
// import { LOCAL_STORAGE_KEYS } from 'src/constants/localStorageKeys';
// import { getDataFromLocaleStorage } from 'src/helpers/localStorage';

import { IProductItem } from 'src/types/product';
import { getDomainName } from '../utils/client-common';

export type GetProductProps = {
  limit: number;
  offset: number;
  minPrice?: number;
  maxPrice?: number;
  dispatch?: React.Dispatch<ActionType>;
  search?: string;
  categoriesIds?: string[];
  tagsIds?: string[];
  priceType?: string;
  merchantTagsIds?: string[];
};
export type SearchProductsProps = GetProductProps & {
  categoryIds?: string[];
  merchantIds?: string[];
  productTagIds?: string[];
  merchantTagIds?: string[];
};
export type GetProductByIdProps = {
  limit: number;
  offset: number;
  minPrice?: number;
  maxPrice?: number;
  dispatch?: React.Dispatch<ActionType>;
  search?: string;
  id?: string | string[];
  categoriesIds: string[];
};

type GetReportsProps = {
  limit: number;
  offset: number;
  dispatch?: React.Dispatch<ActionType>;
  search?: string;
};

export function useGetReports(filter: GetReportsProps) {
  const URL = [endpoints.report.getAll, { params: { ...filter } }];

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });
  const memoizedValue = useMemo(
    () => ({
      reports: (data?.payload as IProductItem[]) || [],
      reportsCount: data?.count,
      loading: isLoading,
      error,
      validating: isValidating,
      empty: !isLoading && !data?.payload.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export const getReports = async (filter: GetReportsProps) => {
  try {
    const { data } = await API({
      url: endpoints.report.getAll,
      method: 'GET',
      params: { ...filter },
    });
    return data;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const createReport = async (body: any) => {
  try {
    const { data } = await API({
      url: `/report`,
      method: 'POST',
      data: body,
    });
    return data;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const deleteReport = async (id: string) => {
  try {
    const { data } = await API({
      url: `/report/${id}`,
      method: 'DELETE',
    });
    return data;
  } catch (e) {
    console.error(e);
  }
  return null;
};
export const createTicket = async (body: any) => {
  try {
    const { data } = await API({
      url: `/ticket`,
      method: 'POST',
      data: body,
    });
    return data;
  } catch (e) {
    console.error(e);
  }
  return null;
};
