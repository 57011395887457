'use client';

import { FC } from 'react';

import { Grid } from '@mui/material';

import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';

const BigBannerElements: FC<ComponentTypeProps> = ({ block }) => {
  const firstImage: any = block?.firstImage;
  const secondImage: any = block?.secondImage;
  const thirdImage: any = block?.thirdImage;
  return (
    <Grid container spacing={1}>
      <Grid item xs={3.8}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Image src={secondImage} sx={{ borderRadius: '15px' }} />
          </Grid>
          <Grid item xs={12}>
            <Image src={thirdImage} sx={{ borderRadius: '15px' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8.2}>
        <Image
          src={firstImage || ''}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '15px',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default BigBannerElements;
