import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { LoadingButton } from '@mui/lab';
import { Stack, Button, TextField, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { BlockType } from '../../../types/page-generator';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import { IVirtualCard } from '../../../types/club-cards';
import { useGetActivityByDomain } from '../../../api/activities';
import { virtualCardTransfer } from '../../../api/virtualCardType';

type Props = {
  onClose: Function;
  block: BlockType | undefined;
  openP2P: IVirtualCard | null;
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const P2PDialog = ({ onClose, block, openP2P }: Props) => {
  const [currentTab, setCurrentTab] = useState(1);
  const minTransfer = block?.settings?.minTransfer;
  const maxTransfer = block?.settings?.maxTransfer;
  const hoursToExpire = block?.settings?.hoursToExpire;
  const { activity } = useGetActivityByDomain();
  const createValidationSchema = () =>
    Yup.object().shape({
      email:
        currentTab === 0
          ? Yup.string().required('Email is required').email('Email must be a valid email address')
          : Yup.string().email('Email must be a valid email address'),
      phone: currentTab === 1 ? Yup.string().required('Phone is required') : Yup.string(),
      value: Yup.number()
        .required('Value is required')
        .min(minTransfer, `Value must be at least ${minTransfer}`)
        .max(maxTransfer, `Value must be less than or equal to ${maxTransfer}`),
      hoursToExpire: Yup.string(),
    });

  const [schema, setSchema] = useState(createValidationSchema());

  useEffect(() => {
    setSchema(createValidationSchema());
  }, [currentTab, minTransfer, maxTransfer]);

  const defaultValues = {
    email: '',
    phone: '',
    value: +minTransfer || 0,
    hoursToExpire: '',
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const value = useWatch({
    name: 'value',
    control: methods.control,
  });

  const values = watch();

  const closeModal = () => {
    onClose();
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      const newData: any = {};
      newData.hoursToExpire = hoursToExpire;
      newData.phoneNumber = data.phone;
      newData.email = data.email;
      newData.amount = data.value;
      newData.virtualCardId = openP2P?.id;
      newData.activityId = activity?.id;
      virtualCardTransfer(newData).then((response) => {
        console.log('response', response.success === true);
        if (response.success === true) {
          closeModal();
        }
      });
    } catch (error) {
      console.error(error);
    }
  });

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handlePhoneChange = (val: string) => {
    setValue('phone', val);
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <DialogTitle maxWidth="100%">
        <Tabs value={currentTab} onChange={handleChangeTab} aria-label="login tabs" centered>
          <Tab label="Email" {...a11yProps(0)} />
          <Tab label="Phone" {...a11yProps(1)} />
        </Tabs>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            width: '100%',
            textAlign: 'center',
          }}
        >{`The order will be valid for ${hoursToExpire} hours`}</Typography>
        <Stack mt={2} gap={1}>
          {currentTab === 0 ? (
            <RHFTextField
              variant="outlined"
              name="email"
              placeholder="Enter the email"
              helperText={
                errors.email ? (
                  <Typography sx={{ fontSize: '10px', color: 'red' }}>
                    {errors.email.message}
                  </Typography>
                ) : null
              }
            />
          ) : (
            <TextField
              name="phone"
              placeholder="Enter the phone"
              value={values.phone || ''}
              onChange={(e) => handlePhoneChange(e.target.value)}
              error={!!errors.phone}
              helperText={errors.phone ? errors.phone.message : ''}
            />
          )}
          <RHFTextField
            variant="outlined"
            name="value"
            placeholder="Enter the value"
            helperText={
              errors.value && (
                <Typography sx={{ fontSize: '10px', color: 'red' }}>
                  {errors.value.message}
                </Typography>
              )
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <LoadingButton variant="contained" color="primary" type="submit" loading={isSubmitting}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
};
