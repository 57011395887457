import { FieldTypes, SettingsField } from 'src/types/page-generator';

const settings: SettingsField[] = [
  {
    name: 'setting',
    type: FieldTypes.input,
    defaultValue: '',
    label: 'Setting',
    rowSize: 2,
  },
];

export default settings;
