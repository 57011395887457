'use client';

import { FC, useMemo } from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
  Stack,
  Accordion,
  Container,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import { FAQItem, FAQ_CITY_PEOPLE } from 'src/_mock/_faq-CP';

import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';
import { useResponsive } from '../../../../hooks/use-responsive';

const FAQCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const isMobile = useResponsive('down', 'sm');

  // === Content Values ===
  const faqList: FAQItem[] = useMemo(() => getFieldValue('faqList'), [block?.faqList]);

  // === Settings Values ====
  const contentQuestionFontSize = useMemo(
    () => getFieldValue('settings.contentQuestionFontSize'),
    [block?.settings.contentQuestionFontSize],
  );
  const contentQuestionTextColor = useMemo(
    () => getFieldValue('settings.contentQuestionTextColor'),
    [block?.settings.contentQuestionTextColor],
  );
  const contentQuestionFontWeight = useMemo(
    () => getFieldValue('settings.contentQuestionFontWeight'),
    [block?.settings.contentQuestionFontWeight],
  );
  const contentQuestionFontStyle = useMemo(
    () => getFieldValue('settings.contentQuestionFontStyle'),
    [block?.settings.contentQuestionFontStyle],
  );
  const contentQuestionTextDecoration = useMemo(
    () => getFieldValue('settings.contentQuestionTextDecoration'),
    [block?.settings.contentQuestionTextDecoration],
  );

  const contentAnswerTextColor = useMemo(
    () => getFieldValue('settings.contentAnswerTextColor'),
    [block?.settings.contentAnswerTextColor],
  );
  const contentAnswerFontSize = useMemo(
    () => getFieldValue('settings.contentAnswerFontSize'),
    [block?.settings.contentAnswerFontSize],
  );
  const contentAnswerFontWeight = useMemo(
    () => getFieldValue('settings.contentAnswerFontWeight'),
    [block?.settings.contentAnswerFontWeight],
  );
  const contentAnswerFontStyle = useMemo(
    () => getFieldValue('settings.contentAnswerFontStyle'),
    [block?.settings.contentAnswerFontStyle],
  );
  const contentAnswerTextDecoration = useMemo(
    () => getFieldValue('settings.contentAnswerTextDecoration'),
    [block?.settings.contentAnswerTextDecoration],
  );

  const contentAnswerTextAlign = useMemo(
    () => getFieldValue('settings.contentAnswerTextAlign'),
    [block?.settings.contentAnswerTextAlign],
  );

  const size = block?.settings?.size || 'sm';

  return (

    <Container maxWidth={isMobile ? 'md' : size} sx={{ mb: { xs: 5 }, p: { xs: 0, md: 0 } }}>
      <Stack spacing={2} sx={{ direction: block?.settings?.forceRtl ? 'rtl' : 'ltr' }}>
        {(faqList || FAQ_CITY_PEOPLE).map((item: FAQItem, idx: number) => (
          <Accordion key={idx}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  paddingRight: '24px',
                  color: contentQuestionTextColor,
                  fontSize: contentQuestionFontSize,
                  fontStyle: contentQuestionFontStyle,
                  fontWeight: contentQuestionFontWeight,
                  textDecoration: contentQuestionTextDecoration,
                }}
              >
                {`${idx + 1}. ${item.question}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  textAlign: contentAnswerTextAlign,
                  color: contentAnswerTextColor,
                  fontSize: contentAnswerFontSize,
                  fontStyle: contentAnswerFontStyle,
                  fontWeight: contentAnswerFontWeight,
                  textDecoration: contentAnswerTextDecoration,
                }}
                paragraph
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Container>
  );
};

export default FAQCityPeople;
