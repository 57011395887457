'use client';

// @mui
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';

// components
import Iconify from 'src/components/iconify';

import { BillingAddress } from 'src/types/address';

//
import CheckoutSummary from './checkout-summary';
import { useBoolean } from '../../hooks/use-boolean';
import { AddressItem, AddressNewForm } from '../address';

interface Props {
  checkoutNextStep: VoidFunction;
  checkoutPrevStep: VoidFunction;
  total: number;
  totalSum: number;
}

export default function CheckoutBillingAddress({
  checkoutNextStep,
  checkoutPrevStep,
  totalSum,
  total,
}: Props) {
  const [addresses, setAddresses] = useState<BillingAddress[]>([]);
  const addressForm = useBoolean();
  const { t } = useTranslation();

  const pushNewAdress = (data: BillingAddress) => {
    const _addresses = [...addresses];
    _addresses.push(data);
    setAddresses(_addresses);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={8}>
          {addresses.map((address, idx) => (
            <AddressItem
              key={idx}
              address={address}
              action={
                <Stack flexDirection="row" flexWrap="wrap" flexShrink={0}>
                  {!address.primary && (
                    <Button size="small" color="error" sx={{ mr: 1 }}>
                      {`${t('Delete')}`}
                    </Button>
                  )}
                  <Button variant="outlined" size="small" onClick={() => {}}>
                    {`${t('Deliver to this Address')}`}
                  </Button>
                </Stack>
              }
              sx={{
                p: 3,
                mb: 3,
                borderRadius: 2,
                // boxShadow: (theme) => theme.customShadows.card,
              }}
            />
          ))}

          <Stack direction="row" justifyContent="space-between">
            <Button
              size="small"
              color="inherit"
              onClick={checkoutPrevStep} // onBackStep
              startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
            >
              {`${t('Back')}`}
            </Button>

            <Button
              size="small"
              color="primary"
              onClick={addressForm.onTrue}
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {`${t('New Address')}`}
            </Button>
          </Stack>
        </Grid>

        <Grid xs={12} md={4}>
          <CheckoutSummary subTotal={total} totalSum={totalSum} discount={0} />
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            // disabled={empty}
            onClick={checkoutNextStep}
          >
            {`${t('Check Out')}`}
          </Button>
        </Grid>
      </Grid>

      <AddressNewForm
        open={addressForm.value}
        onClose={addressForm.onFalse}
        onCreate={pushNewAdress}
      />
    </>
  );
}
