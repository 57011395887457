'use client';

// locales
import { useLocales, useTranslate } from 'src/locales';
import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';

// types

// ----------------------------------------------------------------------

type Props = {};

export default function BankAccount() {
  const { t } = useTranslate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <EditIcon sx={{ cursor: 'pointer' }} />
          Edit
        </MenuItem>
      </Menu>

      <Card sx={{ border: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <Stack direction="column" gap={2} p={3}>
          {[1].map((card, index) => (
            <Stack key={index} direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                Paris Bank №12
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                237
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
                567778878
              </Typography>
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              </div>
            </Stack>
          ))}
        </Stack>
      </Card>
    </>
  );
}
