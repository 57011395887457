'use client';

import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { BlockType } from '../../../../types/generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import SelectGiftWrapper from '../../../../components/select-gift';
import { SelectCoupon } from '../../../wallet/components/Coupons/SelectCoupon';
import { BasicPageParams } from '../../../../types/common';
import { API } from '../../../../helpers/api';
import { BOT_ID } from '../../../../config-global';
import { ICoupon } from '../../../../types/coupons';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { useAuthContext } from '../../../../auth/hooks';

type Props = {
  block: BlockType | undefined;
  params: BasicPageParams | any;
};

export const MyCoupons = ({ block, params }: Props) => {
  const { state: { virtualCards },} = useAppContext();
  const { state, dispatch } = useAppContext();
  const { user } = useAuthContext();
  const activityId = state.smbActivityId;
  const enableSlider = block?.settings?.enableSlider || false;
  const [currentTab, setCurrentTab] = useState(-1);
  const [trigger, setTrigger] = useState(false);
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const localTabs = [
    {
      id: 1,
      label: 'הקופונים שלי',
      show: block?.settings?.enableMyCouponsTab,
    },
    {
      id: 0,
      label: 'מתנה לבחירה',
      show: block?.settings?.enableGiftOfChoiceTab,
    },
  ];
  const cardWithGiftSelectionEventId = virtualCards && virtualCards.find((card: any) => card.giftSelectionEventId);

  const init = async () => {
    if (activityId && user) {
      try {
        const { data } = await API({
          params: {
            botId: BOT_ID,
            smbActivityId: activityId,
          },
          url: `card`,
          method: 'GET',
        });
        if (data?.cards?.length) {
          setCoupons(data.cards);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (activityId && user) {
      init();
    }
  }, [activityId, user, trigger]);

  useEffect(() => {
    const idx: any = localTabs.find((tab) => tab.show)?.id;
    setCurrentTab(idx);
  }, []);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };
  const isMobile = useResponsive('down', 'sm');

  const title: string = block?.mainTitle as string;

  useEffect(() => {
    localStorage.setItem('itemId', '');
    localStorage.setItem('eventId', '');
  }, []);

  return (
    <> {!(coupons?.length) && !cardWithGiftSelectionEventId && (
      <Box sx={{ width: '100%', mb: 3 }}>
        {block?.settings?.enableTitle && (
          <Typography
            sx={{
              direction: block?.settings?.forceRtl ? 'rtl' : '',
              color: block?.settings?.contentTextColor,
              fontSize: block?.settings?.contentFontSize,
              fontStyle: block?.settings?.contentFontStyle,
              fontWeight: block?.settings?.contentFontWeight,
              textDecoration: block?.settings?.contentTextDecoration,
              textAlign: block?.settings?.contentAlign,
              width: 1,
            }}
          >
            {title}
          </Typography>
        )}{' '}
        <Tabs
          sx={{
            mb: 3,
            '& .MuiButtonBase-root': { fontSize: '20px' },
            '& .MuiTabScrollButton-root': {
              display: !isMobile || !localTabs.find((item: any) => item.show) ? 'none' : '',
            },
            '& .MuiTabs-flexContainer': { justifyContent: 'center' },
          }}
          value={currentTab}
          onChange={handleChangeTab}
        >
          {localTabs.map((tab, id) => (
            <Tab key={id} label={tab.label} sx={{ display: tab.show ? '' : 'none' }} />
          ))}
        </Tabs>
        {currentTab === 1 && <SelectGiftWrapper enableSlider={enableSlider} selectTabGiftWrapper={() => {
          setTrigger(true);
          setCurrentTab(0);
          dispatch({ type: ActionTypes.SET_TRIGGER, payload: true });
        }} block={block} />}
        {currentTab === 0 && <SelectCoupon />}
      </Box>
    )}</>
  );
};
