'use client';

import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import Image from '../../../../../components/image';

type Props = {
  img: string;
  title: string;
  price: string;
  link: string;
};

const BestDealsSquare: React.FC<Props> = ({ img, title, price, link }) => (
  <Box
    sx={{
      paddingBottom: 4,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <Link href={link} sx={{ width: '100%', textDecoration: 'none' }}>
      <Box
        sx={{
          width: '100%',
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <Image
          src={img}
          alt="service"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Box>
    </Link>

    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row-reverse',
        padding: '0 8px',
      }}
    >
      <Typography
        variant="body2"
        sx={{ fontWeight: 'bold', fontSize: 16, color: '#a5a6a8' }}
      >
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: 'primary.main', fontSize: 16 }}>
        {price}
      </Typography>
    </Box>
  </Box>
);

export default BestDealsSquare;
