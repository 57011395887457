'use client';

import { FC } from 'react';
import Box from '@mui/material/Box';
import { Button, Card, Container, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useLocales } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';

export const Service: FC<ComponentTypeProps> = ({ block }) => {
  const { currentLang } = useLocales();
  const combined: any = block?.combined || [];
  const router = useRouter();

  return (
    <Container maxWidth="xs">
      <Stack>
        <Image
          src="/assets/images/cleaning.jpeg"
          sx={{ width: '100%', height: '100%' }}
        />
      </Stack>
    </Container>
  );
};
