'use client';

import * as React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts/PieChart';
import Typography from '@mui/material/Typography';
import { useDrawingArea } from '@mui/x-charts/hooks';

import { useResponsive } from '../../../../../../hooks/use-responsive';

type Item = { label: string; value: number };

type Props = {
  title: string;
  data: Item[];
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function Statistics({ title, data }: Props) {
  const isMobile = useResponsive('down', 'sm');
  const size = {
    width: isMobile ? 200 : 400,
    height: 200,
  };
  return (
    <Box sx={{ '& svg:first-of-type': { width: '100%!important', height: '75%!important' } }}>
      <PieChart
        series={[{ data, innerRadius: 80 }]}
        {...size}
        slotProps={
          isMobile
            ? {
                legend: {
                  position: { vertical: 'middle', horizontal: 'left' },
                  direction: 'column',
                  itemMarkWidth: 5,
                  itemMarkHeight: 5,
                  markGap: 5,
                  itemGap: 5,
                  padding: -30,
                  labelStyle: {
                    fontSize: '14px',
                    direction: 'rtl',
                  },
                },
              }
            : {
                legend: {
                  labelStyle: {
                    direction: 'rtl',
                  },
                },
              }
        }
      >
        {!!title.length && (
          <Typography sx={{ fontWeight: 600, fontSize: '20px' }}>{title}</Typography>
        )}
        {/* <PieCenterLabel>Center label</PieCenterLabel> */}
      </PieChart>
    </Box>
  );
}
