import { Box } from '@mui/material';

import { IconPropsType } from '../types';

function DoneIcon({ width, color = '#FFF', height }: IconPropsType) {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height ?? width}
      fill="none"
      viewBox="0 0 120 120"
      margin="0px auto"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="5"
        d="M22.292 62.417l14.413 10.811a4.458 4.458 0 006.126-.744l37.42-45.734"
      />
    </Box>
  );
}

export default DoneIcon;
