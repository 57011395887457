import { Box } from '@mui/material';

import { IconPropsType } from '../types';

function SmileIcon({ width, color = '#FFF', height }: IconPropsType) {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height ?? width}
      fill="none"
      viewBox="0 0 106 106"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M53 102.214A49.214 49.214 0 1053 3.786 49.214 49.214 0 0053 102.214z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M35.964 37.857a1.893 1.893 0 110-3.785m0 3.785a1.893 1.893 0 100-3.785m34.072 3.785a1.893 1.893 0 110-3.785m0 3.785a1.893 1.893 0 100-3.785M53 83.285a26.5 26.5 0 0026.5-26.5h-53a26.5 26.5 0 0026.5 26.5z"
      />
    </Box>
  );
}

export default SmileIcon;
