function StatusChangeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      fill="none"
      viewBox="0 0 41 41"
    >
      <path
        stroke="#004454"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.25 34.167a3.417 3.417 0 100-6.834 3.417 3.417 0 000 6.834z"
      />
      <path
        fill="#004454"
        stroke="#004454"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M30.75 34.167a3.417 3.417 0 100-6.834 3.417 3.417 0 000 6.834z"
      />
      <path
        stroke="#004454"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.25 20.5v-3.417a10.25 10.25 0 1120.5 0V20.5"
      />
      <path
        stroke="#004454"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M25.625 15.375L30.75 20.5l5.125-5.125"
      />
    </svg>
  );
}

export default StatusChangeIcon;
