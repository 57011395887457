import { useScroll } from 'framer-motion';
import { useMemo, useState, useEffect, useCallback } from 'react';

// ----------------------------------------------------------------------

type ReturnType = boolean;

interface UseScrollOptions extends Omit<ScrollOptions, 'container' | 'target'> {
  container?: React.RefObject<HTMLElement>;
  target?: React.RefObject<HTMLElement>;
}

export function useOffSetBottom(bottom = 0, options?: UseScrollOptions): ReturnType {
  const { scrollY } = useScroll(options);

  const [value, setValue] = useState(false);

  const onOffSetBottom = useCallback(() => {
    scrollY.on('change', (scrollHeight) => {
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;
      const offsetFromBottom = documentHeight - (scrollHeight + windowHeight);

      if (offsetFromBottom < bottom) {
        setValue(true);
      } else {
        setValue(false);
      }
    });
  }, [scrollY, bottom]);

  useEffect(() => {
    onOffSetBottom();
  }, [onOffSetBottom]);

  const memoizedValue = useMemo(() => value, [value]);

  return memoizedValue;
}

// Usage
// const offset = useOffSetBottom(100);

// Or
// const offset = useOffSetBottom(100, {
//   container: ref,
// });
