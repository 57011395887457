import { Box, Button, Typography } from '@mui/material';

type PaidServicesSquareProps = {
  header: string;
  text: string;
  hour: string;
  price: string;
  i: number;
};

const PaidServicesSquare = ({
  header,
  text,
  hour,
  price,
  i,
}: PaidServicesSquareProps) => {
  const done = (e: React.MouseEvent) => {
    const btn = e.target as HTMLButtonElement;
    console.log(btn.style.color);
    if (btn.classList.contains('reportSquareBtnActive')) {
      btn.classList.remove('reportSquareBtnActive');
    } else {
      btn.classList.add('reportSquareBtnActive');
    }
  };

  let btnClassName = 'reportSquareBtn';
  if (i === 0) {
    btnClassName = 'reportSquareBtn reportSquareBtnActive';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: 2,
        marginBottom: 2,
        backgroundColor: '#fafafa',
        borderRadius: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 1,
          width: 0.7,
        }}
      >
        <Box>
          <Typography variant="body1">{hour}</Typography>
          <Typography variant="body2" sx={{ color: 'primary.main' }}>
            {price}$
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            {header}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {text}
          </Typography>
        </Box>
      </Box>
      <Button
        onClick={done}
        className={btnClassName}
        sx={{
          height: '30px',
          backgroundColor: 'primary.main',
          color: 'white',
          '&.reportSquareBtnActive': {
            backgroundColor: 'secondary.main',
            '&:hover': {
              backgroundColor: 'secondary.main',
            },
          },
          '&:hover': {
            backgroundColor: 'primary.main',
          },
        }}
      >
        בוצע
      </Button>
    </Box>
  );
};

export default PaidServicesSquare;
