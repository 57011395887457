// TODO: поправить

import { BlocksTabs, FieldTypes } from '../../types/page-generator';
import { IOSection, BlockTypes, FieldValue, SectionTypes } from '../../types/generator';

export type SectionType = {
  label: string;
  tabs?: BlocksTabs;
  icon?: string;
  value?: SectionTypes;
  editableBlocks?: boolean;
  children?: IOSection[];
  isCollection?: boolean;
  isExpansible?: boolean;
};

export type SectionSettingsType = {
  type: SectionTypes;
  settings: Record<string, FieldValue>;
};

export const SECTIONS: SectionType[] = [
  {
    label: 'General',
  },
  {
    label: 'Home',
    icon: 'solar:home-2-bold-duotone',
    value: SectionTypes.home,
    editableBlocks: true,
    tabs: {
      main: {
        title: 'Blocks',
        icon: 'solar:three-squares-bold-duotone',
        content: [],
      },
    },
  },
  {
    label: 'E-commerce',
    tabs: {
      main: {
        title: 'Blocks',
        icon: 'solar:three-squares-bold-duotone',
        content: [],
      },
    },
  },
  {
    label: 'Catalog',
    icon: 'solar:shop-bold-duotone',
    editableBlocks: true,
    value: SectionTypes.catalog,
    tabs: {
      main: {
        title: 'Blocks',
        icon: 'solar:three-squares-bold-duotone',
        content: [],
      },
    },
  },
  {
    label: 'Product',
    icon: 'solar:box-bold-duotone',
    value: SectionTypes.product,
    editableBlocks: true,
    tabs: {
      main: {
        title: 'Blocks',
        icon: 'solar:three-squares-bold-duotone',
        content: [],
      },
    },
  },
  {
    label: 'Cart',
    icon: 'solar:cart-large-bold-duotone',
    value: SectionTypes.cart,
    editableBlocks: true,
    tabs: {
      main: {
        title: 'Blocks',
        icon: 'solar:three-squares-bold-duotone',
        content: [],
      },
    },
  },
  {
    label: 'Wallet',
    icon: 'solar:wallet-bold-duotone',
    value: SectionTypes.wallet,
    editableBlocks: true,
    tabs: {
      main: {
        title: 'Blocks',
        icon: 'solar:three-squares-bold-duotone',
        content: [],
      },
    },
  },
  {
    label: 'Account',
    icon: 'solar:user-bold-duotone',
    value: SectionTypes.account,
    editableBlocks: true,
    tabs: {
      main: {
        title: 'Blocks',
        icon: 'solar:three-squares-bold-duotone',
        content: [],
      },
    },
  },
  { label: 'Info' },
  {
    label: 'Blog',
    icon: 'solar:pen-new-square-bold-duotone',
    value: SectionTypes.blog,
    tabs: {
      main: {
        title: 'Blocks',
        icon: 'solar:three-squares-bold-duotone',
        content: [],
      },
    },
  },
  {
    label: 'Informational',
    icon: 'solar:info-square-bold-duotone',
    value: SectionTypes.informational,
    isCollection: true,
    isExpansible: true,
    editableBlocks: true,
    tabs: {
      main: {
        title: 'Blocks',
        icon: 'solar:three-squares-bold-duotone',
        content: [],
      },
    },
  },
  { label: 'Settings' },
  {
    label: 'Theme',
    icon: 'solar:palette-bold-duotone',
    value: SectionTypes.themeSettings,
    tabs: {
      main: {
        title: 'Palette',
        icon: 'solar:pallete-2-bold-duotone',
        content: [
          {
            type: BlockTypes.themeSettings,
            name: 'Theme ',
            settings: {},
            isServiceBlock: true,
          },
        ],
      },
      css: {
        title: 'CSS',
        icon: 'solar:ruler-cross-pen-bold-duotone',
        content: [
          {
            type: BlockTypes.css,
            name: 'CSS ',
            settings: {},
            isServiceBlock: true,
          },
        ],
      },
      text: {
        title: 'Typography',
        icon: 'solar:text-field-focus-bold-duotone',
        content: [
          {
            type: BlockTypes.fontSettings,
            name: 'Typography',
            settings: {},
            isServiceBlock: true,
          },
        ],
      },
    },
  },
];

export const LOREM_IPSUM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Gravida neque convallis a cras semper auctor neque. Pharetra convallis posuere morbi leo urna. Enim neque volutpat ac tincidunt vitae semper quis lectus nulla. ';

export const SECTIONS_SETTINGS = {
  [SectionTypes.home]: [
    {
      name: 'enable_registration',
      type: FieldTypes.switch,
      defaultValue: true,
      title: 'Enable Registration',
      rowSize: 4,
    },
  ],
};

export const defaultDummy = {
  main: [
    {
      type: BlockTypes.header,
      name: 'Header',
      settings: {},
    },
    {
      type: BlockTypes.footer,
      name: 'Footer',
      settings: {},
    },
  ],
};
