'use client';

import { FC } from 'react';

import { Divider } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import { ComponentTypeProps } from '../../../../types/page-generator';

export const DividerBlock: FC<ComponentTypeProps> = ({ block }) => (
  <Divider sx={{ mt: block?.settings?.mt, mb: block?.settings?.mb, bgcolor: block?.settings?.color }} />
);
