'use client';

import { useParams } from 'next/navigation';
import { useMemo, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

import { Ib2bProduct } from '../../../../types/b2b';
import { getProduct } from '../../../../api/product';
import { getContentValueFromProps } from '../../utils';
import { BlockType } from '../../../../types/page-generator';
import ProductBoomBuyDetailsSummary from './product-boom-buy-details-summary';
import ProductDetailsCarousel from '../../../product/product-details-carousel';

type Props = {
  block: BlockType | undefined;
  product?: any;
};

const ProductBoomBuyDetailsView = ({ block, product }: Props) => {
  const getFieldValue = getContentValueFromProps(block);
  const mainProduct = useMemo(() => getFieldValue('mainProduct'), [block?.mainProduct]);
  const [productToView, setProduct] = useState<Ib2bProduct | any>([]);
  const localProduct: any = block?.settings?.customProduct ? mainProduct[0] || [] : productToView;
  const params = useParams();
  useEffect(() => {
    if (params.title === 'product') {
      const fetchProduct = async () => {
        const _product = await getProduct(params.id);
        if (_product) {
          setProduct(_product);
        }
      };
      fetchProduct();
    }
  }, []);
  return (
    <Box sx={{ p: 0 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={5}>
          <ProductBoomBuyDetailsSummary product={product || localProduct} block={block} />
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
          <ProductDetailsCarousel product={product || localProduct} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProductBoomBuyDetailsView;
