'use client';

import { useParams } from 'next/navigation';
import { useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { getProduct, useGetProduct } from 'src/api/product';

import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';
import { useSettingsContext } from 'src/components/settings';

import { Ib2bProduct } from '../../../types/b2b';
import { BlockType } from '../../../types/page-generator';
import { ProductDetailsSkeleton } from '../product-skeleton';
import { useAppContext } from '../../../contexts/AppContext';
import ProductDetailsCarousel from '../product-details-carousel';
import ProductDetailsDescription from '../product-details-description';
import { uploadProductsImage } from '../../../helpers/uploadProductsImage';
import { ProductDetailsSummary } from '../../b2c-shop/product-details-summary';

// ----------------------------------------------------------------------

const SUMMARY = [
  {
    title: '100% Original',
    description: 'Chocolate bar candy canes ice cream toffee cookie halvah.',
    icon: 'solar:verified-check-bold',
  },
  {
    title: '10 Day Replacement',
    description: 'Marshmallow biscuit donut dragée fruitcake wafer.',
    icon: 'solar:clock-circle-bold',
  },
  {
    title: 'Year Warranty',
    description: 'Cotton candy gingerbread cake I love sugar sweet.',
    icon: 'solar:shield-check-bold',
  },
];

// ----------------------------------------------------------------------

type Props = {
  id: string;
  block: BlockType;
};

export default function ProductDetailsView({ id, block }: Props) {
  const params = useParams();
  const [product, setProduct] = useState<Ib2bProduct | null>(null);

  const { dispatch, state } = useAppContext();

  useEffect(() => {
    const fetchProduct = async () => {
      const _product = await getProduct(params.id);
      if (_product) {
        setProduct(_product);
      }
    };
    fetchProduct();
  }, []);

  const { productLoading, productError } = useGetProduct(id);

  const settings = useSettingsContext();

  const [currentTab, setCurrentTab] = useState('description');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const renderSkeleton = <ProductDetailsSkeleton />;

  const renderError = (
    <EmptyContent
      filled
      title={`${productError?.message}`}
      action={
        <Button
          component={RouterLink}
          href={paths.dashboard.product.root}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
          sx={{ mt: 3 }}
        >
          Back to List
        </Button>
      }
      sx={{ py: 10 }}
    />
  );

  const imageLink =
    (product?.mainImageURL
      ? `${uploadProductsImage(product?.mainImageURL)}`
      : product?.merchants.length &&
        `${uploadProductsImage(product?.merchants[0].mainImageURL)}`) ||
    '/assets/images/product-default.png';

  const renderProduct = product && (
    <>
      <Grid container spacing={3} sx={{ direction: 'rtl' }}>
        <Grid item xs={12} md={6} lg={7}>
          {imageLink ? <ProductDetailsCarousel product={product} imageLink={imageLink} /> : <div />}
        </Grid>

        <Grid item xs={12} md={6} lg={5}>
          <ProductDetailsSummary product={product} imageLink={imageLink} block={block} />
        </Grid>
      </Grid>

      <Box
        gap={5}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
        sx={{ my: 10 }}
      >
        {SUMMARY.map((item) => (
          <Box key={item.title} sx={{ textAlign: 'center', px: 5 }}>
            <Iconify icon={item.icon} width={32} sx={{ color: 'primary.main' }} />

            <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
              {item.title}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {item.description}
            </Typography>
          </Box>
        ))}
      </Box>

      <Card>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            px: 3,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {[
            {
              value: 'description',
              label: 'Description',
            },
            {
              value: 'reviews',
              label: `Reviews (0)`,
            },
          ].map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>

        {currentTab === 'description' && (
          <ProductDetailsDescription
            description={product?.description ? product?.description : ''}
          />
        )}

        {/* {currentTab === 'reviews' && ( */}
        {/*  <ProductDetailsReview */}
        {/*    ratings={product?.ratings ? product.ratings : ''} */}
        {/*    reviews={product?.reviews ? product.reviews : ''} */}
        {/*    totalRatings={product.totalRatings} */}
        {/*    totalReviews={product.totalReviews} */}
        {/*  // /> */}
        {/* )} */}
      </Card>
    </>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      {productLoading && renderSkeleton}

      {productError && renderError}

      {product && renderProduct}
    </Container>
  );
}
