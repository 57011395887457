'use client';

// @mui
import { Stack, Button, TableRow, Checkbox, TableCell } from '@mui/material';

import Label from '../../../../../../components/label';
import Iconify from '../../../../../../components/iconify';

// ----------------------------------------------------------------------

type PaidTableType = {
  id: string;
  date: string;
  description: string;
  regarding: string;
  domain: string;
  status: string;
};

type Props = {
  row: PaidTableType;
};

export default function TableTableRow({ row }: Props) {
  const { date, description, regarding, domain, status } = row;

  return (
    <TableRow hover>
        <TableCell padding="checkbox">
          <Checkbox checked={false} />
        </TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{regarding}</TableCell>
        <TableCell>{domain}</TableCell>
        <TableCell>
          <Label color={status === 'הושלם' ? 'success' : 'info'}>
            {status === 'הושלם' && <Iconify icon="weui:done2-filled" mx={1} />}
            {status}
          </Label>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" sx={{ '&:hover': { color: '#000080' } }}>
            <Button variant="text">לצפייה בקבלה</Button>
            <Iconify icon="ep:arrow-left-bold" />
          </Stack>
        </TableCell>
      </TableRow>
  );
}
