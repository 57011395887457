'use client';

import React, { FC, useEffect } from 'react';
import { Container, Typography, Button, Box, Stack, Link } from '@mui/material';
import SpecialistAbout from './specialist-about';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import Image from '../../../../../components/image';

const Confirm: FC<ComponentTypeProps> = ({ block }) => (
  <Container maxWidth="xs" sx={{ py: 4 }}>
    <Box sx={{ textAlign: 'center', mb: 4 }}>
      <Image
        className="startImg"
        src="/assets/images/start/congrats.png"
        alt="congrats"
      />
      <Stack sx={{ mt: '-100px' }}>
        <Typography variant="h4" sx={{ mt: 2 }}>
          השירות הוזמן בהצלחה
        </Typography>
        <Typography variant="h5" sx={{ mt: 1, mb: 2 }}>
          הוזמן לך שירות מהמנקה יעל כהן
        </Typography>
      </Stack>
      <SpecialistAbout
        img="/assets/images/doctor/doctor1.jpg"
        header="יעל כהן"
        text="מנקה"
        link=""
      />
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">
          יום שישי <span style={{ color: '#16AA5F' }}>11.04</span> ב
          <span style={{ color: '#16AA5F' }}>11:00</span>
        </Typography>
      </Box>
    </Box>
  </Container>
);

export default Confirm;
