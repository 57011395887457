// @types

import { LOREM_IPSUM } from '../constants';
import { FieldTypes, SettingsField } from '../../../types/page-generator';

const banners: SettingsField[] = [
  {
    name: 'title',
    type: FieldTypes.input,
    defaultValue: 'Lorem ipsum dolor',
    showOn: 'settings.enableHeading',
    rowSize: 4,
    label: 'Title',
  },
  {
    name: 'mainImage',
    type: FieldTypes.upload,
    uploadType: 'image',
    rowSize: 2,
    label: 'Main image',
    defaultValue: '',
  },
  {
    name: 'headingLogo',
    type: FieldTypes.upload,
    defaultValue: '',
    label: 'Heading logo',
    uploadType: 'image',
    showOn: 'settings.enableLogo',
    rowSize: 2,
  },
  {
    name: 'contentText',
    type: FieldTypes.richtext,
    defaultValue: LOREM_IPSUM,
    rowSize: 4,
    label: 'Content text',
  },
  {
    name: 'linkText',
    type: FieldTypes.input,
    defaultValue: 'רשימת בתי עסק מכבדים',
    rowSize: 4,
    showOn: 'settings.enableLinkText',
    label: 'Link text',
  },
  {
    name: 'actionButtons',
    type: FieldTypes.buttons,
    defaultValue: [
      { label: 'Test button', color: 'primary', size: 'medium', variant: 'contained', link: '#' },
      {
        label: 'Secondary test',
        color: 'secondary',
        size: 'medium',
        variant: 'contained',
        link: '#',
      },
    ],
    rowSize: 4,
    label: 'Action Buttons',
  },
];

export default banners;
