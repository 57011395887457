'use client';

import Slider from 'react-slick';
import isEqual from 'lodash/isEqual';
import { FC, Key, useRef, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { Pagination } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from '../../../../components/iconify';
import { useRouter } from '../../../../routes/hooks';
import { INetwork } from '../../../../types/networks';
import { IMerchant } from '../../../../types/merchant';
import SvgColor from '../../../../components/svg-color';
import { useAuthContext } from '../../../../auth/hooks';
import { useGetNetworks } from '../../../../api/network';
import MerchantToolbar from './merchants/merchant-toolbar';
import { TableNoData } from '../../../../components/table';
import { useGetProductsTags } from '../../../../api/product';
import { useAppContext } from '../../../../contexts/AppContext';
import { useResponsive } from '../../../../hooks/use-responsive';
import { getKosher, getLocation } from '../../../../api/attributes';
import { ComponentTypeProps } from '../../../../types/page-generator';
import MerchantCard from '../../../../components/cards/merchant-card';
import { AlternativeMerchant } from './merchants/alternativeMerchant';
import { AlternativeBigMerchant } from './merchants/alternativeBigMerchant';
import {
  useGetMerchants, useGetMerchantsCategories,
} from '../../../../api/merchant';
import { ICategoryItem, Ib2bTableFilter, Ib2bTableFilterValue } from '../../../../types/b2b';
import { useDebounce } from '../../../../hooks/use-debounce';

export const Merchants: FC<ComponentTypeProps> = ({ block, params }) => {
  const { user } = useAuthContext();
  const defaultFilters: Ib2bTableFilter = {
    name: '',
    type: [],
    category: [],
    area: [],
    price: [0, 3000],
    search: '',
    distance: '',
  };
  const { dispatch, state } = useAppContext();
  const router = useRouter();
  const isMobile = useResponsive('down', 'sm');
  const [filters, setFilters] = useState(defaultFilters);
  const canReset = !isEqual(defaultFilters, filters);
  const LIMIT = block?.settings?.limit || 12;
  const [currentPage, setCurrentPage] = useState(1);
  const combinedBlock: any = block?.combinedBlock || [];
  const enableCategoryFilter = block?.settings?.enableCategoryFilter;
  const localCategories = block?.settings?.categories || [];
  const enableTagsFilter = block?.settings?.enableTagsFilter;
  const localMerchantsTags = block?.settings?.merchantsTags || [];
  const { networks: localNetworks } = useGetNetworks({});
  // const { merchantsTags } = useGetMerchantsTags({});
  const { merchantsCategories } = useGetMerchantsCategories({});
  const { productsTags } = useGetProductsTags({});
  const actionButtons: any = block?.actionButtons || [];
  const merchantTagIds: string[] = [];
  const productTagIds: string[] = [];
  const categoryIds: string[] = [];
  const sliderRef = useRef<any>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [open, setOpen] = useState(false);
  const [coordinates, setCoordinates] = useState({ latitude: 31.7804637316345, longitude: 35.2232555925889 });
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const debouncedDistance = useDebounce(filters.distance);
  useEffect(() => {
    getKosher(dispatch);
    getLocation(dispatch);
  }, []);

  // filters.type.forEach((fType: string) =>
  //   merchantTagIds.push(String(merchantsTags.find((mt: ITag) => mt.name === fType)?.id))
  // );
  filters.type.forEach((a: string) =>
    merchantTagIds.push(String(state?.location.find((pt: any) => pt.value === a)?.id)),
  );
  // filters.area.forEach((a: string) =>
  //   productTagIds.push(String(productsTags.find((pt: ITag) => pt.name === a)?.id))
  // );
  filters.area.forEach((a: string) =>
    productTagIds.push(String(state?.kosher.find((mt: any) => mt.value === a)?.id)),
  );
  filters.category.forEach((c: string) =>
    categoryIds.push(String(merchantsCategories.find((cat: ICategoryItem) => cat.title === c)?.id)),
  );

  const paramsTagsIds =
    localMerchantsTags.length || merchantTagIds.length
      ? {
        tagsIds:
          enableTagsFilter && localMerchantsTags.length
            ? localMerchantsTags[0].value
            : merchantTagIds,
      }
      : {};
  const paramsCategoryIds =
    localCategories.length || categoryIds.length
      ? {
        categoriesIds:
          enableCategoryFilter && localCategories.length ? localCategories[0].value : categoryIds,
      }
      : {};

  const propsByProduct =
    block?.settings?.isAlternativeDesign && !block?.settings?.partialSize
      ? {
        offset: block?.settings?.enableAllMerchants ? 0 : (currentPage - 1) * LIMIT,
        search: filters.name,
        // ...paramsTagsIds,
        ...paramsCategoryIds,
        productIds: params.title === 'product' && params.id ? params.id : [],
        attributeValueIds: merchantTagIds.concat(productTagIds),
        distanceSearchRadius: Number(debouncedDistance),
        distanceSearchLong: coordinates.longitude,
        distanceSearchLat: coordinates.latitude,
      }
      : {
        ...(block?.settings?.enableAllMerchants ? {} : { limit: LIMIT }),
        offset: block?.settings?.enableAllMerchants ? 0 : (currentPage - 1) * LIMIT,
        search: filters.name,
        // ...paramsTagsIds,
        ...paramsCategoryIds,
        productIds: params.title === 'product' && params.id ? params.id : [],
        attributeValueIds: merchantTagIds.concat(productTagIds),
        distanceSearchRadius: Number(debouncedDistance),
        distanceSearchLong: coordinates.longitude,
        distanceSearchLat: coordinates.latitude,
      };

  const propsByCard =
    block?.settings?.isAlternativeDesign && !block?.settings?.partialSize
      ? {
        offset: block?.settings?.enableAllMerchants ? 0 : (currentPage - 1) * LIMIT,
        search: filters.name,
        // ...paramsTagsIds,
        ...paramsCategoryIds,
        virtualCardTypeId: params.title === 'card' && params.id ? params.id : [],
        attributeValueIds: merchantTagIds.concat(productTagIds),
        distanceSearchRadius: Number(debouncedDistance),
        distanceSearchLong: coordinates.longitude,
        distanceSearchLat: coordinates.latitude,
      }
      : {
        ...(block?.settings?.enableAllMerchants ? {} : { limit: LIMIT }),
        offset: block?.settings?.enableAllMerchants ? 0 : (currentPage - 1) * LIMIT,
        search: filters.name,
        // ...paramsTagsIds,
        ...paramsCategoryIds,
        virtualCardTypeId: params.title === 'card' && params.id ? params.id : [],
        attributeValueIds: merchantTagIds.concat(productTagIds),
        distanceSearchRadius: Number(debouncedDistance),
        distanceSearchLong: coordinates.longitude,
        distanceSearchLat: coordinates.latitude,
      };

  const props = params.title === 'product' ? propsByProduct : propsByCard;

  const { merchants, totalMerchantsCount, merchantsEmpty } = useGetMerchants({ ...props });
  // const { merchants: merchantsCarousel } = useGetMerchants({});

  const handlePageChange = (event: any, newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (isEqual(defaultFilters, filters)) setCurrentPage(1);
  }, [filters]);

  const handleFilters = (name: string, filterValue: Ib2bTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };

  const dataFiltered = applyFilter({
    inputData: merchants,
    filters,
  });

  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  const handleResetArea = () => {
    handleFilters('area', []);
  };

  const handleResetCategory = () => {
    handleFilters('category', []);
  };

  const handleResetType = () => {
    handleFilters('type', []);
  };

  const handleResetNane = () => {
    handleFilters('name', '');
  };

  const ScrollbarContainer = styled('div')({
    display: 'flex',
    width: '100',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  });

  const handleFilterCategory = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      handleFilters(
        'category',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value,
      );
    },
    [handleFilters],
  );

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFilters('name', event.target.value);
    },
    [handleFilters],
  );

  const handleClick = (categoryTitle: string) => {
    const event = {
      target: { value: [categoryTitle] },
    } as SelectChangeEvent<string[]>;

    handleFilterCategory(event);
  };

  const settings = {
    // dots: !(block?.settings?.isAlternativeDesign && !isMobile),
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: block?.settings?.isAlternativeDesign && !isMobile ? 3 : 1,
    slidesToScroll: 1,
    autoplay: block && block.settings ? block.settings.autoplay : false,
    autoplaySpeed: block && block.settings ? (block.settings.playSpeed || 3) * 1000 : 3 * 1000,
    afterChange: (current: number) => setActiveSlide(current),
  };

  const handleSlideChange = (index: number) => {
    setActiveSlide(index);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const filterView = (
    <>
      {block?.settings?.enableFilter &&
        !block?.settings?.customMode &&
        !block?.settings?.enableCategoryFilter &&
        !block?.settings?.enableTagsFilter && (
          <MerchantToolbar
            block={block}
            filters={filters}
            onFilters={handleFilters}
            //
            typeOptions={state?.location || []}
            categoryOptions={merchantsCategories}
            areaOptions={state?.kosher || []}
            handleResetFilters={handleResetFilters}
            canReset={canReset}
            handleResetCategory={handleResetCategory}
            handleResetArea={handleResetArea}
            handleResetTags={handleResetType}
            handleResetName={handleResetNane}
            setCoordinates={setCoordinates}
          />
        )}
    </>
  );

  return (
    <>
      {!!merchants?.length && (
        <Box
          sx={{
            display: isMobile && block?.settings?.disableOnMobile ? 'none' : 'block',
          }}
        >
          {!block?.settings?.isAlternativeDesign ? (
            <>
              <Stack flexWrap="wrap" flexDirection="row-reverse" justifyContent="center" gap={3}>
                {isMobile ? (
                  <>
                    <Stack width={1} my={3}>
                      <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />
                    </Stack>
                    <Drawer open={open} onClose={toggleDrawer(false)}>
                      {filterView}
                    </Drawer>
                  </>
                ) : (
                  <>{filterView}</>
                )}
                {block?.settings?.customMode ? (
                  <Grid container spacing={3} rowGap={3} justifyContent="center">
                    {combinedBlock.map((merchant: any) => {
                      const network = localNetworks.find(
                        (item: INetwork) => item.id === merchant.networkId,
                      );
                      return (
                        <Grid key={merchant.merchant[0].id} item xs={4} justifyContent="center">
                          <MerchantCard
                            merchant={merchant.merchant[0]}
                            block={merchant}
                            network={network}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <Grid container spacing={3} rowGap={3} justifyContent="center">
                    {dataFiltered?.map((merchant: any) => {
                      const network = localNetworks.find(
                        (item: INetwork) => item.id === merchant.networkId,
                      );
                      return (
                        <Grid
                          key={merchant.id}
                          item
                          xs={12}
                          md={4}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <MerchantCard merchant={merchant} block={block} network={network} productId={params.id} />
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Stack>
              <br />
              {!block?.settings?.enableAllMerchants && (
                <>
                  {!block?.settings?.customMode && (
                    <Stack justifyContent="center" alignItems="center">
                      {!merchantsEmpty ? (
                        <Pagination
                          onChange={handlePageChange}
                          page={currentPage}
                          count={Math.round(totalMerchantsCount / LIMIT) || 1}
                          color="primary"
                          sx={{ margin: '0 auto' }}
                        />
                      ) : (
                        <TableNoData notFound={merchantsEmpty} />
                      )}
                    </Stack>
                  )}
                </>
              )}
              <br />
            </>
          ) : (
            <>
              {block?.settings?.partialSize ? (
                <Box
                  sx={{
                    '& .MuiGrid-root': { width: '100%', margin: 0, px: 0 },
                  }}
                >
                  <Stack direction="row" alignItems="center" justifyContent="space-between" my={1}>
                    <Typography
                      sx={{
                        fontSize: isMobile ? '20px' : '24px',
                        fontWeight: 600,
                        fontStyle: 'normal',
                        lineHeight: '67%',
                        letterSpacing: '-0.24px',
                      }}
                    >
                      מבצעים
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      onClick={() => router.push(`/${actionButtons[0]?.link}`)}
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: isMobile ? '12px' : '16px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                          letterSpacing: '-0.24px',
                        }}
                      >
                        {actionButtons[0]?.label}
                      </Typography>
                      <Iconify
                        icon={
                          params.lng !== 'he' ? 'iconamoon:arrow-left-2' : 'iconamoon:arrow-right-2'
                        }
                      />
                    </Stack>
                  </Stack>
                  <Box
                    sx={{
                      height: isMobile ? '230px' : '100%',
                      '& .slick-slide': {
                        direction: 'rtl',
                        px: { md: 1, xs: 0 },
                      },
                    }}
                  >
                    <Slider ref={sliderRef} {...settings}>
                      {merchants?.map((item: any, index: Key) => (
                        <AlternativeBigMerchant block={block} merchant={item} key={index} />
                      ))}
                    </Slider>
                    {isMobile && (
                      <Stack
                        width={1}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        gap={1}
                        sx={{
                          position: 'relative',
                          bottom: '50px',
                          '& .ellipse': {
                            border: '1px solid',
                            borderColor: block?.settings?.dotsColor,
                            borderRadius: '2.46px/2.5px',
                            height: '5px',
                            left: 0,
                            opacity: 0.8,
                            top: 0,
                            width: '5px',
                          },
                        }}
                      >
                        {(() => {
                          const indicators = [];
                          // eslint-disable-next-line no-plusplus
                          for (let id = 0; id < merchants.length; id++) {
                            indicators.push(
                              <Stack alignItems="center" key={id}>
                                {activeSlide === id ? (
                                  <SvgColor
                                    color={block?.settings?.dotsColor}
                                    src="/assets/images/line-5.svg"
                                  />
                                ) : (
                                  <Box className="ellipse" onClick={() => handleSlideChange(id)} />
                                )}
                              </Stack>,
                            );
                          }
                          return indicators;
                        })()}
                      </Stack>
                    )}
                  </Box>
                  {!isMobile && <Box sx={{ my: 2 }} />}
                  <Grid
                    container
                    spacing={1}
                    rowGap={3}
                    justifyContent="center"
                    sx={{ '& .MuiGrid-item': { p: 0, px: 1 }, justifyContent: 'space-between' }}
                  >
                    {dataFiltered
                      ?.slice(0, 9)
                      ?.map((merchant: IMerchant, id: number) => (
                        <AlternativeMerchant block={block} merchant={merchant} key={id} />
                      ))}
                  </Grid>
                </Box>
              ) : (
                <Box>
                  {isMobile ? (
                    <>
                      <Stack width={1} my={3}>
                        <Iconify icon="mdi:filter" onClick={toggleDrawer(true)} />
                      </Stack>
                      <Drawer open={open} onClose={toggleDrawer(false)}>
                        {filterView}
                      </Drawer>
                    </>
                  ) : (
                    <>{filterView}</>
                  )}
                  <Stack direction="row" my={2} justifyContent="center">
                    <ScrollbarContainer>
                      {merchantsCategories.map((category: ICategoryItem) => (
                        <Chip
                          key={category.id}
                          onClick={() => handleClick(category.title)}
                          label={category.title}
                          sx={{ mx: 1, background: '#0148CB', color: 'white', borderRadius: '10px' }}
                        />
                      ))}
                    </ScrollbarContainer>
                  </Stack>
                  <Grid container spacing={3} rowGap={3} justifyContent="center">
                    {dataFiltered?.map((merchant: IMerchant, id: number) => (
                      <AlternativeMerchant block={block} merchant={merchant} key={id} />
                    ))}
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

function applyFilter({ inputData, filters }: { inputData: IMerchant[]; filters: Ib2bTableFilter }) {
  const { name, area, category } = filters;

  if (name) {
    inputData = inputData.filter(
      (product) => product.title.toLowerCase().indexOf(name.toLowerCase()) !== -1,
    );
  }

  // if (area.length) {
  //   inputData = inputData.filter((product) => area.includes(product.area));
  // }
  //
  // if (category.length) {
  //   inputData = inputData.filter((product) => category.includes(product.category));
  // }

  return inputData;
}
