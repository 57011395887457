'use client';

import React from 'react';
import { Stack, Typography } from '@mui/material';
import ServiceItem from './service-item';

// import './ServiceList.css';

type Props = {
  serviceListArr: any;
  text: string;
};

const ServiceList = ({ serviceListArr, text }: Props) => (
  <div className="service_list_sec">
    <Stack direction="row" width={1} justifyContent="space-between" my={1} sx={{direction: 'rtl'}}>
      <Typography
        sx={{
          color: 'primary.main',
          cursor: 'pointer',
        }}
      >
        {'<<'} לכל השירותים
      </Typography>
      <Typography>{text}</Typography>
    </Stack>
    <div className="container">
      <div className="doctors_area services_area">
        <div className="service_box_area">
          {serviceListArr.map((el: any, i: number) => (
            <ServiceItem
              header={el.header}
              link={el.link}
              price={el.price}
              par={el.par}
              img={el.img}
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default ServiceList;
