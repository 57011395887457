'use client';

import { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Image from 'src/components/image';

import SanitizeHTML from '../../../../utils/sanitize';
import { useResponsive } from '../../../../hooks/use-responsive';
import LineIcon from '../../../../assets/icons/happy-gift/lineIcon';
import { ComponentTypeProps } from '../../../../types/page-generator';

const ShopBanner3: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');
  const backgroundImage = '/assets/images/happy-gift/backgrounds/blue_background.png';
  const bannerImage = '/assets/images/happy-gift/banners/inclined/cards-group2.png';
  const title = 'ארוחת בוקר זוגית ברשת מלונות פרימה';
  const contentText =
    'ארוחת בוקר נהדרת במיקום נפלא. ארוחת בוקר נהדרת במיקום נפלא. ארוחת בוקר נהדרת במיקום נפלא';

  return (
    <Card
      sx={{
        width: '100%',
        // borderRadius: '5px',
        backgroundImage: `url(${backgroundImage})`,
        // backgroundColor: '#003DE2',
        backgroundSize: 'cover',
        padding: '50px 80px 50px 40px',
      }}
    >
      <Grid
        container
        spacing={2}
        // eslint-disable-next-line
        flexDirection={isMobile ? 'column-reverse' : 'row-reverse'}
        alignItems=""
      >
        <Grid
          item
          md={7}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: '100%',
          }}
        >
          <Stack
            direction="column"
            width={1}
            alignItems="flex-end"
            justifyContent="center"
            sx={{ height: '100%' }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontStyle: '',
                textDecoration: '',
                color: 'white',
                fontSize: '46px',
                direction: 'rtl',
                textAlign: 'end',
                width: '80%',
                // mb: 2,
                lineHeight: 1,
              }}
            >
              {title}
            </Typography>
            <LineIcon color="#5D21EC" sx={{ my: 2, width: '90%' }} />
            <Typography
              sx={{
                direction: 'rtl',
                color: 'white',
                fontSize: '24',
                fontStyle: '',
                fontWeight: '',
                textDecoration: '',
                textAlign: 'end',
                width: '90%',
              }}
            >
              <SanitizeHTML html={contentText} />
            </Typography>
            <Typography
              sx={{
                direction: 'rtl',
                color: '',
                fontSize: '',
                fontStyle: '',
                fontWeight: '',
                textDecoration: 'underline',
                mb: 1,
              }}
            >
              {}
            </Typography>
            <Stack direction="row" spacing={2} py={1}>
              <Button
                variant="contained"
                sx={{
                  mt: 4,
                  borderRadius: '20px',
                  fontWeight: 600,
                  color: '#27097A',
                  bgcolor: 'secondary.contrastText',
                  '&:hover': {
                    bgcolor: 'secondary.contrastText',
                    color: '#27097A',
                  },
                }}
              >
                לפרטים נוספים
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box sx={{ position: 'relative' }}>
            <Image src={bannerImage} sx={{ width: '80%' }} />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ShopBanner3;
