import { textColorOptions } from './common';
import { FieldTypes, SettingsField } from '../../../types/page-generator';
import {
  fontSizes,
  fontStyles,
  fontWeights,
  percentOptions,
  textDecorations,
} from './fonts-presets';

const banner: SettingsField[] = [
  {
    name: 'backgroundImage',
    label: 'Background Image',
    type: FieldTypes.selectByThumbs,
    defaultValue: 'transparent',
    rowSize: 4,
    options: [
      { label: 'Transparent', value: 'transparent', icon: 'mdi:circle-transparent' },
      {
        label: 'Purple',
        value: '/assets/images/happy-gift/backgrounds/fontHappyGift.png',
        image: '/assets/images/happy-gift/backgrounds/fontHappyGift.png',
      },
      {
        label: 'Orange',
        value: '/assets/images/happy-gift/backgrounds/orange_background.png',
        image: '/assets/images/happy-gift/backgrounds/orange_background.png',
      },
      {
        label: 'Pink',
        value: '/assets/images/xtra/xtra_pink_background.png',
        image: '/assets/images/xtra/xtra_pink_background.png',
      },
      {
        label: 'Grey',
        value: '/assets/images/happy-gift/backgrounds/grey_background.png',
        image: '/assets/images/happy-gift/backgrounds/grey_background.png',
      },
      {
        label: 'Black',
        value: '/assets/images/happy-gift/backgrounds/black_background.png',
        image: '/assets/images/happy-gift/backgrounds/black_background.png',
      },
      {
        label: 'Blue',
        value: '/assets/images/happy-gift/backgrounds/blue_background.png',
        image: '/assets/images/happy-gift/backgrounds/blue_background.png',
      },
      {
        label: 'Dark Pink',
        value: '/assets/images/happy-gift/backgrounds/dark_pink_background.png',
        image: '/assets/images/happy-gift/backgrounds/dark_pink_background.png',
      },
      {
        label: 'White',
        value: '/assets/images/happy-gift/backgrounds/white_background.png',
        image: '/assets/images/happy-gift/backgrounds/white_background.png',
      },
      {
        label: 'Green',
        value: '/assets/images/happy-gift/backgrounds/green_background.png',
        image: '/assets/images/happy-gift/backgrounds/green_background.png',
      },
      {
        label: 'Blue 2',
        value: '/assets/images/happy-gift/backgrounds/blue_2_background.png',
        image: '/assets/images/happy-gift/backgrounds/blue_2_background.png',
      },
    ],
  },
  {
    name: 'bannerImage',
    label: 'Banner Image',
    type: FieldTypes.selectByThumbs,
    defaultValue: '/assets/images/happy-gift/banners/group-cards-white-violet.png',
    rowSize: 4,
    options: [
      { label: 'Transparent', value: 'transparent', icon: 'mdi:circle-transparent' },
      {
        label: 'White Purple',
        value: '/assets/images/happy-gift/banners/group-cards-white-violet.png',
        image: '/assets/images/happy-gift/banners/group-cards-white-violet.png',
      },
      {
        label: 'White Orange',
        value: '/assets/images/happy-gift/banners/group-cards-orange.png',
        image: '/assets/images/happy-gift/banners/group-cards-orange.png',
      },
      {
        label: 'White Pink',
        value: '/assets/images/happy-gift/banners/group-cards-pink.png',
        image: '/assets/images/happy-gift/banners/group-cards-pink.png',
      },
      {
        label: 'White Group',
        value: '/assets/images/happy-gift/banners/group-cards-white.png',
        image: '/assets/images/happy-gift/banners/group-cards-white.png',
      },
      {
        label: 'Blue Purple Group',
        value: '/assets/images/happy-gift/banners/group-cards-blue-purple.png',
        image: '/assets/images/happy-gift/banners/group-cards-blue-purple.png',
      },
      {
        label: 'White Card',
        value: '/assets/images/happy-gift/banners/whiteCardImage.png',
        image: '/assets/images/happy-gift/banners/whiteCardImage.png',
      },
      {
        label: 'Black Card',
        value: '/assets/images/happy-gift/banners/blackCardImage.png',
        image: '/assets/images/happy-gift/banners/blackCardImage.png',
      },
      {
        label: 'Blue Card',
        value: '/assets/images/happy-gift/banners/blueCardImage.png',
        image: '/assets/images/happy-gift/banners/blueCardImage.png',
      },
      {
        label: 'Blue Dark Blue Card',
        value: '/assets/images/happy-gift/banners/cardholder.png',
        image: '/assets/images/happy-gift/banners/cardholder.png',
      },
      {
        label: 'Red Card',
        value: '/assets/images/happy-gift/banners/redCardImage.png',
        image: '/assets/images/happy-gift/banners/redCardImage.png',
      },
      {
        label: 'Pai Card',
        value: '/assets/images/happy-gift/banners/phone.png',
        image: '/assets/images/happy-gift/banners/phone.png',
      },
      {
        label: 'Coffee',
        value: '/assets/images/happy-gift/banners/coffee.png',
        image: '/assets/images/happy-gift/banners/coffee.png',
      },
      {
        label: 'Blue Purple Group Right',
        value: '/assets/images/happy-gift/banners/group-cards-blue-purple-right.png',
        image: '/assets/images/happy-gift/banners/group-cards-blue-purple-right.png',
      },
      {
        label: 'Tables',
        value: '/assets/images/happy-gift/banners/tables.png',
        image: '/assets/images/happy-gift/banners/tables.png',
      },
      {
        label: 'Black Orange',
        value: '/assets/images/happy-gift/banners/inclined/black-orange.png',
        image: '/assets/images/happy-gift/banners/inclined/black-orange.png',
      },
      {
        label: 'Red Orange',
        value: '/assets/images/happy-gift/banners/inclined/red-orange.png',
        image: '/assets/images/happy-gift/banners/inclined/red-orange.png',
      },
      {
        label: 'Blue Pink',
        value: '/assets/images/happy-gift/banners/inclined/blue-pink.png',
        image: '/assets/images/happy-gift/banners/inclined/blue-pink.png',
      },
      {
        label: 'White Pink',
        value: '/assets/images/happy-gift/banners/inclined/white-pink.png',
        image: '/assets/images/happy-gift/banners/inclined/white-pink.png',
      },
      {
        label: 'Black Group',
        value: '/assets/images/happy-gift/banners/inclined/cards-group2.png',
        image: '/assets/images/happy-gift/banners/inclined/cards-group2.png',
      },
      {
        label: 'White Group',
        value: '/assets/images/happy-gift/banners/inclined/cards-group3.png',
        image: '/assets/images/happy-gift/banners/inclined/cards-group3.png',
      },
      {
        label: 'Card Stack',
        value: '/assets/images/happy-gift/banners/inclined/cards-stack.png',
        image: '/assets/images/happy-gift/banners/inclined/cards-stack.png',
      },
      {
        label: 'Team Day',
        value: '/assets/images/happy-gift/banners/inclined/TeamDay_Banner.png',
        image: '/assets/images/happy-gift/banners/inclined/TeamDay_Banner.png',
      },
      {
        label: 'Birthday',
        value: '/assets/images/happy-gift/banners/inclined/Birthday_Banner.png',
        image: '/assets/images/happy-gift/banners/inclined/Birthday_Banner.png',
      },
      {
        label: 'Together',
        value: '/assets/images/happy-gift/banners/inclined/Win2gether_Banner.png',
        image: '/assets/images/happy-gift/banners/inclined/Win2gether_Banner.png',
      },
    ],
  },
  {
    name: 'logoImage',
    label: 'Logo Image',
    type: FieldTypes.selectByThumbs,
    defaultValue: '/assets/images/happy-gift/happy-gift-global-banner.png',
    rowSize: 4,
    options: [
      {
        label: 'Variant 1',
        value: '/assets/images/happy-gift/happy-gift-global-banner.png',
        image: '/assets/images/happy-gift/happy-gift-global-banner.png',
      },
      {
        label: 'Variant 2',
        value: '/assets/images/happy-gift/happy-gift-global.png',
        image: '/assets/images/happy-gift/happy-gift-global.png',
      },
      {
        label: 'Variant 3',
        value: '/assets/images/happy-gift/logo.png',
        image: '/assets/images/happy-gift/logo.png',
      },
      {
        label: 'Variant 4',
        value: '/assets/images/happy-gift/logo/happy-hr-logo.png',
        image: '/assets/images/happy-gift/logo/happy-hr-logo.png',
      },
      {
        label: 'Variant 5',
        value: '/assets/images/happy-gift/logo/happy-gift-multi.png',
        image: '/assets/images/happy-gift/logo/happy-gift-multi.png',
      },
      {
        label: 'Variant 6',
        value: '/assets/images/happy-gift/logo/happy-gift-card.png',
        image: '/assets/images/happy-gift/logo/happy-gift-card.png',
      },
    ],
  },
  {
    name: 'contentStyles',
    rowSize: 2,
    type: FieldTypes.group,
    label: 'Content Style',
    fields: [
      {
        name: 'contentTextColor',
        label: 'Content Text color',
        type: FieldTypes.select,
        defaultValue: 'inherit',
        rowSize: 4,
        options: textColorOptions,
        isColor: true,
      },
      {
        name: 'contentFontSize',
        label: 'Content Font Size',
        type: FieldTypes.select,
        defaultValue: '12px',
        rowSize: 4,
        options: fontSizes,
      },
      {
        name: 'contentFontWeight',
        label: 'Content Font Weight',
        type: FieldTypes.select,
        defaultValue: 400,
        rowSize: 4,
        options: fontWeights,
      },
      {
        name: 'contentFontStyle',
        label: 'Content Font Style',
        type: FieldTypes.select,
        defaultValue: 'normal',
        rowSize: 4,
        options: fontStyles,
      },
      {
        name: 'contentTextDecoration',
        label: 'Content Text Decoration',
        type: FieldTypes.select,
        defaultValue: 'none',
        rowSize: 4,
        options: textDecorations,
      },
    ],
  },
  {
    name: 'headingStyles',
    rowSize: 2,
    type: FieldTypes.group,
    label: 'Heading Style',
    fields: [
      {
        name: 'headingTextColor',
        label: 'Heding text color',
        type: FieldTypes.select,
        defaultValue: 'inherit',
        rowSize: 4,
        options: textColorOptions,
        isColor: true,
      },
      {
        name: 'headingFontSize',
        label: 'Heading Font Size',
        type: FieldTypes.select,
        defaultValue: '35px',
        rowSize: 4,
        options: fontSizes,
      },
      {
        name: 'headingFontWeight',
        label: 'Heading Font Weight',
        type: FieldTypes.select,
        defaultValue: 600,
        rowSize: 4,
        options: fontWeights,
      },
      {
        name: 'headingFontStyle',
        label: 'Heading Font Style',
        type: FieldTypes.select,
        defaultValue: 'normal',
        rowSize: 4,
        options: fontStyles,
      },
      {
        name: 'headingTextDecoration',
        label: 'Heading Text Decoration',
        type: FieldTypes.select,
        defaultValue: 'none',
        rowSize: 4,
        options: textDecorations,
      },
    ],
  },
  {
    name: 'layoutSettings',
    label: 'Layout Settings',
    type: FieldTypes.group,
    rowSize: 4,
    fields: [
      {
        name: 'enableLogo',
        type: FieldTypes.checkbox,
        defaultValue: false,
        label: 'Enable heading logo',
        rowSize: 2,
      },
      {
        name: 'forceRtl',
        label: 'Force RTL',
        type: FieldTypes.checkbox,
        defaultValue: true,
        rowSize: 2,
      },
      {
        name: 'enableHeading',
        type: FieldTypes.checkbox,
        defaultValue: false,
        label: 'Enable heading',
        rowSize: 2,
      },
      {
        name: 'enableLinkText',
        type: FieldTypes.checkbox,
        defaultValue: true,
        label: 'Enable link text',
        rowSize: 2,
      },
      {
        name: 'logoSize',
        label: 'Logo Size',
        type: FieldTypes.select,
        defaultValue: percentOptions[2].value,
        options: percentOptions,
        rowSize: 4,
      },
      {
        name: 'headingWidth',
        label: 'Heading Width',
        type: FieldTypes.select,
        defaultValue: percentOptions[9].value,
        options: percentOptions,
        rowSize: 4,
      },
      {
        name: 'layout',
        label: 'Layout',
        type: FieldTypes.radiogroup,
        defaultValue: 'v1',
        rowSize: 2,
        options: [
          { label: 'V.1', value: 'v1' },
          { label: 'V.2', value: 'v2' },
        ],
      },
      {
        name: 'imageSize',
        label: 'Image Size',
        type: FieldTypes.radiogroup,
        defaultValue: 'smaller',
        rowSize: 2,
        options: [
          { label: 'Smaller', value: 'smaller' },
          { label: 'Bigger', value: 'bigger' },
        ],
      },
      {
        name: 'logoPosition',
        label: 'Logo position',
        type: FieldTypes.radiogroup,
        defaultValue: 'top',
        showOn: 'settings.enableLogo',
        rowSize: 2,
        options: [
          { label: 'Top', value: 'top' },
          { label: 'Middle', value: 'middle' },
          { label: 'Bottom', value: 'bottom' },
        ],
      },
      {
        name: 'contentAlign',
        label: 'Content alignment',
        type: FieldTypes.radiogroup,
        defaultValue: 'start',
        rowSize: 2,
        options: [
          { label: 'Start', value: 'start' },
          { label: 'Center', value: 'center' },
          { label: 'End', value: 'end' },
        ],
      },
      {
        name: 'headingAlign',
        label: 'Heading alignment',
        type: FieldTypes.radiogroup,
        defaultValue: 'start',
        rowSize: 2,
        options: [
          { label: 'Start', value: 'start' },
          { label: 'Center', value: 'center' },
          { label: 'End', value: 'end' },
        ],
      },
      {
        name: 'alignItems',
        label: 'Alignment',
        type: FieldTypes.radiogroup,
        defaultValue: 'start',
        rowSize: 2,
        options: [
          { label: 'Start', value: 'start' },
          { label: 'Center', value: 'center' },
          { label: 'End', value: 'end' },
        ],
      },
    ],
  },
  {
    name: 'classes',
    label: 'Extra classes',
    type: FieldTypes.group,
    rowSize: 4,
    fields: [
      { name: 'bannerClasses', type: FieldTypes.input, label: 'Banner classes', rowSize: 4 },
      { name: 'heroImageClasses', type: FieldTypes.input, label: 'Hero image classes', rowSize: 4 },
      { name: 'headingClasses', type: FieldTypes.input, label: 'Heading classes', rowSize: 4 },
      { name: 'contentClasses', type: FieldTypes.input, label: 'Content classes', rowSize: 4 },
      {
        name: 'headingLogoClasses',
        type: FieldTypes.input,
        label: 'Logo classes',
        rowSize: 4,
        showOn: 'enableLogo',
      },
    ],
  },
];

export default banner;
