// =====================================================================

import { SETTINGS_FIELDS_PRESETS } from '../settings';
import { CONTENT_FIELDS_PRESETS } from '../contentFields';
import { BlockType, FieldTypes, SettingsField } from '../../../types/page-generator';

export function getContentValueFromProps(block?: BlockType) {
  return function returnValue(fieldName: string) {
    const fieldNameSplited = fieldName.split('.');
    if (block && fieldNameSplited.length === 1) {
      if (block[fieldName] === undefined) {
        const field = CONTENT_FIELDS_PRESETS[block?.type]
          .reduce((acc: SettingsField[], current: SettingsField) => {
            let newAcc = [...acc];
            if (current.type === FieldTypes.group) {
              newAcc = newAcc.concat(current.fields as SettingsField[]);
            } else {
              newAcc.push(current);
            }
            return newAcc;
          }, [])
          .find((fieldItem: SettingsField) => fieldItem.name === fieldName);
        if (field) {
          return field.defaultValue;
        }
        return '';
      }
      return block[fieldName];
    }
    if (block && fieldNameSplited[0] === 'settings') {
      const fieldSubName = fieldNameSplited[1];
      if (block.settings[fieldSubName] === undefined) {
        const field = SETTINGS_FIELDS_PRESETS[block.type]
          .reduce((acc: SettingsField[], current: SettingsField) => {
            let newAcc = [...acc];
            if (current.type === FieldTypes.group) {
              newAcc = newAcc.concat(current.fields as SettingsField[]);
            } else {
              newAcc.push(current);
            }
            return newAcc;
          }, [])
          .find((fieldItem: SettingsField) => fieldItem.name === fieldSubName);
        if (field) {
          return field.defaultValue;
        }
        return '';
      }
      return block.settings[fieldSubName];
    }
    return '';
  };
}
