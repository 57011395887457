import { API } from '../helpers/api';
import { BOT_ID } from '../config-global';
import { ActionType, ActionTypes } from '../contexts/AppContext';

export const getProjects = async (dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({
      url: `/project/all`,
      method: 'GET',
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_PROJECT_ALL,
        payload: data.payload,
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getCustomer = async (userId: string, dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({
      url: `/customer/getBySsoUserId/${userId}`,
      method: 'GET',
    });

    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_CUSTOMER_BY_SSO_ID,
        payload: data,
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const addToCart = async (
  dispatch: React.Dispatch<ActionType>,
  options: {
    productId: string;
    smbAccountId: string;
    priceAtPurchase: number;
    activityId: string;
    amount: 1;
  }
) => {
  try {
    const { data } = await API({
      url: `/cart`,
      method: 'POST',
      data: options,
    });

    return Boolean(data?.cart?.length);
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }

    console.error(e);
    return false;
  }
};

export const getHistory = async (
  isClient: boolean,
  customerId: string | null,
  merchantId: number | null,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    if (isClient) {
      if (customerId) {
        const { data } = await API({
          url: `/customer/getUserHistory/${customerId}`,
          method: 'GET',
        });

        if (dispatch) {
          dispatch({ type: ActionTypes.GET_ORDERS_HISTORY, payload: data || [] });
        }
      }
    } else if (merchantId) {
      const { data } = await API({
        url: `/customer/getSupplierHistory/${merchantId}`,
        method: 'GET',
      });

      if (dispatch) {
        dispatch({ type: ActionTypes.GET_ORDERS_HISTORY, payload: data || [] });
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const getClientListByMerchantId = async (
  merchantId: number | null,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    if (merchantId) {
      const { data } = await API({
        url: `/merchant/getClientListByMerchantId/${merchantId}`,
        method: 'GET',
      });
      if (dispatch) {
        dispatch({ type: ActionTypes.GET_CLIENTS_LIST, payload: data.payload || [] });
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const getApartments = async (userId: string, dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({
      url: `/apartment/byUserId/${userId}?botId=${BOT_ID}`,
      method: 'GET',
    });

    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_APARTMENTS,
        payload: { apartments: data.payload || [], count: data.count || 0 },
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }

    console.error(e);
  }
};

export const getApartmentVouchers = async (
  apartmentId: number,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    const { data } = await API({
      url: `/apartment/${apartmentId}?botId=${BOT_ID}`,
      method: 'GET',
    });

    if (dispatch) {
      if (data.success) {
        dispatch({
          type: ActionTypes.GET_APARTMENT_VOUCHERS,
          payload: { vouchers: data.payload, count: data.count },
        });
      } else {
        dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: data.message });
      }
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export const getProjectVouchers = async (
  projectId: number,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    const { data } = await API({
      url: `/project/${projectId}?botId=${BOT_ID}`,
      method: 'GET',
    });

    if (dispatch) {
      if (data.success) {
        dispatch({
          type: ActionTypes.GET_PROJECT_VOUCHERS,
          payload: { vouchers: data.payload, count: data.count },
        });
      } else {
        dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: data.message });
      }
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};
