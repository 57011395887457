'use client';

import { FC, Key } from 'react';

import { Grid, Stack, Pagination } from '@mui/material';

import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';

const VouchersV1: FC<ComponentTypeProps> = ({ block }) => {
  const vouchers = [
    {
      id: 1,
      url: '/assets/images/xtra/vouchers/1.png',
    },
    {
      id: 2,
      url: '/assets/images/xtra/vouchers/2.png',
    },
    {
      id: 3,
      url: '/assets/images/xtra/vouchers/3.png',
    },
    {
      id: 4,
      url: '/assets/images/xtra/vouchers/4.png',
    },
    {
      id: 5,
      url: '/assets/images/xtra/vouchers/5.png',
    },
    {
      id: 6,
      url: '/assets/images/xtra/vouchers/6.png',
    },
    {
      id: 7,
      url: '/assets/images/xtra/vouchers/7.png',
    },
    {
      id: 8,
      url: '/assets/images/xtra/vouchers/8.png',
    },
    {
      id: 9,
      url: '/assets/images/xtra/vouchers/9.png',
    },
    {
      id: 10,
      url: '/assets/images/xtra/vouchers/10.png',
    },
    {
      id: 11,
      url: '/assets/images/xtra/vouchers/11.png',
    },
    {
      id: 12,
      url: '/assets/images/xtra/vouchers/12.png',
    },
  ];
  return (
    <>
      <Grid container>
        {vouchers.map((voucher: any, id: Key | null | undefined) => (
          <Grid item xs={3} key={id}>
            <Image src={voucher.url} />
          </Grid>
        ))}
      </Grid>
      <Stack alignItems="center">
        <Pagination count={10} color="primary" sx={{ margin: '0 auto' }} />
      </Stack>
    </>
  );
};

export default VouchersV1;
