import { NotificationTypesEnum as N_TYPES } from './types';

const notificationIcons: Record<N_TYPES, string> = {
  [N_TYPES.TASK_REMINDERS]: 'mdi:bell',
  [N_TYPES.TASK_ASSIGNMENTS]: 'mdi:assignment',
  [N_TYPES.TASK_COMPLETION]: 'mdi:check-circle',

  [N_TYPES.EMAIL_NEW_EMAIL_RECEIVED]: 'mdi:email',
  [N_TYPES.EMAIL_FOLLOW_UP_REMINDERS]: 'mdi:email-alert',
  [N_TYPES.EMAIL_EMAIL_OPENED]: 'mdi:email-open',

  [N_TYPES.LEAD_OPPORTUNITY_NEW_LEAD_ASSIGNED]: 'mdi:account-plus',
  [N_TYPES.LEAD_OPPORTUNITY_LEAD_STATUS_CHANGE]: 'mdi:account-switch',
  [N_TYPES.LEAD_OPPORTUNITY_OPPORTUNITY_UPDATES]: 'mdi:lightbulb-outline',

  [N_TYPES.CUSTOMER_INTERACTION_NEW_INTERACTION_LOGGED]: 'mdi:account-details',
  [N_TYPES.CUSTOMER_INTERACTION_CUSTOMER_RESPONSE]: 'mdi:message-reply',
  [N_TYPES.CUSTOMER_INTERACTION_CUSTOMER_FEEDBACK]: 'mdi:thumb-up-outline',

  [N_TYPES.DEAL_SALES_NEW_DEAL_CREATED]: 'mdi:briefcase-plus',
  [N_TYPES.DEAL_SALES_DEAL_PROGRESS]: 'mdi:progress-check',
  [N_TYPES.DEAL_SALES_DEAL_CLOSURE]: 'mdi:briefcase-check',

  [N_TYPES.USER_ACTIVITY_LOGIN_ALERTS]: 'mdi:login',
  [N_TYPES.USER_ACTIVITY_PROFILE_UPDATES]: 'mdi:account-edit',
  [N_TYPES.USER_ACTIVITY_SECURITY_ALERTS]: 'mdi:shield-alert',

  [N_TYPES.WORKFLOW_AUTOMATION_WORKFLOW_TRIGGERS]: 'mdi:play-circle-outline',
  [N_TYPES.WORKFLOW_AUTOMATION_AUTOMATION_ACTIONS]: 'mdi:robot',

  [N_TYPES.SYSTEM_UPDATES]: 'mdi:update',
  [N_TYPES.SYSTEM_INTEGRATION_ALERTS]: 'mdi:integration',
  [N_TYPES.SYSTEM_DATA_IMPORT_EXPORT]: 'mdi:database-export',

  [N_TYPES.COLLABORATION_COMMENT_MENTIONS]: 'mdi:comment-account',
  [N_TYPES.COLLABORATION_FILE_SHARING]: 'mdi:file-upload',
  [N_TYPES.COLLABORATION_TEAM_UPDATES]: 'mdi:account-group',

  [N_TYPES.CUSTOM_ALERTS]: 'mdi:alert',
  [N_TYPES.CUSTOM_REPORT_GENERATION]: 'mdi:file-chart',
  [N_TYPES.ORDER_NEW]: '',
  [N_TYPES.ORDER_CHANGE_STATUS]: '',
  [N_TYPES.ORDER_DELIVERY_STARTS]: '',
  [N_TYPES.ORDER_DELIVERY_STATUS]: '',
  [N_TYPES.ORDER_DELIVERY_COMPLETED]: '',
  [N_TYPES.ORDER_DELIVERY_FAILED]: '',
  [N_TYPES.ORDER_COMPLETED]: '',
  [N_TYPES.ORDER_CANCELLED]: '',
  [N_TYPES.PROMOTION]: '',
  [N_TYPES.WEEKLY_PRODUCT_UPDATE]: '',
  [N_TYPES.PROMOTION_INDIVIDUAL]: '',
  [N_TYPES.CUSTOMER_NEW]: '',
  [N_TYPES.CUSTOMER_BIRTHDAY]: '',
  [N_TYPES.COMMENTARY_NEW]: '',
  [N_TYPES.TESTIMONIAL_NEW]: '',
  [N_TYPES.NEWS_ANNOUNCEMENT]: '',
  [N_TYPES.WEEKLY_BLOG_DIGEST]: '',
  [N_TYPES.CUSTOM]: '',
  [N_TYPES.FRIEND]: '',
  [N_TYPES.PROJECT]: '',
  [N_TYPES.FILE]: '',
  [N_TYPES.TAGS]: '',
  [N_TYPES.PAYMENT]: '',
};
export default notificationIcons;
