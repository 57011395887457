'use client';

import dayjs from 'dayjs';
import React, { FC, useMemo, useState, useEffect } from 'react';

import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Stack, Container, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import Iconify from 'src/components/iconify';

import { ComponentTypeProps } from 'src/types/page-generator';
import {
  CustomerHistoryTransaction,
  SupplierHistoryTransaction,
} from 'src/types/history-city-people';

import { getContentValueFromProps } from '../../utils';
import { getProjects } from '../../../../api/citypeople';
import { IProjectCityPeople } from '../../../../types/project-citypeople';
import { useResponsive } from '../../../../hooks/use-responsive';

const HistorySearchCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const isMobile = useResponsive('down', 'sm');
  const { state, dispatch } = useAppContext();

  const [millisecondsFrom, setMillisecondsFrom] = useState<number | null>(null);
  const [millisecondsTo, setMillisecondsTo] = useState<number | null>(null);
  const [search, setSearch] = useState('');

  const isClient = !state?.customer?.merchants?.length;
  const isAlternativeDesign = useMemo(
    () => getFieldValue('settings.isAlternativeDesign') ?? false,
    [block?.settings.isAlternativeDesign]
  );

  useEffect(() => {
    getProjects(dispatch);
  }, []);

  useEffect(() => {
    const filtered: any[] = [];

    if (millisecondsFrom || millisecondsTo || search) {
      dispatch({
        type: ActionTypes.SET_FILTER_QUERY,
        payload: { searchQuery: 'active', type: '' },
      });
    } else {
      dispatch({ type: ActionTypes.SET_FILTER_QUERY, payload: { searchQuery: '', type: '' } });
    }

    state.orderHistory.forEach((item) => {
      const transaction = item as CustomerHistoryTransaction | SupplierHistoryTransaction;

      if (millisecondsFrom) {
        if (!transaction.order?.createdAt) return;

        const orderCreatedTime = new Date(transaction.order.createdAt).getTime();

        if (Number.isNaN(orderCreatedTime)) return;

        if (orderCreatedTime < millisecondsFrom) return;
      }

      if (millisecondsTo) {
        if (!transaction.order?.createdAt) return;

        const orderCreatedTime = new Date(transaction.order.createdAt).getTime();

        if (Number.isNaN(orderCreatedTime)) return;

        if (orderCreatedTime > millisecondsTo) return;
      }

      if (search) {
        if (isClient) {
          const customerTransaction = item as CustomerHistoryTransaction;

          if (
            customerTransaction.merchantTitle &&
            customerTransaction.merchantTitle.includes(search)
          ) {
            filtered.push(customerTransaction);
            return;
          }

          if (customerTransaction.orderNumber && customerTransaction.orderNumber.includes(search)) {
            filtered.push(customerTransaction);
            return;
          }

          if (
            customerTransaction.cardTypeName &&
            customerTransaction.cardTypeName.includes(search)
          ) {
            filtered.push(customerTransaction);
            return;
          }
        } else {
          const supplierTransaction = item as SupplierHistoryTransaction;

          if (
            supplierTransaction.creator.address &&
            supplierTransaction.creator.address.includes(search)
          ) {
            filtered.push(supplierTransaction);
            return;
          }

          if (
            supplierTransaction.creator.email &&
            supplierTransaction.creator.email.includes(search)
          ) {
            filtered.push(supplierTransaction);
            return;
          }

          if (
            supplierTransaction.creator.firstName &&
            supplierTransaction.creator.firstName.includes(search)
          ) {
            filtered.push(supplierTransaction);
            return;
          }

          if (
            supplierTransaction.creator.lastName &&
            supplierTransaction.creator.lastName.includes(search)
          ) {
            filtered.push(supplierTransaction);
            return;
          }
        }

        return;
      }

      filtered.push(transaction);
    });

    dispatch({ type: ActionTypes.SET_FILTERED_ORDERS_HISTORY, payload: filtered });
  }, [millisecondsFrom, millisecondsTo, search]);

  function CustomOpenPickerIcon() {
    return <Iconify color="primary.light" icon="mage:calendar-2" width={20} />;
  }

  return (
    <Container maxWidth={isMobile ? "sm" : "md"}>
      {isAlternativeDesign ? (
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            margin: '0 auto 30px',
            width: { xs: '100%' },
          }}
        >
          {block?.settings?.variant === 'default' && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack
                sx={{ width: '100%', mb: '5px' }}
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
                gap={2.5}
              >
                <DatePicker
                  defaultValue={dayjs('2024-04-17')}
                  label="מתאריך"
                  slots={{ openPickerIcon: CustomOpenPickerIcon }}
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-root': {
                      flexDirection: 'row-reverse',
                    },
                    '& input': {
                      textAlign: 'end',
                    },
                  }}
                />
                <DatePicker
                  defaultValue={dayjs('2024-04-17')}
                  label="עד תאריך"
                  slots={{ openPickerIcon: CustomOpenPickerIcon }}
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-root': {
                      flexDirection: 'row-reverse',
                    },
                    '& input': {
                      textAlign: 'end',
                    },
                  }}
                />
                {/* <TextField */}
                {/*  sx={{ width: '48%' }} */}
                {/*  inputProps={{ className: 'historySearchInput' }} */}
                {/*  InputProps={{ */}
                {/*    startAdornment: ( */}
                {/*      <Iconify color="primary.light" icon="mage:calendar-2" width={20} /> */}
                {/*    ), */}
                {/*  }} */}
                {/*  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { */}
                {/*    const ms = new Date(event.target.value).getTime(); */}
                {/*    setMillisecondsTo(ms > 0 ? ms : null); */}
                {/*  }} */}
                {/*  label="מתאריך" */}
                {/*  type="date" */}
                {/* /> */}
                {/* <TextField */}
                {/*  sx={{ width: '48%' }} */}
                {/*  inputProps={{ className: 'historySearchInput' }} */}
                {/*  InputProps={{ */}
                {/*    startAdornment: ( */}
                {/*      <Iconify color="primary.light" icon="mage:calendar-2" width={20} /> */}
                {/*    ), */}
                {/*  }} */}
                {/*  onChange={(event: React.ChangeEvent<HTMLInputElement>) => { */}
                {/*    const ms = new Date(event.target.value).getTime(); */}
                {/*    setMillisecondsFrom(ms > 0 ? ms : null); */}
                {/*  }} */}
                {/*  label="עד תאריך" */}
                {/*  type="date" */}
                {/* /> */}
              </Stack>
            </LocalizationProvider>
          )}
          <Stack
            width={1}
            direction={block?.settings?.variant === 'default' ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              fullWidth={!block?.settings?.enableList}
              label="חיפוש"
              sx={{
                mt: 1,
                width: block?.settings?.enableList ? '48%' : '100%',
              }}
              InputProps={{
                startAdornment: (
                  <Iconify color="primary.light" icon="mingcute:search-line" width={20} />
                ),
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(event.target.value);
              }}
            />
            {block?.settings?.enableList && block?.settings?.variant !== 'clientList' && (
              <TextField label="בחר פרויקט" select sx={{ mt: 1, width: '48%' }}>
                {state?.projects.map((option: IProjectCityPeople) => (
                  <MenuItem
                    key={option.id}
                    value={option.title}
                    sx={{
                      mx: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {block?.settings?.variant === 'clientList' && (
              <TextField label="בחר פרויקט" select fullWidth sx={{ mt: 1 }}>
                {state?.projects.map((option: IProjectCityPeople) => (
                  <MenuItem
                    key={option.id}
                    value={option.title}
                    sx={{
                      mx: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            margin: '0 auto',
            width: { xs: '100%', sm: '340px' },
          }}
        >
          {block?.settings?.variant === 'default' && (
            <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between">
              <TextField sx={{ width: '45%' }} type="date" />
              <TextField sx={{ width: '45%' }} type="date" />
            </Stack>
          )}
          <Stack
            width={1}
            direction={block?.settings?.variant === 'default' ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems="baseline"
          >
            <TextField
              fullWidth={!block?.settings?.enableList}
              label="חיפוש"
              sx={{ mt: 1, width: block?.settings?.enableList ? '45%' : '100%' }}
            />
            {block?.settings?.enableList && block?.settings?.variant !== 'clientList' && (
              <TextField
                label="בחר פרויקט"
                select
                sx={{ mt: 1, width: block?.settings?.enableList ? '45%' : '100%' }}
              >
                {state?.projects.map((option: IProjectCityPeople) => (
                  <MenuItem
                    key={option.id}
                    value={option.title}
                    sx={{
                      mx: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {block?.settings?.variant === 'clientList' && (
              <TextField select fullWidth sx={{ mt: 1 }} label="בחר פרויקט">
                {state?.projects.map((option: IProjectCityPeople) => (
                  <MenuItem
                    key={option.id}
                    value={option.title}
                    sx={{
                      mx: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
        </Stack>
      )}
    </Container>
  );
};

export default HistorySearchCityPeople;
