'use client';

import { useRouter } from 'next/navigation';
import { FC, useMemo, useCallback } from 'react';

import { Box, Grid, Stack, Button, Container, Typography } from '@mui/material';

import { SERVICES } from 'src/_mock/_services';
import { useAppContext } from 'src/contexts/AppContext';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import { DEFAULT_MERCHANT_IMAGE } from 'src/_mock/_companies';

import Iconify from 'src/components/iconify';

import { useWindowSize } from 'src/hooks/use-window-size';

import { PriceInterface } from 'src/types/b2b';
import { PriceTypeEnum } from 'src/types/product';
import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';
import { fCurrency } from '../../../../utils/format-number';
import { useResponsive } from '../../../../hooks/use-responsive';

const COMPANY = {
  image: DEFAULT_MERCHANT_IMAGE,
  title: 'דא קוצ’ינה מטבחים',
  description:
    'דא קוצ’ינה – החברה המובילה בתחום המטבחים והיבואנית הבלעדית של מותג המטבחים האיטלקי Domino Cucine',
  data1: {
    address: 'סניף בני ברק רח לח"י 13, בני ברק',
    work: 'שעות פתיחה ראשון – חמישי: 9.00-19.00 יום ו’: 09:00-14:00 מוצ”ש: סגור',
  },
};
const BUTTON_TEXT = 'חזור לרשימת הספקים';

const CompanyDescription: FC<ComponentTypeProps> = ({ block }) => {
  const router = useRouter();
  const { state } = useAppContext();
  const { selectedMerchant, selectedProduct } = state;
  const getFieldValue = getContentValueFromProps(block);
  const isMobile = useResponsive('down', 'sm');

  const balanceVoucher = selectedProduct
    ? selectedProduct?.prices?.find((p: PriceInterface) => p.type === PriceTypeEnum.MAX_PRICE)
        ?.value
    : 0;
  const balanceCard = selectedProduct?.balance || 0;
  const balance: number | string = balanceVoucher || balanceCard;

  const { width } = useWindowSize();
  const isSmallDisplay = width < 410;

  const buttonLink: string = useMemo(() => getFieldValue('buttonLink'), [block?.buttonLink]);
  const isAlternativeDesign = useMemo(
    () => getFieldValue('settings.isAlternativeDesign') ?? false,
    [block?.settings.isAlternativeDesign]
  );
  const reverseRedirect = useMemo(
    () => getFieldValue('reverseRedirect') ?? '#',
    [block?.reverseRedirect]
  );

  const getIsExistLink = useCallback((l: string) => l && l !== '#', []);
  const getPreparedLink = (rawLink: string) => {
    if (rawLink && getIsExistLink(rawLink))
      return `${rawLink[0] === '/' ? rawLink : `/${rawLink}`}`;
    return '#';
  };

  const imageLink =
    state.selectedVirtualCard?.mainImageURL || selectedProduct?.cardType?.mainImageURL;
  const getImageLink = (imgLink: string, def: string) =>
    imgLink ? `${BASIC_URL_FOR_IMAGES}${imgLink}` : def;

  const handleBackToCoupons = () => {
    if (reverseRedirect && getIsExistLink(reverseRedirect))
      router.push(getPreparedLink(reverseRedirect));
  };

  const asteriskMini = (
    <Box
      sx={{
        width: '16px',
        height: '16px',
        m: '4px',
        backgroundSize: 'contain',
        backgroundImage: 'url(/assets/images/city-people/asterisk-mini.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%',
      }}
    />
  );

  const cardNumber = (
    <Stack
      sx={{ direction: 'ltr', gap: '3px' }}
      direction={isSmallDisplay ? 'column' : 'row'}
      alignItems={isSmallDisplay ? 'flex-start' : 'center'}
    >
      <Typography fontSize={10} lineHeight="12px" fontWeight={100}>
        {`קוד שובר ${isSmallDisplay ? '' : ':'}`}
      </Typography>
      <Typography fontSize={12} lineHeight="12px" fontWeight={100}>
        {selectedProduct?.cardNumber}
      </Typography>
    </Stack>
  );

  const cardBalance = (
    <Stack
      sx={{ direction: 'ltr', gap: '5px', ...(isSmallDisplay && { pb: '5px' }) }}
      direction={isSmallDisplay ? 'column' : 'row'}
      alignItems="center"
    >
      <Typography
        variant="body1"
        color="primary.light"
        textAlign="center"
        fontSize={isSmallDisplay ? 16 : 10}
        lineHeight="20px"
      >
        {`יתרה למימוש ${isSmallDisplay ? '' : ':'}`}
      </Typography>
      <Typography
        variant="body1"
        color="primary.light"
        textAlign="center"
        fontSize={isSmallDisplay ? 20 : 12}
        lineHeight="12px"
      >
        {fCurrency(balance ?? 0) || '0'}
      </Typography>
    </Stack>
  );

  // const locationIcon = <Iconify icon="fluent:location-28-regular" width={36} height={50} />;

  if (!selectedMerchant) {
    return (
      <Box
        my={10}
        sx={{
          maxWidth: 360,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          m: '30px auto',
        }}
      >
        <Typography textAlign="center" fontSize={22}>
          MERCHANT NOT SELECTED!
        </Typography>
        <Typography textAlign="center">You need to go to the list of merchants</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth={isMobile ? 'sm' : 'md'}>
      <Stack
        direction="column"
        alignItems="center"
        gap={2}
        sx={{
          margin: '0 auto',
          width: { xs: '100%' },
        }}
      >
        <Grid
          container
          spacing={1}
          p={isAlternativeDesign ? 1.4 : 1}
          sx={{
            mt: 2.5,
            borderRadius: 1,
            justifyContent: 'center',
            ...(isAlternativeDesign && {
              border: (theme) => `solid 1px ${theme.palette.primary.main}`,
              borderRadius: 3,
              mb: 10,
            }),
          }}
        >
          {isAlternativeDesign && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pb={2}
              sx={{
                flexDirection: 'column',
                width: '100%',
                minHeight: 150,
                borderBottomWidth: '1px',
                borderBottomColor: 'primary.main',
                borderBottomStyle: 'solid',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={isAlternativeDesign ? 'space-between' : 'normal'}
                sx={{ width: '100%', height: '100%' }}
                gap={0}
              >
                <Stack sx={{ pl: 1, width: '45%' }}>
                  <Typography variant="body1" fontSize={25} fontWeight={100}>
                    {selectedProduct?.name}
                  </Typography>

                  {cardNumber}

                  {selectedProduct?.description && (
                    <Typography fontSize={12} lineHeight="12px">
                      {selectedProduct?.description} 10.02.24
                    </Typography>
                  )}
                  <Box
                    sx={{
                      border: (theme) => `solid 1px ${theme.palette.primary.light}`,
                      borderRadius: '20px',
                      px: '15px',
                      pt: '5px',
                      pb: '2px',
                      width: 'fit-content',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      my: 2,
                    }}
                  >
                    {cardBalance}
                  </Box>
                  <Box
                    sx={{
                      width: 'fit-content',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    onClick={handleBackToCoupons}
                  >
                    <Iconify
                      width={25}
                      color="primary.light"
                      icon="material-symbols-light:keyboard-arrow-right"
                    />
                    <Typography fontSize={13} fontWeight={100} sx={{ textAlign: 'center' }}>
                      {BUTTON_TEXT}
                    </Typography>
                  </Box>
                </Stack>
                <Box
                  width={isAlternativeDesign ? '50%' : 130}
                  height={isAlternativeDesign ? '100%' : 100}
                  borderRadius={isAlternativeDesign ? '0 16px 16px 0' : '16px'}
                  sx={{
                    background: '#FFFFFF',
                    backgroundImage: `url('${getImageLink(imageLink ?? '', SERVICES[0].image)}')`,
                    backgroundPosition: '50%',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </Stack>
            </Box>
          )}

          <Grid item xs={isAlternativeDesign ? 5 : 6} mt={isAlternativeDesign ? 1 : 0}>
            <Box
              width={isAlternativeDesign ? '26vw' : 110}
              height={isAlternativeDesign ? '26vw' : 110}
              sx={{
                position: 'relative',
                borderRadius: 2,
                background: '#FFFFFF',
                ...(state.selectedMerchant && {
                  backgroundImage: `url('${getImageLink(state.selectedMerchant.mainImageURL ?? '', COMPANY.image)}')`,
                  backgroundPosition: '50%',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                }),
                ...(isAlternativeDesign && {
                  maxWidth: 160,
                  maxHeight: 160,
                }),
              }}
            />
          </Grid>
          <Grid item xs={isAlternativeDesign ? 7 : 6} mt={isAlternativeDesign ? 1 : 0}>
            {isAlternativeDesign ? (
              <Typography fontSize={24} fontWeight={100}>
                {state.selectedMerchant?.title || COMPANY.title}
              </Typography>
            ) : (
              <Typography variant="h6">{state.selectedMerchant?.title || COMPANY.title}</Typography>
            )}

            <Typography
              paragraph
              fontSize={isAlternativeDesign ? 16 : 13}
              fontWeight={isAlternativeDesign ? 100 : 400}
            >
              {state.selectedMerchant?.description || ''}
            </Typography>
          </Grid>

          <Box
            sx={{
              mt: '20px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                borderRadius: 2,
                backgroundColor: 'primary.main',
                mt: 2,
                ...(isAlternativeDesign && {
                  backgroundColor: 'primary.light',
                  fontSize: 16,
                  fontWeight: 100,
                  borderRadius: 3,
                  width: 'fit-content',
                  minWidth: '150px',
                  padding: '4px 40px',
                  margin: '16px auto',
                }),
              }}
              onClick={() => {
                if (buttonLink && getIsExistLink(buttonLink))
                  router.push(getPreparedLink(buttonLink));
              }}
            >
              לתשלום
            </Button>
          </Box>
        </Grid>
      </Stack>
    </Container>
  );
};

export default CompanyDescription;
