'use client';

// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
// types
// components
import Iconify from 'src/components/iconify';

import { useTranslate } from '../../locales';

export default function CheckoutBillingInfo() {
  const { t } = useTranslate();
  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader
        title={`${t('Address')}`}
        action={
          <Button size="small" startIcon={<Iconify icon="solar:pen-bold" />}>
            {`${t('Edit')}`}
          </Button>
        }
      />
      <Stack spacing={1} sx={{ p: 3 }}>
        <Box sx={{ typography: 'subtitle2' }}>
          {/* {`${t(`${billing?.name}`)}`} */}
          <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
            {/* ({`${t(`${billing?.addressType}`)}`} */}
          </Box>
        </Box>

        {/* <Box sx={{ color: 'text.secondary', typography: 'body2' }}>{`${
          t()
          // `${billing?.fullAddress}`
        }`}</Box>

        <Box sx={{ color: 'text.secondary', typography: 'body2' }}>{`${
          t()
          // `${billing?.phoneNumber}`
        }`}</Box> */}
      </Stack>
    </Card>
  );
}
