import { enqueueSnackbar } from 'notistack';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// utils
import { fToNow } from 'src/utils/format-time';
import axiosInstance, { endpoints } from 'src/utils/axios';
import { useTranslate } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { NotificationType, NotificationTypesEnum as N_TYPES, ActionOptions } from './types';
import { reader } from './ common';
import ActionProject from './action-components/action-project';
import ActionFile from './action-components/action-file';
import ActionTags from './action-components/action-tags';
import notificationIcons from './notification-icons';

// ----------------------------------------------------------------------

type NotificationItemProps = {
  notification: NotificationType;
  mutate: (id: string, newData: Partial<NotificationType>) => void;
};
export default function NotificationItem({ notification, mutate }: NotificationItemProps) {
  const { isViewed, highPriority, data, createdAt, type } = notification;
  const { icon, title, avatarUrl, actions } = data ?? { icon: null, title: null, avatarUrl: null };
  const theme = useTheme();
  const { t } = useTranslate();

  const renderUnReadBadge = !isViewed && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );

  const iconForAvatar = notificationIcons[type] ?? null;

  const renderAvatar = (
    <ListItemAvatar>
      {icon || avatarUrl || iconForAvatar ? (
        <Avatar sx={{ bgcolor: 'background.neutral' }} {...(avatarUrl && { src: avatarUrl })}>
          {(icon || iconForAvatar) && (
            <Iconify
              icon={icon || iconForAvatar}
              color={highPriority ? theme.palette.primary.main : theme.palette.text.secondary}
            />
          )}
        </Avatar>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            bgcolor: 'background.neutral',
          }}
        >
          {/* <Box
            component="img"
            src={`/assets/icons/notification/${
              (notification.type === 'order' && 'ic_order') ||
              (notification.type === 'chat' && 'ic_chat') ||
              (notification.type === 'mail' && 'ic_mail') ||
              (notification.type === 'delivery' && 'ic_delivery')
            }.svg`}
            sx={{ width: 24, height: 24 }}
          /> */}
        </Stack>
      )}
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={reader(title)}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: 'currentColor',
                mx: 0.5,
                borderRadius: '50%',
              }}
            />
          }
        >
          {`${fToNow(createdAt)} • ${data.projectName}`}
        </Stack>
      }
    />
  );

  const handleAction = async (action: string, id: string, options?: ActionOptions) => {
    try {
      await axiosInstance.post(`${endpoints.notifications.action}/${id}/${action}`);
      mutate(id, { actionPerformed: action });
    } catch (err) {
      enqueueSnackbar({ variant: 'error', message: err.message });
    }
  };

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (_theme) => `dashed 1px ${_theme.palette.divider}`,
      }}
    >
      {renderUnReadBadge}

      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }} spacing={1}>
        {title && renderText}

        {/* Category-specific actions */}
        {type === N_TYPES.PROJECT && <ActionProject notification={notification} />}
        {type === N_TYPES.FILE && <ActionFile notification={notification} />}
        {type === N_TYPES.TAGS && <ActionTags notification={notification} />}

        {/* Action buttons */}
        {!notification.actionPerformed && (
          <Stack direction="row" spacing={1}>
            {actions.map((action) => (
              <Button
                key={action.action}
                variant={action.variant}
                size="small"
                color={action.color}
                onClick={() =>
                  handleAction(action.action, notification.id, {
                    ...(action.link && { link: action.link }),
                  })
                }
                {...(action.startIcon && { startIcon: <Iconify icon={action.startIcon} /> })}
                {...(action.endIcon && { startIcon: <Iconify icon={action.endIcon} /> })}
              >
                {t(action.label)}
              </Button>
            ))}
          </Stack>
        )}
      </Stack>
    </ListItemButton>
  );
}
