'use client';

import React, { FC } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import ProductDetailsSquareList from './product-details-square-list';
import StarRate from './star-rate';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import { useRouter } from '../../../../../routes/hooks';

const ProductDetails: FC<ComponentTypeProps> = ({ block }) => {
  const router = useRouter();
  return(
    <Container maxWidth="xs">
      <Box
        className="PageDad"
        sx={{ display: 'flex', justifyContent: 'center', py: 3 }}
      >
        <Box className="page" sx={{ width: '100%', maxWidth: 1200, px: 2 }}>
          <Box className="productdetail_pages">
            <Box component="section" sx={{ mb: 3 }}>
              <ProductDetailsSquareList />
            </Box>

            <Box sx={{ my: 3, textAlign: 'center' }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                נקיון יסודי של הדירה
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: 'bold', color: 'primary.main' }}
              >
                85$
              </Typography>
            </Box>

            <Box sx={{ my: 3 }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                על השירות
              </Typography>
              <Typography sx={{ color: '#77838f', fontSize: '14px' }}>
                גם אלו מכם שגרים בדירה זקוקים לעזרה מדי פעם. לא משנה מה מצב חייך,
                מגיע לך מנוחה.
              </Typography>
              <Typography sx={{ color: '#77838f', fontSize: '14px' }}>
                הדבר האחרון שתרצו לעשות הוא לחזור הביתה מיום ארוך ולהתחיל לנקות.
              </Typography>
              <Typography sx={{ color: '#77838f', fontSize: '14px' }}>
                שירותי ניקיון הדירות שלנו הם הפתרון המושלם.
              </Typography>
              <Typography sx={{ color: '#77838f', fontSize: '14px' }}>
                הנקיון כולל:
              </Typography>
              <Typography sx={{ color: '#77838f', fontSize: '14px' }}>
                - ניקוי רצפות
              </Typography>
              <Typography sx={{ color: '#77838f', fontSize: '14px' }}>
                - ניקוי רטוב
              </Typography>
              <Typography sx={{ color: '#77838f', fontSize: '14px' }}>
                - ציוד ניקוי
              </Typography>
              <Typography sx={{ color: '#77838f', fontSize: '14px' }}>
                - שטיפת כלים
              </Typography>
              <Typography sx={{ color: '#77838f', fontSize: '14px' }}>
                - הוצאת זבל
              </Typography>
            </Box>
            <StarRate />
            <Box className="box_btn" sx={{ mt: 4 }}>
              <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box className="btn_area" sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    variant="contained"
                    sx={{
                      px: 4,
                      bgcolor: 'primary.main',
                      '&:hover': { bgcolor: 'primary.main' },
                    }}

                  >
                    הוסף לעגלה
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      px: 4,
                      bgcolor: 'primary.main',
                      opacity: 0.5,
                      '&: hover': { bgcolor: 'primary.main', opacity: 0.5 },
                    }}
                    onClick={() => router.push('/addtocart')}
                  >
                    שלם עכשיו
                  </Button>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ProductDetails;
