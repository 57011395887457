'use client';

import { FC, useMemo } from 'react';

import { Typography } from '@mui/material';

import { useLocales } from '../../../../locales';
import { getContentValueFromProps } from '../../utils';
import { useContentContext } from '../../../../components/content';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useAuthContext } from '../../../../auth/hooks';
import { fDate, fDateLocale } from '../../../../utils/format-time';

export const TextEditor: FC<ComponentTypeProps> = ({ block }) => {
  const { user } = useAuthContext();
  const { expirationDate } = useContentContext();
  const closingDate = expirationDate;
  const currentUser = user || {};
  const getFieldValue = getContentValueFromProps(block);
  const { currentLang } = useLocales();
  const title = useMemo(() => getFieldValue('title'), [block?.title]);

  // Функция для подстановки значений в текст
  const interpolateText = (template: string) => template
    .replace('{firstName}', currentUser?.firstName || '')
    .replace('{lastName}', currentUser?.lastName || '')
    .replace('{closingDate}', fDateLocale(closingDate, '', currentLang.value) || '');

  // Обработанный текст
  const processedText = useMemo(() => interpolateText(title), [title, user, closingDate]);

  return (
    <Typography
      sx={{
        '& h1': { my: 1 },
        '& h2': { my: 1 },
        '& h3': { my: 1 },
        '& h4': { my: 1 },
        '& h5': { my: 1 },
        '& h6': { my: 1 },
        '& p': { margin: 0 },
        direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
        width: '100%',
        lineHeight: block?.settings?.lineHeight,
      }}
    >
      <span dangerouslySetInnerHTML={{ __html: processedText }} />
    </Typography>
  );
};
