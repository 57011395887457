import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';

import { useTranslate } from 'src/locales';
import { fToNow } from 'src/utils/format-time';

import { reader } from '../ common';
import { NotificationType } from '../types';

export default function ActionProject({ notification }: { notification: NotificationType }) {
  const { data, createdAt } = notification;
  const { userName, projectName, description } = data;

  const { t } = useTranslate();

  return (
    <>
      <ListItemText
        disableTypography
        primary={reader(
          `<p><strong>${userName}</strong> ${t('mentioned you in')} <strong>${projectName}</strong></p>`
        )}
        secondary={
          <Stack
            direction="row"
            alignItems="center"
            sx={{ typography: 'caption', color: 'text.disabled' }}
            divider={
              <Box
                sx={{
                  width: 2,
                  height: 2,
                  bgcolor: 'currentColor',
                  mx: 0.5,
                  borderRadius: '50%',
                }}
              />
            }
          >
            {fToNow(createdAt)}
          </Stack>
        }
      />
      <Stack alignItems="flex-start">
        <Box
          sx={{
            p: 1.5,
            my: 1.5,
            borderRadius: 1.5,
            color: 'text.secondary',
            bgcolor: 'background.neutral',
          }}
        >
          {reader(description)}
        </Box>

        <Button size="small" variant="contained">
          {t('Reply')}
        </Button>
      </Stack>
    </>
  );
}
