'use client';

import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ComponentTypeProps } from '../../../../types/page-generator';
import Iconify from '../../../../components/iconify';
import ESimStatistics from './e-sim-statistics';

export const MyESim: FC<ComponentTypeProps> = ({ block }) => (
  <Stack>
    <Stack direction="row" width={1} justifyContent="center">
      <Typography textAlign="center" fontWeight={1000} fontSize="30px">
        eSim-
      </Typography>
      <Typography textAlign="center" fontWeight={1000} fontSize="30px">
        1
      </Typography>
    </Stack>
    <Grid container sx={{ '& p': { textAlign: 'center' } }}>
      <Grid item xs={4}>
        <Stack>
          <Stack alignItems="center">
            <Stack direction="row" alignItems="center">
              <Typography>Package</Typography>
              <Iconify icon="tabler:antenna-bars-5" />
            </Stack>
          </Stack>
          <Typography>Turkey 300MB</Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack>
          <Stack alignItems="center">
            <Stack direction="row" alignItems="center">
              <Typography>Speed</Typography>
              <Iconify icon="line-md:speed" />
            </Stack>
          </Stack>
          <Typography>4G</Typography>
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack alignItems="center">
          <Stack direction="row" alignItems="center">
            <Typography>Service</Typography>
            <Iconify icon="hugeicons:internet-antenna-01" />
          </Stack>
          <Typography>Data</Typography>
        </Stack>
      </Grid>
    </Grid>
    <ESimStatistics
      title=""
      chart={{
        series: [
          { label: 'Used', value: 14.6 },
          { label: 'Available', value: 285.4 },
        ],
      }}
    />
    <Stack alignItems="center">
      <Button
        variant="contained"
        color="primary"
        sx={{ '&:hover': { backgroundColor: 'primary.main' } }}
      >
        <Iconify icon="iconamoon:arrow-down-2-thin" />
        Turkey 300Mb
      </Button>
    </Stack>
  </Stack>
);
