import { Box } from '@mui/material';

import { IconPropsType } from '../types';

function SmallZipLineIcon({ width, color = '#3A0D5E', height }: IconPropsType) {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      fill="none"
      viewBox="0 0 122 20"
    >
      <path
        d="M537 6.54054L12.7657 2.10907C12.1568 2.09703 12.0595 2.97771 12.6563 3.09896L41.0882 8.87509C41.6863 8.99659 41.5868 9.87953 40.9767 9.86494L1.00001 8.90909"
        stroke={color || '#3A0D5E'}
        strokeWidth="1"
      />
    </Box>
  );
}

export default SmallZipLineIcon;
