import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';

export const getOrders = async (dispatch: React.Dispatch<ActionType>) => {
  try {
    const url = 'order/getOrders';
    const { data } = await API({
      url,
      method: 'GET',
    });

    dispatch({ type: ActionTypes.GET_ORDERS, payload: data.payload });
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const getOrderById = async (orderId: string, dispatch: React.Dispatch<ActionType>) => {
  try {
    const url = 'order';
    const { data } = await API({
      url,
      method: 'GET',
      params: {
        orderId,
        botId: BOT_ID,
      },
    });

    dispatch({ type: ActionTypes.SET_NEW_ORDER, payload: data.payload });
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};

export const addOrderDocument = async (
  url: string,
  orderId: string,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    const { data } = await API({
      url: 'document-image',
      method: 'POST',
      data: { url, orderId },
    });

    return data;
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    return null;
  }
};
