// eslint-disable-next-line import/no-cycle
import commonContentFields from '../../common/contentFields';
import { FieldTypes, SettingsField } from '../../../../../../types/page-generator';

const contentFields: SettingsField[] = [
  ...commonContentFields,
  {
    name: 'mainImage',
    type: FieldTypes.upload,
    uploadType: 'image',
    rowSize: 4,
    label: 'Main image',
    defaultValue: '',
  },
];

export default contentFields;
