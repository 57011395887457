'use client';

import React, { FC, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Stack,
  Button,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from '../../../../../components/image';
import { ComponentTypeProps } from '../../../../../types/page-generator';


const entranceCleaningHistoryArr = [
  {
    img: '/assets/images/entranceCleaning/banner.jpg',
    title: 'יום שני 09/05',
    desc: 'המנקה שלך – דניאל כהן',
    link: '#',
    class: 'reviewColorGreen',
  },
  {
    img: '/assets/images/entranceCleaning/banner.jpg',
    title: 'יום חמישי 12/05',
    desc: 'המנקה שלך – חיה אזולאי',
    link: '#',
    class: 'reviewColorGreen',
  },
  {
    img: '/assets/images/entranceCleaning/banner.jpg',
    title: 'יום שני 16/05',
    desc: 'המנקה שלך – דניאל כהן',
    link: '#',
    class: 'reviewColorGreen',
  },
  {
    img: '/assets/images/entranceCleaning/banner.jpg',
    title: 'יום חמישי 19/05',
    desc: 'המנקה שלך – חיה אזולאי',
    link: '#',
    class: 'reviewColorWhite',
  },
  {
    img: '/assets/images/entranceCleaning/banner.jpg',
    title: 'יום שני 23/05',
    desc: 'המנקה שלך – דניאל כהן',
    link: '#',
    class: 'reviewColorWhite',
  },
  {
    img: '/assets/images/entranceCleaning/banner.jpg',
    title: 'יום חמישי 26/05',
    desc: 'המנקה שלך – חיה אזולאי',
    link: '#',
    class: 'reviewColorWhite',
  },
];

const ServiceInfo: FC<ComponentTypeProps> = ({ block }) => {
  const [expanded, setExpanded] = useState<number | false>(false);

  // Функция для обработки клика по картинке
  const handleImageClick = (index: number) => {
    setExpanded(expanded === index ? false : index);
  };

  return (
    <Container maxWidth="xs" sx={{ py: 4 }}>
      <Image src="/assets/images/entranceCleaning/banner.jpg" />
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Accordion defaultExpanded sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="p" gutterBottom>
              היסטורית נקיון כניסה
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack>
              {entranceCleaningHistoryArr.map((el, i) => (
                <Accordion
                  key={i}
                  expanded={expanded === i}
                  onChange={() => setExpanded(expanded === i ? false : i)}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      bgcolor:
                        el.class === 'reviewColorGreen'
                          ? 'green.100'
                          : 'grey.100',
                      cursor: 'pointer',
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      width={1}
                      justifyContent="space-between"
                    >
                      <Image
                        src={el.img}
                        alt="Cleaning"
                        style={{
                          width: 'auto',
                          height: '50px',
                          marginBottom: 16,
                          borderRadius: '5px',
                        }}
                        onClick={() => handleImageClick(i)}
                      />
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          {el.title}
                        </Typography>
                        <Typography variant="body2">{el.desc}</Typography>
                      </Box>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor:
                            el.class === 'reviewColorGreen'
                              ? 'primary.main'
                              : 'lightgray',
                          color:
                            el.class === 'reviewColorGreen' ? 'white' : 'black',
                          borderRadius: '20px',
                          '&:hover': {
                            bgcolor:
                              el.class === 'reviewColorGreen'
                                ? 'primary.main'
                                : 'lightgray',
                            color:
                              el.class === 'reviewColorGreen'
                                ? 'white'
                                : 'black',
                          },
                        }}
                      >
                        פרטים
                      </Button>
                    </Stack>
                  </Paper>
                  <AccordionDetails>
                    <Stack>
                      <Typography sx={{ textAlign: 'left' }}>
                        About this service
                      </Typography>
                      <Typography
                        sx={{
                          color: 'grey',
                          textAlign: 'left',
                          fontSize: '12px',
                        }}
                      >
                        Our company serves your entrance twice a week. We always
                        welcome your feedback, comments and suggestions to
                        improve the service. Entrance cleaning includes: - wet
                        cleaning - washing the floor – wall rubbing - watering
                        flowers (if necessary) Recommend our service to your
                        friends!
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: 'primary.main',
                          color: 'white',
                          borderRadius: '20px',
                          '&:hover': {
                            bgcolor: 'primary.main',
                            color: 'white',
                          },
                        }}
                      >
                        Share on WhatsApp
                      </Button>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
};

export default ServiceInfo;
