// eslint-disable-next-line import/no-cycle
import { FieldTypes, SettingsField } from '../../../../../../types/page-generator';

const contentFields: SettingsField[] = [
  {
    name: 'modalId',
    type: FieldTypes.input,
    defaultValue: 'distance',
    label: 'Modal Identifier (required) ',
    rowSize: 2,
  },
];

export default contentFields;
