import { Box } from '@mui/material';

import { IconPropsType } from '../types';

function BigZipLineIcon({ width, color = '#3A0D5E', height, sx }: IconPropsType) {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      fill="none"
      viewBox="0 0 1200 15"
      sx={sx}
    >
      <path
        d="M1200 8.37838L31.7637 1L231.993 14L0 12.2273"
        stroke={color || '#3A0D5E'}
        strokeWidth="2"
      />
    </Box>
  );
}

export default BigZipLineIcon;
