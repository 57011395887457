'use client';

import { FC } from 'react';

import { Stack, Container } from '@mui/material';

import Filter from './filter';
import Iconify from '../../../../../components/iconify';
import { ComponentTypeProps } from '../../../../../types/page-generator';

export const OurServicesAdd: FC<ComponentTypeProps> = ({ block }) => (
  <Container maxWidth="xs">
    <Stack>
      <Iconify icon="ep:arrow-left" sx={{ cursor: 'pointer' }} />
      <Filter />
    </Stack>
  </Container>
);
