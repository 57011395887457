'use client';

import { FC } from 'react';

import { Card } from '@mui/material';

import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';

const MainXtraMainBanner3: FC<ComponentTypeProps> = ({ block }) => {
  const image = '/assets/images/xtra/banners/image_84.png';
  return (
    <Card
      sx={{
        width: '100%',
        borderRadius: '0px',
      }}
    >
      <Image src={image} />
    </Card>
  );
};

export default MainXtraMainBanner3;
