'use client';

// locales
// next
import Head from 'next/head';
import { useState, useEffect } from 'react';

// @mui
import { Stack, Divider, Container, Typography, Pagination } from '@mui/material';

import { useTranslate } from 'src/locales';
// routes
// @types
import { useParams } from 'next/navigation';

// utils

// layouts

// components
import Markdown from 'src/components/markdown';
import { useSettingsContext } from 'src/components/settings';

import { blogs } from '../../../../../_mock';
import { BlogPostHero, BlogPostTags } from './index';
import { IBlogPost } from '../../../../../types/blog';
import BlogPostCommentForm from './comment/BlogPostCommentForm';
import BlogPostCommentList from './comment/BlogPostCommentList';
// sections

export default function BlogPostPage() {
  const { t } = useTranslate();

  const { themeStretch } = useSettingsContext();

  const { id, title } = useParams();

  const [recentPosts, setRecentPosts] = useState<IBlogPost[]>([]);

  const [post, setPost] = useState<IBlogPost | any>(null);

  const [loadingPost, setLoadingPost] = useState(true);

  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    setPost(blogs[0]);
    setRecentPosts([]);
  }, []);

  return (
    <>
      <Head>
        <title>{`${t('Blog')}: ${post?.title || ''}`}</title>
      </Head>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        {post && (
          <Stack
            sx={{
              borderRadius: 2,
              boxShadow: (theme) => ({
                md: theme.customShadows.card,
              }),
            }}
          >
            <BlogPostHero post={post} />

            <Typography
              variant="h6"
              sx={{
                py: 5,
                px: { md: 5 },
              }}
            >
              {post.description}
            </Typography>

            <Markdown
              children={post.body}
              sx={{
                px: { md: 5 },
              }}
            />

            <Stack
              spacing={3}
              sx={{
                py: 5,
                px: { md: 5 },
              }}
            >
              <Divider />
              <BlogPostTags post={post} />
              <Divider />
            </Stack>

            <Stack
              sx={{
                px: { md: 5 },
              }}
            >
              <Stack direction="row" sx={{ mb: 3 }}>
                <Typography variant="h4">{`${t('Remarks')}`}</Typography>

                <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
                  ({post.comments.length})
                </Typography>
              </Stack>
              <BlogPostCommentForm />

              <Divider sx={{ mt: 5, mb: 2 }} />
            </Stack>

            <Stack
              sx={{
                px: { md: 5 },
              }}
            >
              <BlogPostCommentList comments={post.comments} />
              <Pagination
                count={8}
                sx={{
                  my: 5,
                  ml: 'auto',
                  mr: { xs: 'auto', md: 0 },
                }}
              />
            </Stack>
          </Stack>
        )}

        {errorMsg && !loadingPost && <Typography variant="h6">404 {errorMsg}</Typography>}

        {/* {loadingPost && <SkeletonPostDetails />} */}

        {/* {!!recentPosts.length && ( */}
        {/*  <> */}
        {/*    <Typography variant="h4" sx={{ my: 5 }}> */}
        {/*      {`${t('Recent messages')}`} */}
        {/*    </Typography> */}

        {/*    <Box */}
        {/*      gap={3} */}
        {/*      display="grid" */}
        {/*      gridTemplateColumns={{ */}
        {/*        xs: 'repeat(1, 1fr)', */}
        {/*        sm: 'repeat(2, 1fr)', */}
        {/*        md: 'repeat(4, 1fr)', */}
        {/*      }} */}
        {/*    > */}
        {/*      {recentPosts.slice(recentPosts.length - 4).map((recentPost) => ( */}
        {/*        <BlogPostCard key={recentPost.id} post={recentPost} /> */}
        {/*      ))} */}
        {/*    </Box> */}
        {/*  </> */}
        {/* )} */}
      </Container>
    </>
  );
}
