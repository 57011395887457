export const uploadProductsImage = (image: string) => {
  if (!image) {
    return '/';
  }

  if (image[0] === '/') {
    return `${process.env.NEXT_PUBLIC_HOST_MEDIA_KEY}/${image.substring(1, image.length)}`;
  }

  return image.match('http') ? image : `${process.env.NEXT_PUBLIC_HOST_MEDIA_KEY}${image}`;
};
