'use client';

import { Box, IconButton, Link, Typography } from '@mui/material';
import Iconify from '../../../../../components/iconify';
import Image from '../../../../../components/image';

type Props = {
  header: string;
  price: string;
  par: string;
  img: string;
  link: string;
};

const ServiceItem = ({ header, price, par, img, link }: Props) => (
  <Box
    display="flex"
    flexDirection={{ xs: 'row', md: 'row' }}
    justifyContent="space-between"
    alignItems="center"
    sx={{ padding: 1, direction: 'rtl' }}
  >
    <Box
      flex={1}
      padding={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <IconButton component={Link}>
          <Iconify icon="mdi-light:heart" />
        </IconButton>
        <Typography sx={{ textAlign: 'end' }}>{header}</Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="body1"
          sx={{ color: 'primary.main', fontWeight: 1000 }}
        >
          {price}
        </Typography>
        <Typography variant="body2" textAlign="left" color="grey">
          {par}
        </Typography>
      </Box>
    </Box>
    <Box
      className="box_img"
      flexShrink={0}
      sx={{ ml: { md: 2 }, display: { xs: 'block', md: 'block' } }}
    >
      <Image
        id="serviceImg"
        src={img}
        alt="Online"
        style={{ maxWidth: '150px', height: 'auto' }}
      />
    </Box>
  </Box>
);

export default ServiceItem;
