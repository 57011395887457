import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { useResponsive } from 'src/hooks/use-responsive';

import Iconify from 'src/components/iconify';

// ------------------------------

type Props = { children?: React.ReactNode; onClose: () => void; open: boolean };

const BasicModal = ({ children, onClose, open }: Props) => {
  const smUp = useResponsive('up', 'sm');
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: smUp ? '700px' : '100%',
          minHeight: 250,
          bgcolor: 'background.paper',
          borderRadius: '20px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Iconify
            icon="iconamoon:close"
            onClick={onClose}
            sx={{
              cursor: 'pointer',
              color: 'grey.600',
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 100,
            }}
          />
          {children}
        </Box>
      </Box>
    </Modal>
  );
};

export default BasicModal;
