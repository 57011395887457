import { Box } from '@mui/material';

function DoorIcon() {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      fill="none"
      viewBox="0 0 30 37"
    >
      <path
        fill="#004454"
        fillRule="evenodd"
        d="M0 35.844c0-.307.113-.601.314-.818.2-.217.473-.339.757-.339H28.93c.284 0 .556.122.757.34.201.216.314.51.314.817 0 .306-.113.6-.314.817-.2.217-.473.339-.757.339H1.07c-.284 0-.556-.122-.757-.339A1.205 1.205 0 010 35.844zM22.5 4.625h-1.071V2.312H22.5a3.1 3.1 0 012.273 1.016c.603.651.941 1.533.941 2.453v28.907h-2.143V5.78c0-.306-.113-.6-.313-.817a1.033 1.033 0 00-.758-.339z"
        clipRule="evenodd"
      />
      <path
        fill="#004454"
        fillRule="evenodd"
        d="M21.06.282c.116.109.209.243.272.394.064.15.097.315.096.48v33.532h-2.142V2.49L6.428 4.472v30.216H4.287V3.468c0-.278.093-.547.262-.757.17-.21.403-.348.66-.387l15-2.312a.996.996 0 01.453.035c.147.047.283.127.399.235z"
        clipRule="evenodd"
      />
      <path
        fill="#004454"
        d="M15 20.813c0 1.276.48 2.312 1.071 2.312.592 0 1.072-1.036 1.072-2.313 0-1.276-.48-2.312-1.072-2.312-.591 0-1.071 1.036-1.071 2.313z"
      />
    </Box>
  );
}

export default DoorIcon;
