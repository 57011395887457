'use client';

import { FC } from 'react';

import Box from '@mui/material/Box';
import { Grid, Stack, Button, Typography } from '@mui/material';

import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

export const ExchangeOption: FC<ComponentTypeProps> = ({ block }) => {
  const { dispatch } = useAppContext();
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  return (
    <Box sx={{ '& .MuiGrid-root ': { margin: 0, width: '100%' } }}>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid
          item
          md={5.9}
          xs={5.9}
          height={1}
          sx={{
            backgroundColor: 'white',
            border: '1px solid red',
            borderRadius: '10px',
            padding: '0px!important',
          }}
        >
          <Stack
            width={1}
            sx={{
              height: '42px',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              onClick={() => setActiveDialog('want-sell')}
              sx={{ height: '35px' }}
            >
              add +
            </Button>
            <Button
              sx={{
                textAlign: 'center',
                borderRadius: block?.settings?.buttonSellBorderRadius,
                fontWeight: 1000,
                fontStyle: 'bold',
                fontSize: block?.settings?.buttonSellerSize,
                color: block?.settings?.buttonSellColor,
              }}
              size={block?.settings?.buttonSellSize}
            >
              {block?.settings?.buttonSellLabel}
            </Button>
          </Stack>
          <Stack
            sx={{
              direction: 'rtl',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#FFAAAA',
              borderBottomLeftRadius: '6px',
              borderBottomRightRadius: '6px',
            }}
            onClick={() => setActiveDialog('sell')}
          >
            <Typography sx={{ textAlign: 'center' }}>100$</Typography>
            <Typography sx={{ textAlign: 'center', backgroundColor: '#FFCCCC' }}>1000€</Typography>
            <Typography sx={{ textAlign: 'center' }}>150₪</Typography>
            <Typography sx={{ textAlign: 'center', backgroundColor: '#FFCCCC' }}>150₴</Typography>
            <Typography sx={{ textAlign: 'center' }}>2000₽</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          md={5.9}
          xs={5.9}
          height={1}
          sx={{
            backgroundColor: 'white',
            border: '1px solid green',
            borderRadius: '10px',
            padding: '0px!important',
          }}
        >
          <Stack
            width={1}
            sx={{
              height: '42px',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{ height: '35px' }}
              onClick={() => setActiveDialog('want-buy')}
            >
              add +
            </Button>
            <Button
              sx={{
                textAlign: 'center',
                borderRadius: block?.settings?.buttonBuyBorderRadius,
                fontWeight: 1000,
                fontSize: block?.settings?.buttonBuySize,
                color: block?.settings?.buttonBuyColor,
              }}
              size={block?.settings?.buttonBuySize}
            >
              {block?.settings?.buttonBuyLabel}
            </Button>
          </Stack>
          <Stack
            sx={{
              direction: 'rtl',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#A2D264',
              borderBottomLeftRadius: '6px',
              borderBottomRightRadius: '6px',
            }}
            onClick={() => setActiveDialog('buy')}
          >
            <Typography sx={{ textAlign: 'center' }}>150$</Typography>
            <Typography sx={{ textAlign: 'center', backgroundColor: '#C8ED98' }}>1000€</Typography>
            <Typography sx={{ textAlign: 'center' }}>800₪</Typography>
            <Typography sx={{ textAlign: 'center', backgroundColor: '#C8ED98' }}>1500₴</Typography>
            <Typography sx={{ textAlign: 'center' }}>3000₽</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
