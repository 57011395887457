import { Box } from '@mui/material';

import { IconPropsType } from '../types';

function GiftIcon({ width, color = '#3A0D5E', height }: IconPropsType) {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height ?? width}
      fill="none"
      viewBox="0 0 120 120"
    >
      <path
        stroke={color}
        strokeWidth="2"
        d="M60 32.5v75m0-75V20m0 12.5H47.5A12.5 12.5 0 1160 20m0 12.5h12.5A12.5 12.5 0 1060 20M16.25 70h87.5m-87.5 0c0-11.64-1.15-23.25-3.43-34.66l-.32-1.59V32.5h95v1.25l-.32 1.59A176.687 176.687 0 00103.75 70m-87.5 0c0 11.64-1.15 23.25-3.43 34.66l-.32 1.59v1.25h95v-1.25l-.32-1.59A176.687 176.687 0 01103.75 70"
      />
    </Box>
  );
}

export default GiftIcon;
