'use client';

import { useRouter } from 'next/navigation';
import { FC, useMemo, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import { Box, Card, Container, Typography } from '@mui/material';

import { useAuthContext } from 'src/auth/hooks';
import { getApartments } from 'src/api/citypeople';
import { APARTMENTS } from 'src/_mock/_apartments';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';
import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import { IApartment } from 'src/types/apartment';
import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';

export const Apartments: FC<ComponentTypeProps> = ({ block }) => {
  const { state, dispatch } = useAppContext();
  const { isAuthenticated, user } = useAuthContext();

  const { apartments, apartmentsCount } = state;

  useEffect(() => {
    if (isAuthenticated && !apartmentsCount && user) {
      getApartments(user.id, dispatch).catch((e) => {
        console.log(`Error during fetch apartments: ${e}`);
      });
    }
  }, []);

  const isExistApartments = useMemo(() => {
    if (!Array.isArray(apartments)) return false;
    return Boolean(apartments.length);
  }, [apartments]);

  const getFieldValue = getContentValueFromProps(block);

  // === Content Values ===
  const link: string = useMemo(() => getFieldValue('link'), [block?.link]);
  const isOneColumn = useMemo(
    () => getFieldValue('settings.isOneColumn') ?? false,
    [block?.settings.isOneColumn]
  );

  return (
    <Container maxWidth="sm">
      <Stack
        mb={10}
        mt={2}
        gap={3}
        flexWrap={isOneColumn ? 'nowrap' : 'wrap'}
        flexDirection={isOneColumn ? 'column' : 'row-reverse'}
        justifyContent="center"
      >
        {!isExistApartments && (
          <Typography gutterBottom variant="h6" component="div" sx={{ textOverflow: 'ellipsis' }} />
        )}
        {isExistApartments &&
          apartments.map((apartment: IApartment) => (
            <ApartmentCard
              key={apartment.id}
              link={link}
              isOneColumn={isOneColumn}
              apartment={apartment}
            />
          ))}
      </Stack>
    </Container>
  );
};

type ApartmentCardProps = {
  apartment: IApartment;
  link?: string;
  isOneColumn: boolean;
};

export function ApartmentCard({ apartment, link, isOneColumn }: ApartmentCardProps) {
  const { dispatch, state } = useAppContext();
  const router = useRouter();

  // for the time being until the image of the apartment is implemented on the backend
  const localMainImage = useMemo(
    // () => APARTMENTS[getRandomInt(0, APARTMENTS.length - 1)].image,
    () =>
      APARTMENTS[state.apartments.findIndex((apart: IApartment) => apart.id === apartment.id)]
        .image,
    []
  );
  apartment.localImage = localMainImage;

  const { title, mainImageURL, localImage } = apartment;
  const isExistLink = useMemo(() => link && link !== '#', [link]);
  // not contained image in IApartment
  const imageLink = mainImageURL ? `${BASIC_URL_FOR_IMAGES}${mainImageURL}` : localImage;

  const getPreparedLink = () => {
    if (link && isExistLink) return link[0] === '/' ? link : `/${link}`;
    return '#';
  };

  const handleClick = () => {
    dispatch({
      type: ActionTypes.SET_SELECTED_APARTMENT,
      payload: apartment,
    });

    if (link && isExistLink) router.push(getPreparedLink());
  };

  return (
    <Card>
      <Stack
        sx={{
          width: isOneColumn ? '100%' : 156,
          ...(isOneColumn && {
            height: '100%',
            position: 'relative',
          }),
        }}
        direction="column"
        alignItems="center"
        gap={1}
      >
        <Box
          m={isOneColumn ? 0 : 1}
          width={isOneColumn ? '100%' : 140}
          height={isOneColumn ? 300 : 170}
          borderRadius={2}
          sx={{
            background: '#FFFFFF',
            backgroundImage: `url('${imageLink}')`,
            backgroundPosition: '50%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          onClick={handleClick}
        />
        {isOneColumn && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              opacity: 0.4,
            }}
            onClick={handleClick}
          />
        )}
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{
            textOverflow: 'ellipsis',
            ...(isOneColumn && {
              color: 'white',
              fontSize: '26px',
              fontWeight: 100,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }),
            width: '90%',
            textAlign: 'center',
          }}
          onClick={handleClick}
        >
          {title}
        </Typography>
      </Stack>
    </Card>
  );
}
