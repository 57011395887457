'use client';

import React from 'react';

// import './AboutSpesialist.css';
import StarIcon from '@mui/icons-material/Star';
import { Box, Stack, Container, Typography } from '@mui/material';

import Review from './service/review/Review';
import ServiceList from './service/service-list';
import { BlockType } from '../../../../types/generator';

type Props = {
  block?: BlockType | undefined;
};

const Specialist = ({ block }: Props) => {
  const serviceListArr = [
    {
      header: 'נקיון כניסה',
      price: 'חינם',
      par: 'כלול במחיר של תחזוקת הבית',
      img: '/assets/images/servicesList/service1.jpg',
      link: '#',
    },
    {
      header: 'נקיון יסודי',
      price: '75$',
      par: `1:30`,
      img: '/assets/images/servicesList/service2.jpg',
      link: '#',
    },
    {
      header: 'נקיון כללי של הדירה',
      price: '100$',
      par: `3:30`,
      img: '/assets/images/servicesList/service3.jpg',
      link: '#',
    },
    {
      header: 'ניקוי מקצועי של כל מכשירי החשמל הביתיים',
      price: '50$',
      par: `3:15`,
      img: '/assets/images/servicesList/service4.jpg',
      link: '#',
    },
    {
      header: 'שטיפת חלונות',
      price: '80$',
      par: `1:30`,
      img: '/assets/images/servicesList/service5.jpg',
      link: '#',
    },
  ];

  const reviewList = [
    {
      writerName: 'דני קורן',
      date: '11 מאי 2022',
      rating: '5.0',
      text: 'מנקה מעולה! ניקתה את כל הדירה אחרי אירוח.',
      img: '/assets/images/review/review1.jpg',
    },
    {
      writerName: 'טל קרמר',
      date: '7 מאי 2022',
      rating: '4.0',
      text: '',
      img: '/assets/images/review/review2.jpg',
    },
    {
      writerName: 'יונתן ספקטור',
      date: '1 מאי 2022',
      rating: '5.0',
      text: '',
      img: '/assets/images/review/review3.jpg',
    },
  ];

  return (
    <Container maxWidth="xs">
      <Box className="PageDad">
        <Box className="page">
          <Box className="doctor_pages rtl">
            <Box
              className="doctor_banner"
              sx={{
                backgroundImage: `url('/assets/images/aboutSpecialist/banner.jpg')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                color: 'white',
                textAlign: 'center',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                width={1}
                justifyContent="space-between"
                p={1}
              >
                <Stack className="doctor_heading align-center justify-between all_review_section">
                  <Typography
                    variant="body2"
                    display="flex"
                    alignItems="center"
                    sx={{ display: 'flex', alignItems: 'flex-end' }}
                  >
                    <StarIcon sx={{ width: '15px', color: 'primary.main' }} />
                    4.6 (1435 ביקורות){' '}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Typography variant="subtitle1">מנקה</Typography>
                  <Typography variant="h4">יעל כהן</Typography>
                </Stack>
              </Stack>
            </Box>

            <ServiceList
              serviceListArr={serviceListArr}
              text="השירותים של יעל"
            />

            <Box className="review_rating_sec" mt={3}>
              <Container>
                <Box className="doctors_area">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width={1}
                    sx={{direction: 'rtl'}}
                  >
                    <Typography
                      sx={{
                        color: 'primary.main',
                        cursor: 'pointer',
                      }}
                      // onClick={() => router.push('/services')}
                    >
                      {'<<'}
                      לכל הביקורות
                    </Typography>
                    <Typography>ביקורות וציונים</Typography>
                  </Stack>
                  <Box className="service_box_area">
                    {reviewList.map((el, i) => (
                      <Review
                        writerName={el.writerName}
                        date={el.date}
                        rating={el.rating}
                        text={el.text}
                        img={el.img}
                        key={i}
                      />
                    ))}
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Specialist;
