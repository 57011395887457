import Stack from '@mui/material/Stack';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import FileThumbnail from 'src/components/file-thumbnail';
import { fToNow } from 'src/utils/format-time';
import { useTranslate } from 'src/locales';

import { NotificationType } from '../types';
import { reader } from '../ common';

export default function ActionFile({ notification }: { notification: NotificationType }) {
  const { data, createdAt } = notification;
  const { fileName, fileSize, fileUploadedDate, userName, projectName } = data ?? {
    fileName: null,
    fileSize: null,
    fileUploadedDate: null,
    projectName: null,
  };

  const { t } = useTranslate();

  return (
    <>
      <ListItemText
        disableTypography
        primary={reader(
          `<p><strong>${userName}</strong> ${t('added file to')} <strong>${projectName}</strong></p>`
        )}
        secondary={
          <Stack
            direction="row"
            alignItems="center"
            sx={{ typography: 'caption', color: 'text.disabled' }}
            divider={
              <Box
                sx={{
                  width: 2,
                  height: 2,
                  bgcolor: 'currentColor',
                  mx: 0.5,
                  borderRadius: '50%',
                }}
              />
            }
          >
            {fToNow(createdAt)}
          </Stack>
        }
      />
      <Stack
        spacing={1}
        direction="row"
        sx={{
          pl: 1,
          p: 1.5,
          mt: 1.5,
          borderRadius: 1.5,
          bgcolor: 'background.neutral',
        }}
      >
        <FileThumbnail
          file="http://localhost:8080/httpsdesign-suriname-2015.mp3"
          sx={{ width: 40, height: 40 }}
        />

        <Stack
          spacing={1}
          direction={{ xs: 'column', sm: 'row' }}
          flexGrow={1}
          sx={{ minWidth: 0 }}
        >
          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ color: 'text.secondary' }}
                noWrap
              >
                {fileName}
              </Typography>
            }
            secondary={
              <Stack
                direction="row"
                alignItems="center"
                sx={{ typography: 'caption', color: 'text.disabled' }}
                divider={
                  <Box
                    sx={{
                      mx: 0.5,
                      width: 2,
                      height: 2,
                      borderRadius: '50%',
                      bgcolor: 'currentColor',
                    }}
                  />
                }
              >
                <span>{fileSize}</span>
                <span>{fileUploadedDate}</span>
              </Stack>
            }
          />

          <Button size="small" variant="outlined">
            {t('Download')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
