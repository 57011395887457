'use client';

import React, { useState, useCallback } from 'react';

// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// hooks

// utils

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// assets

// components
import Iconify from 'src/components/iconify';

import { useTranslate } from '../../../../locales';
import { FormTab } from './components/water/form-tab';
import { PetsTab } from './components/veterinary/pets-tab';
import { useResponsive } from '../../../../hooks/use-responsive';
import { useSettingsContext } from '../../../../components/settings';
import { ComponentTypeProps } from '../../../../types/page-generator';

// ----------------------------------------------------------------------

type AccountTab = {
  value: string;
  label: string;
};

export default function Veterinary({ block }: ComponentTypeProps) {
  const { t } = useTranslate();
  const isMobile = useResponsive('down', 'sm');
  const settings = useSettingsContext();
  const [activeAction, setActiveAction] = useState('');
  const [currentTab, setCurrentTab] = useState('pets');
  const quickActions = [
    'העברת בעלות על בע”ח',
    'תשלום אגרת חיסון',
    'זימון תור לחיסון',
    'פורטל איתור בעלי-חיים',
  ];
  const TABS: AccountTab[] = [
    {
      value: 'pets',
      label: 'Pets',
    },
    {
      value: 'form',
      label: 'Forms and information',
    },
  ];

  const petsTabItem = Object.prototype.hasOwnProperty.call(block, 'petsTabItem')
    ? block?.petsTabItem
    : true;
  const formTabItem = Object.prototype.hasOwnProperty.call(block, 'veterinaryFormTabItem')
    ? block?.veterinaryFormTabItem
    : true;

  const activeTabNames: string[] = [];

  if (petsTabItem) activeTabNames.push('pets');
  if (formTabItem) activeTabNames.push('form');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Card sx={{ borderRadius: 1, padding: 4 }}>
      <Stack width={1} gap={2}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Box
            sx={{
              background: 'rgb(0, 89, 210)',
              borderRadius: 1,
              width: '48px',
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Iconify icon="la:dog" width={30} color="white" />
          </Box>
          <Typography
            sx={{ fontSize: '24px', fontWeight: 1000 }}
          >{`${t('Veterinary')}`}</Typography>
        </Stack>
        <Stack
          sx={{
            borderRadius: '5px',
            border: '1px solid',
            borderColor: '#d9d9d9',
            padding: '16px',
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: 1000 }}>פעולות מהירות</Typography>
          <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
            {quickActions.map((item: string, id) => (
              <Stack
                key={id}
                direction="row"
                gap={1}
                alignItems="center"
                sx={{
                  borderLeft: activeAction === item && id !== 0 ? '1px solid blue' : 'none',
                  borderRight:
                    activeAction === item && id !== quickActions.length - 1
                      ? '1px solid blue'
                      : 'none',
                }}
              >
                <Iconify icon="iconoir:page" width={10} color="#282894" />
                <Typography
                  onClick={() => setActiveAction(item)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { color: 'blue' },
                  }}
                >
                  {item}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            border: '1px solid #0059d2',
            borderRadius: '5px',
            padding: '5px',
          }}
        >
          <Stack direction={isMobile ? 'column' : 'row'} gap={1} alignItems="center">
            <Iconify
              icon="ic:outline-mail"
              color="#000080"
              sx={{ width: '30px', height: '30px' }}
            />
            <Typography sx={{ fontWeight: 600 }}>קיימת יתרה שלא שולמה באחד מהנכסים שלך-</Typography>
            <Typography>בואו נסגור את היתרה</Typography>
          </Stack>
          <Button
            variant="contained"
            sx={{
              color: 'white',
              bgcolor: '#0059d2',
              borderRadius: '25px',
              px: '40px',
            }}
          >
            מעבר לתשלום
          </Button>
        </Stack>
        <Tabs value={currentTab} onChange={handleChangeTab}>
          {TABS.filter((tab: AccountTab) => activeTabNames.includes(tab.value)).map(
            (tab: AccountTab) => (
              <Tab key={tab.value} label={`${t(`${tab.label}`)}`} value={tab.value} />
            )
          )}
        </Tabs>

        {currentTab === 'pets' && petsTabItem && <PetsTab block={block} />}

        {currentTab === 'form' && formTabItem && <FormTab block={block} />}
      </Stack>
    </Card>
  );
}
