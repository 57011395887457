'use client';

// @mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// types

// components
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';

import { CartInterface } from 'src/types/cart';

//
import CheckoutCartProduct from './checkout-cart-product';

// ----------------------------------------------------------------------

type Props = {
  basketData: CartInterface[];
};

export default function CheckoutCartProductList({ basketData }: Props) {
  const hasNonEmptyAttributeValues = basketData.some(
    (item) => item.attributeValues && item.attributeValues.length > 0
  );

  const TABLE_HEAD = !hasNonEmptyAttributeValues
    ? [
        { id: 'product', label: 'Product' },
        { id: 'price', label: 'Price' },
        { id: 'quantity', label: 'Quantity' },
        { id: 'totalAmount', label: 'Total Price', align: 'right' },
        { id: '' },
      ]
    : [
        { id: 'product', label: 'Product' },
        { id: 'price', label: 'Price' },
        { id: 'quantity', label: 'Quantity' },
        { id: 'attributes', label: 'Attributes' },
        { id: 'totalAmount', label: 'Total Price', align: 'right' },
        { id: '' },
      ];
  return (
    <TableContainer sx={{ overflow: 'unset' }}>
      <Scrollbar>
        <Table sx={{ minWidth: 720 }}>
          <TableHeadCustom headLabel={TABLE_HEAD} />

          <TableBody>
            {basketData.map((row) => (
              <CheckoutCartProduct key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </TableContainer>
  );
}
