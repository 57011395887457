'use client';

import React, { FC, useState } from 'react';
import {
  Box,
  Typography,
  Link,
  Button,
  TextField,
  IconButton,
  InputBase,
  Container,
  RadioGroup,
  Paper,
  FormControlLabel,
  Radio,
  Stack,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import VerifiedIcon from '@mui/icons-material/Verified';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import SearchIcon from '@mui/icons-material/Search';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const AddToCart: FC<ComponentTypeProps> = ({ block }) => {
  const [step, setStep] = useState(0);
  const [selected, setSelected] = useState('card1');

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelected(event.target.value);
  };
  const cardOptions = [
    {
      id: 'card1',
      placeholder: '5555 **** **** **** 7777',
      image: '/assets/images/icons/visa_card.png',
    },
    {
      id: 'card2',
      placeholder: '9999 **** **** **** 2222',
      image: '/assets/images/icons/master_card_1.png',
    },
    {
      id: 'card3',
      placeholder: '3333 **** **** **** 5555',
      image: '/assets/images/icons/visa_card.png',
    },
  ];
  return (
    <Container maxWidth="xs">
      {step === 0 && (
        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.default',
            py: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 3,
              px: 3,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              sx={{
                width: '100%',
                maxWidth: 600,
                p: 1,
                boxShadow: 3,
                borderRadius: 2,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  flexGrow: 1,
                  p: 1,
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: 1,
                }}
              >
                <SearchIcon sx={{ color: 'action.active', mr: 1 }} />
                <InputBase
                  placeholder="חפש שם"
                  type="search"
                  fullWidth
                  sx={{ fontSize: '1rem', color: 'text.primary' }}
                />
              </Box>
              <Box sx={{ bgcolor: 'primary.main', borderRadius: '10px' }}>
                <StarIcon sx={{ fontSize: 45, color: 'white' }} />
              </Box>
            </Box>

            {/* Информация о докторе */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                maxWidth: 600,
                p: 2,
                boxShadow: 3,
                borderRadius: 2,
                gap: 2,
              }}
            >
              <Box
                component="img"
                src="/assets/images/doctor/doctor1.jpg"
                alt="doctor"
                sx={{ width: 50, height: 50, borderRadius: 1, mr: 2 }}
              />
              <Typography variant="h6" fontWeight="bold">
                יעל כהן, מנקה
              </Typography>
            </Box>

            {/* Карточка заказа */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                maxWidth: 600,
                p: 2,
                boxShadow: 3,
                borderRadius: 2,
                gap: 2,
              }}
            >
              <Box
                component="img"
                src="/assets/images/pay/bills1.jpg"
                alt="service"
                sx={{ width: 50, height: 50, borderRadius: 1, mr: 2 }}
              />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6" fontWeight="bold">
                  נקיון יסודי
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  24.04.2022
                </Typography>
              </Box>
              <Button variant="contained" color="primary" sx={{ ml: 2 }}>
                85$
              </Button>
            </Box>

            {/* Поле для кода купона */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                maxWidth: 600,
                p: 2,
                position: 'relative',
                boxShadow: 3,
                borderRadius: 2,
                backgroundImage: `url(${'/assets/images/icons/pay/dotted-border.png'})`,
                backgroundSize: 'cover',
              }}
            >
              <VerifiedIcon color="success" sx={{ ml: 1 }} />
              <TextField
                fullWidth
                variant="outlined"
                placeholder="לשימוש בקופון הכנס כאן את הקוד"
                sx={{ ml: 2 }}
              />
              {/* <IconButton sx={{ position: 'absolute', left: 8 }}> */}
              {/*  <ArrowForwardIosIcon */}
              {/*    fontSize="small" */}
              {/*    sx={{ transform: 'rotate(180deg)' }} */}
              {/*  /> */}
              {/* </IconButton> */}
            </Box>

            {/* Сумма и кнопка оплаты */}
            <Box
              sx={{
                width: '100%',
                maxWidth: 600,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 3,
                borderRadius: 2,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  px: 2,
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  סה"כ
                </Typography>
                <Link
                  href="#"
                  color="primary"
                  underline="none"
                  sx={{ fontSize: '1.25rem' }}
                >
                  $85
                </Link>
              </Box>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setStep(1)}
                sx={{ maxWidth: 300, '&:hover': { bgcolor: 'primary.main' } }}
              >
                שלם עכשיו
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {step === 1 && (
        <Box>
          <Box sx={{ maxWidth: '600px', mx: 'auto', p: 3 }}>
            <Typography variant="h4" align="center" gutterBottom>
              בחר את אמצעי התשלום
            </Typography>

            <RadioGroup value={selected} onChange={handleChange}>
              {cardOptions.map((card) => (
                <Box
                  key={card.id}
                  sx={{
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    component="img"
                    src={card.image}
                    alt="card"
                    sx={{ width: 50, height: 30 }}
                  />
                  <Typography sx={{ mr: 2, color: '#77838f' }}>
                    {card.placeholder}
                  </Typography>

                  <FormControlLabel
                    value={card.id}
                    control={<Radio />}
                    label=""
                  />
                </Box>
              ))}
            </RadioGroup>

            <Typography variant="h6">הוספת כרטיס חדש +</Typography>

            <Stack spacing={2} sx={{ mt: 4 }}>
              <Box>
                <Typography variant="h5">תשלום בנקודות</Typography>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <Typography variant="body1">סה"כ הכל נקודות: 147</Typography>
              </Box>

              <Box>
                <Typography variant="h5">תשלום בבונוסים</Typography>
              </Box>
              <Typography variant="body1">לפתיחת כל הבונוסים שלך +</Typography>
            </Stack>

            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setStep(2)}
                sx={{
                  '&:hover': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                שלם עכשיו
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {step === 2 && (
        <Box className="PageDad">
          <Box className="page">
            <Box className="add_card_pages rtl">
              <Box className="add_card_area" sx={{ mt: 3 }}>
                <Box
                  component="img"
                  src="/assets/images/icons/card_img.png"
                  alt="card-img"
                  sx={{ width: '100%', mb: 2 }}
                />
                <Typography variant="h5" component="h1" sx={{ mb: 3 }}>
                  אמצעי תשלום
                </Typography>

                <Box className="add_card_text_area" sx={{ mb: 3 }}>
                  <Typography variant="h6" sx={{ color: 'primary.main' }}>
                    מספר כרטיס
                  </Typography>

                  <Box
                    className="add_card_number"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2,
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={{ ml: 2 }}>875 5214 2214 9856</Typography>

                    <Box
                      component="img"
                      src="/assets/images/icons/master_card_1.png"
                      alt="master-card"
                      sx={{ width: '40px', height: '25px', mr: 2 }}
                    />
                  </Box>

                  <Box
                    className="add_expiry_cvv"
                    sx={{ display: 'flex', gap: 2 }}
                  >
                    <Box className="add_expiry_date" sx={{ flex: 1 }}>
                      <Typography variant="h6" sx={{ color: 'primary.main' }}>
                        תאריך תפוגה
                      </Typography>
                      <TextField
                        variant="outlined"
                        placeholder="MM/YY"
                        fullWidth
                      />
                    </Box>
                    <Box className="add_cvv" sx={{ flex: 1 }}>
                      <Typography variant="h6" sx={{ color: 'primary.main' }}>
                        CVC/CVV
                      </Typography>
                      <TextField
                        variant="outlined"
                        placeholder="***"
                        fullWidth
                      />
                    </Box>
                  </Box>

                  <Box className="add_card_name" sx={{ mt: 3 }}>
                    <Typography variant="h6" sx={{ color: 'primary.main' }}>
                      השם שעל הכרטיס
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="ישראל ישראלי"
                      fullWidth
                    />
                  </Box>
                </Box>

                <Box className="add_card_pages_box_btn" sx={{ mt: 4 }}>
                  <Link style={{ textDecoration: 'none' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{
                        '&:hover': { bgcolor: 'primary.main' },
                      }}
                      onClick={() => setStep(3)}
                    >
                      שמרי
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {step === 3 && (
        <Box
          className="PageDad"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box className="page" sx={{ textAlign: 'center' }}>
            <Box className="payment_success_pages rtl">
              <Box
                className="payment_success_div"
                sx={{ position: 'relative' }}
              >
                <Box
                  component="img"
                  src="/assets/images/icons/payment_success.png"
                  alt="payment-success"
                  sx={{ width: '100%', mb: 2 }}
                />
                <Typography variant="h4" sx={{ mt: 2 }}>
                  תודה שבחרת בנו!
                </Typography>
                <Typography variant="subtitle1">
                  אישור הזמנה נשלח אליך למייל
                </Typography>
              </Box>

              <Box className="payment_success_pages_box_btn" sx={{ mt: 4 }}>
                <Link style={{ textDecoration: 'none' }}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    sx={{
                      '&:hover': { bgcolor: 'primary.main' },
                    }}
                  >
                    חזרה לדף הבית
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default AddToCart;
