'use client';

import Slider from 'react-slick';
import React, { useState, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Grid, Stack, Divider, TextField, Typography } from '@mui/material';

import Statistics from './statistics';
import { PaidTable } from './paid-table';
import { NotPaidTable } from './not-paid-table';
import Label from '../../../../../../components/label';
import { useTranslate } from '../../../../../../locales';
import Iconify from '../../../../../../components/iconify';
import { useResponsive } from '../../../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../../../types/page-generator';

type AccountTab = {
  value: string;
  label: string;
};

const data_paid_1 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '2',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '3',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
];

const data_not_paid_1 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
];

const data_paid_2 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
];

const data_not_paid_2 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: ' עבר תוקף התשלום',
  },
  {
    id: '2',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: ' עבר תוקף התשלום',
  },
  {
    id: '3',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
];

const data_paid_3 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '2',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '3',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '4',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '5',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '6',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '7',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
  {
    id: '8',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'שולם',
  },
];

const data_not_paid_3 = [
  {
    id: '1',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'עבר תוקף התשלום',
  },
  {
    id: '2',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
  {
    id: '3',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
  {
    id: '4',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'לא שולם',
  },
  {
    id: '4',
    date: '07.05.23',
    paymentForThePeriod: 'אפריל-מאי',
    amountToPay: '754.23ש"ח',
    propertyAddress: 'ביאליק 31',
    status: 'עבר תוקף התשלום',
  },
];

export const PropertyTab = ({ block }: ComponentTypeProps) => {
  const { t } = useTranslate();
  const isMobile = useResponsive('down', 'sm');
  const [currentTab, setCurrentTab] = useState('notPaid');
  const TABS: AccountTab[] = [
    {
      value: 'notPaid',
      label: 'Not paid',
    },
    {
      value: 'paid',
      label: 'Have been paid',
    },
  ];

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const notPaidTabItem = Object.prototype.hasOwnProperty.call(block, 'notPaidTabItem')
    ? block?.notPaidTabItem
    : true;
  const paidTabItem = Object.prototype.hasOwnProperty.call(block, 'paidTabItem')
    ? block?.paidTabItem
    : true;

  const activeTabNames: string[] = [];

  if (notPaidTabItem) activeTabNames.push('notPaid');
  if (paidTabItem) activeTabNames.push('paid');

  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <Stack gap={2}>
      <Accordion sx={{ border: '1px solid grey', borderRadius: '5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Iconify icon="akar-icons:water" color="#000080" width="50px" />
            <Stack gap={1}>
              <Typography sx={{ fontWeight: 600 }}>ביאליק 31 רמת גן</Typography>
              <Stack direction="row" gap={1}>
                <Typography>דירה 3</Typography>
                <li style={{ width: '5px' }} />
                <Typography>מספר נכס 5456322</Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <Label color="info">מגורים</Label>
                <Label color="info">הדירה בבעלותך</Label>
              </Stack>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}>פרטי חשבון</Typography>
          <Stack gap={2}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>שם המשלם</Typography>
                <Typography sx={{ fontWeight: 600 }}>מוטי אברמוב</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מספר משלם</Typography>
                <Typography sx={{ fontWeight: 600 }}>123456789</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מספר כרטיס מים</Typography>
                <Typography sx={{ fontWeight: 600, wordBreak: 'break-word' }}>
                  1234560004440
                </Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>איש קשר בנכס</Typography>
                <Typography sx={{ fontWeight: 600 }}>כתובת למשלוח</Typography>
                <Typography sx={{ fontWeight: 600 }}>הרצל 41 א’, רמת גן</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Divider sx={{ my: 2, color: 'blue' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}>מספר נפשות בנכס</Typography>
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.8} xs={12}>
              <Stack>
                <Stack>
                  <Typography>מספר נפשות</Typography>
                  <Typography sx={{ fontWeight: 600 }}>5</Typography>
                </Stack>
                <Stack>
                  <Typography>כמות זכאים להטבה</Typography>
                  <Typography sx={{ fontWeight: 600 }}>0</Typography>
                </Stack>
                <Stack>
                  <Typography>תאריך עדכון מס’ נפשות</Typography>
                  <Typography sx={{ fontWeight: 600 }}>08.03.17</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              md={0.2}
              xs={12}
              sx={{
                borderRight: '1px solid',
                borderColor: 'rgb(217, 217, 217)',
              }}
            />
            <Grid
              item
              md={8.8}
              xs={12}
              sx={{
                '& .slick-slider': {
                  height: 'auto',
                  '& .slick-list': {
                    height: isMobile ? '500px!important' : '340px!important',
                  },
                  '& .slick-dots': {
                    position: 'absolute',
                    bottom: '0px',
                  },
                },
              }}
            >
              <Slider {...settings}>
                <Stack
                  direction={isMobile ? 'column' : 'row-reverse'}
                  sx={{
                    display: 'flex!important',
                    '& .MuiResponsiveChart-container': {
                      '& svg': { width: '100%!important', height: '65%!important' },
                    },
                  }}
                >
                  <Statistics
                    title=""
                    data={[
                      { label: t('Periodic billing'), value: 3044 },
                      { label: t('Discount amount'), value: 2000 },
                      { label: t('God. keeping'), value: 43913 },
                      { label: t('Fee'), value: 50043 },
                    ]}
                  />
                  <Stack
                    width={isMobile ? 1 : 0.5}
                    padding={!isMobile ? '10px' : 0}
                    sx={{
                      '& .MuiTypography-root': { fontSize: isMobile ? '12px' : '' },
                      '& li': {
                        direction: 'ltr',
                        textAlign: 'justify',
                        fontSize: isMobile ? '12px' : '',
                      },
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, direction: 'ltr' }}>חיסכון במים</Typography>
                    <Typography sx={{ direction: 'ltr' }}>
                      אצריכת המים בנכסים המשמשים למגורים מתחלקת בצורה הבאה:
                    </Typography>
                    <li>40% – הדחת אסלות</li>
                    <li>10% – שתיה, בישול והדחת כלים</li>
                    <li>30% – רחצה</li>
                    <li>20% – כביסה וניקיון</li>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="flex-end"
                      mt={2}
                      sx={{
                        '&:hover': { color: '#000080' },
                        cursor: 'pointer',
                      }}
                    >
                      <Typography>?כיצד לחסוך במים</Typography>
                      <Iconify icon="ep:arrow-left-bold" />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction={isMobile ? 'column' : 'row-reverse'}
                  sx={{
                    height: 'auto',
                    display: 'flex!important',

                    '& .MuiPaper-root': { width: '  40%' },
                  }}
                >
                  <Statistics
                    title=""
                    data={[
                      { label: t('Periodic billing'), value: 3044 },
                      { label: t('Discount amount'), value: 2000 },
                      { label: t('God. keeping'), value: 43913 },
                      { label: t('Fee'), value: 50043 },
                    ]}
                  />
                  <Stack
                    width={isMobile ? 1 : 0.5}
                    padding={!isMobile ? '10px' : 0}
                    sx={{
                      '& .MuiTypography-root': { fontSize: isMobile ? '12px' : '' },
                      '& li': {
                        direction: 'ltr',
                        textAlign: 'justify',
                        fontSize: isMobile ? '12px' : '',
                      },
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, direction: 'ltr' }}>
                      מהי צריכת מים חריגה?
                    </Typography>
                    <Typography sx={{ direction: 'ltr' }}>
                      צריכת מים חריגה היא צריכה העולה על ממוצע הצריכה של אותו לקוח בתקופה נתונה,
                      לאורך זמן. בישראל, הצריכה הממוצעת לאדם הנה כ-80 מ”ק מים בשנה.עלייה בצריכת המים
                      יכולה להתרחש מכמה סיבות, נזילת מים ברשת הפרטית גורמת לאבדן מים ומתוך כך,
                      לצריכת מים מוגברת. לא תמיד ניתן להבחין בנזילה עם היווצרותה ולכן זיהוי מקדים,
                      יכול למנוע את אובדן המים ואת החיוב בגינו
                    </Typography>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      mt={2}
                      mb={2}
                      sx={{
                        '&:hover': { color: '#000080' },
                        cursor: 'pointer',
                      }}
                    >
                      <Typography>?כיצד נזהה צריכה חריגה הנובעת מנזילה</Typography>
                      <Iconify icon="ep:arrow-left-bold" />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction={isMobile ? 'column' : 'row-reverse'}
                  sx={{
                    display: 'flex!important',
                    '& .MuiResponsiveChart-container': { width: '40%' },
                  }}
                >
                  <Statistics
                    title=""
                    data={[
                      { label: t('Periodic billing'), value: 3044 },
                      { label: t('Discount amount'), value: 2000 },
                      { label: t('God. keeping'), value: 43913 },
                      { label: t('Fee'), value: 50043 },
                    ]}
                  />
                  <Stack
                    width={isMobile ? 1 : 0.5}
                    padding={!isMobile ? '10px' : 0}
                    sx={{
                      '& .MuiTypography-root': { fontSize: isMobile ? '12px' : '' },
                      '& li': {
                        direction: 'ltr',
                        textAlign: 'justify',
                        fontSize: isMobile ? '12px' : '',
                      },
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, direction: 'ltr' }}>
                      איך מחושב חשבון המים שלך?
                    </Typography>
                    <Typography sx={{ direction: 'ltr' }}>
                      קריאת מד המים מתבצעת אחת לחודשיים, צריכת המים נמדדת לפי ההפרש בין קריאת מד
                      המים בסוף תקופת החיוב הנוכחית, לבין קריאת מד המים בסוף תקופת החיוב הקודמת.
                      בנכס משותף, בו יש מספר צרכנים, חשבון המים מורכב משני חיובים עיקריים:
                    </Typography>
                    <Stack alignItems="flex-end">
                      <li>הצריכה הפרטיתבנכס אשר נמדדה במד המים המשויך לאותו נכס וצרכן</li>
                      <li>הפרשי המדידה (משותפת)המיוחסים לנכס</li>
                      <Typography sx={{ direction: 'ltr' }}>
                        החיוב הוא מכפלת כמות המים בצריכה הפרטית, כולל החלק של הפרשי המדידה.
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="flex-end"
                      mt={2}
                      sx={{
                        '&:hover': { color: '#000080' },
                        cursor: 'pointer',
                      }}
                    >
                      <Typography>תעריפי המים הרלוונטיים</Typography>
                      <Iconify icon="ep:arrow-left-bold" />
                    </Stack>
                  </Stack>
                </Stack>
              </Slider>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>תשלומי מים לנכס ביאליק 31</Typography>
            <Stack direction={isMobile ? 'column' : 'row'} width={1} justifyContent="space-between">
              <Tabs value={currentTab} onChange={handleChangeTab}>
                {TABS.filter((tab: AccountTab) => activeTabNames.includes(tab.value)).map(
                  (tab: AccountTab) => (
                    <Tab key={tab.value} label={`${t(`${tab.label}`)}`} value={tab.value} />
                  )
                )}
              </Tabs>
              <Stack>
                <TextField
                  label="חיפוש לפי מספר שובר"
                  InputProps={{
                    endAdornment: <Iconify icon="ic:twotone-search" />,
                  }}
                />
              </Stack>
            </Stack>
            {currentTab === 'notPaid' && notPaidTabItem && (
              <NotPaidTable block={block} dataNotPaid={data_not_paid_1} />
            )}

            {currentTab === 'paid' && paidTabItem && (
              <PaidTable block={block} dataPaid={data_paid_1} />
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ border: '1px solid grey', borderRadius: '5px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ margin: '10px 0px 0px 0px' }}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Iconify icon="akar-icons:water" color="#000080" width="50px" />
            <Stack gap={1}>
              <Typography sx={{ fontWeight: 600 }}>ביאליק 41 רמת גן</Typography>
              <Stack direction="row" gap={1}>
                <Typography>דירה 3</Typography>
                <li style={{ width: '5px' }} />
                <Typography>מספר נכס 5456322</Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <Label color="info">מגורים</Label>
                <Label color="info">הדירה בבעלותך</Label>
              </Stack>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}>פרטי חשבון</Typography>
          <Stack gap={2}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>שם המשלם</Typography>
                <Typography sx={{ fontWeight: 600 }}>מוטי אברמוב</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מספר משלם</Typography>
                <Typography sx={{ fontWeight: 600 }}>123456789</Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>מספר כרטיס מים</Typography>
                <Typography sx={{ fontWeight: 600, wordBreak: 'break-word' }}>
                  1234560004440
                </Typography>
              </Grid>
              <Grid item md={2.9} xs={5.9} px={2}>
                <Typography>איש קשר בנכס</Typography>
                <Typography sx={{ fontWeight: 600 }}>כתובת למשלוח</Typography>
                <Typography sx={{ fontWeight: 600 }}>הרצל 41 א’, רמת גן</Typography>
              </Grid>
            </Grid>
          </Stack>
          <Divider sx={{ my: 2, color: 'blue' }} />
          <Typography sx={{ fontWeight: 600, my: 2 }}>מספר נפשות בנכס</Typography>
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={2.8} xs={12}>
              <Stack>
                <Stack>
                  <Typography>מספר נפשות</Typography>
                  <Typography sx={{ fontWeight: 600 }}>7</Typography>
                </Stack>
                <Stack>
                  <Typography>כמות זכאים להטבה</Typography>
                  <Typography sx={{ fontWeight: 600 }}>3</Typography>
                </Stack>
                <Stack>
                  <Typography>תאריך עדכון מס’ נפשות</Typography>
                  <Typography sx={{ fontWeight: 600 }}>10.02.2021</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              md={0.2}
              xs={12}
              sx={{
                borderRight: '1px solid',
                borderColor: 'rgb(217, 217, 217)',
              }}
            />
            <Grid
              item
              md={8.8}
              xs={12}
              sx={{
                '& .slick-slider': {
                  height: 'auto',
                  '& .slick-list': {
                    height: isMobile ? '500px!important' : '340px!important',
                  },
                  '& .slick-dots': {
                    position: 'absolute',
                    bottom: '0px',
                  },
                },
              }}
            >
              <Slider {...settings}>
                <Stack
                  direction={isMobile ? 'column' : 'row-reverse'}
                  sx={{
                    display: 'flex!important',

                    '& .MuiResponsiveChart-container': { width: '40%' },
                  }}
                >
                  <Statistics
                    title=""
                    data={[
                      { label: t('Periodic billing'), value: 3044 },
                      { label: t('Discount amount'), value: 2000 },
                      { label: t('God. keeping'), value: 43913 },
                      { label: t('Fee'), value: 50043 },
                    ]}
                  />
                  <Stack
                    width={isMobile ? 1 : 0.5}
                    padding={!isMobile ? '10px' : 0}
                    sx={{
                      '& .MuiTypography-root': { fontSize: isMobile ? '12px' : '' },
                      '& li': {
                        direction: 'ltr',
                        textAlign: 'justify',
                        fontSize: isMobile ? '12px' : '',
                      },
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, direction: 'ltr' }}>חיסכון במים</Typography>
                    <Typography sx={{ direction: 'ltr' }}>
                      אצריכת המים בנכסים המשמשים למגורים מתחלקת בצורה הבאה:
                    </Typography>
                    <li>40% – הדחת אסלות</li>
                    <li>10% – שתיה, בישול והדחת כלים</li>
                    <li>30% – רחצה</li>
                    <li>20% – כביסה וניקיון</li>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="flex-end"
                      mt={2}
                      sx={{
                        '&:hover': { color: '#000080' },
                        cursor: 'pointer',
                      }}
                    >
                      <Typography>?כיצד לחסוך במים</Typography>
                      <Iconify icon="ep:arrow-left-bold" />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction={isMobile ? 'column' : 'row-reverse'}
                  sx={{
                    display: 'flex!important',
                  }}
                >
                  <Statistics
                    title=""
                    data={[
                      { label: t('Periodic billing'), value: 3044 },
                      { label: t('Discount amount'), value: 2000 },
                      { label: t('God. keeping'), value: 43913 },
                      { label: t('Fee'), value: 50043 },
                    ]}
                  />
                  <Stack
                    width={isMobile ? 1 : 0.5}
                    padding={!isMobile ? '10px' : 0}
                    sx={{
                      '& .MuiTypography-root': { fontSize: isMobile ? '12px' : '' },
                      '& li': {
                        direction: 'ltr',
                        textAlign: 'justify',
                        fontSize: isMobile ? '12px' : '',
                      },
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, direction: 'ltr' }}>
                      מהי צריכת מים חריגה?
                    </Typography>
                    <Typography sx={{ direction: 'ltr' }}>
                      צריכת מים חריגה היא צריכה העולה על ממוצע הצריכה של אותו לקוח בתקופה נתונה,
                      לאורך זמן. בישראל, הצריכה הממוצעת לאדם הנה כ-80 מ”ק מים בשנה.עלייה בצריכת המים
                      יכולה להתרחש מכמה סיבות, נזילת מים ברשת הפרטית גורמת לאבדן מים ומתוך כך,
                      לצריכת מים מוגברת. לא תמיד ניתן להבחין בנזילה עם היווצרותה ולכן זיהוי מקדים,
                      יכול למנוע את אובדן המים ואת החיוב בגינו
                    </Typography>
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      mt={2}
                      sx={{
                        '&:hover': { color: '#000080' },
                        cursor: 'pointer',
                      }}
                    >
                      <Typography>?כיצד נזהה צריכה חריגה הנובעת מנזילה</Typography>
                      <Iconify icon="ep:arrow-left-bold" />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction={isMobile ? 'column' : 'row-reverse'}
                  sx={{
                    display: 'flex!important',

                    '& .MuiResponsiveChart-container': { width: '40%' },
                  }}
                >
                  <Statistics
                    title=""
                    data={[
                      { label: t('Periodic billing'), value: 3044 },
                      { label: t('Discount amount'), value: 2000 },
                      { label: t('God. keeping'), value: 43913 },
                      { label: t('Fee'), value: 50043 },
                    ]}
                  />
                  <Stack
                    width={isMobile ? 1 : 0.5}
                    padding={!isMobile ? '10px' : 0}
                    sx={{
                      '& .MuiTypography-root': { fontSize: isMobile ? '12px' : '' },
                      '& li': {
                        direction: 'ltr',
                        textAlign: 'justify',
                        fontSize: isMobile ? '12px' : '',
                      },
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, direction: 'ltr' }}>
                      איך מחושב חשבון המים שלך?
                    </Typography>
                    <Typography sx={{ direction: 'ltr' }}>
                      קריאת מד המים מתבצעת אחת לחודשיים, צריכת המים נמדדת לפי ההפרש בין קריאת מד
                      המים בסוף תקופת החיוב הנוכחית, לבין קריאת מד המים בסוף תקופת החיוב הקודמת.
                      בנכס משותף, בו יש מספר צרכנים, חשבון המים מורכב משני חיובים עיקריים:
                    </Typography>
                    <Stack alignItems="flex-end">
                      <li>הצריכה הפרטיתבנכס אשר נמדדה במד המים המשויך לאותו נכס וצרכן</li>
                      <li>הפרשי המדידה (משותפת)המיוחסים לנכס</li>
                      <Typography sx={{ direction: 'ltr' }}>
                        החיוב הוא מכפלת כמות המים בצריכה הפרטית, כולל החלק של הפרשי המדידה.
                      </Typography>
                    </Stack>
                    <Typography />
                    <Stack
                      direction="row-reverse"
                      alignItems="center"
                      justifyContent="flex-end"
                      mt={2}
                      sx={{
                        '&:hover': { color: '#000080' },
                        cursor: 'pointer',
                      }}
                    >
                      <Typography>תעריפי המים הרלוונטיים</Typography>
                      <Iconify icon="ep:arrow-left-bold" />
                    </Stack>
                  </Stack>
                </Stack>
              </Slider>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, color: '#c3cfe7' }} />
          <Stack>
            <Typography sx={{ fontWeight: 600 }}>תשלומי מים לנכס הרצל 41 א’</Typography>
            <Stack direction={isMobile ? 'column' : 'row'} width={1} justifyContent="space-between">
              <Tabs value={currentTab} onChange={handleChangeTab}>
                {TABS.filter((tab: AccountTab) => activeTabNames.includes(tab.value)).map(
                  (tab: AccountTab) => (
                    <Tab key={tab.value} label={`${t(`${tab.label}`)}`} value={tab.value} />
                  )
                )}
              </Tabs>
              <Stack>
                <TextField
                  label="חיפוש לפי מספר שובר"
                  InputProps={{
                    endAdornment: <Iconify icon="ic:twotone-search" />,
                  }}
                />
              </Stack>
            </Stack>
            {currentTab === 'notPaid' && notPaidTabItem && (
              <NotPaidTable block={block} dataNotPaid={data_not_paid_1} />
            )}

            {currentTab === 'paid' && paidTabItem && (
              <PaidTable block={block} dataPaid={data_paid_1} />
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
