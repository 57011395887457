import { useState } from 'react';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { ITransaction } from '../../../../types/club-cards';
import Label from '../../../../components/label';
import { getTransactionTypeColor, getTransactionTypeIcon } from '../../../../utils/color-maps';
import { useTranslate } from '../../../../locales';

// import UserQuickEditForm from './user-quick-edit-form';

// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: ITransaction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function TransactionHistoryTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { t } = useTranslate();

  const [open, setOpen] = useState(false);

  const { amount, transactionType } = row;

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  return (
    <>
      <TableRow hover selected={selected} onClick={() => setOpen(!open)}>
        {/* <TableCell padding="checkbox"> */}
        {/*  <Checkbox checked={selected} onClick={onSelectRow} /> */}
        {/* </TableCell> */}

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{amount}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>-</TableCell>

        {/* <TableCell sx={{ whiteSpace: 'nowrap' }}> */}
        {/*  <ListItemText */}
        {/*    primary={format(new Date(date), 'dd.MM.yyyy')} */}
        {/*    secondary={format(new Date(date), 'HH:mm a')} */}
        {/*    primaryTypographyProps={{ typography: 'body2' }} */}
        {/*    secondaryTypographyProps={{ */}
        {/*      fontSize: 12, */}
        {/*      component: 'p', */}
        {/*      color: 'text.disabled', */}
        {/*    }} */}
        {/*  /> */}
        {/* </TableCell> */}

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label
            variant="soft"
            color={getTransactionTypeColor(transactionType)}
            endIcon={<Iconify width={16} icon={getTransactionTypeIcon(transactionType)} />}
          >
            {t(transactionType)}
          </Label>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
