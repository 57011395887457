'use client';

import React from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  img: any;
  messageBeforeBold: any;
  bold: any;
  messageAfterBold: any;
  date: any;
  btn: any;
  i: any;
};

const NotificationsSquare = ({
  img,
  messageBeforeBold,
  bold,
  messageAfterBold,
  date,
  btn,
  i,
}: Props) => {
  const handleClose = (e: any) => {
    const notification = e.target.closest('.NotificationsSquare');
    if (notification) notification.style.display = 'none';
  };

  return (
    <Box
      className="NotificationsSquare"
      sx={{
        display: 'flex',
        p: 2,
        mb: 2,
        borderRadius: 2,
        boxShadow: 1,
        alignItems: 'center',
        backgroundColor: 'background.paper',
      }}
    >
      <Box
        component="img"
        src={img}
        alt="notification-avatar"
        sx={{ width: 60, height: 60, borderRadius: '50%', mx: 2 }}
      />
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: i % 2 === 0 ? 'bold' : 'normal',
            color: 'text.primary',
          }}
        >
          {messageBeforeBold}
          <Box
            component="span"
            sx={{ fontWeight: 'bold', color: 'text.secondary' }}
          >
            {bold}
          </Box>
          {messageAfterBold}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {date}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              '&:hover': {
                bgcolor: 'primary.main',
              },
            }}
            // onClick={(e) => handleClose(e)}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationsSquare;
