'use client';

import { Box, Container, InputBase, Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import React, { useState } from 'react';
import Iconify from '../../../../../components/iconify';
import DetailSquare from './details-square';
import PaidServicesSquare from '../../../../../../public/assets/images/myProfile/paid-service-square';

type Props = {
  containerArr: {
    img?: string | any;
    icon?: string | any;
    info: string;
    descrip: string;
    link: string;
  }[];
};

const reportSquareArr = [
  {
    header: 'החלפת כבל',
    text: 'שדרות רגר 8, דירה 101',
    hour: '08:00',
    price: `45`,
  },
  {
    header: 'הוספת שקעים',
    text: 'שדרות רגר 8, דירה 151',
    hour: '08:30',
    price: `70`,
  },
  {
    header: 'החלפת כבל',
    text: 'שדרות רגר 8, דירה 101',
    hour: '11:00',
    price: `45`,
  },
  {
    header: 'הוספת שקעים',
    text: 'שדרות רגר 8, דירה 151',
    hour: '16:00',
    price: `70`,
  },
];

const DetailsList = ({ containerArr }: Props) => {
  const [localLink, setLink] = useState(false);

  return (
    <>
      {!localLink ? (
        <Container maxWidth="xs">
          <Box className="detailsList">
            {containerArr.map((el, i) => (
              <DetailSquare
                img={el.img}
                icon={el.icon}
                info={el.info}
                descrip={el.descrip}
                link={el.link}
                key={i}
                setLink={setLink}
              />
            ))}
          </Box>
        </Container>
      ) : (
        <Container maxWidth="xs">
          <Box className="PageDad">
            <Box className="page">
              <Stack alignItems="flex-end">
                <Iconify
                  icon="oui:arrow-left"
                  onClick={() => setLink(false)}
                  sx={{ cursor: 'pointer' }}
                />
              </Stack>
              <Box className="procedursPage reoprtPage">
                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  sx={{
                    width: '100%',
                    maxWidth: 600,
                    p: 1,
                    borderRadius: 2,
                    mb: 2,
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      flexGrow: 1,
                      p: 1,
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: 1,
                    }}
                  >
                    <SearchIcon sx={{ color: 'action.active', mr: 1 }} />
                    <InputBase
                      placeholder="חפש שם"
                      type="search"
                      fullWidth
                      sx={{ fontSize: '1rem', color: 'text.primary' }}
                    />
                  </Box>
                  <Box sx={{ bgcolor: 'primary.main', borderRadius: '10px' }}>
                    <StarIcon sx={{ fontSize: 45, color: 'white' }} />
                  </Box>
                </Box>
                <Box className="procedursSquaresDiv">
                  {reportSquareArr.map((el, i) => (
                    <PaidServicesSquare
                      key={i}
                      header={el.header}
                      text={el.text}
                      hour={el.hour}
                      price={el.price}
                      i={i}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            {/* <Footer /> */}
          </Box>
        </Container>
      )}
    </>
  );
};

export default DetailsList;
