import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { useTranslate } from '../../locales';

interface Props extends DialogProps {
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
  buttonConfirmProps?: ButtonProps;
  buttonCancelProps?: ButtonProps;
}

const ConfirmModal = ({
  open,
  onClose,
  title,
  buttonConfirmProps,
  onConfirm,
  buttonCancelProps,
  ...props
}: Props) => {
  const { t } = useTranslate();

  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogTitle>{title ?? t('Are you sure?')}</DialogTitle>
      <DialogActions>
        {/* TODO: разобраться с типами */}
        {/* @ts-ignore */}
        <Button variant="outlined" onClick={onClose} {...buttonCancelProps}>
          {t('Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm} {...buttonConfirmProps}>
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
