import { IIssuedCardStatus } from 'src/types/order';

export const getCardStatusColor = (status: IIssuedCardStatus) => {
  switch (status) {
    case IIssuedCardStatus.NOT_USED:
      return 'success';
    case IIssuedCardStatus.USED:
      return 'error';
    case IIssuedCardStatus.PARTIALLY_USED:
      return 'warning';
    default:
      return 'info';
  }
};

export const cardStatusOrder = [
  IIssuedCardStatus.NOT_USED,
  IIssuedCardStatus.PARTIALLY_USED,
  IIssuedCardStatus.USED,
  IIssuedCardStatus.EXPIRED,
  IIssuedCardStatus.CANCELLED,
];
