'use client';

import React from 'react';
import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

type Props = {
  writerName: string;
  date: string;
  rating: string;
  text: string;
  img: string | any;
};

const Review = ({ writerName, date, rating, text, img }: Props) => (
  <Box
    className="service_box rating_box review"
    display="flex"
    flexDirection={{ xs: 'row-reverse', md: 'row' }}
    alignItems="center"
    mb={2}
    mt={2}
    sx={{ backgroundColor: '#f5f5f5', padding: 2, borderRadius: 1 }}
  >
    <Box className="box_con review_box" flex={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >

        <Box>
          <Typography>{writerName}</Typography>
          <Typography
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            fontSize="12px"
            color="#8f9bb3"
          >
            {rating}
            <StarIcon
              sx={{ marginRight: 0.5, width: '15px', color: 'primary.main' }}
            />
          </Typography>
        </Box>
        <Typography sx={{ color: '#8f9bb3', fontSize: '10px' }}>
          {date}
        </Typography>
      </Box>
      <Typography sx={{ color: '#8f9bb3', fontSize: '12px', }}>
        {text}
      </Typography>
    </Box>
    <Box
      className="box_round_img"
      flexShrink={0}
      mt={{ xs: 1, md: 0 }}
      ml={{ md: 2 }}
    >
      <img
        src={img}
        alt="Online"
        style={{
          borderRadius: '50%',
          width: '50px',
          height: '50px',
        }}
      />
    </Box>
  </Box>
);

export default Review;
