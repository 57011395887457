'use client';

import { useForm } from 'react-hook-form';
import { useParams } from 'next/navigation';
import React, { useState, useCallback } from 'react';

import IconButton from '@mui/material/IconButton';
import { Box, Modal, Stack, Button, Rating, TextField, Typography } from '@mui/material';

import Iconify from '../iconify';
import { API } from '../../helpers/api';
import { useTranslate } from '../../locales';
import { useAuthContext } from '../../auth/hooks';
import FormProvider, { RHFUploadBox } from '../hook-form';
import { useAppContext } from '../../contexts/AppContext';
import { getTestimonialsByProductId, getTestimonialsByMerchantId } from '../../api/testimonials';

type Props = {
  openTestimonialModal: boolean;
  setOpenTestimonialModal: (item: boolean) => void;
  productId: string;
  page?: boolean;
  pageName?: string;
}

const TestimonialModal = ({ openTestimonialModal, setOpenTestimonialModal, productId, page, pageName }: Props) => {
  const [rating, setRating] = useState<number | null>(0);
  const params = useParams();
  const [reviewText, setReviewText] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [like, setLike] = useState(false);
  const [disLike, setDisLike] = useState(false);
  const { dispatch } = useAppContext();
  const { user } = useAuthContext();
  const { t } = useTranslate();

  let impression: any;
  if (like && !disLike) impression = true;
  if (!like && disLike) impression = false;
  if (!like && !disLike) impression = null;

  const methods = useForm();

  const handleClose = () => {
    setOpenTestimonialModal(false);
  };

  const handleDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setImageFile(file);
      const formData = new FormData();
      formData.append('file', file);

      try {
        const result = await API({
          url: '/upload',
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const uploadedImageUrl = result?.data?.fileName;
        setImageUrl(uploadedImageUrl);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }, []);

  const handleLike = () => {
    setLike(true);
    setDisLike(false);
  };

  const handleDisLike = () => {
    setLike(false);
    setDisLike(true);
  };

  const handleSubmit = async () => {
    try {
      const requestData: any = {
        description: reviewText,
        rating,
        ...(params.title === 'product' && { productId }),
        ...(params.title === 'merchant' && { merchantId: +params.id }),
        ...(page && { pageId: productId, pageName }),
        extra: {},
      };

      if (imageFile) {
        requestData.extra.photo = imageUrl || '';
      }

      if (user) {
        requestData.extra.user = user;
        requestData.customerId = user.id;
      }

      requestData.extra.impression = impression;

      const { data } = await API({
        url: `testimonial`,
        data: requestData,
        method: 'POST',
      });

      if (data.success) {
        if (params.title === 'product') {
          await getTestimonialsByProductId(params.id, dispatch);
        } else {
          await getTestimonialsByMerchantId(params.id, dispatch);
        }
        handleClose();
      }
    } catch (e) {
      console.error(e);
      handleClose();
    }
  };

  return (
    <Modal open={openTestimonialModal} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 700,
          bgcolor: 'background.paper',
          borderRadius: '20px',
          boxShadow: 24,
          p: 4,
          '@media (max-width: 600px)': {
            top: '40%',
            p: 2,
          },
        }}
      >
        <Stack direction="row" justifyContent="flex-end">
          <Iconify
            icon="iconamoon:close-thin"
            onClick={handleClose}
            sx={{ cursor: 'pointer' }}
          />
        </Stack>
        <Stack spacing={3} alignItems="center">
          <Typography variant="h6">השאר את הביקורת שלך</Typography>
          {!user && (
            <>
              {imageFile ? (
                <Box sx={{ position: 'relative' }}>
                  <IconButton onClick={() => setImageFile(null)} sx={{ position: 'absolute' }}>
                    <Iconify icon="mingcute:close-fill" />
                  </IconButton>
                  <Box
                    component="img"
                    src={URL.createObjectURL(imageFile)} // Предварительный просмотр изображения
                    alt="Preview"
                    sx={{ width: 150, height: 'auto', borderRadius: 2 }}
                  />
                </Box>
              ) : (
                <Stack alignItems="center">
                  <FormProvider methods={methods}>
                    <RHFUploadBox
                      name="photoURL"
                      maxSize={3145728}
                      onDrop={handleDrop}
                    />
                  </FormProvider>
                  <Typography>{t('בחר תמונה')}</Typography>
                </Stack>
              )}
            </>
          )}
          <Rating
            value={rating}
            onChange={(event, newValue) => setRating(newValue)}
          />
          <Stack
            direction="row"
            spacing={2}
            sx={{ pt: 1.5 }}
            alignItems="center"
          >
            <IconButton
              onClick={handleLike}
              sx={{
                color: like ? 'green' : 'inherit',
              }}
            >
              <Iconify
                icon="solar:like-outline"
                width={16}
                sx={{ mr: 0.5 }}
              />
            </IconButton>
            <IconButton
              onClick={handleDisLike}
              sx={{
                color: disLike ? 'error.main' : 'inherit',
              }}
            >
              <Iconify
                icon="solar:dislike-outline"
                width={16}
                sx={{ mr: 0.5 }}
              />
            </IconButton>
          </Stack>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="כתוב את הביקורת שלך"
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!reviewText || rating === 0}
          >
            שלח משוב
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default TestimonialModal;
