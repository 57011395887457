// /* eslint-disable import/no-extraneous-dependencies */@types/sanitize-html
/* eslint-disable react/no-danger */
import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedAttributes: {
    a: ['href'],
    img: ['src', 'alt', 'title', 'srcset'],
    p: ['style'],
    span: ['style'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
};

const sanitize = (dirty: string, options?: Record<string, any>) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
});

const SanitizeHTML = ({ html, options }: { html: string; options?: Record<string, any> }) => (
  <span dangerouslySetInnerHTML={sanitize(html, options)} />
);

export default SanitizeHTML;
