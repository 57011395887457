// eslint-disable-next-line import/no-cycle

import { FieldTypes, SettingsField } from '../../../../../../types/page-generator';

const contentFields: SettingsField[] = [
  {
    name: 'modalId',
    type: FieldTypes.input,
    defaultValue: 'register',
    label: 'Modal Identifier (required)',
    rowSize: 2,
  },
  {
    name: 'mainImage',
    type: FieldTypes.upload,
    uploadType: 'image',
    rowSize: 4,
    label: 'Main image',
    defaultValue: '',
  },
  {
    name: 'presentText',
    type: FieldTypes.input,
    rowSize: 4,
    label: 'Title',
    defaultValue: 'Nice to meet you',
  },
  {
    name: 'presentSubText',
    type: FieldTypes.input,
    rowSize: 4,
    label: 'Subtitle',
    defaultValue: 'To register, enter email, password and password verification',
  },
];

export default contentFields;
