'use client';

import { FC, useState, useEffect } from 'react';

import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';

// GLOBAL CUSTOM COMPONENTS
import { Carousel } from 'src/extra-components/bazaar/carousel';
import BazaarImage from 'src/extra-components/bazaar/BazaarImage';
// CUSTOM DATA MODEL
import Brand from 'src/extra-components/bazaar/models/Brand.model';
import { FlexRowCenter } from 'src/extra-components/bazaar/flex-box';

import { BlockType } from '../../../../../types/generator';
import { b2bBrandList } from '../../../../../_mock/_barnds';
import { useResponsive } from '../../../../../hooks/use-responsive';

// ======================================================================
type Props = { brands: Brand[]; divider: boolean; direction: string; block?: BlockType };
// ======================================================================

const BrandListCarouselArea: FC<Props> = ({ brands, divider, direction, block }) => {
  const smDown = useResponsive('down', 'sm');

  const responsive = [
    { breakpoint: 1024, settings: { slidesToShow: 3 } },
    { breakpoint: 650, settings: { slidesToShow: 2 } },
    { breakpoint: 426, settings: { slidesToShow: 4 } },
  ];
  const [localBrands, setLocalBrands] = useState(b2bBrandList);
  useEffect(() => {
    if (brands.length) setLocalBrands(brands);
  }, [brands]);
  const slideToShow = 9;
  // if (brands.length > 9) slideToShow = brands.length / 2;
  return (
    <Container
      sx={{
        mt: !smDown ? 0 : '',
        '& .slick-track': { display: 'flex', alignItems: 'center' },
        '& .slick-slide': { width: smDown ? '' : 'auto!important' },
      }}
    >
      {divider && (
        <Divider
          sx={{
            mb: 4,
            borderColor: 'grey.400',
          }}
        />
      )}

      <Carousel
        autoplay
        autoplaySpeed={block && block.settings ? (block.settings.playSpeed || 3) * 1000 : 3 * 1000}
        arrows={false}
        slidesToShow={!smDown ? slideToShow : 3}
        // responsive={responsive}
        direction={direction}
      >
        {localBrands.map((item, id) => (
          <FlexRowCenter maxWidth={smDown ? {} : 110} height="100%" margin="auto" key={id}>
            <BazaarImage
              alt="brand"
              width="100%"
              src={item.image}
              sx={block?.settings?.blackout ? { filter: 'grayscale(1)' } : {}}
            />
          </FlexRowCenter>
        ))}
      </Carousel>

      {divider && <Divider sx={{ mt: 4, borderColor: 'grey.400' }} />}
    </Container>
  );
};

export default BrandListCarouselArea;
