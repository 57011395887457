export const fontSizes = [
  { label: '12', value: '12px' },
  { label: '13', value: '13px' },
  { label: '14', value: '14px' },
  { label: '15', value: '15px' },
  { label: '16', value: '16px' },
  { label: '18', value: '18px' },
  { label: '21', value: '21px' },
  { label: '24', value: '24px' },
  { label: '27', value: '27px' },
  { label: '32', value: '32px' },
  { label: '35', value: '35px' },
  { label: '42', value: '42px' },
  { label: '48', value: '48px' },
];

export const borderSizes = [
  { label: '1', value: '1px' },
  { label: '2', value: '2px' },
  { label: '3', value: '3px' },
  { label: '4', value: '4px' },
  { label: '5', value: '5px' },
  { label: '6', value: '6px' },
  { label: '7', value: '7px' },
  { label: '8', value: '8px' },
  { label: '9', value: '9px' },
  { label: '10', value: '10px' },
  { label: '11', value: '11px' },
  { label: '12', value: '12px' },
  { label: '13', value: '13px' },
  { label: '14', value: '14px' },
  { label: '15', value: '15px' },
  { label: '16', value: '16px' },
  { label: '17', value: '17px' },
  { label: '18', value: '18px' },
  { label: '19', value: '19px' },
  { label: '20', value: '20px' },
];

export const fontStyles = [
  { label: 'Normal', value: 'normal' },
  { label: 'Inherit', value: 'inherit' },
  { label: 'Italic', value: 'italic' },
  { label: 'Oblique', value: 'oblique' },
];

export const fontWeights = [
  { label: 'Light', value: 300 },
  { label: 'Normal', value: 400 },
  { label: 'Medium', value: 500 },
  { label: 'Semi-Bold', value: 600 },
  { label: 'Bold', value: 700 },
  { label: 'Semi-Black', value: 800 },
  { label: 'Black', value: 800 },
];

export const textDecorations = [
  { label: 'None', value: 'none' },
  { label: 'Underline', value: 'underline' },
  { label: 'Underline Dotted', value: 'underline dotted' },
  { label: 'Underline Wavy', value: 'underline wavy' },
  { label: 'Line Through', value: 'line-through' },
];

export const textDecorationsAlign = [
  { label: 'None', value: 'none' },
  { label: 'End', value: 'end' },
  { label: 'Start', value: 'start' },
  { label: 'Center', value: 'center' },
];

export const borderDecorations = [
  { label: 'Solid', value: 'solid' },
  { label: 'Dashed', value: 'dashed' },
];

export const percentOptions = [
  { label: '10%', value: '10%' },
  { label: '20%', value: '20%' },
  { label: '30%', value: '30%' },
  { label: '40%', value: '40%' },
  { label: '50%', value: '50%' },
  { label: '60%', value: '60%' },
  { label: '70%', value: '70%' },
  { label: '80%', value: '80%' },
  { label: '90%', value: '90%' },
  { label: '100%', value: '100%' },
];
