// @types
import { textColorOptions } from './common';
import { FieldTypes, SettingsField } from '../../../types/page-generator';
import {
  fontSizes,
  fontStyles,
  borderSizes,
  fontWeights,
  textDecorations,
  borderDecorations,
} from './fonts-presets';

const header: SettingsField[] = [
  {
    name: 'mainSubtitle',
    type: FieldTypes.subheader,
    label: 'Main',
    rowSize: 4,
    defaultValue: null,
  },
  {
    type: FieldTypes.group,
    label: 'Buttons',
    name: 'buttonsGroup',
    rowSize: 4,
    defaultValue: '',
    fields: [
      {
        name: 'showWallet',
        type: FieldTypes.checkbox,
        defaultValue: true,
        label: 'Show Wallet',
        rowSize: 2,
      },
      {
        name: 'showLogin',
        type: FieldTypes.checkbox,
        defaultValue: true,
        label: 'Show Login',
        rowSize: 2,
      },
      {
        name: 'showSearh',
        type: FieldTypes.checkbox,
        defaultValue: true,
        label: 'Show Search',
        rowSize: 2,
      },
      {
        name: 'showCart',
        type: FieldTypes.checkbox,
        defaultValue: true,
        label: 'Show Cart',
        rowSize: 2,
      },
      {
        name: 'showNotifications',
        type: FieldTypes.checkbox,
        defaultValue: true,
        label: 'Show Notifications',
        rowSize: 2,
      },
      {
        name: 'showLinks',
        type: FieldTypes.checkbox,
        defaultValue: true,
        label: 'Show Head Menu',
        rowSize: 2,
      },
      {
        name: 'showBurgerMenu',
        type: FieldTypes.checkbox,
        defaultValue: true,
        label: 'Show Burger Menu',
        rowSize: 2,
      },
    ],
  },
  {
    name: 'logo',
    type: FieldTypes.upload,
    defaultValue: null,
    multiple: true,
    uploadType: 'image',
    label: 'Logo',
    rowSize: 4,
  },
  {
    name: 'burgerMenuChildrenStyles',
    rowSize: 2,
    type: FieldTypes.group,
    label: 'Burger Menu Style Children Items',
    fields: [
      {
        name: 'contentTextColorChildren',
        label: 'Content Text color',
        type: FieldTypes.select,
        defaultValue: 'inherit',
        rowSize: 4,
        options: textColorOptions,
        isColor: true,
      },
      {
        name: 'contentFontSizeChildren',
        label: 'Content Font Size',
        type: FieldTypes.select,
        defaultValue: '12px',
        rowSize: 4,
        options: fontSizes,
      },
      {
        name: 'contentFontWeightChildren',
        label: 'Content Font Weight',
        type: FieldTypes.select,
        defaultValue: 400,
        rowSize: 4,
        options: fontWeights,
      },
      {
        name: 'contentFontStyleChildren',
        label: 'Content Font Style',
        type: FieldTypes.select,
        defaultValue: 'normal',
        rowSize: 4,
        options: fontStyles,
      },
      {
        name: 'contentTextDecorationChildren',
        label: 'Content Text Decoration',
        type: FieldTypes.select,
        defaultValue: 'none',
        rowSize: 4,
        options: textDecorations,
      },
    ],
  },
  {
    name: 'burgerMenuParentStyles',
    rowSize: 2,
    type: FieldTypes.group,
    label: 'Burger Menu Style Parent Items',
    fields: [
      {
        name: 'contentTextColorParent',
        label: 'Content Text color',
        type: FieldTypes.select,
        defaultValue: 'inherit',
        rowSize: 4,
        options: textColorOptions,
        isColor: true,
      },
      {
        name: 'contentFontSizeParent',
        label: 'Content Font Size',
        type: FieldTypes.select,
        defaultValue: '12px',
        rowSize: 4,
        options: fontSizes,
      },
      {
        name: 'contentFontWeightParent',
        label: 'Content Font Weight',
        type: FieldTypes.select,
        defaultValue: 400,
        rowSize: 4,
        options: fontWeights,
      },
      {
        name: 'contentFontStyleParent',
        label: 'Content Font Style',
        type: FieldTypes.select,
        defaultValue: 'normal',
        rowSize: 4,
        options: fontStyles,
      },
      {
        name: 'contentTextDecorationParent',
        label: 'Content Text Decoration',
        type: FieldTypes.select,
        defaultValue: 'none',
        rowSize: 4,
        options: textDecorations,
      },
    ],
  },
  {
    name: 'borderSettings',
    label: 'Border Settings',
    type: FieldTypes.group,
    rowSize: 4,
    fields: [
      {
        name: 'borderWidth',
        label: 'Border Font Size',
        type: FieldTypes.select,
        defaultValue: '1px',
        rowSize: 4,
        options: borderSizes,
      },
      {
        name: 'borderColor',
        label: 'Border color',
        type: FieldTypes.select,
        defaultValue: 'black',
        rowSize: 4,
        options: textColorOptions,
        isColor: true,
      },
      {
        name: 'borderDecoration',
        label: 'Border Decoration',
        type: FieldTypes.select,
        defaultValue: 'solid',
        rowSize: 4,
        options: borderDecorations,
      },
      {
        name: 'forceRtlChildren',
        label: 'Force RTL Children Items',
        type: FieldTypes.checkbox,
        defaultValue: false,
        rowSize: 2,
      },
    ],
  },
];

export default header;
