'use client';

import React, { FC } from 'react';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Container, Link, Box, Typography } from '@mui/material';
import SpecialistsListSqaure from './specialists-list-sqaure';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const SpecialistsList: FC<ComponentTypeProps> = ({ block }) => (
  <Container maxWidth="xs">
    <Box className="specialistsListSec" sx={{ padding: 2 }}>
      <Box className="specialistsArea">
        <Box
          className="row specialistsBoxArea"
          sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}
        >
          <SpecialistsListSqaure />
        </Box>
      </Box>
    </Box>
  </Container>
);

export default SpecialistsList;
