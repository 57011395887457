import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';

import { useRouter } from 'src/routes/hooks';

import { useTranslate } from 'src/locales';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';

import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';

import { SearchType, SearchResult } from 'src/types/shop';

import { ActionTypes, useAppContext } from '../../contexts/AppContext';

// ----------------------------------------------------------------------

type Props = {
  query: string;
  results: SearchResult[];
  onSearch: (inputValue: string) => void;
  hrefItem: (id: string, entityType: SearchType) => string;
  loading?: boolean;
  searchQuery?: string;
};

export default function HeaderSearch({
  query,
  results,
  onSearch,
  hrefItem,
  loading,
  searchQuery,
}: Props) {
  const { t } = useTranslate();
  const router = useRouter();
  const { dispatch, state } = useAppContext();
  const handleClick = (id: string, entityType: SearchType) => {
    router.push(hrefItem(id, entityType));
  };

  const goToSearchPage = () => {
    dispatch({ type: ActionTypes.LOADING_SEARCH_PRODUCTS, payload: true });
    router.push('/search');
    const selectItem = results.filter((item) => item.title === searchQuery);
    const payload = {
      type: selectItem && selectItem[0] && selectItem[0].entityType,
      searchQuery,
    };
    dispatch({ type: ActionTypes.SET_FILTER_QUERY, payload });
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (query && searchQuery) {
      if (event.key === 'Enter') {
        goToSearchPage();
      }
    }
  };
  return (
    <Autocomplete
      sx={{ width: { xs: 1, sm: 260 } }}
      loading={loading}
      autoHighlight
      popupIcon={null}
      groupBy={(option) => t(option.entityType)}
      options={results}
      onInputChange={(event, newValue) => onSearch(newValue)}
      getOptionLabel={(option) => option.title}
      noOptionsText={<SearchNotFound query={query} sx={{ bgcolor: 'unset' }} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      slotProps={{
        popper: {
          placement: 'bottom-start',
          sx: {
            minWidth: 320,
          },
        },
        paper: {
          sx: {
            [` .${autocompleteClasses.option}`]: {
              pl: 0.75,
            },
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={`${t('Search')}...`}
          onKeyUp={handleKeyUp}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ ml: 1, color: 'text.disabled', cursor: 'pointer' }}
                  onClick={goToSearchPage}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? <Iconify icon="svg-spinners:8-dots-rotate" sx={{ mr: -3 }} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, item, { inputValue }) => {
        const matches = match(item.title, inputValue);
        const parts = parse(item.title, matches);

        const coverImg = item.mainImageURL
          ? `${BASIC_URL_FOR_IMAGES}${item.mainImageURL}`
          : ('merchants' in item &&
              item?.merchants?.length &&
              `${BASIC_URL_FOR_IMAGES}${item.merchants[0].mainImageURL}`) ||
            '/assets/images/product-default.png';

        return (
          <Box
            component="li"
            {...props}
            onClick={() => handleClick(item.id, item.entityType)}
            key={item.id}
          >
            <Avatar
              key={item.id}
              alt={item.title}
              src={coverImg}
              variant="rounded"
              sx={{
                width: 48,
                height: 48,
                flexShrink: 0,
                mr: 1.5,
                borderRadius: 1,
              }}
            />

            <div key={inputValue}>
              {parts.map((part, index) => (
                <Typography
                  key={index}
                  component="span"
                  color={part.highlight ? 'primary' : 'textPrimary'}
                  sx={{
                    typography: 'body2',
                    fontWeight: part.highlight ? 'fontWeightSemiBold' : 'fontWeightMedium',
                  }}
                >
                  {part.text}
                </Typography>
              ))}
            </div>
          </Box>
        );
      }}
    />
  );
}
