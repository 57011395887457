'use client';

import React, { FC } from 'react';
import Slider from 'react-slick';

import Box from '@mui/material/Box';

import ShopBanner55 from './shop-banner-55';
import StandardBanner from './standard-banner';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import ThreeColumnsIconInfo from './three-column-info';
import Iconify from '../../../../components/iconify';
import { useLocales } from '../../../../locales';

const AllShopBanners22: FC<ComponentTypeProps> = ({ block }) => {
  const combinedBlock: any = block?.combinedBlock;
  const isMobile = useResponsive('down', 'sm');
  const { currentLang } = useLocales();
  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        right: isMobile ? '-20px' : '-20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: block?.settings.colorButtons || 'primary.main',
      }}
    >
      {currentLang.value === 'he' ? (
        <Iconify icon="ep:arrow-left-bold" color="#EB157B" />
      ) : (
        <Iconify icon="ep:arrow-right-bold" color="#EB157B" />
      )}
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: isMobile ? '-20px' : '-20px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
        color: block?.settings.colorButtons || 'primary.main',
      }}
    >
      {currentLang.value === 'he' ? (
        <Iconify icon="ep:arrow-right-bold" color="#EB157B" />
      ) : (
        <Iconify icon="ep:arrow-left-bold" color="#EB157B" />
      )}
    </Box>
  );

  const settings = {
    dots: true,
    arrows: !isMobile,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomPrevArrow onClick={() => {}} />,
    prevArrow: <CustomNextArrow onClick={() => {}} />,
  };

  let localHeight = block?.settings?.sliderHeight
    ? `${Number(block.settings.sliderHeight) + 30}px`
    : '400px';

  if (isMobile) localHeight = 'auto';

  return (
    <>
      <>
        {combinedBlock.length > 1 && (
          <Box
            sx={{
              '& .slick-slide': {
                // Добавляем отступы между слайдами
                direction: 'rtl',
                mb: '25px',
                display: 'grid!important',
                alignItems: 'end',
              },
              '& .slick-slider': {
                '& .slick-dots': {
                  position: 'absolute',
                  bottom: '35px',
                  '& .slick-active': {
                    '& button': {
                      '&::before': {
                        color: block?.settings?.dotsColor,
                        fontSize: '10px',
                      },
                    },
                  },
                  '& li': {
                    '& button': {
                      width: '20%',
                      '&::before': {
                        color: block?.settings?.dotsColor,
                        fontSize: '10px',
                      },
                    },
                  },
                },
              },
            }}
          >
            <Slider {...settings}>
              {combinedBlock.map((banner: any, id: number) => (
                <Box
                  key={id}
                  sx={{
                    height: localHeight,
                    maxHeight: block?.settings?.sliderHeight,
                  }}
                >
                  {banner.bannerType === 'standard' && <StandardBanner banner={banner} />}
                  {banner.bannerType === 'threeImage' && <ShopBanner55 block={banner} />}
                  {banner.bannerType === 'threeColumn' && <ThreeColumnsIconInfo block={banner} />}
                </Box>
              ))}
            </Slider>
          </Box>
        )}
      </>
      <>
        {combinedBlock.length === 1 && (
          <Box>
            {combinedBlock.map((banner: any, id: number) => (
              <Box key={id}>
                {banner.bannerType === 'standard' && <StandardBanner banner={banner} />}
                {banner.bannerType === 'threeImage' && <ShopBanner55 block={banner} />}
                {banner.bannerType === 'threeColumn' && <ThreeColumnsIconInfo block={banner} />}
              </Box>
            ))}
          </Box>
        )}
      </>
    </>
  );
};

export default AllShopBanners22;
