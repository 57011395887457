export const COMPANIES = [
  { id: 1, image: '/assets/images/city-people/kitchenCompany_1.png' },
  { id: 2, image: '/assets/images/city-people/kitchenCompany_2.png' },
  { id: 3, image: '/assets/images/city-people/kitchenCompany_3.png' },
  { id: 4, image: '/assets/images/city-people/kitchenCompany_4.png' },
  { id: 5, image: '/assets/images/city-people/kitchenCompany_5.png' },
  { id: 6, image: '/assets/images/city-people/kitchenCompany_6.png' },
];

export const DEFAULT_MERCHANT_IMAGE = '/assets/images/city-people/default_merchant.png';
