import { API } from '../helpers/api';
import { ActionType, ActionTypes } from '../contexts/AppContext';

export const getCustomerByPhoneOrMail = async (
  userPhone: string,
  userEmail: string,
  dispatch: React.Dispatch<ActionType>
) => {
  try {
    const params =
      userPhone === '' && userEmail !== '' ? { email: userEmail } : { phone: userPhone };
    const { data } = await API({
      url: `customer/findCustomers`,
      method: 'GET',
      params,
    });
    return data;
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
    return null;
  }
};

export const getAllCustomers = async () => {
  try {
    const { data } = await API({
      url: `/admin/customer/getAll`,
      method: 'GET',
    });
    console.log('setAllCustomersdata', data);
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
