'use client';

import React, { FC, useState } from 'react';
import { Box, Typography, Tab, Tabs, Container, Button } from '@mui/material';
import ProductsSquareList from './all-services';
import BestDealsSquareList from './best-deals-squre-list';
// eslint-disable-next-line import/no-cycle
import { ComponentTypeProps } from '../../../../../types/page-generator';


const MarketPlace: FC<ComponentTypeProps> = ({ block }) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabsBtnsArr = [
    { text: 'הכל' },
    { text: 'נקיון' },
    { text: 'חשמל' },
    { text: 'שיפוצים' },
    { text: 'אינסטלציה' },
  ];

  const handleTabChange = (newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="xs">
      <Box className="page">
        {/* Tabs for categories */}
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto', // добавляем горизонтальную прокрутку
            whiteSpace: 'nowrap', // не переносить кнопки на следующую строку
            '&::-webkit-scrollbar': { display: 'none' }, // скрываем скроллбар для вебкита (Safari, Chrome)
            '-ms-overflow-style': 'none', // скрываем скроллбар для IE и Edge
            'scrollbar-width': 'none', // скрываем скроллбар для Firefox
          }}
        >
          {tabsBtnsArr.map((el, i) => (
            <Button
              key={i}
              onClick={() => handleTabChange(i)}
              variant={activeTab === i ? 'contained' : 'text'}
              sx={{
                borderRadius: '5px',
                fontWeight: activeTab === i ? 'bold' : 'normal',
                backgroundColor: activeTab === i ? 'primary.main' : 'transparent',
                color: activeTab === i ? 'white' : 'primary.main',
                marginRight: 1,
                '&:hover': {
                  backgroundColor: activeTab === i ? 'primary.main' : 'transparent',
                },
              }}
            >
              {el.text}
            </Button>
          ))}
        </Box>


        {/* Display content based on selected tab */}
        <Box id="tab1" className="tab-content">
          <ProductsSquareList />
        </Box>

        {/* Popular services section */}
        <Typography sx={{ marginBottom: 2 }}>שירותים פופלרים</Typography>
        <Box id="tabs-content">
          <Box id="tab1" className="tab-content" >
            <BestDealsSquareList />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MarketPlace;
