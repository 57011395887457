'use client';

import { useMemo, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Ib2bProduct } from '../../types/b2b';
import { useRouter } from '../../routes/hooks';
import Iconify from '../../components/iconify';
import { BlockType } from '../../types/page-generator';
import { useResponsive } from '../../hooks/use-responsive';
import { extractPrices } from '../../helpers/extractPrices';
import { getContentValueFromProps } from '../generate-page/utils';
import { uploadProductsImage } from '../../helpers/uploadProductsImage';
import Image from '../../components/image';
import { useLocales } from '../../locales';

type Props = {
  block: BlockType | undefined;
  product: Ib2bProduct;
};

export const AlternativeProduct = ({ block, product }: Props) => {
  const { currentLang } = useLocales();
  // const { product: product } = useGetProduct(product?.id);
  const getFieldValue = getContentValueFromProps(block);
  const [matchingTags, setMatchingTags] = useState<any>([]);

  const isMobile = useResponsive('down', 'sm');
  const isLaptop = useResponsive('down', 'xs');
  const router = useRouter();

  const imageLink =
    (product?.mainImageURL
      ? `${uploadProductsImage(`${product?.mainImageURL}`)}`
      : product?.merchants &&
      product?.merchants.length &&
      `${uploadProductsImage(`${product?.merchants[0]?.mainImageURL}`)}`) || '';
  const { price, basePrice, businessPrice } = extractPrices(product?.prices);
  const length = block?.settings?.partialSize ? 10 : 20;
  const pointsLength = block?.settings?.partialSize
    ? product?.title.length > 10
    : product?.title.length > 20;
  const sliceLength = isMobile ? 10 : 45;
  const title = !block?.settings?.partialSize
    ? product?.title.slice(0, sliceLength)
    : product?.title.slice(0, length);
  const points = pointsLength ? '...' : '';

  const tagsImages = useMemo(
    () => getFieldValue('tagsImages'),
    [block?.tagsImages],
  );

  const productsTags = useMemo(() => {
    const tagsValue = getFieldValue('productsTags');
    if (Array.isArray(tagsValue)) {
      return tagsValue.map((tag: any) => ({
        ...tag,
        image: tagsImages[tag.value] ?? tag?.image,
      }));
    }
    return [];
  }, [block, tagsImages]);

  useEffect(() => {
    if (!product.tags || !block?.productsTags) return;
    if (block?.settings?.enableSmallInfo) {
      const tags = productsTags.filter((tag: any) =>
        product.tags.some((productsTag: any) => productsTag.name === tag.title),
      );
      setMatchingTags(tags);
    }
  }, [product.tags, block?.productsTags, block?.settings?.enableSmallInfo]);
  return (
    <Grid
      item
      xs={block?.settings?.partialSize && !isMobile ? 2 : 4}
      md={block?.settings?.partialSize && !isMobile ? 2 : 4}
      display="flex"
      width={1}
      alignItems="center"
      sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    >
      <Stack width={1} alignItems="center" height={!isMobile ? '100%' : '98px'} sx={{ position: 'relative' }}>
        <Box
          onClick={() => {
            router.push(`/product/${product.id}`);
          }}
          sx={{
            backgroundSize: 'cover',
            backgroundImage: `url(${imageLink})`,
            backgroundRepeat: 'no-repeat',
            height: {
              xs: '100%',
              md: block?.settings?.partialSize && !isMobile ? '100px' : '180px',
            },
            borderRadius: '10px',
            transform: block?.settings?.enableRotation && 'rotate(-20deg)',
            mt: block?.settings?.enableRotation && '50px',
            width: block?.settings?.enableRotation ? '85%' : '100%',
            mx: block?.settings?.enableRotation && 'auto',
            cursor: 'pointer',
          }}
        />
        {block?.settings?.enableSmallInfo && (
          <Stack   sx={{
            position: 'absolute',
            left: currentLang.value === 'he' ? '-20px' : 'auto',
            right: currentLang.value !== 'he' ? '-30px' : 'auto',
            top: '-10px',
            p: 1,
          }}>
            {matchingTags.map((tag: any, index: number) => (
              <Stack key={index} direction={currentLang.value === 'he' ? 'row-reverse' : 'row'} alignItems="center">
                {(block?.settings?.smallInfoViewSettings === 'all' ||
                  block?.settings?.smallInfoViewSettings === 'icon') && (
                  <Image
                    src={tag.image}
                    sx={{ width: '25px', height: '25px', mr: 1 }}
                  />
                )}
                {(block?.settings?.smallInfoViewSettings === 'all' ||
                  block?.settings?.smallInfoViewSettings === 'text') && (
                  <Typography
                    sx={{
                      color: block?.settings?.smallInfoTextColor,
                      fontSize: block?.settings?.smallInfoFontSize,
                      fontWeight: block?.settings?.smallInfoFontWeight,
                      fontStyle: block?.settings?.smallInfoFontStyle,
                      textDecoration: block?.settings?.smallInfoTextDecoration,
                    }}
                  >
                    {tag.title}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
      {isMobile ? (
        <>
          {!block?.settings?.disableName && (
            <Typography
              sx={{
                width: '100%',
                wordBreak: 'break-word',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '26px',
                direction: 'ltr',
              }}
            >
              {`${product?.title}`}
            </Typography>
          )}
          {block?.settings?.enablePrice &&
            <Typography sx={{ width: '100%', direction: 'ltr' }}>שובר ₪{price}</Typography>}
        </>
      ) : (
        <>
          <Tooltip title={product?.title}>
            {!block?.settings?.disableName ? (
              <Typography
                sx={{
                  width: '100%',
                  wordBreak: 'break-word',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '26px',
                  cursor: 'pointer',
                  direction: 'ltr',
                }}
              >
                {`${product?.title}`}
              </Typography>
            ) : <></>}
          </Tooltip>
          {block?.settings?.enablePrice &&
            <Typography sx={{ width: '100%', direction: 'ltr' }}>שובר ₪{price}</Typography>}
        </>
      )}
    </Grid>
  );
};
