import { FieldTypes, SettingsField } from 'src/types/page-generator';

const contentFields: SettingsField[] = [
  {
    name: 'modalId',
    type: FieldTypes.input,
    defaultValue: '',
    label: 'Modal Identifier (required) ',
    rowSize: 2,
  },
];

export default contentFields;
