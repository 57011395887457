'use client';

import isEqual from 'lodash/isEqual';
import { useState, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';

import { _roles } from '../../../_mock';
import Label from '../../../components/label';
import { useTranslate } from '../../../locales';
import Iconify from '../../../components/iconify';
import { useBoolean } from '../../../hooks/use-boolean';
import { _tabHistory } from '../../../_mock/_virtualCards';
import { useResponsive } from '../../../hooks/use-responsive';
import Scrollbar from '../../../components/scrollbar/scrollbar';
import TabHistoryTableRow from '../../generate-page/components/virtual-cards/tab-history-table-row';
import VirtualCardsTableToolbar from '../../generate-page/components/virtual-cards/virtual-cards-table-toolbar';
import {
  IVirtualCardsTabHistory,
  IVirtualCardsTabHistoryTableFilters,
  IVirtualCardsTabHistoryTableFilterValue,
} from '../../../types/club-cards';
import {
  useTable,
  emptyRows,
  TableNoData,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../components/table';

const defaultFilters: IVirtualCardsTabHistoryTableFilters = {
  name: '',
  role: [],
  type: 'all',
};

const STATUS_OPTIONS = [
  { value: 'all', label: 'הכל' },
  { value: 'חיוב', label: 'חיוב' },
  { value: 'זיכוי', label: 'זיכוי' },
];

export const TabHistory = () => {
  const isMobile = useResponsive('down', 'sm');
  const [filters, setFilters] = useState(defaultFilters);
  const table = useTable();
  const [tableData, setTableData] = useState(_tabHistory);
  const confirm = useBoolean();
  const { t } = useTranslate();
  const TABLE_HEAD = [
    { id: 'name', label: 'שם הפעולה', width: 300 },
    { id: 'total', label: 'סה”כ', width: 180 },
    { id: 'type', label: 'סוג ', width: 180 },
    { id: 'date', label: 'תאריך  ', width: 100 },
    { id: '', width: 88 },
  ];

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;
  const handleFilters = useCallback(
    (name: string, value: IVirtualCardsTabHistoryTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleFilterType = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('type', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);
  return (
    <Container maxWidth={isMobile ? 'xs' : 'xl'} sx={{ p: 0 }}>
      <Paper
        elevation={3}
        sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}
      >
        <Tabs
          value={filters.type}
          onChange={handleFilterType}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label
                  variant={
                    ((tab.value === 'all' || tab.value === filters.type) && 'filled') || 'soft'
                  }
                  color={
                    (tab.value === 'זיכוי' && 'error') ||
                    (tab.value === 'חיוב' && 'success') ||
                    'default'
                  }
                >
                  {tab.value === 'all' && _tabHistory.length}
                  {tab.value === 'זיכוי' &&
                    _tabHistory.filter((user) => user.type === 'זיכוי').length}

                  {tab.value === 'חיוב' &&
                    _tabHistory.filter((user) => user.type === 'חיוב').length}
                </Label>
              }
            />
          ))}
        </Tabs>

        <VirtualCardsTableToolbar
          filters={filters}
          onFilters={handleFilters}
          //
          roleOptions={_roles}
        />

        {/* {canReset && ( */}
        {/*  <VirtualCardsTableFiltersResult */}
        {/*    filters={filters} */}
        {/*    onFilters={handleFilters} */}
        {/*    // */}
        {/*    onResetFilters={handleResetFilters} */}
        {/*    // */}
        {/*    results={dataFiltered.length} */}
        {/*    sx={{ p: 2.5, pt: 0 }} */}
        {/*  /> */}
        {/* )} */}
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              )
            }
            action={
              <Tooltip title={t('Delete')}>
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
              />

              <TableBody>
                {dataFiltered
                  .slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )
                  .map((row) => (
                    <TabHistoryTableRow
                      key={row.id}
                      row={row}
                      selected={table.selected.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onDeleteRow={() => {}}
                      onEditRow={() => {}}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
          sx={{ width: '100%' }}
        />
      </Paper>
    </Container>
  );
};

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: IVirtualCardsTabHistory[];
  comparator: (a: any, b: any) => number;
  filters: IVirtualCardsTabHistoryTableFilters;
}) {
  const { name, type, role } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (card) => card.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (type !== 'all') {
    inputData = inputData.filter((card) => card.type === type);
  }

  return inputData;
}
