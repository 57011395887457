'use client';

import { useMemo, useEffect } from 'react';
import { useRouter } from 'next/navigation';

import { Box, Stack, Typography } from '@mui/material';

import { ActionTypes, useAppContext } from 'src/contexts/AppContext';

import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../../utils';

export const ConfirmCityPeople = ({ block }: ComponentTypeProps) => {
  const router = useRouter();
  const { dispatch } = useAppContext();
  const getFieldValue = getContentValueFromProps(block);

  const firstRaw = useMemo(() => getFieldValue('firstRaw') || 'בקשתכם התקבלה!', [block?.firstRaw]);

  const secondRaw = useMemo(
    () => getFieldValue('secondRaw') || 'נצור קשר בהקדם',
    [block?.secondRaw]
  );

  const redirectLink = useMemo(() => getFieldValue('redirectLink'), [block?.redirectLink]);

  useEffect(
    () => () => {
      setTimeout(() => {
        router.push(redirectLink);
        dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
      }, 5000);
    },
    []
  );

  return (
    <Box>
      <Stack p={3} gap={2} alignItems="center" sx={{ m: '0 auto' }} maxWidth="460px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            mt: 1,
            p: 3,
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: 2.5,
            backdropFilter: 'blur(20px)',
            backgroundColor: block?.settings?.hasBackground ? 'white' : block?.settings?.bgColor,
            backgroundImage: block?.settings?.hasBackground
              ? 'linear-gradient(62deg, rgba(255, 86, 48, 0.12) 0%, rgba(0, 184, 217, 0.12) 100%)'
              : '',
            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Typography sx={{ color: block?.settings?.textColor }}>{firstRaw}</Typography>
          <Typography sx={{ color: block?.settings?.textColor }}>{secondRaw}</Typography>
        </Box>
      </Stack>
    </Box>
  );
};
