import { Box, Stack, Button, TextField, Typography } from '@mui/material';
import { useLocales } from '../../../../../../locales';
import { BlockType } from '../../../../../../types/generator';

type Props = {
  block: BlockType;
};

export const ClosingSite = ({ block }: Props) => {
  const { currentLang } = useLocales();
  const text: string = block?.text as string;
  return (
    <Box>
      <Typography
        sx={{
          '& h1': { my: 1 },
          '& h2': { my: 1 },
          '& h3': { my: 1 },
          '& h4': { my: 1 },
          '& h5': { my: 1 },
          '& h6': { my: 1 },
          '& p': { margin: 0 },
          direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
          width: '100%',
          lineHeight: block?.settings?.lineHeight,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
    </Box>
  );
};

export default ClosingSite;
