'use client';

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Link,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const SpecialistsListSquare = () => {
  const specialistsListArr = [
    {
      image: '/assets/images/specialists/specialists1.jpg',
      firstName: 'דניאל כהן',
      position: 'מנקה',
      rating: '4.6',
      link: '/aboutspesialist',
    },
    {
      image: '/assets/images/specialists/specialists2.jpg',
      firstName: 'יעל כהן',
      position: 'שיפוצניקית',
      rating: '4.6',
      link: '/aboutspesialist',
    },
    {
      image: '/assets/images/specialists/specialists3.jpg',
      firstName: 'איתי ערד',
      position: 'שרברב',
      rating: '4.6',
      link: '/aboutspesialist',
    },
    {
      image: '/assets/images/specialists/specialists4.jpg',
      firstName: 'חיה אזולאי',
      position: 'מנקה',
      rating: '4.6',
      link: '/aboutspesialist',
    },
    {
      image: '/assets/images/specialists/specialists5.jpg',
      firstName: 'יוני לוי',
      position: 'מנקה',
      rating: '4.6',
      link: '/aboutspesialist',
    },
  ];

  return (
    <Swiper
      spaceBetween={30} // Увеличено пространство между слайдами
      slidesPerView={3}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }}
    >
      {specialistsListArr.map((el, index) => (
        <SwiperSlide key={index} style={{ width: '100px' }}>
          <Link style={{ textDecoration: 'none' }}>
            <Card
              sx={{
                width: '100px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CardMedia
                component="img"
                image={el.image}
                alt={el.firstName}
                sx={{
                  height: 80,
                  width: 80,
                  objectFit: 'cover',
                  margin: '0 auto',
                  borderRadius: '50%', // Добавлено округление для изображения
                }}
              />
              <CardContent>
                <Typography variant="h6" component="div" noWrap>
                  {el.firstName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {el.position}
                </Typography>
                <Box display="flex" alignItems="center" mt={1}>
                  <Typography variant="body2" mr={0.5}>
                    {el.rating}
                  </Typography>
                  <StarIcon fontSize="small" color="primary" />
                </Box>
              </CardContent>
            </Card>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SpecialistsListSquare;
