'use client';

// locales
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// components
import Iconify from 'src/components/iconify';
import { usePopover } from 'src/components/custom-popover';

import { ICategoryItem } from '../../../../../types/product';
import { BlockType } from '../../../../../types/page-generator';
import { Ib2bTableFilter, Ib2bTableFilterValue } from '../../../../../types/b2b';

// ----------------------------------------------------------------------

type Props = {
  block: BlockType;
  filters: Ib2bTableFilter;
  onFilters: (name: string, value: Ib2bTableFilterValue) => void;
  //
  typeOptions?: string[];
  categoryOptions: ICategoryItem[];
  areaOptions?: string[];
  handleResetFilters: Function;
  handleResetName: Function;
  canReset: boolean;
};

export default function NetworkToolbar({
  block,
  filters,
  onFilters,
  categoryOptions,
  typeOptions,
  areaOptions,
  handleResetFilters,
  canReset,
  handleResetName,
}: Props) {
  const { t } = useTranslation();

  const popover = usePopover();
  const defaultSlider = [0, 3000];
  const [valueSlider, setValue] = useState<number[]>(defaultSlider);
  const { enableButtons, enablePriceFilter, enableArea, enableCategory, enableType, enableSearch } =
    block.settings;

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterArea = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'area',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterCategory = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'category',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterType = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        'type',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterPrice = useCallback(
    (event: any) => {
      onFilters('price', event.target.value);
    },
    [onFilters]
  );

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    handleFilterPrice(event);
  };

  return (
    <Box sx={{ direction: 'rtl' }}>
      <Stack
        spacing={1}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <IconButton
          onClick={() => {
            handleResetFilters();
            setValue(defaultSlider);
          }}
          disabled={!canReset}
        >
          <Iconify icon="basil:trash-solid" color={!canReset ? 'grey.500' : 'primary.main'} />
        </IconButton>
        {enableArea && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
              minWidth: { xs: 1, md: 60 },
              maxWidth: { xs: 1, md: 120 },
            }}
          >
            <InputLabel>{t('אזור')}</InputLabel>

            <Select
              multiple
              value={filters.area}
              onChange={handleFilterArea}
              input={<OutlinedInput label="אזור" />}
              renderValue={(selected) => selected.map((value) => value).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {areaOptions &&
                areaOptions?.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox disableRipple size="small" checked={filters.area.includes(option)} />
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {enableCategory && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>{t('קטגוריה')}</InputLabel>

            <Select
              multiple
              value={filters.category}
              onChange={handleFilterCategory}
              input={<OutlinedInput label="קטגוריה" />}
              renderValue={(selected) => selected.map((value) => value).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {categoryOptions?.map((option: any, id) => (
                <MenuItem key={id} value={option?.title}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.category.includes(option.title)}
                  />
                  {option?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {enableType && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
              minWidth: { xs: 1, md: 60 },
              maxWidth: { xs: 1, md: 120 },
            }}
          >
            <InputLabel>{t('סוג')}</InputLabel>

            <Select
              multiple
              value={filters.type}
              onChange={handleFilterType}
              input={<OutlinedInput label="סוג" />}
              renderValue={(selected) => selected.map((value) => value).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {['home', 'food'].map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox disableRipple size="small" checked={filters.type.includes(option)} />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {enableSearch && (
          <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
            <TextField
              sx={{ '& input': { direction: 'ltr' } }}
              fullWidth
              value={filters.name}
              onChange={handleFilterName}
              placeholder={`${t('חיפוש...')}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    {!!filters.name && (
                      <Iconify
                        icon="iconamoon:close-fill"
                        sx={{ color: 'text.disabled', cursor: 'pointer' }}
                        onClick={() => handleResetName()}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
