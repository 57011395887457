function BathroomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill="#004454"
        d="M27.916 16.525H3.988V5.309a3.194 3.194 0 01.942-2.274l.025-.025a3.221 3.221 0 013.651-.633 4.929 4.929 0 00.734 6.021l.683.682-1.257 1.256 1.41 1.41 1.256-1.256 6.96-6.96 1.256-1.255-1.41-1.41-1.256 1.256-.682-.682a4.928 4.928 0 00-6.29-.56 5.21 5.21 0 00-6.465.721l-.025.025a5.175 5.175 0 00-1.526 3.684v11.216H0v1.994h1.994v1.913c0 .16.026.32.077.473l1.855 5.564a1.493 1.493 0 001.418 1.023h.804l-.727 2.492h2.077l.727-2.492h12.967l.748 2.492h2.081l-.747-2.492h1.291a1.493 1.493 0 001.42-1.023l1.854-5.564c.051-.153.077-.312.077-.473v-1.913h1.994v-1.994h-1.994zM10.75 2.848a2.93 2.93 0 014.14 0l.682.683-4.14 4.14-.682-.683a2.93 2.93 0 010-4.14zm15.172 17.503l-1.716 5.147H5.704L3.988 20.35v-1.832h21.934v1.832z"
      />
    </svg>
  );
}

export default BathroomIcon;
