'use client';

import Button from '@mui/material/Button';
import { Stack, TextField, Typography } from '@mui/material';

import { useTranslate } from '../../../../../../locales';
import { ButtonField } from '../../../../../../types/generator';
import { ComponentTypeProps } from '../../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';

export default function WantBuy({ block }: ComponentTypeProps) {
  const { t } = useTranslate();
  const { dispatch, state } = useAppContext();
  const actionButtons: any = block?.actionButtons || [];
  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };
  return (
    <Stack alignItems="center" gap={1} sx={{ direction: 'rtl' }}>
      <Typography sx={{ py: 1 }} color="green">
        I want to Buy
      </Typography>
      <Stack direction="row" width={1} gap={1} alignItems="center">
        <TextField placeholder="Amount" fullWidth />
        <TextField select label="Currency" fullWidth>
          {['$', '€', '₪', '₴', '₽'].map((card, id) => (
            <option key={id} value={card}>
              {card}
            </option>
          ))}
        </TextField>
      </Stack>
      <Stack direction="row" width={1} gap={1} alignItems="center">
        <Typography sx={{ width: 0.5 }}>Rate:</Typography>
        <TextField sx={{ width: 0.5 }} />
      </Stack>
      <Stack direction="row" width={1} gap={1} alignItems="center">
        <Typography sx={{ width: 0.5 }}>Pay:</Typography>
        <TextField select label="Currency" sx={{ width: 0.5 }}>
          {['$', '€', '₪', '₴', '₽'].map((card, id) => (
            <option key={id} value={card}>
              {card}
            </option>
          ))}
        </TextField>
      </Stack>
      <Stack direction="row" width={1} gap={1} alignItems="center">
        <Typography sx={{ width: 0.5 }}>Place:</Typography>
        <TextField sx={{ width: 0.5 }} placeholder="City" />
      </Stack>
      <Stack direction="row" width="100%" spacing={2} py={1} alignItems="center">
        {actionButtons.map((actionButton: ButtonField, idx: number) => (
          // @ts-ignore - проблемы с совместимостью типов, не критично
          <Button
            type={actionButton.label !== 'cancel' && actionButton.label !== 'ביטול' && 'submit'}
            variant={actionButton.variant}
            color={actionButton.color}
            size={actionButton.size}
            key={`actionButton_${idx}`}
            sx={{ borderRadius: 0, fontWeight: 400, width: '50%' }}
            href={
              (!actionButton?.link.includes('modal') &&
                !actionButton.isDialogToggler &&
                actionButton?.link) ||
              ''
            }
            onClick={() => {
              if (actionButton.isDialogToggler && actionButton.modalId) {
                setActiveDialog(actionButton.modalId);
              }
              if (actionButton.link === '#close') {
                setActiveDialog(null);
              }
            }}
          >
            {actionButton.label || `[${t('no label')}]`}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
}
