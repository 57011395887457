import { useMemo } from 'react';

import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';

import { useSWR, endpoints } from '../utils/axios';

export type GetNetworkProps = {
  id?: string | string[];
  limit?: number;
  offset?: number;
  minPrice?: number;
  maxPrice?: number;
  search?: string;
  categoriesIds?: string[];
  priceType?: string;
  tagsIds?: string[];
  merchantTagIds?: string[];
  productIds?: string | string[];
};
export type GetProductProps = {
  id?: string | string[];
  limit?: number;
  offset?: number;
  minPrice?: number;
  maxPrice?: number;
  dispatch?: React.Dispatch<ActionType>;
  search?: string;
  categoriesIds?: string[];
  priceType?: string;
  tagsIds?: string[];
  merchantTagIds?: string[];
  productIds?: string | string[];
};

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const FALLBACK_DATA = { payload: [], count: 0 };
const FALLBACK_DATA_SINGLE = { payload: null };

export function useGetNetworks(props: GetNetworkProps) {
  const URL = [
    endpoints.networks.all,
    {
      params: {
        ...props,
        botId: BOT_ID,
        active: true,
        relations: ['branches', 'asterisks', 'branches.asterisks'],
        limit: props.limit,
        offset: props.offset,
        search: props.search,
        productIds: props.productIds,
        tagsIds: props.tagsIds,
        merchantTagIds: props.merchantTagIds,
      },
    },
  ];

  const URLById = [
    endpoints.networks.byId,
    {
      params: {
        ...props,
        id: props.id ? props.id : '',
        botId: BOT_ID,
        active: true,
        relations: ['branches', 'asterisks'],
        limit: props.limit,
        offset: props.offset,
        search: props.search,
        productIds: props.productIds,
        tagsIds: props.tagsIds,
        merchantTagIds: props.merchantTagIds,
      },
    },
  ];

  const currentURL = props.id ? URLById : URL;
  const { data, isLoading, error, isValidating } = useSWR(currentURL, options);
  const { payload: networks, count: networksCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      networks,
      totalCount: networksCount,
      networksError: error,
      networksLoading: isLoading,
      networksValidating: isValidating,
      networksEmpty: !isLoading && !data?.payload?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export const getNetworks = async (
  productIds: string | string[],
  { limit, offset, dispatch, search, tagsIds, merchantTagIds }: GetProductProps
) => {
  try {
    const { data } = await API({
      url: `network/all?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        active: true,
        relations: ['branches', 'asterisks', 'branches.asterisks'],
        limit,
        offset,
        search,
        productIds,
        tagsIds,
        merchantTagIds,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_NETWORKS,
        payload: { networks: data.payload, count: data.count },
      });
    }
    return data.payload;
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
  return true;
};

export const getNetworksTags = async ({ limit, offset, dispatch, search }: GetProductProps) => {
  try {
    const { data } = await API({
      url: `network/getTags?botId=${BOT_ID}`,
      method: 'GET',
      params: {
        active: true,
        relations: ['branches', 'asterisks', 'branches.asterisks'],
        limit,
        offset,
        search,
      },
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_NETWORKS_TAGS,
        payload: { networksTags: data.payload },
      });
    }
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
};

export function useGetNetworksTags(props: GetNetworkProps) {
  const URL = [
    endpoints.networks.getTags,
    {
      params: {
        ...props,
        botId: BOT_ID,
        active: true,
        limit: props.limit,
        offset: props.offset,
      },
    },
  ];

  const { data, isLoading, error, isValidating } = useSWR(URL, options);
  const { payload: networksTags, count: networksTagsCount } = data ?? FALLBACK_DATA;

  const memoizedValue = useMemo(
    () => ({
      networksTags,
      networksTagsCount,
      networksTagsError: error,
      networksTagsLoading: isLoading,
      networksTagsValidating: isValidating,
      networksTagsEmpty: !isLoading && !data?.payloasd?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export const getNetworkByIds = async ({ id, dispatch }: GetProductProps) => {
  try {
    const { data } = await API({
      url: `network`,
      method: 'GET',
      params: {
        active: true,
        id,
        relations: ['branches', 'asterisks'],
      },
    });
    return data.payload;
  } catch (e) {
    if (dispatch) {
      dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
    }
    console.error(e);
  }
  return null;
};
