'use client';

import Slider from 'react-slick';
import NextLink from 'next/link';
import { FC, Key, useRef, useState } from 'react';

import { Box, Card, Stack, Button, Typography } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import SvgColor from '../../../../components/svg-color';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';
import { useLocales } from '../../../../locales';

export const Banner1Carousel: FC<ComponentTypeProps> = ({ block }) => {
  const { combinedCarouselBanner1 }: any = block;
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();
  const { dispatch, state } = useAppContext();
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef<any>(null);
  const { currentLang } = useLocales();

  const position = (item: any) => {
    if (item.position === 'start') return 'flex-start';
    if (item.position === 'center') return 'center';
    if (item.position === 'end') return 'flex-end';
    return 'center';
  };

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: block?.settings?.isAlternativeDesign && !isMobile ? 3 : 1,
    slidesToScroll: 1,
    autoplay: block?.settings?.autoplay || false,
    autoplaySpeed: (block?.settings?.playSpeed || 3) * 1000,
    afterChange: (current: number) => setActiveSlide(current),
  };

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const click = (item: any) => {
    if (item.buttonDrawer) setActiveDialog(item.buttonModal);
    if (item.buttonHref.includes('#')) {
      const newLink = item.buttonHref.replace('#', '');
      window.location.href = `#${newLink}`;
    } else {
      router.push(item.buttonHref);
    }
  };

  const handleSlideChange = (index: number) => {
    setActiveSlide(index);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <Box
      sx={{
        height: isMobile ? 'auto' : '100%',
        '& .slick-slide': {
          direction: 'rtl',
          px: { md: 1, xs: 1 },
        },
      }}
    >
      <Slider ref={sliderRef} {...settings}>
        {combinedCarouselBanner1?.map((item: any, index: Key) => (
          <Card
            onClick={() => click(item)}
            key={index}
            component={Stack}
            justifyContent={position(item)}
            alignItems={item.contentAlign}
            sx={{
              cursor: 'pointer',
              boxShadow: 0,
              width: '100%',
              borderRadius: block?.settings?.isAlternativeDesign ? '15px' : '0px',
              backgroundSize: 'auto',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <Image
              src={item?.backgroundImage}
              alt="Banner Image"
              placeholder={<Box sx={{ backgroundColor: '#f0f0f0', height: '100%' }} />}
              sx={{ width: '100%', height: '100%' }}
            />
            <Stack
              className={item.contentClass}
              alignItems={item.position}
              justifyContent='flex-end'
              gap={2}
              width={1}
              sx={{
                position: 'absolute',
                p: 3,
                top: 0,
                left: 0,
                height: '100%'
              }}
            >
              {item.enableTitle && (
                <Typography
                  className={item.typographyClass}
                  sx={{
                    width: '100%',
                    fontWeight: item.contentTitleFontWeight,
                    fontSize: item.contentTitleFontSize,
                    color: item.contentTitleTextColor,
                    fontStyle: item.contentTitleFontStyle,
                    textDecoration: item.contentTitleTextDecoration,
                    textAlign: item.contentTextAlignItems,
                  }}
                >
                  {item.title}
                </Typography>
              )}
              {item.enableSubtitle && (
                <Typography
                  className={item.typographyClass}
                  sx={{
                    width: '100%',
                    '& h1': { my: 1 },
                    '& h2': { my: 1 },
                    '& h3': { my: 1 },
                    '& h4': { my: 1 },
                    '& h5': { my: 1 },
                    '& h6': { my: 1 },
                    '& p': { margin: 0 },
                    direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                    lineHeight: item.lineHeight,
                  }}
                >
                    <span
                      dangerouslySetInnerHTML={{ __html: item?.subtitle }}
                    />
                </Typography>
              )}
              {item.enableButton && (
                <Button
                  component={
                    item?.buttonHref.includes('modal') || item.buttonHref.includes('#')
                      ? Button
                      : NextLink
                  }
                  variant={item?.variant || 'contained'}
                  color={item?.color || 'primary'}
                  size={item?.size || 'small'}
                  sx={{
                    fontSize: '12px',
                    direction: 'rtl',
                    borderRadius: '20px',
                    color: item.buttonColor,
                    bgcolor: item.buttonBgColor,
                    '&:hover': {
                      color: item.buttonColor,
                      bgcolor: item.buttonBgColor,
                    },
                  }}
                  href={
                    (!item?.buttonHref.includes('modal') &&
                      !item.buttonHref.includes('#') &&
                      !item.buttonDrawer &&
                      item?.link) ||
                    ''
                  }
                  endIcon={<Iconify icon={item.buttonIcon || ''} />}
                  onClick={() => {
                    if (item.buttonDrawer && item.buttonModal) {
                      setActiveDialog(item.buttonModal);
                    }
                    if (item.buttonHref.includes('#')) {
                      const newLink = item?.buttonHref.replace('#', '');
                      window.location.href = `#${newLink}`;
                    }
                  }}
                >
                  {item.buttonLabel || 'No label'}
                </Button>
              )}
            </Stack>
          </Card>
        ))}
      </Slider>
      {isMobile && (
        <Stack
          width={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{
            position: 'relative',
            bottom: '50px',
            '& .ellipse': {
              border: '1px solid',
              borderColor: block?.settings?.dotsColor,
              borderRadius: '2.46px/2.5px',
              height: '5px',
              opacity: 0.8,
              width: '5px',
            },
          }}
        >
          {(() => {
            const indicators = [];
            // eslint-disable-next-line no-plusplus
            for (let id = 0; id < combinedCarouselBanner1.length; id++) {
              indicators.push(
                <Stack alignItems="center" key={id}>
                  {activeSlide === id ? (
                    <SvgColor color={block?.settings?.dotsColor} src="/assets/images/line-5.svg" />
                  ) : (
                    <Box className="ellipse" onClick={() => handleSlideChange(id)} />
                  )}
                </Stack>
              );
            }
            return indicators;
          })()}
        </Stack>
      )}
    </Box>
  );
};
