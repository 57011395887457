'use client';

// types

// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

// hooks

// locales

// components
import { ITransaction } from 'src/types/club-cards';

import Label from '../../../components/label';
import { useTranslate } from '../../../locales';
import Iconify from '../../../components/iconify';
import { getTransactionTypeIcon, getTransactionTypeColor } from '../../../utils/color-maps';

// ----------------------------------------------------------------------

type Props = {
  row: ITransaction;
  cellStyle?: any;
};

export default function TransactionsTableRow({ row, cellStyle }: Props) {
  const { t } = useTranslate();
  const { amount, transactionType } = row;
  return (
    <TableRow hover>
      <TableCell style={cellStyle}>
        <Label
          variant="soft"
          color={getTransactionTypeColor(transactionType)}
          endIcon={<Iconify width={16} icon={getTransactionTypeIcon(transactionType)} />}
        >
          {t(transactionType)}
        </Label>
      </TableCell>

      <TableCell style={cellStyle} sx={{ whiteSpace: 'nowrap' }}>
        {amount}
      </TableCell>
    </TableRow>
  );
}
