'use client';

import { FC, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

import Grid from '@mui/material/Grid';
import { Box, Stack, Button } from '@mui/material';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { useRouter } from '../../../../routes/hooks';
import { getContentValueFromProps } from '../../utils';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../contexts/AppContext';

type ButtonItem = {
  img: string;
  button: any;
  banner: any;
};

const CatalogBoomBuy: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const combinedBlock: any = block?.combinedBlock;
  const swiperRef = useRef<SwiperCore | null>(null);
  const { t } = useTranslation();
  const isMobile = useResponsive('down', 'sm');
  const router = useRouter();

  const variant = useMemo(() => getFieldValue('settings.variant'), [block?.settings.variant]);
  const { state, dispatch } = useAppContext();

  const indentation = useMemo(
    () => getFieldValue('settings.indentation'),
    [block?.settings.indentation],
  );

  const setActiveDialog = (payload: null | string) => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload });
  };

  const clickToTheCard = (item: any) => {
    if (item?.bannerDrawer && item?.bannerModal) {
      setActiveDialog(item?.bannerModal);
    } else if (item.bannerHref.includes('#')) {
      const newLink = item.bannerHref.replace('#', '');
      window.location.href = `#${newLink}`;
    } else if (item?.bannerHref) {
      const bannerHref = item.bannerHref;

      if (bannerHref.startsWith('http://') || bannerHref.startsWith('https://')) {
        window.location.href = bannerHref;
      } else {
        router.push(bannerHref);
      }
    }
  };


  const gridMd = combinedBlock && combinedBlock.length ? 12 / combinedBlock.length : 3;

  return (
    <>
      {variant === 'v1' ? (
        <Grid container sx={{ height: 'auto' }} spacing={indentation} justifyContent="center">
          {combinedBlock?.map((item: any, id: number) => (
            <Grid item md={gridMd} xs={6} key={id}>
              <Box
                key={id}
                sx={{
                  position: 'relative',
                  backgroundPosition: '50%',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  width: '100%',
                  height: '100%',
                  cursor: block?.settings?.variant === 'v1' ? 'pointer' : '',
                }}
                onClick={() => clickToTheCard(item)}
              >
                <Image src={item.image} sx={block?.settings?.enableHoverAnimation ? {
                  '& img': {
                    '&:hover': {
                      border: '2px solid',
                      borderColor: block?.settings?.colorHoverAnimation,
                      borderRadius: '10px',
                    },
                  },
                } : {}} />
                <Stack width={1} alignItems="center">
                  <Button
                    variant={item.buttonVariant || 'contained'}
                    size={isMobile ? 'small' : item.buttonSize || 'medium'}
                    sx={{
                      mb: 1,
                      display: !item.enableButton ? 'none' : '',
                      borderRadius: 10,
                      // position: 'absolute',
                      // bottom: isMobile ? '20px' : '25px',
                      color: item.enableColor,
                      bgcolor: item.buttonBgColor,
                      '&:hover': {
                        color: item.enableColor,
                        bgcolor: item.buttonBgColor,
                      },
                    }}
                    endIcon={<Iconify icon={item?.buttonIcon || ''} />}
                    href={
                      ((!item.buttonHref.includes('modal') &&
                          !item.buttonDrawer &&
                          !item.buttonHref.includes('#') &&
                          item.buttonHref) ||
                        '')
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                      if (item.buttonDrawer && item.buttonModal) {
                        setActiveDialog(item.buttonModal);
                      } else if (item.buttonHref.includes('#')) {
                        const newLink = item.buttonHref.replace('#', '');
                        window.location.href = `#${newLink}`;
                      }
                    }}
                  >
                    {item.buttonLabel || `[${t('no  label')}]`}
                  </Button>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {!isMobile ? (
            <Box
              sx={{
                width: '100%',
                borderRadius: '0px',
                backgroundSize: 'cover',
                padding: 5,
              }}
            >
              <Stack direction="row" height="255px" gap={indentation}>
                {combinedBlock?.map((item: any, id: number) => (
                  <Box
                    key={`${item.image}_${id}`}
                    sx={{
                      position: 'relative',
                      backgroundImage: `url(${item.image})`,
                      backgroundPosition: '50%',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      width: '100%',
                      height: '100%',
                      cursor: block?.settings?.variant === 'card' ? 'pointer' : '',
                    }}
                    onClick={() => clickToTheCard(item)}
                  >
                    <Stack width={1} alignItems="center">
                      <Button
                        variant={item.buttonVariant || 'contained'}
                        size={isMobile ? 'small' : item.buttonSize || 'medium'}
                        sx={{
                          display: !item.enableButton ? 'none' : '',
                          borderRadius: 10,
                          position: 'absolute',
                          bottom: isMobile ? '20px' : '25px',
                          color: item.enableColor,
                          bgcolor: item.buttonBgColor,
                          '&:hover': {
                            color: item.enableColor,
                            bgcolor: item.buttonBgColor,
                          },
                        }}
                        endIcon={<Iconify icon={item?.buttonIcon || ''} />}
                        href={
                          ((!item.buttonHref.includes('modal') &&
                              !item.buttonDrawer &&
                              !item.buttonHref.includes('#') &&
                              item.buttonHref) ||
                            '')
                        }
                        onClick={() => {
                          if (item.buttonDrawer && item.buttonModal) {
                            setActiveDialog(item.buttonModal);
                          } else if (item.buttonHref.includes('#')) {
                            const newLink = item.buttonHref.replace('#', '');
                            window.location.href = `#${newLink}`;
                          }
                        }}
                      >
                        {item.buttonLabel || `[${t('no  label')}]`}
                      </Button>
                    </Stack>
                  </Box>
                ))}
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                '& .swiper-slide': { width: '70vw', margin: 1 },
                '& .swiper-wrapper': { width: '100vw' },
              }}
            >
              <Stack direction="row" height="255px">
                <Swiper
                  speed={500}
                  slidesPerView="auto"
                  mousewheel={{
                    forceToAxis: true,
                    sensitivity: 1,
                    releaseOnEdges: true,
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                >
                  {combinedBlock?.map((item: any, id: number) => (
                    <SwiperSlide key={`${item.image}_${id}`}>
                      <Box
                        key={`${item.image}_${id}`}
                        sx={{
                          position: 'relative',
                          backgroundImage: `url(${item.image})`,
                          backgroundPosition: '50%',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <Stack width={1} alignItems="center">
                          <Button
                            variant={item.buttonVariant || 'contained'}
                            size={item.buttonSize || 'medium'}
                            href={item.buttonHref}
                            sx={{
                              display: !item.enableButton ? 'none' : '',
                              borderRadius: 10,
                              position: 'absolute',
                              bottom: '25px',
                              color: item.enableColor,
                              bgcolor: item.buttonBgColor,
                              '&:hover': {
                                color: item.enableColor,
                                bgcolor: item.buttonBgColor,
                              },
                            }}
                            endIcon={<Iconify icon={item?.buttonIcon || ''} />}
                          >
                            {item.buttonLabel || `[${t('no label')}]`}
                          </Button>
                        </Stack>
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Stack>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CatalogBoomBuy;
