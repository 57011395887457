// SLICK CAROUSEL CSS
import 'slick-carousel/slick/slick.css';
import { FC, PropsWithChildren } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import SlickCarousel, { Settings } from 'react-slick';

import { Theme, SxProps, useTheme } from '@mui/material/styles';

// STYLED COMPONENT
import { RootStyle } from './styles';
// LOCAL CUSTOM COMPONENTS
import CarouselDots from './carousel-dots';
import CarouselArrows from './carousel-arrows';

// ==============================================================
interface Props extends PropsWithChildren, Settings {
  dotColor?: string;
  spaceBetween?: number;
  dotStyles?: SxProps<Theme>;
  arrowStyles?: SxProps<Theme>;
  direction?: string;
}
// ==============================================================

const Carousel: FC<Props> = ({
  dotColor,
  children,
  arrowStyles,
  dots = false,
  arrows = true,
  slidesToShow = 4,
  spaceBetween = 10,
  dotStyles = { mt: 4 },
  direction,
  ...props
}) => {
  const theme = useTheme();

  const settings: Settings = {
    dots,
    arrows,
    slidesToShow,
    rtl: theme.direction === direction,
    ...CarouselArrows(arrowStyles),
    ...CarouselDots({ dotColor, sx: dotStyles }),
    ...props,
  };

  return (
    <RootStyle space={spaceBetween}>
      <SlickCarousel {...settings}>{children}</SlickCarousel>
    </RootStyle>
  );
};

export default Carousel;
