import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type InstallPwaModalProps = {
  open: boolean;
  onClose: () => void;
};

const InstallPwaModal: React.FC<InstallPwaModalProps> = ({ open, onClose }) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="install-pwa-modal-title"
    aria-describedby="install-pwa-modal-description"
  >
    <Box sx={modalStyle}>
      <Typography id="install-pwa-modal-description-hebrew" sx={{ mt: 2 }}>
        התקנת האפליקציה רק בספארי. להתקנה, לחץ על סמל השיתוף ובחר הוסף למסך הבית.
      </Typography>

      <Box
        sx={{
          width: '100%',
          height: 'auto',
          overflow: 'auto', // Add scrollbars if content overflows
        }}
      >
        <Stack direction="row" justifyContent="center" spacing={1} sx={{ marginTop: 2 }}>
          <Box>
            <img
              width={100}
              src="/assets/images/pwa/manual_2.jpg"
              alt="manual 1"
              style={imageStyle}
            />
          </Box>
          <Box>
            <img
              width={100}
              src="/assets/images/pwa/manual_1.jpg"
              alt="manual 2"
              style={imageStyle}
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  </Modal>
);

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Adjust width for responsiveness
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh', // Ensure the modal fits within the screen height
  overflowY: 'auto', // Enable scrolling if content overflows
};

const imageStyle = {
  marginTop: '16px',
};

export default InstallPwaModal;
