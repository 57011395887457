import { memo } from 'react';

import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function HomeMenuIcon({ ...other }: BoxProps) {
  const color = other.color === 'string' ? other.color : '#004454';

  return (
    <Box
      component="svg"
      viewBox="0 0 32 32"
      width="100%"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M1 16.3059C1 12.8732 1 11.1569 1.7718 9.73411C2.54361 8.31132 3.95365 7.42826 6.77372 5.66219L9.74677 3.8003C12.7278 1.93343 14.2183 1 15.8653 1C17.5122 1 19.0027 1.93343 21.9838 3.8003L24.9569 5.66218C27.777 7.42826 29.1869 8.31132 29.9587 9.73411C30.7305 11.1569 30.7305 12.8732 30.7305 16.3059V18.5875C30.7305 24.4387 30.7305 27.3644 28.9889 29.1821C27.2475 31 24.4443 31 18.8383 31H12.8922C7.28617 31 4.48316 31 2.74157 29.1821C1 27.3644 1 24.4387 1 18.5875V16.3059Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M20.3249 25H11.4058" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </Box>
  );
}

export default memo(HomeMenuIcon);
