'use client';

import { FC } from 'react';

import { Box } from '@mui/material';

// eslint-disable-next-line import/no-cycle
import { ComponentTypeProps } from '../../../../types/page-generator';

export const IndentationBlock: FC<ComponentTypeProps> = ({ block }) => (
  <Box
    sx={{
      width: '100%',
      height: {
        md: block?.settings?.height?.length ? `${block?.settings?.height}px` : '0px',
        xs: block?.settings?.mobileHeight?.length
          ? `${block?.settings?.mobileHeight}px`
          : `${block?.settings?.height}px`,
      },
    }}
  />
);
