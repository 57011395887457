'use client';

import React, { FC } from 'react';
import { Box, Typography, Container } from '@mui/material';

import NotificationsSquare from './notification-square';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const Notifications: FC<ComponentTypeProps> = ({ block }) => {
  const notificationsArr = [
    {
      img: '/assets/images/specialists/specialists5.jpg',
      messageBeforeBold: 'הזמנת בדיקת חשמל של ',
      bold: 'אבי דוידוף',
      messageAfterBold: 'היום ב11:00',
      date: '11:00 ,היום',
      formatDate: '16 Mar 2022 11:00:00 GMT',
      btn: '/images/notifications/okBtn.png',
    },
    {
      img: '/assets/images/specialists/specialists2.jpg',
      messageBeforeBold: 'הזמנת נקיון יסודי של ',
      bold: 'יעל כהן',
      messageAfterBold: '',
      date: '12:30 ,15 מרץ 2022',
      formatDate: '15 Mar 2022 11:00:00 GMT',
      btn: '/images/notifications/okBtn.png',
    },
    {
      img: '/assets/images/specialists/specialists6.jpg',
      messageBeforeBold: 'נקיון הכניסה השבועי יבוצע על ידי',
      bold: 'נעם מאיר',
      messageAfterBold: '',
      date: '08:10 ,14 מרץ 2022',
      formatDate: '14 Mar 2022 08:10:00 GMT',
      btn: '/images/notifications/doneBtn.png',
    },
  ];

  notificationsArr.sort((a, b) => {
    const dateA = new Date(a.formatDate).getTime();
    const dateB = new Date(b.formatDate).getTime();
    return dateB - dateA;
  });

  return (
    <Box
      className="PageDad"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Container
        className="page"
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Box
          component="main"
          className="notifications"
          sx={{ mt: 2, width: '100%', maxWidth: 600 }}
        >
          {notificationsArr.map((el, i) => (
            <NotificationsSquare
              img={el.img}
              messageBeforeBold={el.messageBeforeBold}
              bold={el.bold}
              messageAfterBold={el.messageAfterBold}
              date={el.date}
              btn={el.btn}
              key={i}
              i={i}
            />
          ))}
        </Box>
      </Container>
      {/* <Footer page="notifications" /> */}
    </Box>
  );
};

export default Notifications;
