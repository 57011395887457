import { ICategoryItem } from 'src/types/b2b';
import { IMerchant } from 'src/types/merchant';
import { IOProductItem } from 'src/types/product';

export enum SearchType {
  category = 'Categories',
  product = 'Products',
  merchant = 'Merchants',
}

export type SearchResult = (IOProductItem | ICategoryItem | IMerchant) & {
  entityType: SearchType;
};
