'use client';

import React, { FC, useMemo } from 'react';

import { Grid } from '@mui/material';

import { getContentValueFromProps } from '../../utils';
import { ComponentTypeProps } from '../../../../types/page-generator';
// @ts-ignore
const TopBanners: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const bannerUrl = useMemo(() => getFieldValue('bannerUrl') || '/assets/cards/1.jpg', [block]);
  const imageWidth = useMemo(() => getFieldValue('settings.imageWidth'), [block]);
  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={bannerUrl} alt="" style={{ borderRadius: 10, width: imageWidth }} />
      </Grid>
    </Grid>
  );
};

export default TopBanners;
