'use client';

import React from 'react';

import { Box, Link, Avatar, Typography } from '@mui/material';


type Props = {
  img: string;
  info: string;
  descrip: string;
  icon: string;
  link: string;
  setLink: any;
};

const DetailSquare = ({ img, info, descrip, icon, link, setLink }: Props) => (
    <Link
        style={{ textDecoration: 'none', cursor: 'pointer' }}
        onClick={() => setLink(true)}
      >
        <Box
          display="flex"
          alignItems="center"
          className="detailSquare"
          sx={{
            padding: 2,
            marginBottom: 2,
          }}
        >
          <Box className="detailImgDiv" sx={{ mx: 2 }}>
            {img ? (
              <Avatar
                alt={info}
                src={img}
                sx={{
                  width: 56,
                  height: 56,
                  border: '4px solid lightgreen',
                  borderRadius: '50%',
                }}
              />
            ) : (
              icon
            )}
          </Box>

          <Box className="detailTextDiv">
            <Typography variant="h6" component="div" className="detailInfo">
              {info}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="detailEdit"
            >
              {descrip}
            </Typography>
          </Box>
        </Box>
      </Link>
  );

export default DetailSquare;
