import { ButtonColor, ButtonVariant } from '../../../types/generator';

export enum NotificationTypesEnum {
  ORDER_NEW = 'ORDER_NEW',
  ORDER_CHANGE_STATUS = 'ORDER_CHANGE_STATUS',
  ORDER_DELIVERY_STARTS = 'ORDER_DELIVERY_STARTS',
  ORDER_DELIVERY_STATUS = 'ORDER_DELIVERY_STATUS',
  ORDER_DELIVERY_COMPLETED = 'ORDER_DELIVERY_COMPLETED',
  ORDER_DELIVERY_FAILED = 'ORDER_DELIVERY_FAILED',
  ORDER_COMPLETED = 'ORDER_COMPLETED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  PROMOTION = 'PROMOTION',
  WEEKLY_PRODUCT_UPDATE = 'WEEKLY_PRODUCT_UPDATE',
  PROMOTION_INDIVIDUAL = 'PROMOTION_INDIVIDUAL',
  CUSTOMER_NEW = 'CUSTOMER_NEW',
  CUSTOMER_BIRTHDAY = 'CUSTOMER_BIRTHDAY',
  COMMENTARY_NEW = 'COMMENTARY_NEW',
  TESTIMONIAL_NEW = 'TESTIMONIAL_NEW',
  NEWS_ANNOUNCEMENT = 'NEWS_ANNOUNCEMENT',
  WEEKLY_BLOG_DIGEST = 'WEEKLY_BLOG_DIGEST',
  CUSTOM = 'CUSTOM',
  FRIEND = 'FRIEND',
  PROJECT = 'PROJECT',
  FILE = 'FILE',
  TAGS = 'TAGS',
  PAYMENT = 'PAYMENT',
  // *** CRM
  // TASK NOTIFICATIONS
  TASK_REMINDERS = 'TASK_REMINDERS',
  TASK_ASSIGNMENTS = 'TASK_ASSIGNMENTS',
  TASK_COMPLETION = 'TASK_COMPLETION',

  // EMAIL NOTIFICATIONS
  EMAIL_NEW_EMAIL_RECEIVED = 'EMAIL_NEW_EMAIL_RECEIVED',
  EMAIL_FOLLOW_UP_REMINDERS = 'EMAIL_FOLLOW_UP_REMINDERS',
  EMAIL_EMAIL_OPENED = 'EMAIL_EMAIL_OPENED',

  // LEAD AND OPPORTUNITY NOTIFICATIONS
  LEAD_OPPORTUNITY_NEW_LEAD_ASSIGNED = 'LEAD_OPPORTUNITY_NEW_LEAD_ASSIGNED',
  LEAD_OPPORTUNITY_LEAD_STATUS_CHANGE = 'LEAD_OPPORTUNITY_LEAD_STATUS_CHANGE',
  LEAD_OPPORTUNITY_OPPORTUNITY_UPDATES = 'LEAD_OPPORTUNITY_OPPORTUNITY_UPDATES',

  // CUSTOMER INTERACTION NOTIFICATIONS
  CUSTOMER_INTERACTION_NEW_INTERACTION_LOGGED = 'CUSTOMER_INTERACTION_NEW_INTERACTION_LOGGED',
  CUSTOMER_INTERACTION_CUSTOMER_RESPONSE = 'CUSTOMER_INTERACTION_CUSTOMER_RESPONSE',
  CUSTOMER_INTERACTION_CUSTOMER_FEEDBACK = 'CUSTOMER_INTERACTION_CUSTOMER_FEEDBACK',

  // DEAL AND SALES NOTIFICATIONS
  DEAL_SALES_NEW_DEAL_CREATED = 'DEAL_SALES_NEW_DEAL_CREATED',
  DEAL_SALES_DEAL_PROGRESS = 'DEAL_SALES_DEAL_PROGRESS',
  DEAL_SALES_DEAL_CLOSURE = 'DEAL_SALES_DEAL_CLOSURE',

  // USER ACTIVITY NOTIFICATIONS
  USER_ACTIVITY_LOGIN_ALERTS = 'USER_ACTIVITY_LOGIN_ALERTS',
  USER_ACTIVITY_PROFILE_UPDATES = 'USER_ACTIVITY_PROFILE_UPDATES',
  USER_ACTIVITY_SECURITY_ALERTS = 'USER_ACTIVITY_SECURITY_ALERTS',

  // WORKFLOW AND AUTOMATION NOTIFICATIONS
  WORKFLOW_AUTOMATION_WORKFLOW_TRIGGERS = 'WORKFLOW_AUTOMATION_WORKFLOW_TRIGGERS',
  WORKFLOW_AUTOMATION_AUTOMATION_ACTIONS = 'WORKFLOW_AUTOMATION_AUTOMATION_ACTIONS',

  // SYSTEM NOTIFICATIONS
  SYSTEM_UPDATES = 'SYSTEM_UPDATES',
  SYSTEM_INTEGRATION_ALERTS = 'SYSTEM_INTEGRATION_ALERTS',
  SYSTEM_DATA_IMPORT_EXPORT = 'SYSTEM_DATA_IMPORT_EXPORT',

  // COLLABORATION NOTIFICATIONS
  COLLABORATION_COMMENT_MENTIONS = 'COLLABORATION_COMMENT_MENTIONS',
  COLLABORATION_FILE_SHARING = 'COLLABORATION_FILE_SHARING',
  COLLABORATION_TEAM_UPDATES = 'COLLABORATION_TEAM_UPDATES',

  // CUSTOM NOTIFICATIONS
  CUSTOM_ALERTS = 'CUSTOM_ALERTS',
  CUSTOM_REPORT_GENERATION = 'CUSTOM_REPORT_GENERATION',
}

export type NotificationType = {
  id: string;
  type: NotificationTypesEnum;
  createdAt: string;
  isViewed: boolean;
  highPriority: boolean;
  actionPerformed: null | string;
  data: {
    title: string;
    description: string;
    icon?: string | null;
    link?: string | null;
    userId?: string | null;
    userName?: string | null;
    avatarUrl?: string | null;
    projectName?: string | null;
    projectId?: string | null;
    fileName?: string | null;
    fileSize?: string | null;
    fileUploadedDate?: string | null;
    fileLink?: string | null;
    tags: { label: string; value: string }[];
    paymentAmount: number | null;
    transactionId: string | null;
    iconAside?: boolean;
    hideActionsOnNotification?: boolean;
    actions: {
      color: ButtonColor;
      action: string;
      variant: ButtonVariant;
      label: string;
      startIcon?: string;
      endIcon?: string;
      link?: string;
    }[];

    // Orders
    orderNumber?: number;
  };
};

export type ActionOptions = {
  link?: string;
};
