'use client';

import { useRouter } from 'next/navigation';
import { FC, useMemo, useEffect } from 'react';

import { Box, Stack, Container, Typography } from '@mui/material';

import { useAppContext } from 'src/contexts/AppContext';
import { BASIC_URL_FOR_IMAGES } from 'src/constants/common';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { IApartment } from 'src/types/apartment';
import { PriceTypeEnum } from 'src/types/product';
import { Ib2bProduct, PriceInterface } from 'src/types/b2b';
import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';

const BannerWithBalanceCityPeople: FC<ComponentTypeProps> = ({ block }) => {
  const router = useRouter();
  const { state } = useAppContext();
  const getFieldValue = getContentValueFromProps(block);
  const { title, mainImageURL, apartmentType, localImage } = (state.selectedApartment ||
    {}) as IApartment;

  const userTotalBalance = useMemo(
    () =>
      state.products.reduce((acc: number, i: Ib2bProduct) => {
        const price = i.prices.find((p: PriceInterface) => p.type === PriceTypeEnum.MAX_PRICE);
        return Number.isNaN(Number(price?.value)) ? acc : acc + Number(price?.value);
      }, 0),
    [state.products]
  );

  const imageLink = mainImageURL ? `${BASIC_URL_FOR_IMAGES}${mainImageURL}` : localImage;

  const balanceColor = 'black';

  // Content style
  const contentTitleTextColor = useMemo(
    () => getFieldValue('settings.contentTitleTextColor'),
    [block?.settings.contentTitleTextColor]
  );
  const contentTitleFontSize = useMemo(
    () => getFieldValue('settings.contentTitleFontSize'),
    [block?.settings.contentTitleFontSize]
  );
  const contentTitleFontWeight = useMemo(
    () => getFieldValue('settings.contentTitleFontWeight'),
    [block?.settings.contentTitleFontWeight]
  );
  const contentTitleFontStyle = useMemo(
    () => getFieldValue('settings.contentTitleFontStyle'),
    [block?.settings.contentTitleFontStyle]
  );
  const contentTitleTextDecoration = useMemo(
    () => getFieldValue('settings.contentTitleTextDecoration'),
    [block?.settings.contentTitleTextDecoration]
  );
  const contentTextAlignItems = useMemo(
    () => getFieldValue('settings.contentTextAlignItems'),
    [block?.settings.contentTextAlignItems]
  );
  const isAlternativeDesign = useMemo(
    () => getFieldValue('settings.isAlternativeDesign'),
    [block?.settings.isAlternativeDesign]
  );
  const backdropLink = useMemo(() => getFieldValue('backdropLink') ?? '/', [block?.backdropLink]);

  useEffect(() => {
    if (!state.selectedApartment?.id && block?.backdropLink) {
      router.push(String(backdropLink));
    }
  }, [backdropLink]);

  return (
    <Container maxWidth="sm">
      <Box sx={{ borderRadius: 0, backgroundColor: 'transparent' }}>
        {isAlternativeDesign && (
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
              width: '100%',
              position: 'relative',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={1}
              sx={{ width: '85%', margin: '0 auto' }}
            >
              <Image
                src="/assets/images/city-people/asterisk.png"
                sx={{
                  width: contentTitleFontSize
                    ? `${parseInt(contentTitleFontSize, 10) + 5}px`
                    : '40px',
                  height: contentTitleFontSize
                    ? `${parseInt(contentTitleFontSize, 10) + 5}px`
                    : '40px',
                  '& img': { objectFit: 'contain' },
                  transform: 'translateY(-15%)',
                }}
              />
              <Typography
                sx={{
                  mb: '10px',
                  fontWeight: contentTitleFontWeight,
                  fontSize: contentTitleFontSize,
                  color: contentTitleTextColor,
                  fontStyle: contentTitleFontStyle,
                  textDecoration: contentTitleTextDecoration,
                }}
              >
                {title}
              </Typography>
            </Stack>
            <Iconify
              width={60}
              color="primary.light"
              icon="material-symbols-light:keyboard-arrow-right"
              onClick={() => router.push(String(block?.backdropLink))}
              sx={{
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translate(-20px, -60%)',
              }}
            />
          </Stack>
        )}
        <Stack
          position="relative"
          alignItems="center"
          justifyContent="center"
          gap={1}
          width="100%"
          height="120px"
          sx={{
            width: '100%',
            backgroundImage: `url(${imageLink})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            ...(isAlternativeDesign && {
              borderRadius: '25px',
              height: 300,
            }),
          }}
          mb={3}
        >
          {!isAlternativeDesign && (
            <>
              {title && (
                <Typography
                  sx={{
                    paddingX: '10px',
                    marginTop: 0,
                    marginBottom: 0,
                    width: '100%',
                    fontWeight: contentTitleFontWeight,
                    fontSize: contentTitleFontSize,
                    lineHeight: contentTitleFontSize,
                    color: contentTitleTextColor,
                    fontStyle: contentTitleFontStyle,
                    textDecoration: contentTitleTextDecoration,
                    textAlign: contentTextAlignItems,
                  }}
                >
                  {title}
                </Typography>
              )}
              {apartmentType && (
                <Typography
                  sx={{
                    paddingX: '10px',
                    marginTop: 0,
                    marginBottom: 0,
                    width: '100%',
                    fontWeight: contentTitleFontWeight,
                    fontSize: contentTitleFontSize,
                    lineHeight: contentTitleFontSize,
                    color: contentTitleTextColor,
                    fontStyle: contentTitleFontStyle,
                    textDecoration: contentTitleTextDecoration,
                    textAlign: contentTextAlignItems,
                  }}
                >
                  {apartmentType}
                </Typography>
              )}
            </>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'absolute',
              bottom: '-15px',
              padding: '10px 20px',
              fontSize: '1.25rem',
              direction: 'rtl',
              borderRadius: 2.5,
              color: balanceColor,
              ...(!isAlternativeDesign && {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                backgroundImage:
                  'linear-gradient(62deg, rgba(255, 86, 48, 0.12) 0%, rgba(0, 184, 217, 0.12) 100%)',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
              }),
              ...(isAlternativeDesign && {
                padding: '10px 25px',
                backgroundColor: 'primary.light',
                borderRadius: 4,
                bottom: 0,
                transform: 'translateY(50%)',
              }),
            }}
          >
            <Typography
              variant="h4"
              sx={{
                ...(isAlternativeDesign && {
                  color: 'primary.contrastText',
                  fontWeight: 100,
                }),
              }}
            >
              {`₪ ${userTotalBalance}${isAlternativeDesign ? ' :יתרה למימוש' : ''}`}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default BannerWithBalanceCityPeople;
