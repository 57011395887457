export enum Langs {
  he = 'he',
  en = 'en',
}

export type BasicPageParams = {
  title: string;
  lng: Langs;
  domain: string;
  id?: string;
};
