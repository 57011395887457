import {Box} from "@mui/material";

type Props = {}

const AuthLockIcon = (props: Props) => {
    return (
        <Box component='svg'
             width="35px" height="35px"
             viewBox="0 0 35 35" fill="none"
        >
            <path d="M2.9165 23.3334C2.9165 19.2086 2.9165 17.1462 4.19791 15.8648C5.47932 14.5834 7.54171 14.5834 11.6665 14.5834H23.3332C27.4579 14.5834 29.5203 14.5834 30.8017 15.8648C32.0832 17.1462 32.0832 19.2086 32.0832 23.3334C32.0832 27.4581 32.0832 29.5205 30.8017 30.8019C29.5203 32.0834 27.4579 32.0834 23.3332 32.0834H11.6665C7.54171 32.0834 5.47932 32.0834 4.19791 30.8019C2.9165 29.5205 2.9165 27.4581 2.9165 23.3334Z" stroke="#004454" strokeWidth="1.5"/>
            <path d="M8.75 14.5833V11.6666C8.75 6.83413 12.6675 2.91663 17.5 2.91663C22.3325 2.91663 26.25 6.83413 26.25 11.6666V14.5833" stroke="#004454" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M11.6665 23.3334H11.6796M17.4867 23.3334H17.4998M23.32 23.3334H23.3332" stroke="#004454" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>

        </Box>
    )
}

export default AuthLockIcon
