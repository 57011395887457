'use client';

import { Person, LocationOn, Star } from '@mui/icons-material';
import { Box, Typography, Button, Avatar } from '@mui/material';
import { FC } from 'react';
import profilePhoto from '../../assets/images/myProfile/profilePhoto.png';
// eslint-disable-next-line import/no-cycle
import DetailsList from './details-list';
import { ComponentTypeProps } from '../../../../../types/page-generator';

const MyProfile: FC<ComponentTypeProps> = ({ block }) => {
  const containerArr = [
    {
      img: '/assets/images/myProfile/profilePhoto.png',
      info: 'אתי ליפשיץ',
      descrip: 'ערוך',
      link: '#',
    },
    {
      icon: <Person className="detailImg" />,
      img: false,
      info: 'פרטי התקשרות',
      descrip: 'שם, פלאפון, אימייל',
      link: '#',
    },
    {
      icon: <LocationOn className="detailImg" />,
      img: false,
      info: 'כתובת',
      descrip: 'שדרות רגר 8, באר שבע',
      link: '#',
    },
    {
      icon: <Star className="detailImg" />,
      img: false,
      info: 'השירותים שהוזמנו',
      descrip: 'שדרות רגר 8, באר שבע',
      link: '/paidservices',
    },
  ];

  return (
    <Box className="PageDad">
      <Box className="page">
        <Box className="contactPage">
          <DetailsList containerArr={containerArr} />
        </Box>
      </Box>
    </Box>
  );
};

export default MyProfile;
