'use client';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Image from '../../../../components/image';

export const SixTableElements = () => (
  <Stack direction="row" flexWrap="wrap" px={20} justifyContent="center">
    <Stack sx={{ width: '33%' }}>
      <Image src="/assets/images/happy-gift/home/1.png" />
    </Stack>
    <Stack sx={{ width: '33%' }}>
      <Image src="/assets/images/happy-gift/home/2.png" />
    </Stack>
    <Stack sx={{ width: '33%' }}>
      <Image src="/assets/images/happy-gift/home/3.png" />
    </Stack>
    <Stack sx={{ width: '33%' }}>
      <Image src="/assets/images/happy-gift/home/4.png" />
    </Stack>
    <Stack sx={{ width: '33%' }}>
      <Image src="/assets/images/happy-gift/home/5.png" />
    </Stack>
    <Stack sx={{ width: '33%' }}>
      <Image src="/assets/images/happy-gift/home/6.png" />
    </Stack>
    <Button
      variant="contained"
      sx={{
        mt: 3,
        borderRadius: '20px',
        bgcolor: 'primary.main',
        color: 'secondary.contrastText',
        '&:hover': { bgcolor: 'primary.main', color: 'secondary.contrastText' },
      }}
    >
      לכל המוצרים
    </Button>
  </Stack>
);
