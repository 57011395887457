function ClosetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      fill="none"
      viewBox="0 0 30 34"
    >
      <path
        stroke="#004454"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.333 19.667H8.777M21.222 19.667h-1.556"
      />
      <path
        stroke="#004454"
        strokeWidth="2"
        d="M15 1h13.067c.515 0 .933.418.933.933v29.245a.933.933 0 01-.933.933H15M15 1H1.933A.933.933 0 001 1.933v29.245c0 .515.418.933.933.933H15M15 1v31.111"
      />
    </svg>
  );
}

export default ClosetIcon;
