'use client';

import { useMemo, SyntheticEvent } from 'react';

import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Button from '@mui/material/Button';
import { Box, Paper, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import Image from '../../../components/image';
import Iconify from '../../../components/iconify';
import { BlockType } from '../../../types/page-generator';
import { useResponsive } from '../../../hooks/use-responsive';
import { getContentValueFromProps } from '../../generate-page/utils';

type Props = {
  changeTitle: Function;
  handleChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
  activeTitle: number;
  block: BlockType | undefined;
};

export const MainTabs = ({ changeTitle, handleChange, activeTitle, block }: Props) => {
  const getFieldValue = getContentValueFromProps(block);
  const isMobile = useResponsive('down', 'sm');

  const enableHistoryTab = useMemo(() => getFieldValue('settings.enableHistoryTab'), [block]);

  const enableAdvantagesTab = useMemo(() => getFieldValue('settings.enableAdvantagesTab'), [block]);

  const enableWalletTab = useMemo(() => getFieldValue('settings.enableWalletTab'), [block]);

  const enableBAccountTab = useMemo(() => getFieldValue('settings.enableBAccountTab'), [block]);

  const enableFamilyTab = useMemo(() => getFieldValue('settings.enableFamilyTab'), [block]);

  const tabsBackground = useMemo(() => getFieldValue('settings.tabsBackground'), [block]);

  const tabsTitleTextColor = useMemo(() => getFieldValue('settings.tabsTitleTextColor'), [block]);

  const tabsTitleFontSize = useMemo(() => getFieldValue('settings.tabsTitleFontSize'), [block]);

  const tabsTitleFontWeight = useMemo(() => getFieldValue('settings.tabsTitleFontWeight'), [block]);

  const tabsTitleFontStyle = useMemo(() => getFieldValue('settings.tabsTitleFontStyle'), [block]);

  const tabsTitleTextDecoration = useMemo(
    () => getFieldValue('settings.tabsTitleTextDecoration'),
    [block]
  );
  const titleButton = [
    {
      id: 0,
      title: 'הכרטיס שלי',
      img: '/assets/images/happy-gift/cards.svg',
      show: enableWalletTab,
      icon: 'solar:card-2-outline',
    },
    {
      id: 1,
      title: 'ההטבות בשבילי',
      img: '/assets/images/happy-gift/bags.svg',
      show: enableAdvantagesTab,
      icon: 'mdi:shopping-outline',
    },
    {
      id: 2,
      title: 'חשבון',
      img: '/assets/images/happy-gift/check.svg',
      show: enableBAccountTab,
      icon: 'fluent:document-text-24-filled',
    },
    {
      id: 3,
      title: 'היסטוריה',
      img: '/assets/images/happy-gift/$.svg',
      show: enableHistoryTab,
      icon: 'streamline:subscription-cashflow-solid',
    },
    {
      id: 4,
      title: 'המשפחה',
      img: '/assets/images/happy-gift/family.svg',
      show: enableFamilyTab,
      icon: 'arcticons:familywall',
    },
  ];

  const smUp = useResponsive('up', 'sm');

  return (
    <>
      {!isMobile ? (
        <Card
          sx={{
            width: smUp ? 'auto' : '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            columnGap: 1,
            px: 3,
            py: 3,
            mb: 2,
          }}
        >
          {titleButton.map(({ id, title, img, show }) => (
            <Button
              key={id}
              sx={{
                display: !show ? 'none' : '',
                direction: 'rtl',
                maxWidth: '100vw',
                borderRadius: '5px',
                fontSize: tabsTitleFontSize,
                color: activeTitle === id ? tabsTitleTextColor : 'grey.light',
                fontWeight: activeTitle === id ? tabsTitleFontWeight : 200,
                fontStyle: tabsTitleFontStyle,
                textDecoration: tabsTitleTextDecoration,
                backgroundColor: activeTitle === id ? tabsBackground : null,
                '&:hover': {
                  backgroundColor: activeTitle === id ? tabsBackground : null,
                },
              }}
              onClick={() => changeTitle(id)}
            >
              {title}
              <Image src={img} width="25%" />
            </Button>
          ))}
        </Card>
      ) : (
        <Paper elevation={5} sx={{ width: '100%' }}>
          {activeTitle === 2 && (
            <Stack
              sx={{
                alignItems: 'center',
                margin: 'auto',
                my: 2,
              }}
            >
              <Typography>
                חשבון מספר:
                <b>7482245285702036</b>
              </Typography>
              <Typography>
                תאריך תפוגה:
                <b> 31.07.2027</b>
              </Typography>
            </Stack>
          )}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              width: '95%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Tabs value={activeTitle} onChange={handleChange}>
              {titleButton.map(({ id, title, img, show, icon }) => (
                <Tab
                  sx={{ display: !show ? 'none' : '' }}
                  key={id}
                  label={
                    <>
                      <Typography>{title}</Typography>
                      <Iconify icon={icon} sx={{ mx: 1 }} />
                    </>
                  }
                />
              ))}
            </Tabs>
          </Box>
        </Paper>
      )}
    </>
  );
};
