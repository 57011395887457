// @types
import { BlockTypes } from 'src/types/generator';
import { FieldTypes, SettingsField } from 'src/types/page-generator';

// parts
import bannerSettings from './banner';
import headerSettings from './header';
import verticalBannerSettings from './vertical-banner';
import headerCityPeopleSettings from './header-city-people';
import { colorOptions, colorTextOptions, textColorOptions, buttonColorOptions } from './common';
import {
  fontSizes,
  fontStyles,
  borderSizes,
  fontWeights,
  percentOptions,
  textDecorations,
  textDecorationsAlign,
} from './fonts-presets';

export const SETTINGS_FIELDS_PRESETS: Record<BlockTypes, SettingsField[]> = {
  [BlockTypes.header]: headerSettings,
  [BlockTypes.headercitypeople]: headerCityPeopleSettings,
  [BlockTypes.footer]: [
    {
      name: 'backgroundColor',
      type: FieldTypes.select,
      defaultValue: colorOptions[1].value,
      label: 'Background color',
      isColor: true,
      rowSize: 4,
      options: colorOptions,
    },
    {
      name: 'textColor',
      type: FieldTypes.select,
      defaultValue: textColorOptions[1].value,
      label: 'Text color',
      isColor: true,
      rowSize: 4,
      options: textColorOptions,
    },
    {
      name: 'socialLinksGroup',
      label: 'Social links',
      type: FieldTypes.group,
      defaultValue: '',
      rowSize: 4,
      fields: [
        {
          name: 'socialsBackgroundColor',
          type: FieldTypes.select,
          defaultValue: colorOptions[3].value,
          isColor: true,
          label: 'Background color',
          rowSize: 2,
          options: colorOptions,
        },
        {
          name: 'socialsBackgroundColorHover',
          type: FieldTypes.select,
          defaultValue: colorOptions[1].value,
          isColor: true,
          label: 'Background color (hover)',
          rowSize: 2,
          options: colorOptions,
        },
        {
          name: 'socialLinksColor',
          type: FieldTypes.select,
          defaultValue: textColorOptions[1].value,
          isColor: true,
          options: textColorOptions,
          label: 'Text color',
          rowSize: 2,
        },
        {
          name: 'socialLinksColorHover',
          type: FieldTypes.select,
          defaultValue: textColorOptions[1].value,
          isColor: true,
          options: textColorOptions,
          label: 'Text color (hover)',
          rowSize: 2,
        },
      ],
    },
  ],
  [BlockTypes.banner]: [
    {
      name: 'testfield',
      type: FieldTypes.input,
      defaultValue: 'test field',
      label: 'Test Field',
      rowSize: 3,
    },
  ],
  [BlockTypes.cards]: [
    {
      name: 'testfield',
      type: FieldTypes.input,
      defaultValue: 'test field',
      label: 'Test Field',
      rowSize: 3,
    },
  ],
  [BlockTypes.filter]: [
    {
      name: 'testfield',
      type: FieldTypes.input,
      defaultValue: 'test field',
      label: 'Test Field',
      rowSize: 3,
    },
  ],
  [BlockTypes.textblock]: [
    {
      name: 'testfield',
      type: FieldTypes.input,
      defaultValue: 'test field',
      label: 'Test Field',
      rowSize: 3,
    },
  ],
  [BlockTypes.swiper]: [
    {
      name: 'testfield',
      type: FieldTypes.input,
      defaultValue: 'test field',
      label: 'Test Field',
      rowSize: 3,
    },
  ],
  [BlockTypes.carousel]: [],
  [BlockTypes.bigdiscountlist]: [],
  [BlockTypes.cardlist]: [],
  [BlockTypes.categories]: [],
  [BlockTypes.categorylist]: [],
  [BlockTypes.productwithfilter]: [],
  [BlockTypes.moreitems]: [],
  [BlockTypes.newarrivalslist]: [],
  [BlockTypes.productcarousel]: [],
  [BlockTypes.ratecomponent]: [],
  [BlockTypes.servicelist]: [],
  [BlockTypes.hotdealscarousel]: [],
  [BlockTypes.multibannerscarousel]: [],
  [BlockTypes.multirowbanner]: [],
  [BlockTypes.serviceitems]: [],
  [BlockTypes.subscribenewsletter]: [],
  [BlockTypes.trendingitems]: [],
  [BlockTypes.bigbannerscarousel]: [],
  [BlockTypes.categorybanner]: [],
  [BlockTypes.instagramphotogallery]: [],
  [BlockTypes.salesbanner]: [],
  [BlockTypes.topcategories]: [],
  [BlockTypes.searchbarwithbackground]: [],
  [BlockTypes.popularproductswithmultilevelmenu]: [],
  [BlockTypes.multilinecategories]: [],
  [BlockTypes.testimonials]: [],
  [BlockTypes.productscarouselhovereffect]: [],
  [BlockTypes.bestsellingcategories]: [],
  [BlockTypes.blogcard]: [],
  [BlockTypes.bloglistarea]: [],
  [BlockTypes.brandlistcarouselarea]: [],
  [BlockTypes.fullwidthbanner]: [],
  [BlockTypes.offerbanner]: [],
  [BlockTypes.productlistcolumn]: [],
  [BlockTypes.bestsellingproducts]: [],
  [BlockTypes.StandartHorizontalBannerV1]: bannerSettings,
  [BlockTypes.sixhoverblocks]: bannerSettings,
  [BlockTypes.whitecardbanner]: bannerSettings,
  [BlockTypes.happyhrbanner]: [],
  [BlockTypes.b2cbanner]: [],
  [BlockTypes.b2cwhitecardbanner]: [],
  [BlockTypes.b2chappygiftglobal]: [],
  [BlockTypes.b2chappygiftworld]: [],
  [BlockTypes.b2chappygiftbluecardbanner]: [],
  [BlockTypes.threecolumnsinfo]: [],
  [BlockTypes.wallet]: [
    {
      name: 'forceRtl',
      label: 'Force RTL',
      type: FieldTypes.checkbox,
      defaultValue: true,
      rowSize: 4,
    },
    {
      name: 'enableLogo',
      type: FieldTypes.checkbox,
      defaultValue: true,
      label: 'Enable heading logo',
      rowSize: 2,
    },
    {
      name: 'enableWalletTab',
      type: FieldTypes.checkbox,
      defaultValue: true,
      label: 'Enable wallet tab',
      rowSize: 2,
    },
    {
      name: 'enableAdvantagesTab',
      type: FieldTypes.checkbox,
      defaultValue: true,
      label: 'Enable advantages tab',
      rowSize: 2,
    },
    {
      name: 'enableHistoryTab',
      type: FieldTypes.checkbox,
      defaultValue: true,
      label: 'Enable history tab',
      rowSize: 2,
    },
    {
      name: 'titleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Title Style',
      fields: [
        {
          name: 'contentTitleTextColor',
          label: 'Content Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentTitleFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '32px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentTitleFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentTitleFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentTitleTextDecoration',
          label: 'Content Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'tabsStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Tabs Style',
      fields: [
        {
          name: 'tabsTitleTextColor',
          label: 'Tabs Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'tabsTitleFontSize',
          label: 'Tabs Font Size',
          type: FieldTypes.select,
          defaultValue: '15px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'tabsTitleFontWeight',
          label: 'Tabs Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'tabsTitleFontStyle',
          label: 'Tabs Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'tabsTitleTextDecoration',
          label: 'Tabs Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'primaryStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Primary Text Style',
      fields: [
        {
          name: 'primaryTitleTextColor',
          label: 'Primary Title Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'primaryTitleFontSize',
          label: 'Primary Title Font Size',
          type: FieldTypes.select,
          defaultValue: '15px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'primaryTitleFontWeight',
          label: 'Primary Title Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'primaryTitleFontStyle',
          label: 'Primary Title Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'primaryTitleTextDecoration',
          label: 'Primary Title Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'secondaryStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Secondary Text Style',
      fields: [
        {
          name: 'secondaryTitleTextColor',
          label: 'Secondary Title Text color',
          type: FieldTypes.select,
          defaultValue: 'text.disabled',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'secondaryTitleFontSize',
          label: 'Secondary Title Font Size',
          type: FieldTypes.select,
          defaultValue: '12px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'secondaryTitleFontWeight',
          label: 'Secondary Title Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'secondaryTitleFontStyle',
          label: 'Secondary Title Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'secondaryTitleTextDecoration',
          label: 'Secondary Title Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'titleStylesAlignItems',
      label: 'Title Decoration',
      type: FieldTypes.select,
      defaultValue: 'center',
      rowSize: 4,
      options: textDecorationsAlign,
    },
    {
      name: 'classes',
      label: 'Extra classes',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'contentClasses',
          type: FieldTypes.input,
          label: 'Content classes',
          rowSize: 4,
        },
      ],
    },
  ],
  [BlockTypes.cart]: [],
  [BlockTypes.b2bshop]: [],
  [BlockTypes.wallet2]: [],
  [BlockTypes.videobanner]: [],
  [BlockTypes.themeSettings]: [],
  [BlockTypes.css]: [],
  [BlockTypes.account]: [],
  [BlockTypes.businessAccount]: [],
  [BlockTypes.residentAccount]: [],
  [BlockTypes.happygift_activitybanner]: [],
  [BlockTypes.stepbanner]: [],
  [BlockTypes.combinedstepbanner]: [],
  [BlockTypes.cardsbanner]: [],
  [BlockTypes.catalog]: [],
  [BlockTypes.product]: [],
  [BlockTypes.shopbanner]: [],
  [BlockTypes.shopbanner2]: [],
  [BlockTypes.shopbanner3]: [],
  [BlockTypes.shopbanner4]: [],
  [BlockTypes.shopbanner5]: [],
  [BlockTypes.shopbanner6]: [],
  [BlockTypes.buttonsbannerhappyhr]: [],
  [BlockTypes.allhappyhrbanner]: [],
  [BlockTypes.hottestoffers]: [
    {
      name: 'titleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Heading Style',
      fields: [
        {
          name: 'headingTitleTextColor',
          label: 'Heading Text color',
          type: FieldTypes.select,
          defaultValue: '#27097A',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'headingTitleFontSize',
          label: 'Heading Font Size',
          type: FieldTypes.select,
          defaultValue: '36px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'headingTitleFontWeight',
          label: 'Heading Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'headingTitleFontStyle',
          label: 'Heading Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'headingTitleTextDecoration',
          label: 'Heading Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'headingTextAlignItems',
          label: 'Heading Text Align',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'titleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Title Style',
      fields: [
        {
          name: 'contentTitleTextColor',
          label: 'Content Text color',
          type: FieldTypes.select,
          defaultValue: '#27097A',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentTitleFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentTitleFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentTitleFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentTitleTextDecoration',
          label: 'Content Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'contentTextAlignItems',
          label: 'Content Text Align',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'otherStyle',
      label: 'Other',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'forceRtl',
          label: 'Force RTL',
          type: FieldTypes.checkbox,
          defaultValue: false,
          rowSize: 2,
        },
      ],
    },
    {
      name: 'classes',
      label: 'Extra classes',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'contentClasses',
          type: FieldTypes.input,
          label: 'Content classes',
          rowSize: 4,
        },
        {
          name: 'imagedClasses',
          type: FieldTypes.input,
          label: 'Images classes',
          rowSize: 4,
        },
      ],
    },
  ],
  [BlockTypes.popularcategories]: [
    {
      name: 'titleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Heading Style',
      fields: [
        {
          name: 'headingTitleTextColor',
          label: 'Heading Text color',
          type: FieldTypes.select,
          defaultValue: '#27097A',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'headingTitleFontSize',
          label: 'Heading Font Size',
          type: FieldTypes.select,
          defaultValue: '36px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'headingTitleFontWeight',
          label: 'Heading Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'headingTitleFontStyle',
          label: 'Heading Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'headingTitleTextDecoration',
          label: 'Heading Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'headingTextAlignItems',
          label: 'Heading Text Align',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'titleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Title Style',
      fields: [
        {
          name: 'contentTitleTextColor',
          label: 'Content Text color',
          type: FieldTypes.select,
          defaultValue: '#27097A',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentTitleFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentTitleFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentTitleFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentTitleTextDecoration',
          label: 'Content Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'contentTextAlignItems',
          label: 'Content Text Align',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'otherStyle',
      label: 'Other',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'forceRtl',
          label: 'Force RTL',
          type: FieldTypes.checkbox,
          defaultValue: false,
          rowSize: 2,
        },
      ],
    },
    {
      name: 'classes',
      label: 'Extra classes',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'contentClasses',
          type: FieldTypes.input,
          label: 'Content classes',
          rowSize: 4,
        },
        {
          name: 'imagedClasses',
          type: FieldTypes.input,
          label: 'Images classes',
          rowSize: 4,
        },
      ],
    },
  ],
  [BlockTypes.products]: [],
  [BlockTypes.selectedCoupons]: [],
  [BlockTypes.products2]: [],
  [BlockTypes.myESim]: [],
  [BlockTypes.chat]: [],
  [BlockTypes.myCoupons]: [],
  [BlockTypes.myActiveCoupon]: [],
  [BlockTypes.reverseBlock]: [],
  [BlockTypes.productDescription]: [],
  [BlockTypes.about]: [],
  [BlockTypes.productpagination]: [],
  [BlockTypes.productdetailsview]: [],
  [BlockTypes.coupondetailsview]: [],
  [BlockTypes.banner1]: [],
  [BlockTypes.textEditor]: [],
  [BlockTypes.banner2]: [],
  [BlockTypes.banner3]: [],
  [BlockTypes.banner4]: [],
  [BlockTypes.banner1Carousel]: [],
  [BlockTypes.addsCarousel]: [],
  [BlockTypes.addsCarouselSmall]: [],
  [BlockTypes.bannerproducts]: [],
  [BlockTypes.bannerproducts2]: [],
  [BlockTypes.bannerproducts3]: [],
  [BlockTypes.catalogboombuy]: [],
  [BlockTypes.catalogboombuyzara]: [],
  [BlockTypes.catalogboombuyzara2]: [],
  [BlockTypes.categoriesboomBuy]: [],
  [BlockTypes.mainmainbanner]: [],
  [BlockTypes.mainzarabanner]: [],
  [BlockTypes.productboombuydatailsview]: [],
  [BlockTypes.headbanner]: [],
  [BlockTypes.headzarabanner]: [],
  [BlockTypes.headzboombuybanner]: [],
  [BlockTypes.pastimebanner]: [],
  [BlockTypes.voucherbanner]: [],
  [BlockTypes.attractionscoupons]: [],
  [BlockTypes.bigbannerhappygift]: [],
  [BlockTypes.search]: [],
  [BlockTypes.specialcategories]: [],
  [BlockTypes.allshopbanners]: [],
  [BlockTypes.cardholderbanner]: [],
  [BlockTypes.sixtableelements]: [],
  [BlockTypes.clubcards]: [],
  [BlockTypes.fourcolumnelements]: [],
  [BlockTypes.threecolumnscardinfowithicons]: [],
  [BlockTypes.titleandsubtitle]: [
    {
      name: 'titleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Title Style',
      fields: [
        {
          name: 'contentTitleTextColor',
          label: 'Content Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentTitleFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '32px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentTitleFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentTitleFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentTitleTextDecoration',
          label: 'Content Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'subtitleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Subtitle Style',
      fields: [
        {
          name: 'contentSubtitleTextColor',
          label: 'Content Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentSubtitleFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '14px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentSubtitleFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentSubtitleFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentSubtitleTextDecoration',
          label: 'Content Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'forceRtl',
      label: 'Force RTL',
      type: FieldTypes.checkbox,
      defaultValue: false,
      rowSize: 2,
    },
  ],
  [BlockTypes.threecolumncardinfowithicons]: [
    {
      name: 'titleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'First Column Title Style',
      fields: [
        {
          name: 'contentFirstTextColor',
          label: 'Content First Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentFirstFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentFirstFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentFirstFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentFirstTextDecoration',
          label: 'Content Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'contentFirstTextAlignItems',
          label: 'Content First Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'titleSubtitleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'First Column Subtitle Style',
      fields: [
        {
          name: 'contentFirstSubtitleColor',
          label: 'Content First Subtitle ext color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentFirstSubtitleFontSize',
          label: 'Content Subtitle Font Size',
          type: FieldTypes.select,
          defaultValue: '12px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentFirstSubtitleFontWeight',
          label: 'Content Subtitle Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentFirstSubtitleFontStyle',
          label: 'Content Subtitle Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentFirstSubtitleDecoration',
          label: 'Content Subtitle Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'contentFirstSubtitleAlignItems',
          label: 'Content First Subtitle Decoration',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'secondStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Second Column Title Style',
      fields: [
        {
          name: 'contentSecondTextColor',
          label: 'Content Second Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentSecondFontSize',
          label: 'Content Second Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentSecondFontWeight',
          label: 'Content Second Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentSecondFontStyle',
          label: 'Content Second Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentSecondTextDecoration',
          label: 'Content Second Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'contentSecondTextAlignItems',
          label: 'Content Second Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'secondSubtitleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Second Column Subtitle Style',
      fields: [
        {
          name: 'contentSecondSubtitleColor',
          label: 'Content Second Subtitle Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentSecondSubtitleFontSize',
          label: 'Content Second Subtitle Font Size',
          type: FieldTypes.select,
          defaultValue: '12px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentSecondSubtitleFontWeight',
          label: 'Content Second Subtitle Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentSecondSubtitleFontStyle',
          label: 'Content Second Subtitle Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentSecondSubtitleDecoration',
          label: 'Content SecondSubtitle Subtitle Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'contentSecondSubtitleAlignItems',
          label: 'Content Second Subtitle Decoration',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'thirdStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Third Column Title Style',
      fields: [
        {
          name: 'contentThirdTextColor',
          label: 'Content Third Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentThirdFontSize',
          label: 'Content Third Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentThirdFontWeight',
          label: 'Content Third Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentThirdFontStyle',
          label: 'Content Third Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentThirdTextDecoration',
          label: 'Content Third Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'contentThirdTextAlignItems',
          label: 'Content Third Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'thirdSubtitleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Third Column Subtitle Style',
      fields: [
        {
          name: 'contentThirdSubtitleColor',
          label: 'Content Third Subtitle Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentThirdSubtitleFontSize',
          label: 'Content Third Subtitle Font Size',
          type: FieldTypes.select,
          defaultValue: '12px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentThirdSubtitleFontWeight',
          label: 'Content Third Subtitle Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentThirdSubtitleFontStyle',
          label: 'Content Third Subtitle Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentThirdSubtitleDecoration',
          label: 'Content Third Subtitle Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'contentThirdSubtitleAlignItems',
          label: 'Content Third Subtitle Decoration',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'otherStyles',
      rowSize: 4,
      type: FieldTypes.group,
      label: 'Other Style',
      fields: [
        {
          name: 'cardsHeight',
          type: FieldTypes.input,
          defaultValue: '200',
          label: 'Cards Height',
          rowSize: 2,
        },
        {
          name: 'forceRtl',
          label: 'Force RTL',
          type: FieldTypes.checkbox,
          defaultValue: false,
          rowSize: 2,
        },
        {
          name: 'imagesSize',
          label: 'Images Size',
          type: FieldTypes.select,
          defaultValue: percentOptions[1].value,
          options: percentOptions,
          rowSize: 2,
        },
      ],
    },
    {
      name: 'buttonStyles',
      rowSize: 4,
      type: FieldTypes.group,
      label: 'Button Style',
      fields: [
        {
          name: 'buttonTextColor',
          label: 'Button Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.contrastText',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'buttonTextFontSize',
          label: 'Button Text Font Size',
          type: FieldTypes.select,
          defaultValue: '14px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'buttonTextFontWeight',
          label: 'Button Text Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'buttonTextFontStyle',
          label: 'Button Text Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'buttonTextDecoration',
          label: 'Button Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'buttonBackgroundColor',
          type: FieldTypes.select,
          defaultValue: colorOptions[7].value,
          label: 'Button Background color',
          isColor: true,
          rowSize: 4,
          options: colorOptions,
        },
        {
          name: 'buttonBorderRadius',
          type: FieldTypes.select,
          defaultValue: borderSizes[18].value,
          label: 'Button Border',
          rowSize: 4,
          options: borderSizes,
        },
      ],
    },
  ],
  [BlockTypes.customtestimonials]: [
    {
      name: 'TextStyles',
      rowSize: 4,
      type: FieldTypes.group,
      label: 'Text Style',
      fields: [
        {
          name: 'contentTitleTextColor',
          label: 'Content Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentTitleFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '12px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentTitleFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentTitleFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentTitleTextDecoration',
          label: 'Content Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'contentTextAlignItems',
          label: 'Content First Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
        {
          name: 'forceRtl',
          label: 'Force RTL',
          type: FieldTypes.checkbox,
          defaultValue: false,
          rowSize: 2,
        },
      ],
    },
  ],
  [BlockTypes.searchbycategory]: [
    {
      name: 'titleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Title Style',
      fields: [
        {
          name: 'contentTitleTextColor',
          label: 'Content Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentTitleFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '32px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentTitleFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentTitleFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentTitleTextDecoration',
          label: 'Content Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'titleStylesAlignItems',
          label: 'Title Decoration',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'imagesTextStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Images Text Style',
      fields: [
        {
          name: 'imagesTitleTextColor',
          label: 'Images Text  color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'imagesTitleFontSize',
          label: 'Images Text Font Size',
          type: FieldTypes.select,
          defaultValue: '32px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'imagesTitleFontWeight',
          label: 'Images Text Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'imagesTitleFontStyle',
          label: 'Images Text Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'imagesTitleTextDecoration',
          label: 'Images Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'imagesStylesAlignItems',
          label: 'Images Text Align',
          type: FieldTypes.select,
          defaultValue: 'center',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'layoutSettings',
      label: 'Layout Settings',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'enableButtons',
          type: FieldTypes.checkbox,
          defaultValue: true,
          label: 'Enable buttons',
          rowSize: 2,
        },
        {
          name: 'enableTitle',
          type: FieldTypes.checkbox,
          defaultValue: true,
          label: 'Enable title',
          rowSize: 2,
        },
      ],
    },
    {
      name: 'classes',
      label: 'Extra classes',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'bannerClasses',
          type: FieldTypes.input,
          label: 'Banner classes',
          rowSize: 4,
        },
        {
          name: 'contentClasses',
          type: FieldTypes.input,
          label: 'Content classes',
          rowSize: 4,
        },
      ],
    },
  ],

  [BlockTypes.forcolumnelements]: [
    {
      name: 'titleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'First Column Title Style',
      fields: [
        {
          name: 'contentFirstTextColor',
          label: 'Content First Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentFirstFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentFirstFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentFirstFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentFirstTextDecoration',
          label: 'Content Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'titleSubtitleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'First Column Subtitle Style',
      fields: [
        {
          name: 'contentFirstSubtitleColor',
          label: 'Content First Subtitle ext color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentFirstSubtitleFontSize',
          label: 'Content Subtitle Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentFirstSubtitleFontWeight',
          label: 'Content Subtitle Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentFirstSubtitleFontStyle',
          label: 'Content Subtitle Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentFirstSubtitleDecoration',
          label: 'Content Subtitle Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'secondStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Second Column Title Style',
      fields: [
        {
          name: 'contentSecondTextColor',
          label: 'Content Second Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentSecondFontSize',
          label: 'Content Second Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentSecondFontWeight',
          label: 'Content Second Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentSecondFontStyle',
          label: 'Content Second Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentSecondTextDecoration',
          label: 'Content Second Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'secondSubtitleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Second Column Subtitle',
      fields: [
        {
          name: 'contentSecondSubtitleColor',
          label: 'Content Second Subtitle Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentSecondSubtitleFontSize',
          label: 'Content Second Subtitle Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentSecondSubtitleFontWeight',
          label: 'Content Second Subtitle Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentSecondSubtitleFontStyle',
          label: 'Content Second Subtitle Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentSecondSubtitleDecoration',
          label: 'Content SecondSubtitle Subtitle Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'thirdStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Third Column Title Style',
      fields: [
        {
          name: 'contentThirdTextColor',
          label: 'Content Third Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentThirdFontSize',
          label: 'Content Third Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentThirdFontWeight',
          label: 'Content Third Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentThirdFontStyle',
          label: 'Content Third Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentThirdTextDecoration',
          label: 'Content Third Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'thirdSubtitleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Third Column Subtitle Style',
      fields: [
        {
          name: 'contentThirdSubtitleColor',
          label: 'Content Third Subtitle Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentThirdSubtitleFontSize',
          label: 'Content Third Subtitle Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentThirdSubtitleFontWeight',
          label: 'Content Third Subtitle Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentThirdSubtitleFontStyle',
          label: 'Content Third Subtitle Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentThirdSubtitleDecoration',
          label: 'Content Third Subtitle Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'fourthStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Fourth Column Title Style',
      fields: [
        {
          name: 'contentFourthTextColor',
          label: 'Content Fourth Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentFourthFontSize',
          label: 'Content Fourth Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentFourthFontWeight',
          label: 'Content Fourth Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentFourthFontStyle',
          label: 'Content Fourth Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentFourthTextDecoration',
          label: 'Content Fourth Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'fourthSubtitleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Fourth Column Subtitle',
      fields: [
        {
          name: 'contentFourthSubtitleColor',
          label: 'Content Fourth Subtitle Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentFourthSubtitleFontSize',
          label: 'Content Fourth Subtitle Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentFourthSubtitleFontWeight',
          label: 'Content Fourth Subtitle Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentFourthSubtitleFontStyle',
          label: 'Content Fourth Subtitle Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentFourthSubtitleDecoration',
          label: 'Content Fourth Subtitle Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'zipLineStyles',
      rowSize: 4,
      type: FieldTypes.group,
      label: 'Zip Line Styles',
      fields: [
        {
          name: 'firstColor',
          type: FieldTypes.select,
          defaultValue: colorTextOptions[0].value,
          label: 'First Color',
          rowSize: 2,
          options: colorTextOptions,
        },
        {
          name: 'secondColor',
          type: FieldTypes.select,
          defaultValue: colorTextOptions[0].value,
          label: 'Second Color',
          rowSize: 2,
          options: colorTextOptions,
        },
        {
          name: 'thirdColor',
          type: FieldTypes.select,
          defaultValue: colorTextOptions[0].value,
          label: 'Third Color',
          rowSize: 2,
          options: colorTextOptions,
        },
        {
          name: 'fourthColor',
          type: FieldTypes.select,
          defaultValue: colorTextOptions[0].value,
          label: 'Fourth Color',
          rowSize: 2,
          options: colorTextOptions,
        },
      ],
    },

    {
      name: 'forceRtl',
      label: 'Force RTL',
      type: FieldTypes.checkbox,
      defaultValue: false,
      rowSize: 2,
    },
  ],
  [BlockTypes.titleandzipline]: [
    {
      name: 'fourthSubtitleStyles',
      rowSize: 4,
      type: FieldTypes.group,
      label: 'Title Style',
      fields: [
        {
          name: 'contentColor',
          label: 'Content Text color',
          type: FieldTypes.select,
          defaultValue: textColorOptions[6].value,
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '35px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 1000,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        // {
        //   name: 'contentDecoration',
        //   label: 'Content Decoration',
        //   type: FieldTypes.select,
        //   defaultValue: 'none',
        //   rowSize: 4,
        //   options: textDecorations
        // },
      ],
    },
    {
      name: 'zipLineStyles',
      rowSize: 4,
      type: FieldTypes.group,
      label: 'Zip Line Style',
      fields: [
        {
          name: 'color',
          type: FieldTypes.select,
          defaultValue: colorTextOptions[0].value,
          label: 'Color',
          rowSize: 2,
          options: colorTextOptions,
        },
        {
          name: 'width',
          type: FieldTypes.select,
          defaultValue: percentOptions[4].value,
          label: 'Width',
          rowSize: 2,
          options: percentOptions,
        },
      ],
    },
  ],
  [BlockTypes.brandscarousel]: [],
  [BlockTypes.standardverticalbanner]: [...verticalBannerSettings],
  [BlockTypes.threecolumnsinfowithIcons]: [],
  [BlockTypes.fontSettings]: [],
  [BlockTypes.cardsbannerwithbutton]: [],
  [BlockTypes.allshopbanners2]: [],
  [BlockTypes.allshopbanners22]: [],
  [BlockTypes.mainxtramainbanner]: [],
  [BlockTypes.mainxtramainbanner2]: [],
  [BlockTypes.mainxtramainbanner3]: [],
  [BlockTypes.vouchersv1]: [],
  [BlockTypes.vouchersv2]: [],
  [BlockTypes.vouchersv3]: [],
  [BlockTypes.brandscarousel2]: [],
  [BlockTypes.couponsv2]: [],
  [BlockTypes.bigbannerelements]: [],
  [BlockTypes.logocomponent]: [],
  [BlockTypes.AllHappyHoldersBanners]: [],
  [BlockTypes.SpecialCategoriesHappyHolders]: [],
  [BlockTypes.greybanner]: [],
  [BlockTypes.blackbanner]: [],
  [BlockTypes.MainBannerHolders]: [],
  [BlockTypes.merchant]: [],
  [BlockTypes.merchants]: [],
  [BlockTypes.networks]: [],
  [BlockTypes.filterv2]: [],
  [BlockTypes.popup]: [],
  [BlockTypes.accessRestrictions]: [],
  [BlockTypes.providers]: [],
  [BlockTypes.provider]: [],
  [BlockTypes.allProviders]: [],
  [BlockTypes.carouselStandardHorizontalBanner]: [],
  [BlockTypes.bannerwithbalancecitypeople]: [
    {
      name: 'settings',
      rowSize: 4,
      type: FieldTypes.group,
      label: 'Settings',
      fields: [
        {
          name: 'enableButton',
          label: 'Enable button',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
        {
          name: 'enableTitle',
          label: 'Enable title',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
        {
          name: 'enableSubtitle',
          label: 'Enable subtitle',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
      ],
    },
    {
      name: 'titleStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Title Style',
      fields: [
        {
          name: 'contentTitleTextColor',
          label: 'Content Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentTitleFontSize',
          label: 'Content Font Size',
          type: FieldTypes.select,
          defaultValue: '25px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentTitleFontWeight',
          label: 'Content Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentTitleFontStyle',
          label: 'Content Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentTitleTextDecoration',
          label: 'Content Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'contentTextAlignItems',
          label: 'Content Text Align',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'headingStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Heading Style',
      fields: [
        {
          name: 'headingTitleTextColor',
          label: 'Heading Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'headingTitleFontSize',
          label: 'Heading Font Size',
          type: FieldTypes.select,
          defaultValue: '15px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'headingTitleFontWeight',
          label: 'Heading Font Weight',
          type: FieldTypes.select,
          defaultValue: 800,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'headingTitleFontStyle',
          label: 'Heading Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'headingTitleTextDecoration',
          label: 'Heading Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
        {
          name: 'headingTextAlignItems',
          label: 'Heading Text Align',
          type: FieldTypes.select,
          defaultValue: 'end',
          rowSize: 4,
          options: textDecorationsAlign,
        },
      ],
    },
    {
      name: 'alignStyles',
      rowSize: 4,
      type: FieldTypes.group,
      label: 'Align Style',
      fields: [
        {
          name: 'position',
          label: 'Position',
          type: FieldTypes.radiogroup,
          defaultValue: 'end',
          rowSize: 2,
          options: [
            { label: 'Start', value: 'flex-start' },
            { label: 'Center', value: 'center' },
            { label: 'End', value: 'flex-end' },
          ],
        },
        {
          name: 'contentAlign',
          label: 'Content alignment',
          type: FieldTypes.radiogroup,
          defaultValue: 'start',
          rowSize: 2,
          options: [
            { label: 'Start', value: 'start' },
            { label: 'Center', value: 'center' },
            { label: 'End', value: 'end' },
          ],
        },
      ],
    },
  ],
  [BlockTypes.apartments]: [],
  [BlockTypes.onebigbutton]: [
    {
      name: 'layoutSettings',
      label: 'Layout Settings',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'enableButtons',
          type: FieldTypes.checkbox,
          defaultValue: true,
          label: 'Enable buttons',
          rowSize: 2,
        },
      ],
    },
    {
      name: 'classes',
      label: 'Extra classes',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'bannerClasses',
          type: FieldTypes.input,
          label: 'Banner classes',
          rowSize: 4,
        },
      ],
    },
    {
      name: 'buttonColor',
      type: FieldTypes.select,
      defaultValue: buttonColorOptions[6].value, // secondary
      label: 'Button color',
      isColor: true,
      rowSize: 2,
      options: buttonColorOptions,
    },
    {
      name: 'buttonWidth',
      type: FieldTypes.select,
      defaultValue: percentOptions[4].value,
      label: 'Button width',
      isColor: true,
      rowSize: 2,
      options: percentOptions,
    },
    {
      name: 'buttonMinWidth',
      label: 'Min Button Width',
      type: FieldTypes.number,
      defaultValue: 100,
      rowSize: 4,
    },
    {
      name: 'buttonHoverColor',
      type: FieldTypes.select,
      defaultValue: colorOptions[7].value,
      label: 'Button Hover color',
      isColor: true,
      rowSize: 2,
      options: colorOptions,
    },
    {
      name: 'withArrowIcon',
      type: FieldTypes.checkbox,
      defaultValue: false,
      label: 'Arrow Icon',
      rowSize: 2,
    },
  ],
  [BlockTypes.totalinfocitypeople]: [],
  [BlockTypes.servicelistcitypeople]: [],
  [BlockTypes.singleservicecitypeople]: [],
  [BlockTypes.balancecitypeople]: [],
  [BlockTypes.categoriesslider]: [],
  [BlockTypes.companylistcitypeople]: [],
  [BlockTypes.companydescription]: [],
  [BlockTypes.ordercitypeople]: [],
  [BlockTypes.historycitypeople]: [],
  [BlockTypes.supplierhistorycitypeople]: [],
  [BlockTypes.historysearchcitypeople]: [],
  [BlockTypes.bannerwithbackgroundpic]: [
    {
      name: 'backgroundImage',
      type: FieldTypes.upload,
      uploadType: 'image',
      rowSize: 2,
      label: 'Background image',
      defaultValue: '',
    },
    {
      name: 'bannerImage',
      type: FieldTypes.upload,
      uploadType: 'image',
      rowSize: 2,
      label: 'Banner image',
      defaultValue: '',
    },
  ],
  [BlockTypes.footercitypeople]: [
    {
      name: 'backgroundColor',
      type: FieldTypes.select,
      defaultValue: colorOptions[7].value,
      label: 'Background color',
      isColor: true,
      rowSize: 4,
      options: colorOptions,
    },
    {
      name: 'textColor',
      type: FieldTypes.select,
      defaultValue: textColorOptions[1].value,
      label: 'Label color',
      isColor: true,
      rowSize: 2,
      options: textColorOptions,
    },
    {
      name: 'fontSizeLabel',
      label: 'Label Font Size',
      type: FieldTypes.select,
      defaultValue: '14px',
      rowSize: 2,
      options: fontSizes,
    },
    {
      name: 'iconSize',
      label: 'Icon Size',
      type: FieldTypes.select,
      defaultValue: '35px',
      rowSize: 2,
      options: fontSizes,
    },
    {
      name: 'borderSettings',
      label: 'Border Settings',
      type: FieldTypes.group,
      rowSize: 4,
      fields: [
        {
          name: 'hasBorderRadius',
          label: 'Has Border Radius',
          type: FieldTypes.checkbox,
          defaultValue: true,
          rowSize: 2,
        },
      ],
    },
  ],
  [BlockTypes.faq]: [
    {
      name: 'questionStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Question Style',
      fields: [
        {
          name: 'contentQuestionTextColor',
          label: 'Question text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentQuestionFontSize',
          label: 'Question Font Size',
          type: FieldTypes.select,
          defaultValue: '18px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentQuestionFontWeight',
          label: 'Question Font Weight',
          type: FieldTypes.select,
          defaultValue: 600,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentQuestionFontStyle',
          label: 'Question Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentQuestionTextDecoration',
          label: 'Question Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
    {
      name: 'answerStyles',
      rowSize: 2,
      type: FieldTypes.group,
      label: 'Answer Style',
      fields: [
        {
          name: 'contentAnswerTextColor',
          label: 'Answer Text color',
          type: FieldTypes.select,
          defaultValue: 'primary.main',
          rowSize: 4,
          options: textColorOptions,
          isColor: true,
        },
        {
          name: 'contentAnswerFontSize',
          label: 'Answer Font Size',
          type: FieldTypes.select,
          defaultValue: '14px',
          rowSize: 4,
          options: fontSizes,
        },
        {
          name: 'contentAnswerFontWeight',
          label: 'Answer Font Weight',
          type: FieldTypes.select,
          defaultValue: 400,
          rowSize: 4,
          options: fontWeights,
        },
        {
          name: 'contentAnswerFontStyle',
          label: 'Answer Font Style',
          type: FieldTypes.select,
          defaultValue: 'normal',
          rowSize: 4,
          options: fontStyles,
        },
        {
          name: 'contentAnswerTextDecoration',
          label: 'Answer Text Decoration',
          type: FieldTypes.select,
          defaultValue: 'none',
          rowSize: 4,
          options: textDecorations,
        },
      ],
    },
  ],
  [BlockTypes.searchSection]: [],
  [BlockTypes.blogs]: [],
  [BlockTypes.newsletter]: [],
  [BlockTypes.reportBlock]: [],
  [BlockTypes.listingReports]: [],
  [BlockTypes.blogsTextAfter]: [],
  [BlockTypes.blog]: [],
  [BlockTypes.headActiveBanner]: [],
  [BlockTypes.bannersCarousel]: [],
  [BlockTypes.photoDescription]: [],
  [BlockTypes.networkInfo]: [],
  [BlockTypes.indentationBlock]: [],
  [BlockTypes.dividerBlock]: [],
  [BlockTypes.exchangeOption]: [],
  [BlockTypes.paymentMethods]: [],
  [BlockTypes.iframe]: [],
  [BlockTypes.buttonInfoBanner]: [],
  [BlockTypes.contactcitypeople]: [],
  [BlockTypes.infoField]: [],
  [BlockTypes.services]: [],
  [BlockTypes.service]: [],
  [BlockTypes.ourServices]: [],
  [BlockTypes.ourServicesAdd]: [],
  [BlockTypes.serviceInfo]: [],
  [BlockTypes.specialist]: [],
  [BlockTypes.specialists]: [],
  [BlockTypes.confirm]: [],
  [BlockTypes.appointment]: [],
  [BlockTypes.titleAndButton]: [],
  [BlockTypes.searchComponent]: [],
  [BlockTypes.marketPlace]: [],
  [BlockTypes.productDetails]: [],
  [BlockTypes.addToCart]: [],
  [BlockTypes.notifications]: [],
  [BlockTypes.myProfile]: [],
  [BlockTypes.specialistsList]: [],
};
