import { FieldTypes, SettingsField } from 'src/types/page-generator';

import commonContentFields from '../../common/contentFields';

const contentFields: SettingsField[] = [
  ...commonContentFields,
  {
    name: 'lead',
    type: FieldTypes.input,
    defaultValue: '',
    label: 'Lead V1',
    rowSize: 2,
  },
];

export default contentFields;
