import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Box } from '@mui/material';
import Image from '../../../../components/image';
import { useLocales } from '../../../../locales';
import { BlockType } from '../../../../types/generator';

type ShopBannerBlockType = {
  firstImage: string;
  firstIcon: string;
  firstTitle: string;
  firstContentText: string;
  secondImage: string;
  secondIcon: string;
  secondTitle: string;
  secondContentText: string;
  thirdImage: string;
  thirdIcon: string;
  thirdTitle: string;
  thirdContentText: string;
};
type ShopBannerProps = { block: BlockType & ShopBannerBlockType };

const ThreeColumnsIconInfo: FC<ShopBannerProps> = ({ block }) => {
  const { currentLang } = useLocales();
  const {
    firstImage,
    firstIcon,
    firstTitle,
    firstContentText,
    secondImage,
    secondIcon,
    secondTitle,
    secondContentText,
    thirdImage,
    thirdIcon,
    thirdTitle,
    thirdContentText,
  } = block || {};

  const data = [
    {
      id: 1,
      title: firstTitle ?? 'בטיחות',
      description:
        firstContentText ??
        'כסף מאובטח ושמור! כרטיס הפי גיפט מאובטח בתקני האבטחה הגבוהים ביותר ובהפקדה יתרה על תנאי אבטחה ושמירת הכסף בשבילכם.',
      icon: firstIcon ?? '/assets/images/happy-gift/ic_payment_b2c.svg',
      image: firstImage ?? '/assets/images/happy-gift/banners/cardholder.png',
    },
    {
      id: 2,
      title: secondTitle ?? 'ללא גבולות',
      description:
        secondContentText ??
        'אפשרות למימוש בכל העולם! הפי גיפט אינו מגביל אתכם במימוש ההטבה בארץ ומאפשר מימוש בכל העולם ככרטיס אוניברסלי.',
      icon: secondIcon ?? '/assets/images/happy-gift/ic_assurances_b2c.svg',
      image: secondImage ?? '/assets/images/happy-gift/banners/cardholder.png',
    },
    {
      id: 3,
      title: thirdTitle ?? 'כל בתי עסק',
      description:
        thirdContentText ??
        'אין הגבלת בתי עסק למימוש! עם הפי גיפט תוכלו לרכוש ולממש את המתנה בכל בית עסק שמכבד כרטיסי מאסטר קארד. בכל מקום באונליין ובחנויות.',
      icon: thirdIcon ?? '/assets/images/happy-gift/ic_refund_b2c.svg',
      image: thirdImage ?? '/assets/images/happy-gift/banners/cardholder.png',
    },
  ];

  return (
    <Grid container spacing={1}>
      {data.map((item, id) => (
        <Grid key={id} item xs={4} alignItems="center">
          <Box width="100%" sx={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={item.image} sx={{ width: '50%' }} />
          </Box>
          <Stack
            alignItems="center"
            ml={1}
            sx={{
              borderRight: id !== 0 ? '1px solid' : null,
              borderColor: 'primary.main',
            }}
          >
            <Image src={item.icon} width="20%" />
            <Typography
              sx={{
                '& h1': { my: 1 },
                '& h2': { my: 1 },
                '& h3': { my: 1 },
                '& h4': { my: 1 },
                '& h5': { my: 1 },
                '& h6': { my: 1 },
                '& p': { margin: 0 },
                direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                // color: 'primary.main',
                // fontSize: '30px',
                // fontWeight: 600,
                my: 2,
              }}
            >
              {/* {item.title} */}
              <span dangerouslySetInnerHTML={{ __html: item?.title }} />
            </Typography>
            <Typography
              sx={{
                '& h1': { my: 1 },
                '& h2': { my: 1 },
                '& h3': { my: 1 },
                '& h4': { my: 1 },
                '& h5': { my: 1 },
                '& h6': { my: 1 },
                '& p': { margin: 0 },
                direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                // color: 'primary.main',
                // fontSize: '15px',
                // fontWeight: 400,
                width: '70%',
              }}
            >
              {/* {item.description} */}
              <span dangerouslySetInnerHTML={{ __html: item?.description }} />
            </Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default ThreeColumnsIconInfo;
