import Link from 'next/link';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import Scrollbar from '../scrollbar';
import { useTranslate } from '../../locales';
import { Ib2bProduct } from '../../types/b2b';
import BasicModal from '../modals/basic-modal';
import { useRouter } from '../../routes/hooks';
import SanitizeHTML from '../../utils/sanitize';
import { IAsterisk } from '../../types/asterisk';
import { useBoolean } from '../../hooks/use-boolean';
import { BlockType } from '../../types/page-generator';
import { IBranche, INetwork } from '../../types/networks';
import { useResponsive } from '../../hooks/use-responsive';
import { uploadProductsImage } from '../../helpers/uploadProductsImage';

type Props = {
  network: INetwork;
  extraButton?: React.ReactNode;
  isBuilder?: boolean;
  product?: Ib2bProduct;
  block?: BlockType;
};

const NetworkCard = ({ network, extraButton, isBuilder, product, block }: Props) => {
  const { t } = useTranslate();
  const smUp = useResponsive('up', 'sm');
  const router = useRouter();
  const branches = useBoolean();
  const details = useBoolean();
  const [currentNetworks, setCurrentNetworks] = useState<IBranche[]>([]);
  const imageSrc = network?.mainImageURL
    ? uploadProductsImage(network?.mainImageURL)
    : '/assets/images/network-default.png';
  const cleanDescription = network?.description?.replace(/<\/?[^>]+(>|$)/g, '') || '';
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const array: IBranche[] = [];
    const addedNetworkIds = new Set<string>();

    network?.branches?.forEach((item: IBranche) => {
      if (!item.networkId || !addedNetworkIds.has(item.networkId)) {
        array.push(item);
        if (item.networkId) {
          addedNetworkIds.add(item.networkId);
        }
      }
    });
    setCurrentNetworks(array);
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const getBranchAsterisk = useCallback(
    (branch: IBranche): IAsterisk | null => {
      const merchantAsterisks = branch.asterisks;

      if (Array.isArray(merchantAsterisks) && merchantAsterisks.length) {
        return merchantAsterisks[0];
      }

      const merchantCategories = branch.categories;

      if (Array.isArray(merchantCategories) && merchantCategories.length) {
        const merchantCategoryAsterisks = merchantCategories[0].asterisks;

        if (Array.isArray(merchantCategoryAsterisks) && merchantCategoryAsterisks.length) {
          return merchantCategoryAsterisks[0];
        }
      }

      if (Array.isArray(network.asterisks) && network.asterisks.length) {
        return network.asterisks[0];
      }

      return null;
    },
    [network]
  );

  const networkAsterisk = useMemo((): IAsterisk | null => {
    if (Array.isArray(network?.asterisks) && network.asterisks.length) {
      return network.asterisks[0];
    }
    return null;
  }, [network]);

  const openInfo = () => {
    if (block?.settings?.variantViewInfo === 'popup') {
      branches.onTrue();
    } else if (block?.settings?.variantViewInfo === 'page') {
      router.push(`/network/${network?.id}`);
    }
  };

  return (
    <Card
      sx={{
        p: 1,
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Image
        src={imageSrc}
        sx={{
          borderRadius: '5px',
          height: 100,
          '& img': { objectFit: 'contain' },
        }}
      />
      <Typography
        sx={{
          my: 1,
          height: '20px',
          color: 'primary.main',
          fontWeight: 600,
        }}
      >
        {network.name}
      </Typography>
      <Stack
        direction="row-reverse"
        alignItems="baseline"
        justifyContent="space-around"
        width={1}
        gap={1}
      >
        {network?.branches?.length ? (
          <Button
            variant="outlined"
            color="primary"
            sx={{
              mt: 3,
              borderRadius: '20px',
              color: 'primary.main',
              px: 4,
            }}
            onClick={() => openInfo()}
          >
            {t('Branches')}
          </Button>
        ) : (
          ''
        )}
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            mt: 3,
            borderRadius: '20px',
            color: 'primary.main',
            px: 4,
          }}
          onClick={details.onTrue}
        >
          {t('Details')}
        </Button>
        {!!extraButton && extraButton}
      </Stack>
      <BasicModal open={branches.value} onClose={() => branches.onFalse()}>
        <Stack alignItems="flex-start" sx={{ '& .rtl-jjtu05': { width: '100%' } }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
            סניפים
          </Typography>
          <TextField
            sx={{ py: 2 }}
            fullWidth
            placeholder="חיפוש..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  {searchText !== '' && (
                    <Iconify
                      icon="solar:trash-bin-trash-bold"
                      color="red"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setSearchText('')}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={handleSearchChange}
          />
          <Scrollbar sx={{ height: smUp ? '400px' : '150px', width: '100%' }}>
            <Stack gap={2} height="450px">
              {network?.branches?.map((branch: IBranche, id: number) => {
                const image = branch?.mainImageURL
                  ? uploadProductsImage(branch?.mainImageURL || '')
                  : '/assets/images/network-default.png';
                const branchAsterisk = getBranchAsterisk(branch);

                if (branch.title.includes(searchText) || branch.content.includes(searchText)) {
                  return (
                    <Stack key={id} sx={{ my: 1 }}>
                      <Image
                        onClick={() => router.push(`/merchant/${branch?.id}`)}
                        src={uploadProductsImage(image)}
                        sx={{ '& img': { width: '100px' } }}
                      />
                      <Typography
                        variant="h4"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => router.push(`/merchant/${branch?.id}`)}
                      >
                        {branch.title}
                      </Typography>
                      <Typography sx={{ color: 'grey.600' }}>
                        <SanitizeHTML html={branch.content} />
                      </Typography>
                      <Link href={branch?.url || ''} target="_blank">
                        <Typography sx={{ textDecoration: 'underline' }}>
                          לאתר בית העסק לחץ כאן
                        </Typography>
                      </Link>
                      {branchAsterisk && (
                        <Typography
                          sx={{
                            textAlign: 'start',
                            width: '100%',
                            fontSize: '10px',
                            mt: 1,
                            height: '10px',
                            color: 'red',
                          }}
                        >
                          {!!branchAsterisk && '*'}
                          {branchAsterisk.description}
                        </Typography>
                      )}
                    </Stack>
                  );
                }
                return null;
              })}
            </Stack>
          </Scrollbar>
        </Stack>
      </BasicModal>
      <BasicModal open={details.value} onClose={() => details.onFalse()}>
        <Stack alignItems="flex-start">
          <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
            {network.name || ''}
          </Typography>
          <Image src={imageSrc} sx={{ '& img': { width: '150px' } }} />
          <Stack
            direction="row-reverse"
            justifyContent="space-between"
            width={1}
            alignItems="center"
          >
            {network?.url && (
              <Stack direction="row-reverse" gap={2} sx={{ color: 'secondary.main' }}>
                <Link href={network?.url}>
                  <Typography sx={{ textDecoration: 'underline' }}>
                    לאתר בית העסק לחץ כאן
                  </Typography>
                  <Iconify icon="fa-solid:globe" />
                </Link>
              </Stack>
            )}
            <Typography>{cleanDescription}</Typography>
            <SanitizeHTML html={network?.content} />
          </Stack>
        </Stack>
        {networkAsterisk && (
          <Typography
            sx={{
              textAlign: 'start',
              width: '100%',
              fontSize: '10px',
              mt: 1,
              height: '10px',
              color: 'red',
            }}
          >
            {!!networkAsterisk && '*'}
            {networkAsterisk.description}
          </Typography>
        )}
      </BasicModal>
    </Card>
  );
};

export default NetworkCard;
