import { memo } from 'react';

import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function WalletMenuIcon({ ...other }: BoxProps) {
  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M8.91895 15H14.8651"
        stroke="#004454"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9692 16.5H27.1005C24.4481 16.5 22.2979 18.5146 22.2979 21C22.2979 23.4854 24.4481 25.5 27.1005 25.5H30.9692C31.0932 25.5 31.155 25.5 31.2074 25.4969C32.009 25.4475 32.6475 24.8493 32.7001 24.0981C32.7035 24.049 32.7035 23.991 32.7035 23.8751V18.1249C32.7035 18.009 32.7035 17.9509 32.7001 17.9019C32.6475 17.1507 32.009 16.5525 31.2074 16.5031C31.155 16.5 31.0932 16.5 30.9692 16.5Z"
        stroke="#004454"
        strokeWidth="1.5"
      />
      <path
        d="M31.1651 16.5C31.0496 13.6915 30.677 11.9696 29.4755 10.7574C27.7341 9 24.9309 9 19.3249 9H14.8654C9.25931 9 6.4563 9 4.71472 10.7574C2.97314 12.5147 2.97314 15.3432 2.97314 21C2.97314 26.6568 2.97314 29.4853 4.71472 31.2426C6.4563 33 9.25931 33 14.8654 33H19.3249C24.9309 33 27.7341 33 29.4755 31.2426C30.677 30.0304 31.0496 28.3085 31.1651 25.5"
        stroke="#004454"
        strokeWidth="1.5"
      />
      <path
        d="M8.91895 9L14.4719 5.2847C16.0355 4.23843 18.1541 4.23843 19.7178 5.2847L25.2707 9"
        stroke="#004454"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M26.7446 21H26.7581"
        stroke="#004454"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Box>
  );
}

export default memo(WalletMenuIcon);
