import { API } from '../helpers/api';
import { IUserAccount } from '../types/user';

type UploadFile = File & { preview: any };

export const uploadFile = async (file: UploadFile) => {
  try {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const { data } = await API({
        url: '/upload',
        method: 'POST',
        data: formData,
      });

      const { fileName } = data;

      return `${process.env.NEXT_PUBLIC_HOST_API}/${fileName}`;
    }
  } catch (err) {
    console.error(err);
    return '';
  }

  return '';
};

export const fileManager = async (
  files: File[],
  path: string,
  ownerDetails: IUserAccount | any,
  ssoUsersDetails?: IUserAccount[] | undefined
) => {
  const formData: any = new FormData();
  formData.append('ownerDetails', JSON.stringify(ownerDetails));
  files.forEach((file: File) => formData.append('files', file));
  if (ssoUsersDetails) formData.append('ssoUsersDetails', JSON.stringify(ssoUsersDetails));
  if (path) formData.append('path', path);

  try {
    if (files) {
      const { data } = await API({
        url: '/file-manager',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      return data;
    }
  } catch (err) {
    console.error(err);
    return '';
  }

  return '';
};

export const fileManagerDelete = async (fullFileNames: string[], userId: string) => {
  try {
    console.log('userId', userId);
    const { data } = await API({
      url: '/file-manager/delete',
      method: 'DELETE',
      params: {
        ssoUserId: userId,
        fileNames: fullFileNames,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    return '';
  }
};
