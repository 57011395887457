'use client';

import { FC, useMemo } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Image from 'src/components/image';

import { getContentValueFromProps } from '../../utils';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';

const ThreeColumnsInfo: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');
  const getFieldValue = getContentValueFromProps(block);
  // Buttons style
  const buttonColor = useMemo(() => getFieldValue('settings.buttonColor'), [block?.buttonColor]);
  const buttonSize = useMemo(() => getFieldValue('settings.buttonSize'), [block?.buttonSize]);
  const buttonVariant = useMemo(
    () => getFieldValue('settings.buttonVariant'),
    [block?.buttonVariant]
  );
  // First column
  const firstColumnTitle = useMemo(
    () => getFieldValue('firstColumnTitle'),
    [block?.firstColumnTitle]
  );
  const firstColumnSubtitle = useMemo(
    () => getFieldValue('firstColumnSubtitle'),
    [block?.firstColumnSubtitle]
  );
  const firstColumnFirstText = useMemo(
    () => getFieldValue('firstColumnFirstText'),
    [block?.firstColumnFirstText]
  );
  const firstColumnSecondText = useMemo(
    () => getFieldValue('firstColumnSecondText'),
    [block?.firstColumnSecondText]
  );
  const firstColumnThirdText = useMemo(
    () => getFieldValue('firstColumnThirdText'),
    [block?.firstColumnThirdText]
  );
  const firstColumnFourthText = useMemo(
    () => getFieldValue('firstColumnFourthText'),
    [block?.firstColumnFourthText]
  );
  const firstButtonText = useMemo(() => getFieldValue('firstButtonText'), [block?.firstButtonText]);
  const firstButtonLink = useMemo(() => getFieldValue('firstButtonLink'), [block?.firstButtonLink]);
  // Second column
  const secondColumnTitle = useMemo(
    () => getFieldValue('firstColumnTitle'),
    [block?.firstColumnTitle]
  );
  const secondColumnSubtitle = useMemo(
    () => getFieldValue('firstColumnSubtitle'),
    [block?.firstColumnSubtitle]
  );
  const secondColumnFirstText = useMemo(
    () => getFieldValue('firstColumnFirstText'),
    [block?.firstColumnFirstText]
  );
  const secondColumnSecondText = useMemo(
    () => getFieldValue('firstColumnSecondText'),
    [block?.firstColumnSecondText]
  );
  const secondColumnThirdText = useMemo(
    () => getFieldValue('firstColumnThirdText'),
    [block?.firstColumnThirdText]
  );
  const secondColumnFourthText = useMemo(
    () => getFieldValue('firstColumnFourthText'),
    [block?.firstColumnFourthText]
  );
  const secondButtonText = useMemo(
    () => getFieldValue('firstButtonText'),
    [block?.firstButtonText]
  );
  const secondButtonLink = useMemo(
    () => getFieldValue('secondButtonLink'),
    [block?.secondButtonLink]
  );
  // Third column
  const thirdColumnTitle = useMemo(
    () => getFieldValue('firstColumnTitle'),
    [block?.firstColumnTitle]
  );
  const thirdColumnSubtitle = useMemo(
    () => getFieldValue('firstColumnSubtitle'),
    [block?.firstColumnSubtitle]
  );
  const thirdColumnFirstText = useMemo(
    () => getFieldValue('firstColumnFirstText'),
    [block?.firstColumnFirstText]
  );
  const thirdColumnSecondText = useMemo(
    () => getFieldValue('firstColumnSecondText'),
    [block?.firstColumnSecondText]
  );
  const thirdColumnThirdText = useMemo(
    () => getFieldValue('firstColumnThirdText'),
    [block?.firstColumnThirdText]
  );
  const thirdColumnFourthText = useMemo(
    () => getFieldValue('firstColumnFourthText'),
    [block?.firstColumnFourthText]
  );
  const thirdButtonText = useMemo(() => getFieldValue('firstButtonText'), [block?.firstButtonText]);
  const thirdButtonLink = useMemo(() => getFieldValue('thirdButtonLink'), [block?.thirdButtonLink]);

  // styles
  // Title style
  const titleTextColor = useMemo(
    () => getFieldValue('settings.titleTextColor'),
    [block?.settings.titleTextColor]
  );
  const titleFontSize = useMemo(
    () => getFieldValue('settings.titleFontSize'),
    [block?.settings.titleFontSize]
  );
  const titleFontWeight = useMemo(
    () => getFieldValue('settings.titleFontWeight'),
    [block?.settings.titleFontWeight]
  );
  const titleFontStyle = useMemo(
    () => getFieldValue('settings.titleFontStyle'),
    [block?.settings.titleFontStyle]
  );
  const titleTextDecoration = useMemo(
    () => getFieldValue('settings.titleTextDecoration'),
    [block?.settings.titleTextDecoration]
  );
  // Subtitle style
  const subtitleTextColor = useMemo(
    () => getFieldValue('settings.subtitleTextColor'),
    [block?.settings.subtitleTextColor]
  );
  const subtitleFontSize = useMemo(
    () => getFieldValue('settings.subtitleFontSize'),
    [block?.settings.subtitleFontSize]
  );
  const subtitleFontWeight = useMemo(
    () => getFieldValue('settings.subtitleFontWeight'),
    [block?.settings.subtitleFontWeight]
  );
  const subtitleFontStyle = useMemo(
    () => getFieldValue('settings.subtitleFontStyle'),
    [block?.settings.subtitleFontStyle]
  );
  const subtitleTextDecoration = useMemo(
    () => getFieldValue('settings.subtitleTextDecoration'),
    [block?.settings.subtitleTextDecoration]
  );
  // Texts style
  const contentTextColor = useMemo(
    () => getFieldValue('settings.contentTextColor'),
    [block?.settings.contentTextColor]
  );
  const contentFontSize = useMemo(
    () => getFieldValue('settings.contentFontSize'),
    [block?.settings.contentFontSize]
  );
  const contentFontWeight = useMemo(
    () => getFieldValue('settings.contentFontWeight'),
    [block?.settings.contentFontWeight]
  );
  const contentFontStyle = useMemo(
    () => getFieldValue('settings.contentFontStyle'),
    [block?.settings.contentFontStyle]
  );
  const contentTextDecoration = useMemo(
    () => getFieldValue('settings.contentTextDecoration'),
    [block?.settings.contentTextDecoration]
  );

  const data = [
    {
      id: 3,
      title: firstColumnTitle,
      subTitle: firstColumnSubtitle,
      text1: firstColumnFirstText,
      text2: firstColumnSecondText,
      text3: firstColumnThirdText,
      text4: firstColumnFourthText,
      button: {
        title: firstButtonText,
        link: firstButtonLink,
      },
    },
    {
      id: 2,
      title: secondColumnTitle,
      subTitle: secondColumnSubtitle,
      text1: secondColumnFirstText,
      text2: secondColumnSecondText,
      text3: secondColumnThirdText,
      text4: secondColumnFourthText,
      button: {
        title: secondButtonText,
        link: secondButtonLink,
      },
    },
    {
      id: 1,
      title: thirdColumnTitle,
      subTitle: thirdColumnSubtitle,
      text1: thirdColumnFirstText,
      text2: thirdColumnSecondText,
      text3: thirdColumnThirdText,
      text4: thirdColumnFourthText,
      button: {
        title: thirdButtonText,
        link: thirdButtonLink,
      },
    },
  ];
  return (
    <Grid container spacing={1}>
      {data.map((item, id) => (
        <Grid
          key={id}
          item
          md={4}
          xs={12}
          alignItems="center"
          sx={
            !isMobile
              ? {
                  borderBottom: '1px solid #3A0D5E',
                  borderLeft: id !== 0 ? '1px solid #3A0D5E' : null,
                }
              : { borderBottom: '1px solid #3A0D5E' }
          }
        >
          <Stack alignItems="center">
            <Typography
              sx={{
                color: titleTextColor,
                fontSize: titleFontSize,
                fontWeight: titleFontWeight,
                fontStyle: titleFontStyle,
                textDecoration: titleTextDecoration,
              }}
            >
              {item.title}
            </Typography>
            <Image
              src="/assets/images/happy-gift/Vector-4.png"
              sx={{ mb: 2, mt: '-15px', width: '75%' }}
            />
            <Typography
              sx={{
                color: subtitleTextColor,
                fontSize: subtitleFontSize,
                fontWeight: subtitleFontWeight,
                fontStyle: subtitleFontStyle,
                textDecoration: subtitleTextDecoration,
                width: '70%',
              }}
            >
              {item.subTitle}
            </Typography>
            <Typography
              sx={{
                color: contentTextColor,
                fontSize: contentFontSize,
                fontWeight: contentFontWeight,
                fontStyle: contentFontStyle,
                textDecoration: contentTextDecoration,
                borderBottom: '1px solid #3A0D5E',
                mt: 2,
                pl: 2,
              }}
            >
              {item.text1}
            </Typography>
            <Typography
              sx={{
                color: contentTextColor,
                fontSize: contentFontSize,
                fontWeight: contentFontWeight,
                fontStyle: contentFontStyle,
                textDecoration: contentTextDecoration,
                borderBottom: '1px solid #3A0D5E',
                mt: 2,
                pl: 2,
              }}
            >
              {item.text2}
            </Typography>
            <Typography
              sx={{
                color: contentTextColor,
                fontSize: contentFontSize,
                fontWeight: contentFontWeight,
                fontStyle: contentFontStyle,
                textDecoration: contentTextDecoration,
                borderBottom: '1px solid #3A0D5E',
                mt: 2,
                pl: 2,
              }}
            >
              {item.text3}
            </Typography>
            <Typography
              sx={{
                color: contentTextColor,
                fontSize: contentFontSize,
                fontWeight: contentFontWeight,
                fontStyle: contentFontStyle,
                textDecoration: contentTextDecoration,
                borderBottom: '1px solid #3A0D5E',
                mt: 2,
                pl: 2,
              }}
            >
              {item.text4}
            </Typography>
            <Button
              variant={buttonVariant}
              size={buttonSize}
              color={buttonColor}
              href={item.button.link}
              sx={{
                my: 3,
                borderRadius: '20px',
              }}
            >
              {item.button.title}
            </Button>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default ThreeColumnsInfo;
