'use client';

import { FC, useMemo, useState, useEffect } from 'react';

import { Box, Grid, Stack, Button, useTheme, Typography } from '@mui/material';

import SanitizeHTML from 'src/utils/sanitize';

import SvgColor from 'src/components/svg-color';

import SixHoverBlocksGrid from './SicHoverBlocksGrid';
import { getContentValueFromProps } from '../../../utils';
import { ButtonField } from '../../../../../types/generator';
import { useResponsive } from '../../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../../types/page-generator';

// =====================================================================

const SixHoverBlocks: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);

  const theme = useTheme();

  const smDown = useResponsive('down', 'sm');

  const [content, setContent] = useState('center');
  useEffect(() => {
    if (getFieldValue('settings.buttonsAlign') === 'end') {
      setContent('flex-end');
    }
    if (getFieldValue('settings.buttonsAlign') === 'start') {
      setContent('flex-start');
    }
    if (getFieldValue('settings.buttonsAlign') === 'center') {
      setContent('center');
    }
  }, [getFieldValue('settings.buttonsAlign')]);

  const mainTitle = useMemo(() => {
    if (block?.mainTitle) {
      return block?.mainTitle;
    }
    return getFieldValue('mainTitle');
  }, [block?.contentText]);

  const contentText = useMemo(() => {
    if (block?.contentText) {
      return block?.contentText;
    }
    return getFieldValue('contentText');
  }, [block?.contentText]);

  const title = useMemo(() => {
    if (block?.title) {
      return block?.title;
    }
    return getFieldValue('title');
  }, [block?.title]);

  const actionButtons = useMemo(
    () => (getFieldValue('actionButtons') as ButtonField[]) || [],
    [block?.actionButtons]
  );

  const layout = useMemo(() => getFieldValue('settings.layout'), [block?.settings.layout]);

  const bannerClasses = useMemo(
    () => getFieldValue('settings.bannerClasses'),
    [block?.settings.bannerClasses]
  );
  const headingClasses = useMemo(
    () => getFieldValue('settings.headingClasses'),
    [block?.settings.headingClasses]
  );
  const contentClasses = useMemo(
    () => getFieldValue('settings.contentClasses'),
    [block?.settings.contentClasses]
  );

  const mainHeadingClasses = useMemo(
    () => getFieldValue('settings.mainHeadingClasses'),
    [block?.settings.mainHeadingClasses]
  );

  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [block?.settings.forceRtl]);

  const enableHeading = useMemo(
    () => getFieldValue('settings.enableHeading'),
    [block?.settings.enableHeading]
  );

  const enableTitle = useMemo(
    () => getFieldValue('settings.enableTitle'),
    [block?.settings.enableTitle]
  );

  const enableContent = useMemo(
    () => getFieldValue('settings.enableContent'),
    [block?.settings.enableContent]
  );

  const enableButtons = useMemo(
    () => getFieldValue('settings.enableButtons'),
    [block?.settings.enableButtons]
  );

  const contentAlign = useMemo(
    () => getFieldValue('settings.contentAlign'),
    [block?.settings.contentAlign]
  );
  const headingAlign = useMemo(
    () => getFieldValue('settings.headingAlign'),
    [block?.settings.headingAlign]
  );
  const mainHeadingAlign = useMemo(
    () => getFieldValue('settings.mainHeadingAlign'),
    [block?.settings.mainHeadingAlign]
  );

  const contentTextColor = useMemo(
    () => getFieldValue('settings.contentTextColor'),
    [block?.settings.contentTextColor]
  );
  const contentFontSize = useMemo(
    () => getFieldValue('settings.contentFontSize'),
    [block?.settings.contentFontSize]
  );
  const contentFontWeight = useMemo(
    () => getFieldValue('settings.contentFontWeight'),
    [block?.settings.contentFontWeight]
  );
  const contentFontStyle = useMemo(
    () => getFieldValue('settings.contentFontStyle'),
    [block?.settings.contentFontStyle]
  );
  const contentTextDecoration = useMemo(
    () => getFieldValue('settings.contentTextDecoration'),
    [block?.settings.contentTextDecoration]
  );

  const headingTextColor = useMemo(
    () => getFieldValue('settings.headingTextColor'),
    [block?.settings.headingTextColor]
  );
  const headingFontSize = useMemo(
    () => getFieldValue('settings.headingFontSize'),
    [block?.settings.headingFontSize]
  );
  const headingFontWeight = useMemo(
    () => getFieldValue('settings.headingFontWeight'),
    [block?.settings.headingFontWeight]
  );
  const headingFontStyle = useMemo(
    () => getFieldValue('settings.headingFontStyle'),
    [block?.settings.headingFontStyle]
  );
  const headingTextDecoration = useMemo(
    () => getFieldValue('settings.headingTextDecoration'),
    [block?.settings.headingTextDecoration]
  );

  const mainHeadingTextColor = useMemo(
    () => getFieldValue('settings.mainHeadingTextColor'),
    [block?.settings.mainHeadingTextColor]
  );
  const mainHeadingFontSize = useMemo(
    () => getFieldValue('settings.mainHeadingFontSize'),
    [block?.settings.mainHeadingFontSize]
  );
  const mainHeadingFontWeight = useMemo(
    () => getFieldValue('settings.mainHeadingFontWeight'),
    [block?.settings.mainHeadingFontWeight]
  );
  const mainHeadingFontStyle = useMemo(
    () => getFieldValue('settings.mainHeadingFontStyle'),
    [block?.settings.mainHeadingFontStyle]
  );
  const mainHeadingTextDecoration = useMemo(
    () => getFieldValue('settings.mainHeadingTextDecoration'),
    [block?.settings.mainHeadingTextDecoration]
  );

  return (
    <Box>
      {enableHeading && (
        <Typography
          className={mainHeadingClasses}
          textAlign="center"
          sx={{
            color: mainHeadingTextColor,
            fontSize: mainHeadingFontSize,
            fontWeight: mainHeadingFontWeight,
            fontStyle: mainHeadingFontStyle,
            textDecoration: mainHeadingTextDecoration,
            textAlign: mainHeadingAlign,
            mb: 2,
            direction: forceRtl ? 'rtl' : undefined,
          }}
        >
          {mainTitle}
        </Typography>
      )}
      <Grid
        container
        justifyContent="space-between"
        p={1}
        alignItems="center"
        spacing={smDown ? 3 : 20}
        flexDirection={layout === 'v2' ? 'row-reverse' : 'row'}
      >
        <Grid item md={6}>
          <SixHoverBlocksGrid block={block} />
        </Grid>
        <Grid item md={6} dir="rtl">
          <Stack>
            {enableTitle && (
              <Typography
                color="primary.main"
                className={headingClasses}
                sx={{
                  color: headingTextColor,
                  fontSize: headingFontSize,
                  fontWeight: headingFontWeight,
                  fontStyle: headingFontStyle,
                  textDecoration: headingTextDecoration,
                  textAlign: headingAlign,
                  direction: forceRtl ? 'rtl' : undefined,
                }}
              >
                {title}
              </Typography>
            )}
            {enableContent && (
              <Typography
                fontSize="17px"
                color="primary.main"
                className={contentClasses}
                sx={{
                  color: contentTextColor,
                  fontSize: contentFontSize,
                  fontWeight: contentFontWeight,
                  fontStyle: contentFontStyle,
                  textDecoration: contentTextDecoration,
                  textAlign: contentAlign,
                  direction: forceRtl ? 'rtl' : undefined,
                }}
              >
                <SanitizeHTML html={contentText} />
              </Typography>
            )}
          </Stack>
          {enableButtons && (
            <Stack direction="row" gap={2} mt={1} width={1} justifyContent={content}>
              {actionButtons.map((actionButton: ButtonField, idx: number) => (
                <Box key={idx}>
                  {idx === actionButtons.length - 1 ? (
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Typography
                        key={`actionButton_${idx}`}
                        sx={{
                          cursor: 'pointer',
                          borderRadius: 10,
                          color: `${actionButton.color}.main`,
                        }}
                      >
                        {actionButton.label || 'no label'}
                      </Typography>
                      <SvgColor
                        src="/assets/icons/happy-gift/arrow_1.svg"
                        sx={{
                          width: 35,
                          color: `${actionButton.color}.main`,
                        }}
                      />
                    </Stack>
                  ) : (
                    // @ts-ignore - проблемы с совместимостью типов, не кртичино
                    <Button
                      variant={actionButton.variant}
                      color={actionButton.color}
                      size="medium"
                      key={`actionButton_${idx}`}
                      sx={{ borderRadius: 10 }}
                    >
                      {actionButton.label || 'no label'}
                    </Button>
                  )}
                </Box>
              ))}
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SixHoverBlocks;
