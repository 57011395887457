import contentConfig from 'content-config';

import { FieldTypes, SettingsField } from '../../../types/page-generator';
// @types

const themeSettings: SettingsField[] = [
  {
    name: 'primary',
    label: 'Primary color',
    rowSize: 2,
    type: FieldTypes.group,
    fields: [
      {
        name: 'primaryMainColor',
        rowSize: 4,
        defaultValue: contentConfig.builder.theme.palette.primary.main,
        label: 'Main',
        type: FieldTypes.color,
      },
      {
        name: 'primaryLightColor',
        rowSize: 2,
        defaultValue: contentConfig.builder.theme.palette.primary.light,
        label: 'Light',
        type: FieldTypes.color,
      },
      {
        name: 'primaryLighterColor',
        rowSize: 2,
        defaultValue: contentConfig.builder.theme.palette.primary.lighter,
        label: 'Lighter',
        type: FieldTypes.color,
      },
      {
        name: 'primaryDarkColor',
        rowSize: 2,
        defaultValue: contentConfig.builder.theme.palette.primary.dark,
        label: 'Dark',
        type: FieldTypes.color,
      },
      {
        name: 'primaryDarkerColor',
        rowSize: 2,
        defaultValue: contentConfig.builder.theme.palette.primary.darker,
        label: 'Darker',
        type: FieldTypes.color,
      },
      {
        name: 'primaryContrastText',
        rowSize: 4,
        defaultValue: contentConfig.builder.theme.palette.primary.contrastText,
        label: 'Contrast text',
        type: FieldTypes.color,
      },
    ],
  },
  {
    name: 'secondary',
    label: 'Secondary color',
    rowSize: 2,
    type: FieldTypes.group,
    fields: [
      {
        name: 'secondaryMainColor',
        rowSize: 4,
        defaultValue: contentConfig.builder.theme.palette.secondary.main,
        label: 'Main',
        type: FieldTypes.color,
      },
      {
        name: 'secondaryLightColor',
        rowSize: 2,
        defaultValue: contentConfig.builder.theme.palette.secondary.light,
        label: 'Light',
        type: FieldTypes.color,
      },
      {
        name: 'secondaryLighterColor',
        rowSize: 2,
        defaultValue: contentConfig.builder.theme.palette.secondary.lighter,
        label: 'Lighter',
        type: FieldTypes.color,
      },
      {
        name: 'secondaryDarkColor',
        rowSize: 2,
        defaultValue: contentConfig.builder.theme.palette.secondary.dark,
        label: 'Dark',
        type: FieldTypes.color,
      },
      {
        name: 'secondaryDarkerColor',
        rowSize: 2,
        defaultValue: contentConfig.builder.theme.palette.secondary.darker,
        label: 'Darker',
        type: FieldTypes.color,
      },
      {
        name: 'secondaryContrastText',
        rowSize: 4,
        defaultValue: contentConfig.builder.theme.palette.secondary.contrastText,
        label: 'Contrast text',
        type: FieldTypes.color,
      },
    ],
  },
];

export default themeSettings;
