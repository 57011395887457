'use client';

import { useState } from 'react';
import utc from 'dayjs/plugin/utc';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
// import { useSearchParams } from 'next/navigation';

import Button from '@mui/material/Button';
import { Grid, Stack, Typography } from '@mui/material';

import { useTranslate } from 'src/locales';
// import { addGreeting } from 'src/redux/slices/merchant';
// import { useDispatch, useSelector } from 'src/redux/store';

// eslint-disable-next-line import/no-named-as-default
import GreetingForm from './GreetingForm';
import GreetingPhone from './GreetingPhone';
import Iconify from '../../components/iconify';
// import { useCheckoutContext } from '../checkout/context';
import { CartInterface } from '../../types/cart';
import { useDebounce } from '../../hooks/use-debounce';
import { BlockType } from '../../types/page-generator';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  basketData?: CartInterface[];
  cart: boolean;
  checkoutNextStep?: VoidFunction;
  checkoutPrevStep?: VoidFunction;
  block: BlockType;
}

export default function AddGreetings({
  basketData,
  cart,
  block,
  checkoutNextStep,
  checkoutPrevStep,
}: Props) {
  const { t } = useTranslate();
  // const params = useSearchParams();
  // const hideCoupons = params.get('hideCoupons');
  // TODO: Временное решение, заменить на нормальное
  const [data, setData] = useState<any>({});
  const [time, setTime] = useState<Dayjs>(dayjs.utc());
  // const checkout = useCheckoutContext();
  const isEmptyCart = !basketData?.length;
  const [senderName, setSenderName] = useState('');
  const [addressee, setAddressee] = useState('');
  const [mailingMessage, setMailingMessage] = useState('');

  // const activeMerchantId = useSelector((state) => state.merchant.activeEvent.id);
  // const activeMerchant = useSelector((state) => state.merchant.activeEvent);
  // const { pickedMailingCoupons } = useSelector((state) => state.product_2);
  // const { merchantEvents } = useSelector((state) => state.merchant);
  // const { customerMailing } = useSelector((state) => state.customer);

  const [merchantDomains, setMerchantDomains] = useState<string[]>([]);
  const [openGreetingConfirm, setOpenGreetingConfirm] = useState(false);
  const [openCustomersMailingList, setOpenCustomersMailingList] = useState(false);

  // useEffect(() => {
  //   merchantEvents.forEach((event: any) => {
  //     if (
  //       event?.settings?.couponIds &&
  //       event?.settings?.couponIds?.length > 0 &&
  //       event.domain !== '' &&
  //       !merchantDomains.includes(event.domain)
  //     ) {
  //       setMerchantDomains((prevMerchantDomain) => [...prevMerchantDomain, event.domain]);
  //     }
  //   });
  // }, [merchantDomains, merchantEvents]);

  const debouncedValues = useDebounce(data, 500);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (Object.keys(data).length === 0) return;
  //
  //   if (activeMerchantId) {
  //     dispatch(addGreeting(debouncedValues, activeMerchantId));
  //   }
  // }, [activeMerchantId, data, debouncedValues, dispatch]);

  return (
    <>
      <Stack>
        <Typography variant="h4" sx={{ marginBottom: 1 }}>
          {`${t('Edit and design your own wish')}`}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${t('We will keep the design of the wish for you for future sendings as well')}`}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        spacing={3}
        sx={{
          '@media (min-width: 300px)  and (max-width: 900px)': {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <Stack direction="column" spacing={3}>
          <GreetingForm
            cart={cart}
            block={block}
            setData={setData}
            time={time}
            setTime={setTime}
            openConfirm={openGreetingConfirm}
            handleCloseConfirm={() => setOpenGreetingConfirm(false)}
            openCustomersMailingList={openCustomersMailingList}
            handleCloseCustomersMailingList={() => setOpenCustomersMailingList(false)}
            senderName={senderName}
            setSenderName={setSenderName}
            addressee={addressee}
            setAddressee={setAddressee}
            mailingMessage={mailingMessage}
            setMailingMessage={setMailingMessage}
          />

          <Grid
            container
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(2, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(3, 1fr)',
              },
              gap: '16px',
              px: 2,
            }}
          >
            {/* {!hideCoupons && */}
            {/*  pickedMailingCoupons.map((coupon: ProductInterace) => ( */}
            {/*    <Card sx={{ width: 1 }}> */}
            {/*      <Stack> */}
            {/*        <Box */}
            {/*          sx={{ */}
            {/*            height: 100, */}
            {/*            m: 1, */}
            {/*            borderRadius: 2, */}
            {/*            backgroundImage: `url('${uploadProductsImage( */}
            {/*              coupon?.merchants[0]?.mainImageURL */}
            {/*            )}')`, */}
            {/*            backgroundSize: 'cover', */}
            {/*            cursor: 'pointer', */}
            {/*          }} */}
            {/*        /> */}
            {/*        <Typography variant="subtitle2" component="div" sx={{ p: 1 }}> */}
            {/*          {coupon.title} */}
            {/*        </Typography> */}
            {/*      </Stack> */}
            {/*    </Card> */}
            {/*  ))} */}
          </Grid>

          {/* <Button
            variant="contained"
            sx={{ borderRadius: 20, width: 100 }}
            onClick={() => setOpenCustomersMailingList(true)}
            disabled={!customerMailing.length}
          >
            {`${translate('Recipients')}`}
          </Button> */}
        </Stack>

        <GreetingPhone
          senderName={senderName}
          addressee={addressee}
          mailingMessage={mailingMessage}
        />
      </Stack>
      {cart && (
        <Stack direction="row" justifyContent="start" alignItems="center">
          <Button
            size="large"
            type="submit"
            variant="contained"
            disabled={isEmptyCart}
            onClick={checkoutNextStep}
            sx={{ height: '30px' }}
          >
            {`${t('Next')}`}
          </Button>
          <Button
            size="small"
            color="inherit"
            onClick={checkoutPrevStep}
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          >
            {`${t('Back')}`}
          </Button>
        </Stack>
      )}
    </>
  );
}
