import { Service } from '../types/service';

export const SERVICES: Service[] = [
  {
    id: 1,
    image: '/assets/images/city-people/service_1.jpeg',
    name: 'שדרוג מטבח',
    description: 'תוקף עד ',
    balance: '10000',
  },
  {
    id: 2,
    image: '/assets/images/city-people/service_2.jpeg',
    name: 'שיפוץ',
    description: 'תוקף עד ',
    balance: '10000',
  },
  {
    id: 3,
    image: '/assets/images/city-people/service_3.jpeg',
    name: 'קררמיקה',
    description: 'תוקף עד ',
    balance: '10000',
  },
  {
    id: 4,
    image: '/assets/images/city-people/service_4.jpeg',
    name: 'אינסטלציה',
    description: 'תוקף עד ',
    balance: '10000',
  },
  {
    id: 5,
    image: '/assets/images/city-people/service_5.jpeg',
    name: 'צבע',
    description: 'תוקף עד ',
    balance: '10000',
  },
];
