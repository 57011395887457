
export const SUPPLIER_HISTORY_ITEMS = [
  {
    id: 'd54948ff-4edf-4266-8d91-d2357d9f62eb',
    amount: '900.00',
    orderId: 'e7e44b69-f1bb-4126-9e43-f5cb074ae4ec',
    relatedActivityTransactionId: 'e7e44b69-f1bb-4126-9e43-f5cb074ae4ec',
    virtualCardId: '969b5189-e013-4566-ba22-b03e40229c95',
    transactionType: 'DEBIT',
    order: {
      id: 'e7e44b69-f1bb-4126-9e43-f5cb074ae4ec',
      orderNumber: 'ORD-744744',
      botId: 501,
      userId: '9b4a6409-6365-43a4-a28e-6c4baca22b71',
      status: 'NEW',
      documentImageUrl:
        'https://api-citypeople.b24online.com/upload/f6a6aad2-9ae8-4b7b-8160-80a89444cc0a.jpg',
      transactionId: null,
      createdAt: '2024-06-12T20:53:04.579Z',
      smbAccountId: 'c9f45afc-5147-49f1-bdad-0f970e9d21f5',
      sendGiftEventItemId: null,
    },
    creator: {
      firstName: 'ורד ויעקב',
      lastName: 'מינץ',
      email: 'ymintz19@gmail.com',
      phones: ['0522783263'],
      address: 'בניין חדש - צייטלין 15 - דירה 25',
    },
  },
  {
    id: 'f74dacf8-7d3c-4319-9646-00cb3ac553e4',
    amount: '3500.00',
    orderId: 'afe3d2f1-5265-4fa3-b436-51d4b1ccf8d5',
    relatedActivityTransactionId: 'afe3d2f1-5265-4fa3-b436-51d4b1ccf8d5',
    virtualCardId: '969b5189-e013-4566-ba22-b03e40229c95',
    transactionType: 'DEBIT',
    order: {
      id: 'afe3d2f1-5265-4fa3-b436-51d4b1ccf8d5',
      orderNumber: 'ORD-321185',
      botId: 501,
      userId: '9b4a6409-6365-43a4-a28e-6c4baca22b71',
      status: 'NEW',
      documentImageUrl:
        'https://api-citypeople.b24online.com/upload/f6a6aad2-9ae8-4b7b-8160-80a89444cc0a.jpg',
      transactionId: null,
      createdAt: '2024-06-12T20:52:08.680Z',
      smbAccountId: 'c9f45afc-5147-49f1-bdad-0f970e9d21f5',
      sendGiftEventItemId: null,
    },
    creator: {
      firstName: 'ורד ויעקב',
      lastName: 'מינץ',
      email: 'ymintz19@gmail.com',
      phones: ['0522783263'],
      address: 'בניין חדש - צייטלין 15 - דירה 25',
    },
  },
  {
    id: 'd54948ff-4edf-4266-8d91-d2357d9f62eb',
    amount: '900.00',
    orderId: 'e7e44b69-f1bb-4126-9e43-f5cb074ae4ec',
    relatedActivityTransactionId: 'e7e44b69-f1bb-4126-9e43-f5cb074ae4ec',
    virtualCardId: '969b5189-e013-4566-ba22-b03e40229c95',
    transactionType: 'DEBIT',
    order: {
      id: 'e7e44b69-f1bb-4126-9e43-f5cb074ae4ec',
      orderNumber: 'ORD-744744',
      botId: 501,
      userId: '9b4a6409-6365-43a4-a28e-6c4baca22b71',
      status: 'NEW',
      documentImageUrl:
        'https://api-citypeople.b24online.com/upload/f6a6aad2-9ae8-4b7b-8160-80a89444cc0a.jpg',
      transactionId: null,
      createdAt: '2024-06-12T20:53:04.579Z',
      smbAccountId: 'c9f45afc-5147-49f1-bdad-0f970e9d21f5',
      sendGiftEventItemId: null,
    },
    creator: {
      firstName: 'ורד ויעקב',
      lastName: 'מינץ',
      email: 'ymintz19@gmail.com',
      phones: ['0522783263'],
      address: 'בניין חדש - צייטלין 15 - דירה 25',
    },
  },
  {
    id: 'f74dacf8-7d3c-4319-9646-00cb3ac553e4',
    amount: '3500.00',
    orderId: 'afe3d2f1-5265-4fa3-b436-51d4b1ccf8d5',
    relatedActivityTransactionId: 'afe3d2f1-5265-4fa3-b436-51d4b1ccf8d5',
    virtualCardId: '969b5189-e013-4566-ba22-b03e40229c95',
    transactionType: 'DEBIT',
    order: {
      id: 'afe3d2f1-5265-4fa3-b436-51d4b1ccf8d5',
      orderNumber: 'ORD-321185',
      botId: 501,
      userId: '9b4a6409-6365-43a4-a28e-6c4baca22b71',
      status: 'NEW',
      documentImageUrl:
        'https://api-citypeople.b24online.com/upload/f6a6aad2-9ae8-4b7b-8160-80a89444cc0a.jpg',
      transactionId: null,
      createdAt: '2024-06-12T20:52:08.680Z',
      smbAccountId: 'c9f45afc-5147-49f1-bdad-0f970e9d21f5',
      sendGiftEventItemId: null,
    },
    creator: {
      firstName: 'ורד ויעקב',
      lastName: 'מינץ',
      email: 'ymintz19@gmail.com',
      phones: ['0522783263'],
      address: 'בניין חדש - צייטלין 15 - דירה 25',
    },
  },
  {
    id: '09c147fa-c263-4fed-9280-16e9da4b9d46',
    amount: '750.00',
    orderId: 'eb28b90b-92d1-4543-8d5c-ed7b880c1eb7',
    relatedActivityTransactionId: 'eb28b90b-92d1-4543-8d5c-ed7b880c1eb7',
    virtualCardId: '969b5189-e013-4566-ba22-b03e40229c95',
    transactionType: 'DEBIT',
    order: {
      id: 'eb28b90b-92d1-4543-8d5c-ed7b880c1eb7',
      orderNumber: 'ORD-289017',
      botId: 501,
      userId: '9b4a6409-6365-43a4-a28e-6c4baca22b71',
      status: 'NEW',
      documentImageUrl:
        'https://api-citypeople.b24online.com/upload/f6a6aad2-9ae8-4b7b-8160-80a89444cc0a.jpg',
      transactionId: null,
      createdAt: '2024-06-12T20:52:31.178Z',
      smbAccountId: 'c9f45afc-5147-49f1-bdad-0f970e9d21f5',
      sendGiftEventItemId: null,
    },
    creator: {
      firstName: 'ורד ויעקב',
      lastName: 'מינץ',
      email: 'ymintz19@gmail.com',
      phones: ['0522783263'],
      address: 'בניין חדש - צייטלין 15 - דירה 25',
    },
  },
] as any[];
