'use client';

import { FC, useState } from 'react';

import {
  Grid,
  Link,
  Stack,
  CardMedia,
  Container,
  Typography,
} from '@mui/material';

import { useLocales } from '../../../../../locales';
import Iconify from '../../../../../components/iconify';
import { ComponentTypeProps } from '../../../../../types/page-generator';
import { useRouter } from '../../../../../routes/hooks';

const paidServiceArr = [
  {
    img: '/assets/images/home/paidService1.jpg',
    title: 'ניקוי כניסה',
    link: '#',
  },
  {
    img: '/assets/images/home/paidService2.jpg',
    title: 'חשמל',
    link: '#',
  },
  {
    img: '/assets/images/home/paidService3.jpg',
    title: 'תיקונים',
    link: '#',
  },
  {
    img: '/assets/images/home/paidService4.jpg',
    title: 'אינסטלציה',
    link: '#',
  },
  {
    img: '/assets/images/home/paidService5.jpg',
    title: 'כביסה',
    link: '#',
  },
  {
    img: '/assets/images/home/paidService6.jpg',
    title: 'פינוי זבל',
    link: '#',
  },
  {
    img: '/assets/images/home/paidService7.jpg',
    title: 'הובלות',
    link: '/providers',
  },
  {
    img: '/assets/images/home/paidService8.jpg',
    title: 'נקיון כללי',
    link: '#',
  },
  {
    img: '/assets/images/home/paidService9.jpg',
    title: 'כניסה',
    link: '#',
  },
];

export const OurServices: FC<ComponentTypeProps> = ({ block }) => {
  const { currentLang } = useLocales();
  const combined: any = block?.combined || [];
  const router = useRouter();

  return (
    <Container maxWidth="xs">
      <Stack gap={1}>
        {!block?.settings?.allServices && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width={1}
          >
            <Typography sx={{ fontWeight: 400 }}>השירותים שלנו</Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 400,
                cursor: 'pointer',
              }}
              // onClick={() => router.push('/services')}
            >
              לכל השירותים {'>>'}
            </Typography>
          </Stack>
        )}
        <Grid container gap={1} width="100%" justifyContent="space-between">
          {paidServiceArr?.map((item: any, index: number) => (
            <Grid
              item
              xs={3.7}
              key={index}
              className={item.contentClass}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                padding: 1,
                transition: '0.3s',
                '&:hover': {
                  boxShadow: 3,
                  borderRadius: 2,
                },
              }}
            >
              <Link
                href={item.link}
                sx={{ textDecoration: 'none', color: 'inherit' }}
              >
                <CardMedia
                  component="img"
                  src={item.img}
                  alt="Service Image"
                  sx={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 1,
                  }}
                />
                {/* {item?.enableTitle && ( */}
                <Typography
                  className={item.typographyClass}
                  sx={{
                    mt: 1,
                    '& h1': { my: 1 },
                    '& h2': { my: 1 },
                    '& h3': { my: 1 },
                    '& h4': { my: 1 },
                    '& h5': { my: 1 },
                    '& h6': { my: 1 },
                    '& p': { margin: 0 },
                    direction: currentLang.value === 'he' ? 'ltr' : 'rtl',
                    lineHeight: item.lineHeight,
                    textAlign: 'center',
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item?.title,
                    }}
                  />
                </Typography>
                {/* )} */}
              </Link>
            </Grid>
          ))}
        </Grid>
        <Stack alignItems="flex-end">
          <Iconify
            sx={{ color: 'primary.main', cursor: 'pointer' }}
            icon="typcn:plus"
            onClick={() => router.push('/ourspecialists')}
          />
        </Stack>
      </Stack>
    </Container>
  );
};
