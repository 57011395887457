'use client';

import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { fData } from 'src/utils/format-number';

import { useAuthContext } from 'src/auth/hooks';

import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSwitch, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';

import { BlockType } from '../../../../types/generator';
import { BasicPageParams } from '../../../../types/common';
import { useTranslation } from '../../../../app/i18n/client';

type Props = {
  block: BlockType | undefined;
  params: BasicPageParams | any;
};

export default function AccountGeneral({ block, params }: Props) {
  const { t } = useTranslation(params.params.lng);
  const { user } = useAuthContext();
  const attributes = user?.attributes;
  const { enqueueSnackbar } = useSnackbar();

  // const UpdateUserSchema = Yup.object().shape({
  //   displayName: Yup.string().required('Name is required'),
  //   email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  //   photoURL: Yup.mixed<any>().nullable().required('Avatar is required'),
  //   phoneNumber: Yup.string().required('Phone number is required'),
  //   country: Yup.string().required('Country is required'),
  //   address: Yup.string().required('Address is required'),
  //   state: Yup.string().required('State is required'),
  //   city: Yup.string().required('City is required'),
  //   zipCode: Yup.string().required('Zip code is required'),
  //   about: Yup.string().required('About is required'),
  //   // not required
  //   isPublic: Yup.boolean(),
  // });

  const defaultValues = {
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    isVerified: user?.isVerified,
    phone: user?.phone,
    projects: user?.projects || [],
    userRoles: user?.userRoles || [],
  };

  const methods = useForm({
    // resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    // setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar(t('Update success!'));
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    // const file = acceptedFiles[0];
    // const newFile = Object.assign(file, {
    //   preview: URL.createObjectURL(file),
    // });
    // if (file) {
    //   setValue('photoURL', newFile, { shouldValidate: true });
    // }
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3, textAlign: 'center' }}>
            <RHFUploadAvatar
              name="photoURL"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  {`${t('Allowed *.jpeg, *.jpg, *.png, *.gif')}`}
                  <br /> max size of {fData(3145728)}
                </Typography>
              }
            />

            <RHFSwitch
              name="isPublic"
              labelPlacement="start"
              label={`${t('Public Profile')}`}
              sx={{ mt: 5 }}
            />
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              {/* <RHFTextField name="firstName" label={currentLang.value === 'he' ? 'שם פרטי' : "Name"} /> */}
              <RHFTextField name="firstName" label={`*${t('Name')}`} />
              <RHFTextField name="lastName" label={`*${t('Last Name')}`} />
              <RHFTextField name="phone" label={`*${t('Phone Number')}`} />
              <RHFTextField name="area" label={`${t('Area')}`} />
            </Box>
            <Box sx={{ my: 2 }}>
              <RHFTextField
                sx={{ width: { sm: '100%', md: '49%' } }}
                name="email"
                label={`*${t('E-mail Address')}`}
              />
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="city" label={t('Town / City')} />

              <RHFTextField name="zipCode" label={t('Zip/Code')} />

              <RHFTextField name="street" label={t('Street')} />

              <RHFTextField name="home" label={t('Home')} />
            </Box>
            <Box sx={{ my: 2 }}>
              <RHFTextField rows={4} multiline name="more" label={t('More details')} />
            </Box>
            {/* <RHFAutocomplete */}
            {/*  name="country" */}
            {/*  label="Country" */}
            {/*  options={countries.map((country) => country.label)} */}
            {/*  getOptionLabel={(option) => option} */}
            {/*  renderOption={(props, option) => { */}
            {/*    const { code, label, phone } = countries.filter( */}
            {/*      (country) => country.label === option */}
            {/*    )[0]; */}

            {/*    if (!label) { */}
            {/*      return null; */}
            {/*    } */}

            {/*    return ( */}
            {/*      <li {...props} key={label}> */}
            {/*        <Iconify */}
            {/*          key={label} */}
            {/*          icon={`circle-flags:${code.toLowerCase()}`} */}
            {/*          width={28} */}
            {/*          sx={{ mr: 1 }} */}
            {/*        /> */}
            {/*        {label} ({code}) +{phone} */}
            {/*      </li> */}
            {/*    ); */}
            {/*  }} */}
            {/* /> */}

            <Stack spacing={3} alignItems="flex-start" sx={{ mt: 3 }}>
              {/* <RHFTextField name="about" multiline rows={4} label="About" /> */}

              {block?.settings?.enableAttributes && (
                <Stack width={1} justifyContent="center">
                  {attributes &&
                    attributes.map((attribute: any) => (
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography sx={{ fontSize: '20px', fontWeight: 1000 }}>
                          {attribute.name}
                        </Typography>
                        {attribute?.attributeValue?.map((value: any) => (
                          <Typography>{value.value}</Typography>
                        ))}
                      </Stack>
                    ))}
                </Stack>
              )}

              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {`${t('Save Changes')}`}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
