'use client';

import { useState } from 'react';
import { format } from 'date-fns';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack, Collapse } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { IVirtualCardsTable } from '../../../../types/club-cards';

// import UserQuickEditForm from './user-quick-edit-form';

// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: IVirtualCardsTable;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function VirtualCardsTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const [open, setOpen] = useState(false);

  const { name, secondName, total, code, date, place, company, type } = row;

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  return (
    <>
      <TableRow hover selected={selected} onClick={() => setOpen(!open)}>
        {/* <TableCell padding="checkbox"> */}
        {/*  <Checkbox checked={selected} onClick={onSelectRow} /> */}
        {/* </TableCell> */}

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <ListItemText
            primary={name}
            secondary={secondName}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              fontSize: 12,
              component: 'p',
              color: 'text.disabled',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{total}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{code}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <ListItemText
            primary={format(new Date(date), 'dd.MM.yyyy')}
            secondary={format(new Date(date), 'HH:mm a')}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              fontSize: 12,
              component: 'p',
              color: 'text.disabled',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{place}</TableCell>

        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <Iconify icon="ep:arrow-up-bold" /> : <Iconify icon="ep:arrow-down-bold" />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack
            sx={{ margin: 1 }}
            direction="row"
            width={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Typography variant="h3">Zara</Typography>
              <Typography sx={{ fontSize: '12px', color: 'grey' }}>
                מספר מזוהה של בי העסק:.......
              </Typography>
            </Stack>
            <Typography sx={{ fontSize: '18px', fontWeight: 800 }}>₪300</Typography>
          </Stack>
        </Collapse>
      </TableCell>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
