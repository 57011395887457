'use client';

import isEqual from 'lodash/isEqual';
import { useState, useCallback } from 'react';

import { Badge, Stack, Paper, Slider, Button, Tooltip, TextField, IconButton } from '@mui/material';

import { InputRange } from 'src/extra-components/b24';

import Iconify from 'src/components/iconify/iconify';

import { BlockType } from 'src/types/page-generator';
import { IProductFilterValue } from 'src/types/product';

// ----------------------------------------------------------------------

export type IProductFilters = {
  rating?: string;
  gender?: string[];
  category?: string;
  colors?: string[];
  priceRange?: number[];
  searchKey?: string;
};

const defaultFilters: IProductFilters = {
  //   gender: [],
  //   colors: [],
  //   rating: '',
  //   category: 'all',
  priceRange: [0, 200],
  searchKey: '',
};

// ----------------------------------------------------------------------
export interface FilterProps {
  block: BlockType;
}
// ----------------------------------------------------------------------

export default function Filter({ block }: FilterProps) {
  const marksLabel = [...Array(21)].map((_, index) => {
    const value = index * 10;

    const firstValue = index === 0 ? `$${value}` : `${value}`;

    return {
      value,
      label: index % 4 ? '' : firstValue,
    };
  });

  const [filters, setFilters] = useState(defaultFilters);

  const canReset = !isEqual(defaultFilters, filters);

  const handleFilters = useCallback((name: string, value: IProductFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleFilterPriceRange = useCallback(
    (event: Event, newValue: number | number[]) => {
      handleFilters('priceRange', newValue as number[]);
    },
    [handleFilters]
  );

  const handleRegularFilter = useCallback(
    (event: any) => {
      if ((event?.target?.name && event?.target?.value) || event?.target?.value === '')
        handleFilters(event.target.name, event.target.value);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <Stack direction="row" spacing={2}>
      <FilterSection>
        <Stack direction="row" spacing={5}>
          <InputRange type="min" value={filters.priceRange ?? [0, 0]} onFilters={handleFilters} />
          <InputRange type="max" value={filters.priceRange ?? [0, 0]} onFilters={handleFilters} />
        </Stack>

        <Slider
          value={filters.priceRange}
          onChange={handleFilterPriceRange}
          step={10}
          min={0}
          max={200}
          marks={marksLabel}
          getAriaValueText={(value) => `$${value}`}
          valueLabelFormat={(value) => `$${value}`}
          sx={{
            alignSelf: 'center',
            width: `calc(100% - 24px)`,
          }}
        />
      </FilterSection>
      <FilterSection>
        <TextField
          label="Search..."
          name="searchKey"
          variant="filled"
          value={filters.searchKey}
          onChange={handleRegularFilter}
        />
      </FilterSection>
      <FilterSection direction="row" alignCenter spacing={1}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Iconify icon="solar:check-circle-bold" />}
        >
          Apply
        </Button>
        <Tooltip title="Reset">
          <IconButton onClick={handleResetFilters}>
            <Badge color="error" variant="dot" invisible={!canReset}>
              <Iconify icon="solar:restart-bold" />
            </Badge>
          </IconButton>
        </Tooltip>
      </FilterSection>
    </Stack>
  );
}

function FilterSection({
  children,
  direction = 'column',
  alignCenter = false,
  spacing = 0,
}: {
  children: React.ReactNode;
  direction?: 'column' | 'row';
  alignCenter?: boolean;
  spacing?: number;
}) {
  return (
    <Paper
      component={Stack}
      direction={direction}
      justifyContent="center"
      alignItems={alignCenter ? 'center' : undefined}
      spacing={spacing}
      sx={(theme) => ({ background: theme.palette.grey[200], py: 1.25, px: 2, flex: 1 })}
    >
      {children}
    </Paper>
  );
}
