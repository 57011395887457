'use client';

import { Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';

import { useTranslate } from 'src/locales';

import Iconify from '../../../../../../components/iconify';
import Scrollbar from '../../../../../../components/scrollbar';
import { BlockType } from '../../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';

export default function VendorsBranches({ block }: { block: BlockType }) {
  const { t } = useTranslate();
  const { dispatch } = useAppContext();

  const { settings } = block;
  const { showPhoneNumber } = settings;
  const onClose = () => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
  };

  const branchItems = [
    {
      title: 'פיצה האט הורייזנס',
      subTitle: 'שדרות יהודי דרום אפריקה 14, אופקים',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט דימונה',
      subTitle: 'קניון פרץ, דימונה',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט דימונה',
      subTitle: 'קניון פרץ, דימונה',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט אוניקס',
      subTitle: 'קניון פרץ, דימונה',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט הורייזנס',
      subTitle: 'שדרות יהודי דרום אפריקה 14, אופקים',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט דימונה',
      subTitle: 'קניון פרץ, דימונה',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט דימונה',
      subTitle: 'קניון פרץ, דימונה',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט אוניקס',
      subTitle: 'קניון פרץ, דימונה',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט הורייזנס',
      subTitle: 'שדרות יהודי דרום אפריקה 14, אופקים',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט דימונה',
      subTitle: 'קניון פרץ, דימונה',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט דימונה',
      subTitle: 'קניון פרץ, דימונה',
      phone: '1-700-50-60-70',
    },
    {
      title: 'פיצה האט אוניקס',
      subTitle: 'קניון פרץ, דימונה',
      phone: '1-700-50-60-70',
    },
  ];

  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <Iconify
          icon="iconamoon:close"
          onClick={() => onClose()}
          sx={{ cursor: 'pointer', color: 'grey.600' }}
        />
      </Stack>
      <Stack alignItems="flex-start">
        <Typography id="modal-modal-title" variant="h6" component="h2" color="secondary.main">
          סניפים
        </Typography>
        <TextField
          sx={{ py: 2 }}
          fullWidth
          placeholder="חיפוש..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
        <Scrollbar sx={{ height: '400px', width: '100%' }}>
          <Stack gap={2} height="500px">
            {branchItems.map((branch, id) => (
              <Stack key={id}>
                <Typography>{branch.title}</Typography>
                <Typography sx={{ color: 'grey.600' }}>{branch.subTitle}</Typography>
                <Typography sx={{ color: 'grey.600' }}>{branch.phone}</Typography>
              </Stack>
            ))}
          </Stack>
        </Scrollbar>
      </Stack>
    </>
  );
}
