'use client';

import { useState } from 'react';
import isEqual from 'lodash/isEqual';

import { Box, Table, TableBody, TableContainer } from '@mui/material';

import CatTableRow from './CatTableRow';
import { useTranslate } from '../../../../../../locales';
import Scrollbar from '../../../../../../components/scrollbar';
import { BlockType } from '../../../../../../types/page-generator';
import {
  useTable,
  emptyRows,
  TableNoData,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../../../../../components/table';

const defaultFilters = {};

type Props = {
  block: BlockType | undefined;
  dataPaid: PaidTableType[];
};

type PaidTableType = {
  id: string;
  date: string;
  paymentForThePeriod: string;
  amountToPay: string;
  propertyAddress: string;
  status: string;
};

export const CatTable = ({ block, dataPaid }: Props) => {
  const { t } = useTranslate();
  const table = useTable();
  const { dense, page, rowsPerPage, onChangeDense, onChangePage, onChangeRowsPerPage } = useTable();
  const [tableData, setTableData] = useState<PaidTableType[]>(dataPaid);
  const [filters, setFilters] = useState(defaultFilters);
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;
  const TABLE_HEAD = [
    { id: 'date', label: t('Date') },
    {
      id: 'paymentForThePeriod',
      label: t('Payment for the period'),
      width: 180,
    },
    { id: 'amountToPay', label: t('Amount to pay'), width: 180 },
    { id: 'propertyAddress', label: t('Property address'), width: 180 },
    { id: 'status', label: t('Status'), width: 100 },
    { id: '', label: '', width: 188 },
  ];

  return (
    <Box>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
            />

            <TableBody>
              {dataFiltered
                .slice(
                  table.page * table.rowsPerPage,
                  table.page * table.rowsPerPage + table.rowsPerPage
                )
                .map((row: PaidTableType) => (
                  <CatTableRow key={row.id} row={row} />
                ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        count={dataPaid.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        //
        dense={dense}
        onChangeDense={onChangeDense}
      />
    </Box>
  );
};

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: any;
  comparator: (a: any, b: any) => number;
  filters: any;
}) {
  return inputData;
}
