'use client';

import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// locales
import { useTranslate } from 'src/locales';

import FormProvider, { RHFTextField } from '../../../../../../components/hook-form';
// components

// ----------------------------------------------------------------------

type FormValuesProps = {
  comment: string;
  name: string;
  email: string;
};

export default function BlogPostCommentForm() {
  const { t } = useTranslate();

  const CommentSchema = Yup.object().shape({
    comment: Yup.string().required(`${t('Note is required')}`),
    name: Yup.string().required(`${t('Name is required')}`),
    email: Yup.string()
      .required(`${t('Email is required')}`)
      .email(`${t('The email must be a valid email address')}`),
  });

  const defaultValues = {
    comment: '',
    name: '',
    email: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CommentSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="flex-end">
        <RHFTextField
          name="comment"
          placeholder={`${t('Write some of your notes...')}`}
          multiline
          rows={3}
        />

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {`${t('Post reply')}`}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
