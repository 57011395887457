'use client';

import React from 'react';
import { Box, Typography, Stack, Divider, Button } from '@mui/material';
import FiveStar from './five-star';

const StarRate = () => {
  const arr = [0, 1, 2, 3, 4];

  return (
    <>
      {/* Карточка с рейтингом */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          backgroundColor: 'background.paper',
          borderRadius: 2,
          p: 2,
          boxShadow: 3,
          width: 100,
        }}
      >
        <Box textAlign="center">
          <Typography
            variant="h5"
            sx={{ fontWeight: 'bold', color: 'primary.main' }}
          >
            8.0
          </Typography>
          <Typography
            color="text.secondary"
            sx={{ color: 'primary.main', fontSize: '10px' }}
          >
            מתוך 10
          </Typography>
        </Box>
      </Box>

      {/* Блок с отзывами и звездами */}
      <Box sx={{ mt: 4 }}>
        <Typography sx={{ textAlign: 'right', mb: 2 }}>ביקורות</Typography>
        <Stack spacing={2} alignItems="center">
          {arr.map((el, i) => (
            <Box
              key={i}
              sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              <FiveStar />
              <Button
                variant="contained"
                fullWidth
                sx={{ bgcolor: '#16AA5F', '&:hover': { bgcolor: '#16AA5F' } }}
              />
            </Box>
          ))}
          <Typography
            width={1}
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: 'left' }}
          >
            248 ביקורות
          </Typography>
        </Stack>
      </Box>
    </>
  );
};

export default StarRate;
