// @types
import { FieldTypes, SettingsField } from 'src/types/page-generator';

import { colorOptions, textColorOptions } from './common';
import {
  fontSizes,
  fontStyles,
  borderSizes,
  fontWeights,
  textDecorations,
  borderDecorations,
} from './fonts-presets';

const headerCityPeople: SettingsField[] = [
  {
    name: 'mainSubtitle',
    type: FieldTypes.subheader,
    label: 'Main',
    rowSize: 4,
    defaultValue: null,
  },
  {
    name: 'backgroundColor',
    type: FieldTypes.select,
    defaultValue: colorOptions[1].value, // primary.main
    label: 'Background color',
    isColor: true,
    rowSize: 4,
    options: colorOptions,
  },
  {
    type: FieldTypes.group,
    label: 'Buttons',
    name: 'buttonsGroup',
    rowSize: 4,
    defaultValue: '',
    fields: [
      {
        name: 'showNotifications',
        type: FieldTypes.checkbox,
        defaultValue: false,
        label: 'Show Notifications',
        rowSize: 2,
      },
      {
        name: 'showBurgerMenu',
        type: FieldTypes.checkbox,
        defaultValue: false,
        label: 'Show Burger Menu',
        rowSize: 2,
      },
    ],
  },

  {
    name: 'logo',
    type: FieldTypes.upload,
    defaultValue: null,
    uploadType: 'image',
    label: 'Logo',
    rowSize: 4,
  },
  {
    name: 'logo',
    type: FieldTypes.input,
    defaultValue: null,
    uploadType: 'image',
    label: 'Logo (link)',
    rowSize: 4,
  },
  {
    name: 'favicon',
    type: FieldTypes.input,
    defaultValue: '',
    label: 'Favicon (link)',
    rowSize: 4,
  },
  {
    name: 'burgerMenuChildrenStyles',
    rowSize: 2,
    type: FieldTypes.group,
    label: 'Burger Menu Style Children Items',
    fields: [
      {
        name: 'contentTextColorChildren',
        label: 'Content Text color',
        type: FieldTypes.select,
        defaultValue: 'inherit',
        rowSize: 4,
        options: textColorOptions,
        isColor: true,
      },
      {
        name: 'contentFontSizeChildren',
        label: 'Content Font Size',
        type: FieldTypes.select,
        defaultValue: '12px',
        rowSize: 4,
        options: fontSizes,
      },
      {
        name: 'contentFontWeightChildren',
        label: 'Content Font Weight',
        type: FieldTypes.select,
        defaultValue: 400,
        rowSize: 4,
        options: fontWeights,
      },
      {
        name: 'contentFontStyleChildren',
        label: 'Content Font Style',
        type: FieldTypes.select,
        defaultValue: 'normal',
        rowSize: 4,
        options: fontStyles,
      },
      {
        name: 'contentTextDecorationChildren',
        label: 'Content Text Decoration',
        type: FieldTypes.select,
        defaultValue: 'none',
        rowSize: 4,
        options: textDecorations,
      },
    ],
  },
  {
    name: 'burgerMenuParentStyles',
    rowSize: 2,
    type: FieldTypes.group,
    label: 'Burger Menu Style Parent Items',
    fields: [
      {
        name: 'contentTextColorParent',
        label: 'Content Text color',
        type: FieldTypes.select,
        defaultValue: 'inherit',
        rowSize: 4,
        options: textColorOptions,
        isColor: true,
      },
      {
        name: 'contentFontSizeParent',
        label: 'Content Font Size',
        type: FieldTypes.select,
        defaultValue: '12px',
        rowSize: 4,
        options: fontSizes,
      },
      {
        name: 'contentFontWeightParent',
        label: 'Content Font Weight',
        type: FieldTypes.select,
        defaultValue: 400,
        rowSize: 4,
        options: fontWeights,
      },
      {
        name: 'contentFontStyleParent',
        label: 'Content Font Style',
        type: FieldTypes.select,
        defaultValue: 'normal',
        rowSize: 4,
        options: fontStyles,
      },
      {
        name: 'contentTextDecorationParent',
        label: 'Content Text Decoration',
        type: FieldTypes.select,
        defaultValue: 'none',
        rowSize: 4,
        options: textDecorations,
      },
    ],
  },
  {
    name: 'borderSettings',
    label: 'Border Settings',
    type: FieldTypes.group,
    rowSize: 4,
    fields: [
      {
        name: 'borderWidth',
        label: 'Border Font Size',
        type: FieldTypes.select,
        defaultValue: '1px',
        rowSize: 4,
        options: borderSizes,
      },
      {
        name: 'borderColor',
        label: 'Border color',
        type: FieldTypes.select,
        defaultValue: 'black',
        rowSize: 4,
        options: textColorOptions,
        isColor: true,
      },
      {
        name: 'borderDecoration',
        label: 'Border Decoration',
        type: FieldTypes.select,
        defaultValue: 'solid',
        rowSize: 4,
        options: borderDecorations,
      },
      {
        name: 'forceRtlChildren',
        label: 'Force RTL Children Items',
        type: FieldTypes.checkbox,
        defaultValue: false,
        rowSize: 2,
      },
      {
        name: 'reverseDirection',
        label: 'Reverse direction',
        type: FieldTypes.checkbox,
        defaultValue: false,
        rowSize: 2,
      },
    ],
  },
];

export default headerCityPeople;
