'use client';

import useSWR from 'swr';
import axios, { AxiosRequestConfig } from 'axios';

import { HOST_API_KEY, XTRA_API_KEY, RAAYONIT_API_KEY } from 'src/config-global';

// ----------------------------------------------------------------------

export enum hosts {
  api = 'api',
  extra = 'extra',
  raayonit = 'raayonit',
}

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const GetAPI = (url: string, host?: hosts) => {
  let urlPrefix: string | undefined;

  switch (host) {
    case hosts.raayonit:
      urlPrefix = RAAYONIT_API_KEY;
      break;
    case hosts.extra:
      urlPrefix = XTRA_API_KEY;
      break;
    default:
      urlPrefix = HOST_API_KEY;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useSWR(`${urlPrefix}${url}`, fetcher);
};

export const postAPI = async (url: string, data: any, host?: hosts) => {
  let urlPrefix: string | undefined;

  switch (host) {
    case hosts.raayonit:
      urlPrefix = RAAYONIT_API_KEY;
      break;
    case hosts.extra:
      urlPrefix = XTRA_API_KEY;
      break;
    default:
      urlPrefix = HOST_API_KEY;
  }

  const res = await axiosInstance.post(`${urlPrefix}${url}`, data);
  return res;
};

export const API = {
  get: GetAPI,
  post: postAPI,
};

// ----------------------------------------------------------------------

export const endpoints = {
  merchant: {
    byDomain: '/merchant?domain=',
  },
  generator: {
    getSections: '/siteBuilder/getAllSectionsByActivityId',
  },
};
