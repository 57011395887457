'use client';

import Carousel from 'react-slick';
import { useRouter } from 'next/navigation';
import { FC, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Grid, Button, Container } from '@mui/material';

import Image from 'src/components/image';

import Iconify from '../../../../components/iconify';
import { getContentValueFromProps } from '../../utils';
import { providers } from '../../../../_mock/_providers';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';

const Providers: FC<ComponentTypeProps> = ({ block }) => {
  const getFieldValue = getContentValueFromProps(block);
  const isMobile = useResponsive('down', 'sm');

  const [showAllProviders, setShowAllProviders] = useState(false);

  const {
    forceRtl,
    headingTitleFontSize,
    headingTitleFontStyle,
    headingTitleFontWeight,
    headingTitleTextDecoration,
    headingTextAlignItems,
    firstGradientColorStop,
    secondGradientColorStop,
    thirdGradientColorStop,
    firstGradientColor,
    secondGradientColor,
    thirdGradientColor,
    addGradientStyle,
    headingTitleTextColor,
  } = block?.settings || {};

  const mainTitle = useMemo(() => getFieldValue('mainTitle'), [block?.mainTitle]);
  const router = useRouter();
  const firstStop = firstGradientColorStop ? `${firstGradientColorStop}%` : '40%';
  const secondStop = secondGradientColorStop ? `${secondGradientColorStop}%` : '80%';
  const thirdStop = thirdGradientColorStop ? `${thirdGradientColorStop}%` : '100%';

  const colorTitle = addGradientStyle
    ? {
        background: `linear-gradient(90deg, ${firstGradientColor} ${firstStop}, ${secondGradientColor} ${secondStop}, ${thirdGradientColor} ${thirdStop} )`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
      }
    : { color: headingTitleTextColor };

  const toggleShowAllProviders = () => {
    if (block?.settings?.view === 'v1') {
      setShowAllProviders((prev) => !prev);
    } else if (block?.settings?.view === 'v2') router.push('/providers');
  };
  const settings = {
    rtl: block?.settings?.rtl,
  };

  return (
    <>
      {block?.settings.variant === 'v1' ? (
        <>
          {block?.settings.enableTitle && (
            <Stack direction="row" justifyContent="flex-start">
              <Typography
                sx={{
                  direction: forceRtl ? 'rtl' : undefined,
                  fontSize: isMobile ? '20px' : headingTitleFontSize,
                  fontStyle: headingTitleFontStyle,
                  fontWeight: headingTitleFontWeight,
                  textDecoration: headingTitleTextDecoration,
                  textAlign: headingTextAlignItems,
                  ...colorTitle,
                }}
              >
                {mainTitle}
              </Typography>
            </Stack>
          )}
        </>
      ) : (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            sx={{
              direction: forceRtl ? 'rtl' : undefined,
              fontSize: isMobile ? '20px' : headingTitleFontSize,
              fontStyle: headingTitleFontStyle,
              fontWeight: headingTitleFontWeight,
              textDecoration: headingTitleTextDecoration,
              textAlign: headingTextAlignItems,
              ...colorTitle,
            }}
          >
            {mainTitle}
          </Typography>
          <Button
            variant="text"
            endIcon={<Iconify icon="fluent:ios-arrow-24-filled" />}
            sx={{ textDecoration: 'underline' }}
            onClick={toggleShowAllProviders}
          >
            {block?.settings?.view === 'v2' && <>{showAllProviders ? 'הסתר' : 'לכל הספקים'}</>}
          </Button>
        </Stack>
      )}
      {block?.settings.variant === 'v1' ? (
        <Stack alignItems="center" width={1}>
          <Container maxWidth="xl">
            <Carousel autoplay arrows={false} slidesToShow={isMobile ? 3 : 5} {...settings}>
              {providers?.map((item, id) => (
                <Image
                  key={id}
                  onClick={() => router.push(`/provider/${item.id}`)}
                  src={item.image}
                  sx={{
                    borderRadius: 1.25,
                    width: '180x',
                    height: isMobile ? '100px' : '180px',
                  }}
                />
              ))}
            </Carousel>
          </Container>
        </Stack>
      ) : (
        <Grid container spacing={1}>
          {providers.slice(0, showAllProviders ? providers.length : 5).map((item, index) => (
            <Grid
              item
              md={2.4}
              xs={2.4}
              key={index}
              onClick={() => router.push(`/provider/${item.id}`)}
            >
              <Image
                src={item.image}
                sx={{
                  borderRadius: 1.25,
                  width: '100%',
                  height: '100%',
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Providers;
