'use client';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

// components
import Iconify from 'src/components/iconify';

import { Ib2bTableFilter } from '../../types/b2b';

type Props = {
  filters?: Ib2bTableFilter | any;
};

const MAX_SLIDER_VALUE = 1400;

export default function Filterv2({ filters }: Props) {
  const { t } = useTranslation();
  const [valueSlider, setValue] = useState<number[]>([0, MAX_SLIDER_VALUE]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  return (
    <Box sx={{ direction: 'rtl' }}>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
          mb: 3,
        }}
      >
        <IconButton onClick={() => {}}>
          <Iconify icon="basil:trash-solid" color="secondary.main" />
        </IconButton>

        {/* Bullshit code!!! */}
        <div>
          <Button
            variant="contained"
            sx={{
              width: '200px',
              px: 2,
              borderRadius: '20px',
              bgcolor: 'primary.light',
              color: 'secondary.contrastText',
              '&:hover': {
                bgcolor: 'primary.light',
                color: 'secondary.contrastText',
              },
            }}
          >
            {/* <Image src="/assets/images/happy-gift/present-icon.png" ml={1} /> */}
            לחפש
          </Button>
        </div>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ '& .MuiTypography-root': { color: 'primary.main' } }}
            >
              <Typography sx={{ fontWeight: 800 }}>0 - {MAX_SLIDER_VALUE} ₪</Typography>
              <Typography>תקציב</Typography>
            </Stack>
            <Slider
              value={valueSlider}
              min={0}
              max={MAX_SLIDER_VALUE}
              onChange={handleChange}
              valueLabelDisplay="auto"
            />
          </Stack>
        </FormControl>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>{t('קטגוריה')}</InputLabel>

          <Select
            multiple
            value={filters?.category}
            input={<OutlinedInput label="קטגוריה" />}
            renderValue={(selected) => selected.map((value: any) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          />
        </FormControl>

        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            sx={{ '& input': { direction: 'ltr' } }}
            fullWidth
            value={filters?.name}
            placeholder={t('חיפוש...')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
