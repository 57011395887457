'use client';

import Slider from 'react-slick';
import { format } from 'date-fns';
import { useParams } from 'next/navigation';
import React, { FC, useRef, useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import { useLocales } from '../../../../locales';
import { fetchJSON } from '../../../../api/common';
import { endpoints } from '../../../../utils/axios';
import Iconify from '../../../../components/iconify';
import { getContentValueFromProps } from '../../utils';
import { useAuthContext } from '../../../../auth/hooks';
import { useAppContext } from '../../../../contexts/AppContext';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ComponentTypeProps } from '../../../../types/page-generator';
import TestimonialModal from '../../../../components/modals/add-testimonial';
import { uploadProductsImage } from '../../../../helpers/uploadProductsImage';
import {
  getTestimonialsByPageId,
  getTestimonialsByProductId,
  getTestimonialsByMerchantId,
} from '../../../../api/testimonials';

const CustomTestimonials: FC<ComponentTypeProps> = ({ block }) => {
  const isMobile = useResponsive('down', 'sm');
  const { user } = useAuthContext();
  const { state, dispatch } = useAppContext();
  const { productTestimonials, merchantTestimonials, pageTestimonials } = state;
  const { currentLang } = useLocales();
  const getFieldValue = getContentValueFromProps(block);
  const [carouselData, setCarouselData] = useState<any>([]);
  const [sections, setSection] = useState<{ id: string; name: string }[]>([]);
  const params = useParams();
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [maxHeight, setMaxHeight] = useState(0);
  const [openTestimonialModal, setOpenTestimonialModal] = useState(false);
  const excludedTitles = [
    'themeSettings',
    'generalSettings',
    'popups',
    'product',
    'merchant',
  ];

  const contentTitleTextColor = useMemo(() => getFieldValue('settings.contentTitleTextColor'), [getFieldValue]);
  const contentTitleFontSize = useMemo(() => getFieldValue('settings.contentTitleFontSize'), [getFieldValue]);
  const contentTitleFontWeight = useMemo(() => getFieldValue('settings.contentTitleFontWeight'), [getFieldValue]);
  const contentTitleFontStyle = useMemo(() => getFieldValue('settings.contentTitleFontStyle'), [getFieldValue]);
  const contentTitleTextDecoration = useMemo(() => getFieldValue('settings.contentTitleTextDecoration'), [getFieldValue]);
  const contentTextAlignItems = useMemo(() => getFieldValue('settings.contentTextAlignItems'), [getFieldValue]);
  const forceRtl = useMemo(() => getFieldValue('settings.forceRtl'), [getFieldValue]);
  const activityId = state.smbActivityId || '';
  const currentPageId = useMemo(() => {
    const title = params.title === undefined ? 'home' : params.title;
    const section = sections.find((item) => item.name === title);
    return section ? section.id : '';
  }, [sections, params.title]);
  const pageName = useMemo(() => {
    const title = params.title === undefined ? 'home' : params.title;
    const section = sections.find((item) => item.name === title);
    return section ? section.name : '';
  }, [sections, params.title]);

  const fetchSection = async () => {
    const result = await fetchJSON(endpoints.generator.getSections, {
      activityId,
    });
    const titlesArray = Array.isArray(result)
      ? result
        .filter(
          (section) => !excludedTitles.includes(section.sectionType ?? ''),
        )
        .map((section) => ({
          id: section.id,
          name: section.url
            ? section.url.replace(/\//g, '')
            : section.sectionType,
        }))
      : [];
    setSection(titlesArray);
  };

  useEffect(() => {
    if (block?.settings?.enableForThePage) {
      fetchSection();
    }
  }, [block?.settings?.enableForThePage]);


  const init = async () => {
    if (params.title === 'product') {
      try {
        await getTestimonialsByProductId(params.id, dispatch);
      } catch (error) {
        console.error(error);
      }
    }
    if (params.title === 'merchant') {
      try {
        await getTestimonialsByMerchantId(params.id, dispatch);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const initForPage = async () => {
    try {
      await getTestimonialsByPageId(currentPageId, dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (currentPageId) {
      initForPage();
    }
    init();
  }, [currentPageId, user]);

  const setData = useCallback(async () => {
    if (!block?.settings?.enableForThePage) {
      if (params.title === 'product') {
        const data = await Promise?.all(
          productTestimonials?.map(async (item: any) =>
            // const merchant = await getMerchant(item.merchantId, dispatch);
            ({
              avatar: item.customer ? `${process.env.NEXT_PUBLIC_HOST_API}/${item.customer.avatar}` : `${process.env.NEXT_PUBLIC_HOST_API}/${item.extra.photo}`,
              name: item.customer ? `${item.customer.firstName} ${item.customer.lastName}` : 'Anonymous',
              date: format(new Date(item.createdAt), 'dd.MM.yyyy'),
              rating: item.rating,
              text: item.description,
              // company: merchant?.mainImageURL || '',
              company: '',
            }),
          ),
        );
        setCarouselData(data);
      }
      if (params.title === 'merchant') {
        const data = await Promise.all(
          merchantTestimonials?.map(async (item: any) =>
            // const merchant = await getMerchant(item.merchantId, dispatch);
            ({
              avatar: item.customer ? `${process.env.NEXT_PUBLIC_HOST_API}/${item.customer.avatar}` : `${process.env.NEXT_PUBLIC_HOST_API}/${item.extra.photo}`,
              name: item.customer ? `${item.customer.firstName} ${item.customer.lastName}` : 'Anonymous',
              date: format(new Date(item.createdAt), 'dd.MM.yyyy'),
              rating: item.rating,
              text: item.description,
              // company: merchant?.mainImageURL || '',
              company: '',
            }),
          ),
        );
        setCarouselData(data);
      }
    } else {
      const data = await Promise.all(
        pageTestimonials?.map(async (item: any) =>
          ({
            avatar: item.customer ? `${process.env.NEXT_PUBLIC_HOST_API}/${item.customer.avatar}` : `${process.env.NEXT_PUBLIC_HOST_API}/${item.extra.photo}`,
            name: item.customer ? `${item.customer.firstName} ${item.customer.lastName}` : 'Anonymous',
            date: format(new Date(item.createdAt), 'dd.MM.yyyy'),
            rating: item.rating,
            text: item.description,
            company: '',
          }),
        ),
      );
      setCarouselData(data);
    }
  }, [productTestimonials, merchantTestimonials, pageTestimonials, dispatch]);

  useEffect(() => {
    if (cardRefs.current.length) {
      const heights = cardRefs.current.map(ref => ref?.offsetHeight || 0);
      setMaxHeight(Math.max(...heights));
    }
  }, [carouselData]);

  useEffect(() => {
    setData();
  }, [productTestimonials, merchantTestimonials, pageTestimonials]);

  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '-35px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      {currentLang.value === 'he' ? (
        <Iconify icon="bi:arrow-right-circle-fill" sx={{ color: '#EB157B' }} />
      ) : (
        <Iconify icon="bi:arrow-left-circle-fill" sx={{ color: '#EB157B' }} />
      )}
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '50%',
        right: '-35px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      {currentLang.value === 'he' ? (
        <Iconify icon="bi:arrow-left-circle-fill" sx={{ color: '#EB157B' }} />
      ) : (
        <Iconify icon="bi:arrow-right-circle-fill" sx={{ color: '#EB157B' }} />
      )}
    </Box>
  );

  const settings = {
    dots: true,
    arrows: carouselData.length !== 1,
    infinite: carouselData.length >= 3,
    speed: 500,
    slidesToShow: Math.min(carouselData.length, isMobile ? 1 : 3),
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <Box
      sx={{
        display: !carouselData.length ? 'none' : 'block',
        mb: 5,
        '& img': {
          objectFit: 'contain',
        },
        '& .slick-track': {
          display: 'flex',
          gap: 1,
        },
        '& .slick-slide': {
          width: isMobile ? '250px!important' : '320px!important',
        },
        '& .slick-slider': {
          '& .slick-arrow': {
            backgroundColor: '#EB157B',
            borderRadius: '50%',
          },
          '& .slick-dots': {
            '& .slick-active button::before': {
              color: '#EB157B !important',
            },
            '& li button::before': {
              color: '#F26BAB !important',
            },
          },
        },
      }}
    >
      {block?.settings?.enableButtonAdd && (
        <Iconify icon="ic:outline-feedback" onClick={() => {
          setOpenTestimonialModal(true);
        }} sx={{ cursor: 'pointer' }} />
      )}
      <Box sx={{ margin: 'auto', width: isMobile ? '80%' : '100%' }}>
        <Slider {...settings}>
          {carouselData.map((item: any, index: number) => (
            <Card
              key={index}
              sx={{
                boxShadow: 3,
                width: isMobile ? '100%' : '320px!important',
                height: maxHeight ? `${maxHeight}px` : 'auto',
                mx: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Stack direction="row" justifyContent="space-between">
                <Image src={uploadProductsImage(item.company)} height="30px" sx={{ objectFit: 'contain' }} />
                <Stack direction="row" gap={1}>
                  <Stack alignItems="flex-end">
                    <Typography sx={{ color: 'primary.main', fontSize: '12px', fontWeight: 600 }}>
                      {item.name}
                    </Typography>
                    <Typography sx={{ color: 'grey.600', fontSize: '10px' }}>
                      {item.date}
                    </Typography>
                    <Rating name="read-only" value={item.rating} readOnly size="small" />
                  </Stack>
                  <Image
                    sx={{ width: '50px', height: '50px', borderRadius: '50%', '& img': { objectFit: 'cover' } }}
                    src={item.avatar} />
                </Stack>
              </Stack>
              <Box sx={{ height: '60px', overflow: 'hidden' }}> {/* Фиксированная высота для текста */}
                <Typography
                  sx={{
                    textAlign: contentTextAlignItems,
                    color: contentTitleTextColor,
                    fontSize: contentTitleFontSize,
                    fontWeight: contentTitleFontWeight,
                    fontStyle: contentTitleFontStyle,
                    textDecoration: contentTitleTextDecoration,
                    direction: forceRtl ? 'rtl' : '',
                    wordBreak: 'break-word',
                    px: 1,
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            </Card>
          ))}
        </Slider>
      </Box>
      <Modal
        open={openTestimonialModal}
        onClose={() => setOpenTestimonialModal(false)}
      >
        <TestimonialModal
          openTestimonialModal={openTestimonialModal}
          setOpenTestimonialModal={setOpenTestimonialModal}
          productId={currentPageId}
          page
          pageName={pageName}
        />
      </Modal>
    </Box>
  );
};

export default CustomTestimonials;
