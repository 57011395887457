'use client';

import React from 'react';

// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
import ListItemText from '@mui/material/ListItemText';
import Paper, { PaperProps } from '@mui/material/Paper';

// components
import Iconify from 'src/components/iconify';

import Image from '../../../components/image';
import { useTranslate } from '../../../locales';
import { ICheckoutDeliveryOption } from '../../../types/checkout';
import { fCurrency } from '../../../utils/format-number';
// types

// ----------------------------------------------------------------------

type Props = CardProps & {
  options: ICheckoutDeliveryOption[];
  isDeliveryEnable: boolean;
  onChange: (option: ICheckoutDeliveryOption) => void;
  selectedOption: ICheckoutDeliveryOption;
};

export default function Delivery({
  options,
  isDeliveryEnable,
  onChange,
  selectedOption,
  ...other
}: Props) {
  const { t } = useTranslate();

  return (
    <Card {...other}>
      <CardHeader title={`${t('Delivery')}`} />
      <Box
        columnGap={2}
        rowGap={2.5}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
        sx={{ p: 3 }}
      >
        {options.map((option) => (
          <OptionItem
            key={option.id}
            option={option}
            selected={selectedOption.id === option.id}
            onClick={() => onChange(option)}
          />
        ))}
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

type OptionItemProps = PaperProps & {
  option: ICheckoutDeliveryOption;
  selected: boolean;
  onClick: () => void;
};

function OptionItem({ option, selected, onClick, ...other }: OptionItemProps) {
  const { price, label, description, imageUrl } = option;
  const { t } = useTranslate();
  return (
    <Paper
      variant="outlined"
      onClick={onClick}
      sx={{
        p: 2.5,
        cursor: 'pointer',
        display: 'flex',
        ...(selected && {
          boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
        }),
      }}
      {...other}
    >
      {imageUrl && (
        <Box sx={{ width: 32, height: 32 }}>
          {imageUrl.includes('/') ? (
            <Image src={imageUrl} sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          ) : (
            <Iconify icon={imageUrl} />
          )}
        </Box>
      )}

      <ListItemText
        sx={{ ml: 2 }}
        primary={
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={{ flexGrow: 1 }}>
              {`${t(`${label}`)}`}
            </Box>
            {typeof price === 'string' && (
              <Box component="span" sx={{ typography: 'h6' }}>
                {fCurrency(price)}
              </Box>
            )}
          </Stack>
        }
        secondary={`${t(`${description}`)}`}
        primaryTypographyProps={{ typography: 'subtitle1', mb: 0.5 }}
        secondaryTypographyProps={{ typography: 'body2' }}
      />
    </Paper>
  );
}
