export type FAQItem = {
  question: string;
  answer: string;
};

export const FAQ_CITY_PEOPLE: FAQItem[] = [
  {
    question: 'אודות הפרויקט ',
    answer:
      'ברוכים הבאים הביתה!.\nאפליקציה זו נוצרה עבורכם, בעלי הדירות המשתייכים לקהילת אנשי העיר, בכדי להקל עליכם בניהול התקציבים הנועדו לשדרוג דירתכם ובתשלומים לספקים הנבחרים. חשוב לנו להפוך את תהליך ניהול התקציבים והתשלום לספקים לקל ופשוט ככל האפשר באמצעות האפליקציה. כאן תוכלו לנהל את התקציב היישר מן הנייד בנוחות מקסימלית וללא פשרות.',
  },
  {
    question: 'האם הספקים באפליקציה זו בטוחים ואמינים?',
    answer:
      'כלל ומבחר הספקים שלנו אמינים ומבוקרים בהחלט, בעלי רישיון והוכרה מזה שנים רבות. כלל הספקים מוכרים בשל איכותם, מגוון מוצריהם הרחב ושירותם המופלא.',
  },
  {
    question: 'כיצד ניתן לשלם? / מהם אמצעי התשלום?',
    answer:
      'פשוט וקל. לוחצים על תקציב מסוים, בוחרים ספק, מזינים את הסכום הנדרש ומצלמים את קבלת ההזמנה.',
  },
  {
    question: 'מה ניתן לראות את היסטוריית התשלומים שלי?',
    answer:
      'בהיסטוריית התשלומים ניתן לראות את כל פעולות התשלום. ניתן לראות אל מי נשלח, מתי נשלח, תוכן התשלום והסכום, כולל צילום המסמך החתום.',
  },
  {
    question: 'אילו סוגי תקציבים יש?',
    answer:
      'התקציבים מחולקים לפי קטגוריות כגון: כלים סניטרים, שיפוצים, קרמיקה, מטבח, אמבטיה ושירותים וכו"... אין אפשרות להעביר ו/או לשתף בין סוגי תקציב שונים. סכום התקציב צבוע לסוג ספציפי אחד.',
  },
];
