'use client';

import { FC } from 'react';

import BlogPostPage from './blog/BlogPostPage';
import { ComponentTypeProps } from '../../../../types/page-generator';

const Blog: FC<ComponentTypeProps> = ({ block }) => <BlogPostPage />;

export default Blog;
