'use client';

import { useMemo } from 'react';
import Slider from 'react-slick';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { getContentValueFromProps } from '../../utils';
import { BlockType } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';
import { useLocales } from '../../../../locales';

type Props = {
  block: BlockType | undefined;
};

type CategoryType = {
  value: string;
  title: string;
};

export const CustomSlider = ({ block }: Props) => {
  const getFieldValue = getContentValueFromProps(block);
  const {currentLang} = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const categoriesImages = useMemo(
    () => getFieldValue('categoriesImages'),
    [block?.categoriesImages]
  );

  const categories = useMemo(() => {
    const categoriesValue = getFieldValue('categories');
    if (Array.isArray(categoriesValue)) {
      return categoriesValue.map((category: CategoryType) => ({
        ...category,
        image: categoriesImages[category.value] ?? '/assets/img-placeholder.svg',
      }));
    }
    return [];
  }, [block?.categories, categoriesImages]);

  let sliderRef: Slider | any;
  const CustomPrevArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        right: isMobile ? '-25px' : '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      {currentLang.value === 'he' ? (
      <Iconify icon="ep:arrow-left-bold" color="#EB157B" />
      ) : (
        <Iconify icon="ep:arrow-right-bold" color="#EB157B" />
      )}
    </Box>
  );

  const CustomNextArrow = ({ onClick }: any) => (
    <Box
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: isMobile ? '-25px' : '-40px',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 100,
      }}
    >
      {currentLang.value === 'he' ? (
        <Iconify icon="ep:arrow-right-bold" color="#EB157B" />
      ) : (
        <Iconify icon="ep:arrow-left-bold" color="#EB157B" />
      )}
    </Box>
  );

  const handlePrevClick = () => {
    sliderRef.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.slickNext();
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 2 : 4,
    slidesToScroll: 1,
    nextArrow: <CustomPrevArrow onClick={handlePrevClick} />,
    prevArrow: <CustomNextArrow onClick={handleNextClick} />,
  };

  return (
    <Box
      sx={{
        width: isMobile ? '90%' : '90%',
        height: '100%',
        mt: 5,
      }}
    >
      <Slider
        ref={function (slider) {
          sliderRef = slider;
        }}
        {...settings}
      >
        {categories.map((item, index) => (
          <Box key={index} sx={{ position: 'relative', borderRadius: 1.25, p: 1 }}>
            <Image
              src={item.image}
              sx={{ borderRadius: 1.25, width: isMobile ? '140px' : '100%' }}
            />
            <Typography
              sx={{
                direction: block?.settings.forceRtl ? 'rtl' : undefined,
                color: block?.settings.contentTitleTextColor,
                fontSize: block?.settings.contentTitleFontSize,
                fontStyle: block?.settings.contentTitleFontStyle,
                fontWeight: block?.settings.contentTitleFontWeight,
                textDecoration: block?.settings.contentTitleTextDecoration,
                textAlign: block?.settings.contentTextAlignItems,
                bgcolor: 'rgba(255, 255, 255, 0.7)',
                position: 'absolute',
                bottom: isMobile ? '8px' : '0px',
                width: isMobile ? '95%' : '100%',
                height: '35%',
                pt: '10px',
              }}
            >
              {item.title}
            </Typography>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
