import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import { useForm } from 'react-hook-form';
import { Swiper as SwiperCore } from 'swiper/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState, useCallback, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';

import Iconify from '../iconify';
import { useTranslate } from '../../locales';
import { IMerchant } from '../../types/merchant';
import MerchantCard from '../cards/merchant-card';
import { useBoolean } from '../../hooks/use-boolean';
import { useResponsive } from '../../hooks/use-responsive';
import WalletToolBar from '../../sections/wallet/components/wallet-tool-bar';
import { IWalletProductTableFilter, IWalletProductTableFilterValue } from '../../types/wallet';
import { getMerchants } from '../../api/merchant';
import { useAppContext } from '../../contexts/AppContext';

type Props = {
  handleCloseAcceptingCardsModal: Function;
};

export const CanBeUsed = ({ handleCloseAcceptingCardsModal }: Props) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const { state, dispatch } = useAppContext();
  const isMobile = useResponsive('down', 'sm');
  const smUp = useResponsive('up', 'sm');
  const payHere = useBoolean();
  const success = useBoolean();
  const { t } = useTranslate();
  const pagesCount = Math.round(state.merchants && state.merchants.length / 10) || 1; // TEMPORARY

  useEffect(() => {
    getMerchants({ dispatch });
  }, []);

  const defaultFilters: IWalletProductTableFilter = {
    name: '',
    type: [],
    category: [],
    area: [],
  };
  const [filters, setFilters] = useState(defaultFilters);
  const canReset = !isEqual(defaultFilters, filters);

  const dataFiltered = applyFilter({
    inputData: state.merchants,
    filters,
  });

  const handleFilters = (name: string, filterValue: IWalletProductTableFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: filterValue,
    }));
  };

  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  const extraButton = (
    <Button
      variant="outlined"
      color="secondary"
      sx={{
        mt: 3,
        borderRadius: '20px',
        color: 'primary.main',
        px: { xs: 1, md: 1 },
      }}
      onClick={payHere.onTrue}
    >
      {t('Pay here')}
    </Button>
  );

  const filterView = (
    <WalletToolBar
      filters={filters}
      onFilters={handleFilters}
      //
      typeOptions={[]}
      categoryOptions={[]}
      areaOptions={[]}
      handleResetFilters={handleResetFilters}
      canReset={canReset}
      handleCloseAcceptingCardsModal={handleCloseAcceptingCardsModal}
    />
  );
  return (
    <Stack direction="column" alignItems="center">
      <>{filterView}</>
      <Stack
        flexWrap="wrap"
        flexDirection="row-reverse"
        justifyContent={smUp ? '' : 'center'}
        gap={3}
      >
        {isMobile ? (
          <Box
            sx={{
              '& .swiper-slide': { width: 'auto', margin: 1 },
              '& .swiper-wrapper': { justifyContent: 'center' },
            }}
          >
            <Box
              sx={{
                width: '90vw',
              }}
            >
              <Swiper
                speed={500}
                slidesPerView="auto"
                mousewheel={{
                  forceToAxis: true,
                  sensitivity: 1,
                  releaseOnEdges: true,
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
              >
                {dataFiltered?.map((merchant) => (
                  <SwiperSlide>
                    <MerchantCard key={merchant.id} merchant={merchant} extraButton={extraButton} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
        ) : (
          <>
            {dataFiltered?.map((merchant) => (
              <MerchantCard key={merchant.id} merchant={merchant} extraButton={extraButton} />
            ))}
          </>
        )}
      </Stack>
      <Pagination
        count={pagesCount}
        color="primary"
        sx={{ margin: smUp ? '16px auto' : '0px', '& ul': { width: smUp ? '100%' : '110%' } }}
      />
    </Stack>
  );
};

function applyFilter({
  inputData,
  filters,
}: {
  inputData: IMerchant[];
  filters: IWalletProductTableFilter;
}) {
  const {
    name,
    // area,
    // type,
    // category,
  } = filters;

  if (name) {
    inputData = inputData.filter(
      (product) => product.title.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  // if (area.length) {
  //   inputData = inputData.filter((product) => area.includes(product.area));
  // }

  // if (type.length) {
  //   inputData = inputData.filter((product) => type.includes(product.type));
  // }

  // if (category.length) {
  //   inputData = inputData.filter((product) => category.includes(product.category));
  // }

  return inputData;
}
