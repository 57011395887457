'use client';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import { useResponsive } from '../../../../hooks/use-responsive';
import BigZipLine from '../../../../assets/icons/happy-gift/bigZipLine';

const FourCardsBanner = () => {
  const smDown = useResponsive('down', 'sm');
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '20px',
        backgroundSize: 'cover',
        padding: !smDown ? 10 : '',
        mt: !smDown ? '-100px' : '-50px',
      }}
    >
      <Stack alignItems="center" gap={1}>
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 800,
            color: '#3A0D5E',
            textAlign: smDown ? 'center' : '',
          }}
        >
          הצעות ייחודיות למחזיקי כרטיסים הבאים
        </Typography>
        <BigZipLine width={!smDown ? '70%' : '90%'} />
        <Image src="/assets/images/happy-gift/cards-group.png" />
      </Stack>
    </Box>
  );
};

export default FourCardsBanner;
