'use client';

import NextLink from 'next/link';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';

import { CartInterface } from 'src/types/cart';

import CheckoutSummary from './checkout-summary';
import { useAppContext } from '../../contexts/AppContext';
import CheckoutCartProductList from './checkout-cart-product-list';

interface Props {
  basketData: CartInterface[];
  checkoutNextStep: VoidFunction;
  total: number;
  totalSum: number;
}

export default function CheckoutCart({ basketData, checkoutNextStep, total, totalSum }: Props) {
  const isEmptyCart = !basketData.length;
  const { t } = useTranslation();
  const [deliveryCost, setDeliveryCost] = useState(0);

  useEffect(() => {
    let result = 0;
    basketData.map((item) => {
      if (item.deliveryOptionId) {
        const price = item.deliveryOptionId ? Number(item.deliveryOption?.price) : 0;
        result += price;
      }
      setDeliveryCost(result);
      return result;
    });
  }, [basketData]);

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={8}>
        <Card sx={{ mb: 3 }}>
          <CardHeader
            title={
              <Typography variant="h6">
                {`${t('Cart')}`}
                <Typography component="span" sx={{ color: 'text.secondary' }}>
                  &nbsp;{`${t(`(${basketData.length}`)}`} {`${t('item')})`}
                </Typography>
              </Typography>
            }
            sx={{ mb: 3 }}
          />

          {isEmptyCart ? (
            <EmptyContent
              title={`${t('Cart is Empty!')}`}
              description={`${t('Look like you have no items in your shopping cart.')}`}
              imgUrl="/assets/icons/empty/ic_cart.svg"
              sx={{ pt: 5, pb: 10 }}
            />
          ) : (
            <CheckoutCartProductList basketData={basketData} />
          )}
        </Card>

        <Button
          component={NextLink}
          href="/"
          color="inherit"
          endIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
        >
          {`${t('Continue Shopping')}`}
        </Button>
      </Grid>

      <Grid xs={12} md={4}>
        <CheckoutSummary
          totalSum={totalSum}
          discount={0}
          subTotal={total}
          onApplyDiscount={() => {}}
          shipping={deliveryCost}
        />

        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={isEmptyCart}
          onClick={checkoutNextStep}
        >
          {`${t('Check Out')}`}
        </Button>
      </Grid>
    </Grid>
  );
}
