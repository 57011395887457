import { kebabCase } from 'lodash';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import Label from 'src/components/label';

import { fToNow } from 'src/utils/format-time';
import { useTranslate } from 'src/locales';

import { NotificationType } from '../types';
import { reader } from '../ common';

export default function ActionTags({ notification }: { notification: NotificationType }) {
  const { data, createdAt } = notification;
  const { tags, userName, projectName } = data;

  const { t } = useTranslate();

  return (
    <>
      <ListItemText
        disableTypography
        primary={reader(
          `<p><strong>${userName}</strong> ${t('added tags to')} <strong>${projectName}</strong></p>`
        )}
        secondary={
          <Stack
            direction="row"
            alignItems="center"
            sx={{ typography: 'caption', color: 'text.disabled' }}
            divider={
              <Box
                sx={{
                  width: 2,
                  height: 2,
                  bgcolor: 'currentColor',
                  mx: 0.5,
                  borderRadius: '50%',
                }}
              />
            }
          >
            {fToNow(createdAt)}
          </Stack>
        }
      />
      <Stack direction="row" spacing={0.75} flexWrap="wrap" sx={{ mt: 1.5 }}>
        {tags.map((tag) => (
          <Label key={kebabCase(tag.value)} variant="outlined" color="info">
            {t(tag.label)}
          </Label>
        ))}
      </Stack>
    </>
  );
}
