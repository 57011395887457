'use client';

import useSWR from 'swr';
import { m } from 'framer-motion';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState, useEffect, useCallback } from 'react';

// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Avatar, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

// utils
import axiosInstance, { fetcher, endpoints } from 'src/utils/axios';

// _mock
import { BOT_ID } from 'src/config-global';
import { useLocales, useTranslate } from 'src/locales';

// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import { CustomTabs } from 'src/components/custom-tabs';

import { reader } from './ common';
//
import NotificationItem from './notification-item';
import { ActionOptions, NotificationType } from './types';

// ----------------------------------------------------------------------

// TODO: обработка ошибок

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'unread',
    label: 'Unread',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];

// ----------------------------------------------------------------------

export default function NotificationsPopover({
  iconColor,
  notificationIcon,
}: {
  iconColor?: string;
  notificationIcon?: string;
}) {
  const drawer = useBoolean();

  const smUp = useResponsive('up', 'sm');
  const { currentLang } = useLocales();
  const { t } = useTranslate();

  const [currentTab, setCurrentTab] = useState('all');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const params = useMemo(
    () => ({ botId: BOT_ID, projectType: 'crm', lng: currentLang.value }),
    [currentLang]
  );

  const notificationsURL = useMemo(() => {
    if (drawer.value && currentTab === 'all') return [endpoints.notifications.getAll, { params }];
    if (drawer.value && currentTab === 'unread')
      return [endpoints.notifications.getUnread, { params }];
    if (drawer.value && currentTab === 'archived')
      return [endpoints.notifications.getRead, { params }];
    return null;
  }, [currentTab, drawer.value]);

  const {
    data: notificationsData,
    error: notificationsError,
    mutate,
  } = useSWR(notificationsURL, fetcher, {});
  const { data: countData, error: getCountError } = useSWR(
    [endpoints.notifications.getCount, { params: { botId: BOT_ID, projectType: 'crm' } }],
    fetcher,
    {
      refreshInterval: process.env.NODE_ENV === 'development' ? 5000 : 30000,
      shouldRetryOnError: false,
    }
  );
  const [notifications, setNotifications] = useState<NotificationType[]>(
    notificationsData?.payload ?? []
  );
  const counts = countData ?? { unread: 0, all: 0 };
  const { unread: unreadCount } = counts;

  useEffect(() => {
    if (notificationsData?.payload && !notificationsError) {
      const _notifications = [...notificationsData.payload].sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.highPriority === b.highPriority ? 0 : a.highPriority ? -1 : 1
      );
      setNotifications(_notifications);
    }
  }, [notificationsData]);

  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  // const handleMarkAllAsRead = () => {
  //   setNotifications(
  //     notifications.map((notification) => ({
  //       ...notification,
  //       isUnRead: false,
  //     })),
  //   );
  // };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {/* {!!totalUnRead && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )} */}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}

      <IconButton>
        <Iconify icon="solar:settings-bold-duotone" />
      </IconButton>
    </Stack>
  );

  const renderTabs = (
    <CustomTabs variant="fullWidth" value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {counts[tab.value]}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </CustomTabs>
  );

  // eslint-disable-next-line consistent-return
  const handleMutateNotification = (id: string, newValues: Partial<NotificationType>) => {
    let indx = -1;
    const item = notifications.find((notification: NotificationType, idx: number) => {
      const isCorrectItem = notification.id === id;

      if (isCorrectItem) indx = idx;
      return isCorrectItem;
    });
    if (indx > -1) {
      const newData = [...notifications];
      newData[indx] = { ...item, ...newValues } as NotificationType;
      if (item) return mutate({ payload: notifications });
    }
  };

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications.map((notification: NotificationType) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            mutate={handleMutateNotification}
          />
        ))}
      </List>
    </Scrollbar>
  );

  const theme = useTheme();

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={unreadCount} color="error">
          <Iconify
            icon={notificationIcon ?? 'mdi-light:bell'}
            color={iconColor ?? theme.palette.common.white}
            width={24}
          />
        </Badge>
      </IconButton>

      <DisplayPopups />

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        {renderTabs}
        {/* <IconButton onClick={handleMarkAllAsRead}>
          <Iconify icon="solar:settings-bold-duotone" />
        </IconButton> */}

        <Divider />

        {renderList}

        <Box sx={{ p: 1 }}>
          <Button fullWidth size="large">
            {t('View All')}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

function DisplayPopups() {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const { data: notificationsData, error: notificationsError } = useSWR(
    [
      endpoints.notifications.getUnread,
      { params: { botId: BOT_ID, projectType: 'crm', isPopup: 1 } },
    ],
    fetcher,
    {
      refreshInterval: process.env.NODE_ENV === 'development' ? 7000 : 30000,
      shouldRetryOnError: false,
    }
  );

  const { t } = useTranslate();

  useEffect(() => {
    if (notificationsData?.payload && !notificationsError) {
      const _notifications = [...notificationsData.payload].sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.highPriority === b.highPriority ? 0 : a.highPriority ? -1 : 1
      );
      setNotifications(_notifications);
    }
  }, [notificationsData]);

  const isOpen = useMemo(() => Boolean(notifications.length), [notifications]);

  const notification = useMemo(() => notifications[0], [notifications]);

  const handleClose = async (id: string) => {
    try {
      await axiosInstance.post(endpoints.notifications.markAsViewed, { ids: [id] });
      setNotifications(notifications.filter((_notification) => _notification.id !== id));
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: error.message });
    }
  };

  const handleAction = async (action: string, id: string, options?: ActionOptions) => {
    const { link } = options ?? { link: null };
    await axiosInstance.post(`${endpoints.notifications.action}/${id}/${action}`);
  };

  const theme = useTheme();

  return (
    notification && (
      <Dialog open={isOpen}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {reader(notification.data.title)}
            <IconButton onClick={() => handleClose(notification.id)}>
              <Iconify icon="mdi:close" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction={notification.data.iconAside ? 'row' : 'column'}
            spacing={3}
            alignItems={notification.data.iconAside ? 'flex-start' : 'center'}
          >
            {notification.data.icon && (
              <Avatar
                sx={{
                  width: 102,
                  height: 102,
                  bgcolor: notification.highPriority
                    ? theme.palette.primary.main
                    : theme.palette.secondary.light,
                }}
                variant="circular"
              >
                <Iconify icon={notification.data.icon} width={96} height={96} />
              </Avatar>
            )}
            {reader(notification.data.description)}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(notification.id)}>{t('Close')}</Button>
          {notification.data?.actions.map((action) => (
            <Button
              key={action.action}
              variant={action.variant}
              color={action.color}
              onClick={() =>
                handleAction(action.action, notification.id, {
                  ...(action.link && { link: action.link }),
                })
              }
              {...(action.startIcon && { startIcon: <Iconify icon={action.startIcon} /> })}
              {...(action.endIcon && { startIcon: <Iconify icon={action.endIcon} /> })}
            >
              {t(action.label)}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    )
  );
}
