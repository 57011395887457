'use client';

import Slider from 'react-slick';
import { useRouter } from 'next/navigation';
import { FC, useRef, useState, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Box, Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useBoolean } from 'src/hooks/use-boolean';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { ComponentTypeProps } from 'src/types/page-generator';

import { getContentValueFromProps } from '../../utils';
import { useWindowSize } from '../../../../hooks/use-window-size';

const BannerWithBackgroundPic: FC<ComponentTypeProps> = ({ block }) => {
  const router = useRouter();
  const getFieldValue = getContentValueFromProps(block);
  const { height } = useWindowSize();
  const drawer = useBoolean();
  const sliderRef = useRef<Slider>(null);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [icon, setIcon] = useState('');
  const [activeSlide, setActiveSlide] = useState(0);
  const drawers: any = block?.drawers || [];
  useEffect(() => {
    setBackgroundImage(getFieldValue('settings.backgroundImage'));
    setBannerImage(getFieldValue('settings.bannerImage'));
    setIcon(getFieldValue('icon'));
  }, [block]);

  useEffect(() => {
    setTimeout(() => {
      drawer.onTrue();
    }, 1000);
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    beforeChange: (current: any, next: any) => setActiveSlide(next),
  };

  const handleNext = () => {
    if (sliderRef.current && activeSlide !== drawers.length - 1) {
      sliderRef.current.slickNext();
    } else if (activeSlide === drawers.length - 1) router.push(`/${block?.redirect}`);
  };
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <Container maxWidth="sm">
      {/* this box for showcase */}
      {block?.settings?.enableICon && activeSlide !== 0 && (
        <Box
          sx={{
            border: '2px solid white',
            borderRadius: '10px',
            width: '40px',
            height: '40px',
            color: 'white',
            opacity: 0.5,
            position: 'absolute',
            margin: 1,
            top: 0,
            // right: params.lng === 'en' ? 0 : '',
            // left: params.lng === 'he' ? 0 : '',
            left: 0,
            zIndex: 2000,
          }}
          onClick={handlePrev}
        >
          <Iconify
            icon={icon}
            sx={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      )}
      {backgroundImage && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            background: '#FFFFFF',
            backgroundImage: `url('${backgroundImage}')`,
            backgroundPosition: '50%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}

      {bannerImage && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            m: '0 auto',
            width: `${block?.settings?.pictureWidth}%` || '80%',
            ...(height < 777
              ? {
                  mt: '10px',
                }
              : {
                  mt: '70px',
                  mb: '20px',
                }),
          }}
        >
          <Image
            src={bannerImage}
            sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
            alt="Banner Image"
          />
        </Box>
      )}
      {block?.settings?.enableDrawer && (
        <Drawer
          open={drawer.value}
          onClose={() => {}}
          anchor="bottom"
          slotProps={{
            backdrop: { invisible: true },
          }}
          PaperProps={{
            sx: {
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px',
              height: 'auto',
              maxHeight: '80vh',
              overflowY: 'auto',
            },
          }}
          sx={{
            '& .MuiPaper-root': {
              backgroundColor: 'white',
              backgroundImage: '',
            },
          }}
        >
          <Box
            sx={{
              height: 'auto',
              '& .slick-dots': {
                position: 'absolute',
                bottom: '70px',
                '& .slick-active': {
                  '& button': {
                    '&::before': {
                      color: `${block?.settings?.sliderColor}!important`,
                    },
                  },
                },
                '& li': {
                  '& button': {
                    width: '20%',
                    '&::before': {
                      color: `${block?.settings?.sliderColor}!important`,
                    },
                  },
                },
              },
            }}
          >
            <Stack gap={3}>
              <Slider {...settings} ref={sliderRef}>
                {drawers.map((item: any, index: number) => (
                  <Box key={index} sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        color: item?.mainTitleTextColor,
                        textAlign: item?.mainTitleTextAlignItems,
                        my: 3,
                        fontSize: item?.mainTitleFontSize,
                        fontWeight: item?.mainTitleFontWeight,
                        fontStyle: item?.mainTitleFontStyle,
                        width: item?.mainTitleTextWidth,
                        margin: '24px auto',
                      }}
                    >
                      {item?.mainTitle}
                    </Typography>
                    <Typography
                      sx={{
                        color: item?.subtitleTextColor,
                        textAlign: item?.subtitleTextAlignItems,
                        mb: 3,
                        fontSize: item?.subtitleFontSize,
                        fontWeight: item?.subtitleFontWeight,
                        fontStyle: item?.subtitleFontStyle,
                        width: item?.subtitleTextWidth,
                        margin: '24px auto',
                      }}
                    >
                      {item?.subtitle}
                    </Typography>
                    <Stack alignItems="center" width={1}>
                      <Button
                        fullWidth
                        variant={item?.buttonVariant || 'contained'}
                        size={item?.buttonSize || 'medium'}
                        onClick={handleNext}
                        sx={{
                          my: 2,
                          backgroundColor: item?.buttonBgColor,
                          color: item?.buttonColor,
                          borderRadius: item?.buttonBorderRadius,
                          width: '90vw',
                        }}
                      >
                        {item?.buttonLabel}
                      </Button>
                    </Stack>
                  </Box>
                ))}
              </Slider>
            </Stack>
          </Box>
        </Drawer>
      )}
    </Container>
  );
};

export default BannerWithBackgroundPic;
