'use client';

import { FC } from 'react';
import Box from '@mui/material/Box';
import { Button, Card, Container, Stack, Typography } from '@mui/material';

import { useLocales } from '../../../../locales';
import Iconify from '../../../../components/iconify';
import Image from '../../../../components/image';
import { ComponentTypeProps } from '../../../../types/page-generator';
import { useRouter } from '../../../../routes/hooks';

const serviceListArr = [
  {
    image: '/assets/images/home/service1.jpg',
    title: 'נקיון כניסה',
    timing: 'שני, חמישי 08:00 בבוקר',
    link: '/entrancecleaninghistory',
    titleAllServices: 'נקיון כניסה'
  },
  {
    image: '/assets/images/home/service2.jpg',
    title: 'תחזוקת מעליות',
    timing: 'יום חמישי הראשון לחודש',
    link: '/entrancecleaninghistory',
    titleAllServices: 'תחזוקת מעליות'
  },
  {
    image: '/assets/images/home/service3.jpg',
    title: 'פינוי זבל',
    timing: 'כל יום ב18:00',
    link: '/entrancecleaninghistory',
    titleAllServices: 'פינוי זבל'
  },
  {
    image: '/assets/images/home/service4.jpg',
    title: 'אבטחה',
    timing: '24/7',
    link: '/entrancecleaninghistory',
    titleAllServices: 'אבטחה'
  },
];

export const Services: FC<ComponentTypeProps> = ({ block }) => {
  const combined: any = block?.combined || [];
  const router = useRouter();

  return (
    <Container maxWidth="xs">
      <Stack gap={1}>
        {!block?.settings?.allServices && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width={1}
          >
            <Typography sx={{ fontWeight: 400 }}>השירותים שהוזמנו</Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: 400,
                cursor: 'pointer',
              }}
              onClick={() => router.push('/allservices')}
            >
              לכל השירותים {'>>'}
            </Typography>
          </Stack>
        )}
        <Stack width={1} gap={1}>
          {serviceListArr?.map((item: any, index: number) => (
            <Card
              key={index}
              component={Stack}
              sx={{
                width: '100%',
                height: '100px',
                borderRadius: '10px',
                backgroundSize: 'auto',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer',
              }}
              onClick={() => {
                router.push('/servicedetails');
              }}
            >
              <Image src={item.image} sx={{ width: '100%', height: '100%' }} />
              {block?.settings?.allServices ? (
                <Stack
                  className={item.contentClass}
                  gap={2}
                  width={1}
                  sx={{
                    position: 'absolute',
                    px: block?.settings?.allServices ? 1 : 3,
                    py: block?.settings?.allServices ? 1 : 3,
                    top: 0,
                    left: 0,
                    height: 1,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                  }}
                >
                  {/* {item.enableTitle && ( */}
                  <>
                    {block?.settings?.allServices ? (
                      <>
                        {item?.titleAllServices && (
                          <Typography
                            sx={{
                              borderRadius: '5px',
                              color: 'primary.main',
                              background: 'white',
                              width: 'auto',
                              padding: '4px 8px',
                              textAlign: 'left',
                              fontWeight: 1000,
                              mb: '10px'
                            }}
                            className={item.typographyClass}
                          >
                            {item?.titleAllServices}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <>
                        {/* {item?.title && ( */}
                        {/* <Typography */}
                        {/*  className={item.typographyClass} */}
                        {/*  sx={{ */}
                        {/*    width: '100%', */}
                        {/*    '& h1': { my: 1 }, */}
                        {/*    '& h2': { my: 1 }, */}
                        {/*    '& h3': { my: 1 }, */}
                        {/*    '& h4': { my: 1 }, */}
                        {/*    '& h5': { my: 1 }, */}
                        {/*    '& h6': { my: 1 }, */}
                        {/*    '& p': { margin: 0 }, */}
                        {/*    direction: */}
                        {/*      currentLang.value === 'he' ? 'ltr' : 'rtl', */}
                        {/*    lineHeight: item.lineHeight, */}
                        {/*    textAlign: 'left', */}
                        {/*  }} */}
                        {/*  // onClick={() => router.push('/service')} */}
                        {/* > */}
                        {/*  <span */}
                        {/*    dangerouslySetInnerHTML={{ */}
                        {/*      __html: item?.title, */}
                        {/*    }} */}
                        {/*  /> */}
                        {/* </Typography> */}
                        <Typography sx={{ color: 'white' }}>
                          {item?.title}
                        </Typography>
                        <Typography sx={{ color: 'white' }}>
                          {item?.timing}
                        </Typography>
                        {/* )} */}
                      </>
                    )}
                  </>
                  {/* )} */}
                </Stack>
              ) : (
                <Stack
                  className={item.contentClass}
                  gap={1}
                  width={1}
                  sx={{
                    position: 'absolute',
                    p: 3,
                    top: 0,
                    left: 0,
                    height: 1,
                    justifyContent: 'flex-end',
                  }}
                >
                  {/* {item.enableTitle && ( */}
                  {/* <Typography */}
                  {/*  className={item.typographyClass} */}
                  {/*  sx={{ */}
                  {/*    width: '100%', */}
                  {/*    '& h1': { my: 1 }, */}
                  {/*    '& h2': { my: 1 }, */}
                  {/*    '& h3': { my: 1 }, */}
                  {/*    '& h4': { my: 1 }, */}
                  {/*    '& h5': { my: 1 }, */}
                  {/*    '& h6': { my: 1 }, */}
                  {/*    '& p': { margin: 0 }, */}
                  {/*    direction: currentLang.value === 'he' ? 'ltr' : 'rtl', */}
                  {/*    lineHeight: item.lineHeight, */}
                  {/*  }} */}
                  {/*  // onClick={() => router.push('/service')} */}
                  {/* > */}
                  {/*  <span dangerouslySetInnerHTML={{ __html: item?.title }} /> */}
                  {/* </Typography> */}
                  <Typography sx={{ color: 'white', fontWeight: 800 }}>
                    {item?.title}
                  </Typography>
                  <Typography sx={{ color: 'white', fontWeight: 800 }}>
                    {item?.timing}
                  </Typography>
                  {/* )} */}
                </Stack>
              )}
            </Card>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};
