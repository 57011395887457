'use client';

import NextLink from 'next/link';
import { m } from 'framer-motion';

// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from '@mui/material/Paper';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { useTranslate } from 'src/locales';
import { useAppContext } from 'src/contexts/AppContext';
// assets
import { OrderCompleteIllustration } from 'src/assets/illustrations';
import { uploadProductsImage } from 'src/helpers/uploadProductsImage';

// components
import Iconify from 'src/components/iconify';

// types
import { IIssuedCardType } from 'src/types/order';

import Image from '../../components/image';
import { CartInterface } from '../../types/cart';
import { fCurrency } from '../../utils/format-number';
import { BlockType } from '../../types/page-generator';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  onReset: VoidFunction;
  onDownloadPDF: VoidFunction;
  block?: BlockType | any;
  basketData?: CartInterface[];
}

export default function CheckoutOrderComplete({
  open,
  onReset,
  onDownloadPDF,
  block,
  basketData,
}: Props) {
  const { t } = useTranslate();
  const { state } = useAppContext();
  const {
    logo,
    mainTitle,
    subTitle,
    firstLineAfterOrderNumber,
    secondLineAfterOrderNumber,
    ticketsTitle,
  } = block || {};
  const renderContent = state.newOrder ? (
    <Stack
      spacing={5}
      sx={{
        m: 'auto',
        maxWidth: 480,
        textAlign: 'center',
        px: { xs: 2, sm: 0 },
        py: 3,
      }}
    >
      <Typography variant="h4">{mainTitle}</Typography>
      {logo ? (
        <Image src={logo} sx={{ height: 260 }} />
      ) : (
        <OrderCompleteIllustration sx={{ height: 260 }} />
      )}
      <Stack spacing={2}>
        <Typography>{subTitle}</Typography>
        <Link>{state.newOrder.orderNumber}</Link>
        <Typography>You have successfully paid</Typography>
        {basketData &&
          basketData?.map((item, id) => (
            <Stack direction="row" gap={1} key={id}>
              <Typography sx={{ fontWeight: 800 }}>{item.product.title}</Typography>
              <Typography>{fCurrency(item.price)}</Typography>
              <Stack direction="row" gap={1}>
                {item?.attributeValues &&
                  item?.attributeValues.map((attribute, idx) => (
                    <Stack direction="row" key={idx} gap={1}>
                      <Typography sx={{ fontWeight: 800 }}>Attribute:</Typography>
                      <Typography key={idx}>{attribute.value}</Typography>
                    </Stack>
                  ))}
              </Stack>
            </Stack>
          ))}
        <Typography>
          {firstLineAfterOrderNumber}
          <br /> {secondLineAfterOrderNumber}
        </Typography>
      </Stack>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Stack>
        <Typography>{ticketsTitle}</Typography>
        {state.newOrder?.orderItems &&
          state.newOrder?.orderItems.map((item) => (
            <Stack key={item.id} gap={1}>
              {!!item.cards.length &&
                item.cards.map((card) => (
                  <Card sx={{ m: 2, p: 2 }} key={card.id}>
                    {item.product.mainImageURL && (
                      <CardMedia
                        component="img"
                        height="150"
                        image={uploadProductsImage(item.product.mainImageURL)}
                      />
                    )}

                    <Typography>
                      <strong>{t('Product')}:</strong> {item.product.title}
                    </Typography>
                    <Typography>
                      <strong>{t('Card number')}:</strong> {card.cardNumber}
                    </Typography>
                    {card.type === IIssuedCardType.LOAD && (
                      <Typography>
                        <strong>{t('Balance')}:</strong> {card.balance || ''}
                      </Typography>
                    )}
                  </Card>
                ))}
            </Stack>
          ))}
      </Stack>
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Stack
        spacing={2}
        justifyContent="space-between"
        direction={{ xs: 'column-reverse', sm: 'row' }}
      >
        <Button
          component={NextLink}
          href="/"
          fullWidth
          size="large"
          color="inherit"
          variant="outlined"
          onClick={onReset}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          sx={{ padding: 0 }}
        >
          {`${t('Continue Shopping')}`}
        </Button>

        <Button
          fullWidth
          size="large"
          variant="contained"
          startIcon={<Iconify icon="eva:cloud-download-fill" />}
          onClick={onDownloadPDF}
        >
          {`${t('Download as PDF')}`}
        </Button>
      </Stack>
    </Stack>
  ) : null;

  return (
    <Dialog
      fullWidth
      fullScreen
      open={open}
      PaperComponent={(props: PaperProps) => (
        <Box
          component={m.div}
          sx={{
            width: 1,
            height: 1,
            p: { md: 3 },
          }}
        >
          <Paper {...props}>{props.children}</Paper>
        </Box>
      )}
    >
      {renderContent}
    </Dialog>
  );
}
