'use client';

import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useTranslate } from 'src/locales';

import Image from '../../../../../../components/image';
import { BlockType } from '../../../../../../types/page-generator';
import { ActionTypes, useAppContext } from '../../../../../../contexts/AppContext';

export default function Present({ block }: { block: BlockType }) {
  const { t } = useTranslate();
  const { dispatch } = useAppContext();

  const { settings } = block;
  const { showPhoneNumber } = settings;
  const onClose = () => {
    dispatch({ type: ActionTypes.SET_ACTIVE_DIALOG, payload: null });
  };
  return (
    <Stack alignItems="center" gap={2}>
      <Image
        src="/assets/images/happy-gift/logo/global_blue.svg"
        width={1}
        sx={{ '& .component-image-wrapper': { width: '30%!important' } }}
      />
      <Stack alignItems="center">
        <Typography
          sx={{
            fontSize: '28px',
            fontWeight: 600,
            color: 'primary.light',
            lineHeight: 2,
            textAlign: 'start!important',
          }}
        >
          איזה כיף לך! קיבלת מתנה שווה במיוחד!
        </Typography>
        <Image src="/assets/images/happy-gift/cardholder-no-top-margin.png" sx={{ width: '80%' }} />
      </Stack>
      <Stack alignItems="start" sx={{ color: 'primary.light', lineHeight: 2 }}>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 400,
            color: 'primary.light',
            whiteSpace: 'pre-line',
            textAlign: 'center',
            lineHeight: 2,
          }}
        >
          גיפט קארד הניתן למימוש בבתי עסק ואתרי סחר בארץ ובעולם, כולל אפשרות שליחה לעובדים ברחבי
          העולם.כרטיס המתנות היחיד שעובד בכל העולם
        </Typography>
        <ul style={{ margin: 0, lineHeight: 2, fontSize: '18px' }}>
          <li>ללא הגבלה במקומות המימוש</li>
          <li>פשוט כסף שמחכה לך עם חיוך</li>
        </ul>
      </Stack>
      <Stack direction="row-reverse" gap={2} width={1} justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={() => onClose()}
          sx={{
            bgcolor: 'primary.light',
            color: 'white',
            // width: '30%',
            '&:hover': { bgcolor: 'primary.light' },
          }}
        >
          כרטיס פיזי
        </Button>
        <Button
          variant="outlined"
          sx={{
            bgcolor: 'primary.light',
            color: 'white',
            // width: '30%',
            '&:hover': { bgcolor: 'primary.light' },
          }}
          endIcon={<Image src="/assets/images/happy-gift/icons/H-home 1.svg" />}
        >
          כרטיס דיגיטלי
        </Button>
      </Stack>
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 400,
          color: 'primary.light',
          mt: 3,
          textAlign: 'start',
          width: '100%',
        }}
      >
        אנחנו כאן לשירותכם גם בטלפון 03-5106070
      </Typography>
    </Stack>
  );
}
