'use client';

import React, { FC, useRef, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Button,
  Stack,
  Link,
  Collapse,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Container,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormProvider, useForm } from 'react-hook-form';
import { RHFRadioGroup } from '../../../../components/hook-form';
import { ComponentTypeProps } from '../../../../types/page-generator';

const SearchHomeService: FC<ComponentTypeProps> = ({ block }) => {
  const [showFilter, setShowFilter] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | any>(null);
  const messagesEndRef1 = useRef<HTMLDivElement | any>(null);

  const methods = useForm({
    defaultValues: {
      addressType: '',
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <Container maxWidth="xs">
      <Box className="SearchDiv" ref={messagesEndRef}>
        <Stack
          direction="row"
          gap={1}
          className="Search"
          display="flex"
          alignItems="center"
        >
          <TextField
            placeholder="חפשי לפי שם"
            type="search"
            fullWidth
            variant="outlined"
            className="SearchInput"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon sx={{ color: 'primary.main' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Link className="locationIcon">
            <LocationOnIcon />
          </Link>
          <Button
            onClick={() => {
              setShowFilter((prev) => !prev);
            }}
            sx={{
              bgcolor: 'primary.main',
              height: '50px',
              '&:hover': { bgcolor: 'primary.main' },
              borderRadius: '5px',
            }}
          >
            <img src="/assets/images/filter/filterBtn.png" alt="Filter" />
          </Button>
        </Stack>
        <Collapse in={showFilter} sx={{
          position: 'fixed',
          bottom: '70px',
          background: 'white',
          width: '100%',
          zIndex: 100,
          ml: '-35px',
        }}>
          <Box
            className="filter-wrapper"
            id="filter_section"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              className="filter-title d-flex justify-content-between align-items-center"
            >
              <Typography variant="h4">סינון</Typography>
              <Button
                onClick={() => setShowFilter(false)}
                sx={{ color: 'grey' }}
              >
                איפוס
              </Button>
            </Stack>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">סדר לפי</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack sx={{ direction: 'ltr' }}>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <RHFRadioGroup
                        name="addressType"
                        options={[
                          { label: 'ציון', value: '1' },
                          { label: 'אקראי', value: '2' },
                        ]}
                      />
                    </form>
                  </FormProvider>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">מומחיות</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  sx={{
                    direction: 'ltr',
                    '& .MuiFormControlLabel-root': {
                      justifyContent: 'space-between',
                    },
                  }}
                >
                  <FormControlLabel control={<Checkbox />} label="תרפיה" />
                  <FormControlLabel control={<Checkbox />} label="קרדולוגיה" />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="אנדוקרינולוגיה"
                  />
                  <FormControlLabel control={<Checkbox />} label="מנתח" />
                  <FormControlLabel control={<Checkbox />} label="גינקולוג" />
                  <FormControlLabel control={<Checkbox />} label="נוירולוג" />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="דֶרמָטוֹלוֹגִיָה"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="אונוקולוגיה"
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">אזור</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  sx={{
                    direction: 'ltr',
                    maxHeight: '300px', // ограничиваем максимальную высоту
                    overflowY: 'auto', // добавляем вертикальную прокрутку
                    '& .MuiFormControlLabel-root': {
                      justifyContent: 'space-between',
                    },
                  }}
                >
                  <FormControlLabel control={<Checkbox />} label="צפון רחוק" />
                  <FormControlLabel control={<Checkbox />} label="חיפה והצפון" />
                  <FormControlLabel control={<Checkbox />} label="צפון השרון" />
                  <FormControlLabel control={<Checkbox />} label="שרון" />
                  <FormControlLabel control={<Checkbox />} label="דרום השרון" />
                  <FormControlLabel control={<Checkbox />} label="מרכז" />
                  <FormControlLabel control={<Checkbox />} label="שפלה" />
                  <FormControlLabel control={<Checkbox />} label="תל אביב" />
                  <FormControlLabel control={<Checkbox />} label="באר שבע והסביבה" />
                  <FormControlLabel control={<Checkbox />} label="דרום רחוק" />
                  <FormControlLabel control={<Checkbox />} label="ירושלים והסביבה" />
                  <FormControlLabel control={<Checkbox />} label="איו''ש" />
                  <FormControlLabel control={<Checkbox />} label="דֶרמָטוֹלוֹגִיָה" />
                  <FormControlLabel control={<Checkbox />} label="אונוקולוגיה" />
                </Stack>

              </AccordionDetails>
            </Accordion>

            <Box
              className="filter-action"
              ref={messagesEndRef1}
              display="flex"
              justifyContent="center"
              py={4}
            >
              <Button variant="contained" color="primary">
                החל
              </Button>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Container>
  );
};

export default SearchHomeService;
