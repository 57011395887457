import { LabelColor } from 'src/components/label';

const statusOptionsColorsMapById: { [key: string]: LabelColor } = {
  0: 'info',
  1: 'info',
  2: 'secondary',
  3: 'warning',
  4: 'success',
  5: 'error',
};

const statusOptionsColorsMapByName: { [key: string]: LabelColor } = {
  PENDING: 'warning',
  ACTIVE: 'success',
  REJECTED: 'error',
  NEW: 'info',
  COMPLETED: 'success',
  UNPAID: 'error',
  INVOICED: 'warning',
};

const priorityOptionsColorsMap: { [key: string]: LabelColor } = {
  high: 'error',
  low: 'success',
  medium: 'warning',
};

const transactionTypeIconsMap: { [key: string]: string } = {
  credit: 'material-symbols:arrow-upward-rounded',
  debit: 'material-symbols:arrow-downward-rounded',
  refund: 'streamline:arrow-round-left',
};

const transactionTypeColorsMap: { [key: string]: LabelColor } = {
  credit: 'success',
  debit: 'error',
  refund: 'warning',
};

type ObjectKey = keyof typeof statusOptionsColorsMapById;

export const getStatusLabelColor = (id?: number | string): LabelColor => {
  if (typeof id === 'number') {
    return statusOptionsColorsMapById[id as ObjectKey] || 'default';
  }
  if (typeof id === 'string') {
    return statusOptionsColorsMapByName[id] || 'default';
  }
  return 'default';
};

export const getPriorityColor = (priority?: string): LabelColor =>
  priority ? priorityOptionsColorsMap[priority.toLowerCase()] : 'default';

export const getTransactionTypeIcon = (type?: string) =>
  type ? transactionTypeIconsMap[type?.toLowerCase()] : '';

export const getTransactionTypeColor = (type?: string): LabelColor =>
  type ? transactionTypeColorsMap[type?.toLowerCase()] : 'default';
