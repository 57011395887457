'use client';

import { FC } from 'react';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Image from '../../../../components/image';
import { BlockType } from '../../../../types/page-generator';
import { useResponsive } from '../../../../hooks/use-responsive';

type ShopBannerBlockType = {
  TCIWI_COL1_icon?: string;
  TCIWI_COL1_title?: string;
  TCIWI_COL1_description?: string;
  TCIWI_COL1_cardImage?: string;
  TCIWI_COL2_icon?: string;
  TCIWI_COL2_title?: string;
  TCIWI_COL2_description?: string;
  TCIWI_COL2_cardImage?: string;
  TCIWI_COL3_icon?: string;
  TCIWI_COL3_title?: string;
  TCIWI_COL3_description?: string;
  TCIWI_COL3_cardImage?: string;
};
type ShopBannerProps = { block?: BlockType & ShopBannerBlockType };

const ThreeColumnsInfoWithIcons: FC<ShopBannerProps> = ({ block }) => {
  const smDown = useResponsive('down', 'sm');
  const {
    TCIWI_COL1_icon,
    TCIWI_COL1_title,
    TCIWI_COL1_description,
    TCIWI_COL1_cardImage,
    TCIWI_COL2_icon,
    TCIWI_COL2_title,
    TCIWI_COL2_description,
    TCIWI_COL2_cardImage,
    TCIWI_COL3_icon,
    TCIWI_COL3_title,
    TCIWI_COL3_description,
    TCIWI_COL3_cardImage,
  } = block || {};

  const data = [
    {
      id: 1,
      title: TCIWI_COL1_title ?? 'בטיחות',
      description:
        TCIWI_COL1_description ??
        'כסף מאובטח ושמור! כרטיס הפי גיפט מאובטח בתקני האבטחה הגבוהים ביותר ובהפקדה יתרה על תנאי אבטחה ושמירת הכסף בשבילכם.',
      icon: TCIWI_COL1_icon ?? '/assets/images/happy-gift/ic_payment_b2c.svg',
      image: TCIWI_COL1_cardImage ?? '/assets/images/happy-gift/banners/cardholder.png',
    },
    {
      id: 2,
      title: TCIWI_COL2_title ?? 'ללא גבולות',
      description:
        TCIWI_COL2_description ??
        'אפשרות למימוש בכל העולם! הפי גיפט אינו מגביל אתכם במימוש ההטבה בארץ ומאפשר מימוש בכל העולם ככרטיס אוניברסלי.',
      icon: TCIWI_COL2_icon ?? '/assets/images/happy-gift/ic_assurances_b2c.svg',
      image: TCIWI_COL2_cardImage ?? '/assets/images/happy-gift/banners/cardholder.png',
    },
    {
      id: 3,
      title: TCIWI_COL3_title ?? 'כל בתי עסק',
      description:
        TCIWI_COL3_description ??
        'אין הגבלת בתי עסק למימוש! עם הפי גיפט תוכלו לרכוש ולממש את המתנה בכל בית עסק שמכבד כרטיסי מאסטר קארד. בכל מקום באונליין ובחנויות.',
      icon: TCIWI_COL3_icon ?? '/assets/images/happy-gift/ic_refund_b2c.svg',
      image: TCIWI_COL3_cardImage ?? '/assets/images/happy-gift/banners/cardholder.png',
    },
  ];

  return (
    <Grid container spacing={1}>
      {data.map((item, id) => (
        <Grid key={id} item md={4} xs={12} alignItems="center">
          <Box width="100%" sx={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={item.image} sx={{ width: { md: '50%', xs: '25%' } }} />
          </Box>
          <Stack
            alignItems="center"
            ml={1}
            sx={
              !smDown
                ? {
                    borderLeft: id !== 0 ? '1px solid' : null,
                    borderColor: 'primary.main',
                  }
                : {}
            }
          >
            <Image src={item.icon} sx={{ width: { md: '20%', xs: '10%' } }} />
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: { md: '30px', xs: '15px' },
                fontWeight: 600,
                my: { md: 2 },
              }}
            >
              {item.title}
            </Typography>
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: { md: '15px', xs: '12px' },
                fontWeight: 400,
                width: '76%',
                textAlign: 'center',
              }}
            >
              {item.description}
            </Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default ThreeColumnsInfoWithIcons;
